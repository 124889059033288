import React from 'react';
import get from 'lodash/get';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE,
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR,
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE,
  ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_ADMIN_CONFIRM_DROP_OFF,
  TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_DISPUTE,
  TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  txIsBeingDropOff,
  txIWaitingRequestDropoff,
} from '../../util/transaction';
import {
  TRANSITION_LONG_TERM_LAST_ADMIN_TRANSIT_TRIP_END,
  TRANSITION_LTL_CONFIRM_DROP_OFF,
  TRANSITION_LTL_REQUEST_DROP_OFF,
  TRANSITION_LTL_TRIP_END,
  txIWaitingRequestDropoffLTL,
} from '../../util/transactionLongTermLast';
import { VERIFICATION_STATUS_VERIFIED } from '../../util/types';
import { PrimaryButton } from '../../components';
import css from './TransactionPanel.css';
import { displayDateForUser } from '../../util/dates';
import { getTransitErrorLabelId } from './transitionHelper';
import { txLastTransition } from '../../util/transactionLongTermFirst';

export const dropoffTransitions = [
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_ADMIN_CONFIRM_DROP_OFF,
  TRANSITION_LTL_REQUEST_DROP_OFF,
  TRANSITION_LTL_CONFIRM_DROP_OFF,
];
export const LONG_TERM_STATUS_FINISH = 'FINISH';

export const checkDropOffTimeAfterPickUpOneHour = transaction => {
  const transitions = get(transaction, 'attributes.transitions', []);
  const pickUpTransition = transitions.find(transition =>
    [TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE, TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED].includes(
      transition.transition
    )
  );

  const createdAt = pickUpTransition.createdAt;

  let dateShowDropOff = new Date(createdAt);
  dateShowDropOff = dateShowDropOff.setMinutes(createdAt.getMinutes() + 60);
  const timeToShowDropOff = new Date(dateShowDropOff);

  const isTimeToShowTransitionDropoff = new Date() > timeToShowDropOff;
  return isTimeToShowTransitionDropoff;
};

// Functional component as a helper to build ActionButtons for
// provider/customer

export const checkTimeToShowDropOff = transaction => {
  const isHourlyBooking = get(transaction, 'attributes.protectedData.hourlyBooking');
  const displayEnd = get(transaction, 'booking.attributes.displayEnd');

  const dislayEndDateForUser = new Date(displayDateForUser(displayEnd));

  const dateShowDropOff = dislayEndDateForUser.setMinutes(dislayEndDateForUser.getMinutes() - 60);
  // const dateShowDropOff = isHourlyBooking ?
  //   dislayEndDateForUser.setMinutes(dislayEndDateForUser.getMinutes() - 15) :
  //   dislayEndDateForUser.setMinutes(dislayEndDateForUser.getMinutes() - 30)
  const timeToShowDropOff = new Date(dateShowDropOff);
  const isTimeToShowTransitionDropoff = new Date() >= timeToShowDropOff;
  // return isTimeToShowTransitionDropoff;
  return true;
};

const getTransitions = transaction => {
  const lastTransition = txLastTransition(transaction);
  switch (lastTransition) {
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: [TRANSITION_REQUEST_DROP_OFF],
      };
    case TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: [TRANSITION_REQUEST_DROP_OFF],
      };
    case TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: [TRANSITION_CONFIRM_DROP_OFF, TRANSITION_DISPUTE],
      };
    case TRANSITION_REQUEST_DROP_OFF:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: [TRANSITION_CONFIRM_DROP_OFF, TRANSITION_DISPUTE],
      };
    case TRANSITION_LTL_TRIP_END:
    case TRANSITION_LONG_TERM_LAST_ADMIN_TRANSIT_TRIP_END:
      return {
        [TX_TRANSITION_ACTOR_CUSTOMER]: [TRANSITION_LTL_REQUEST_DROP_OFF],
      };
    case TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: [TRANSITION_CONFIRM_DROP_OFF, TRANSITION_DISPUTE],
      };
    case TRANSITION_LTL_REQUEST_DROP_OFF:
      return {
        [TX_TRANSITION_ACTOR_PROVIDER]: [TRANSITION_LTL_CONFIRM_DROP_OFF],
      };
    default:
      return {};
  }
};

const transitionRequiredUploadPhotos = [
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_LTL_REQUEST_DROP_OFF,
];

export const LockUnlockButtonMaybe = props => {
  const {
    className,
    rootClassName,
    canShowButtons,
    transaction: rawTransaction,
    transitInProgress,
    transitError,
    onTransit,
    transitionNames,
    ownListing,
    onPushEventGTM,
    inputOdometerInvalid,
    inputNumberOdometer,
    isLongTerm,
    currentUserRole,
    currentUser
  } = props;

  const transaction = isLongTerm
    ? rawTransaction && rawTransaction.currentChildTransaction
    : rawTransaction;

  const lastTransition = txLastTransition(transaction);
  const isDropoff = transitionNames.includes(TRANSITION_REQUEST_DROP_OFF) ||
  (lastTransition == TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE)
  || lastTransition === TRANSITION_REQUEST_DROP_OFF
  || lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE
  || lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR
  || lastTransition === TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU
  || lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE
  || lastTransition ===  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED
  || lastTransition === TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE
  || lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE
  || lastTransition === TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE;
  const isHost = currentUser.id.uuid === transaction.provider.id.uuid;

  const isDrivelahGo = (transaction.listing
    && transaction.listing.attributes.metadata &&
      transaction.listing.attributes.metadata.isDrivelahGo);
  const transitionsMaybe = get(getTransitions(transaction), currentUserRole, []);

  if(!isDrivelahGo) {
    return null;
  }

  if (!isDropoff || isHost) {
    return null;
  }


  const longTermStatus = get(rawTransaction, 'attributes.metadata.longTermStatus');
  if (!transaction || (longTermStatus !== LONG_TERM_STATUS_FINISH && isLongTerm)) return null;
  const validTransaction = transaction && transaction.customer;
  const allowUserTransit =
    validTransaction &&
    (transaction.customer.attributes.profile.publicData.guestIdentityVerificationStatus ===
      VERIFICATION_STATUS_VERIFIED ||
      transaction.customer.attributes.profile.publicData.hostIdentityVerificationStatus ===
        VERIFICATION_STATUS_VERIFIED);

  const guestIdentityIsNotVerify = !allowUserTransit ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.guestIdentityIsNotVerify" />
    </p>
  ) : null;
  const transitErrorMessage = transitError ? (
    <p className={css.actionError}>
      <FormattedMessage id={getTransitErrorLabelId(transitionsMaybe[0])} />
    </p>
  ) : null;

  // const isDropoff = transitionNames.includes(TRANSITION_REQUEST_DROP_OFF);
  // const isNotTimeToShowTransitionDropoff =
  //   !isDropoff ? false : timeToShowDropOff > new Date();



  // if(!isTimeToShowTransitionDropoff) {
  //   return null;
  // }

  const classes = classNames(rootClassName || css.actionButtons, className);


  const providerTripPhoto = txIsBeingDropOff(transaction);
  const allowTripPhotos =
    txIWaitingRequestDropoff(transaction) || txIWaitingRequestDropoffLTL(transaction);

  const emptyPhoto = allowTripPhotos && props.tripPhotos && props.tripPhotos.length === 0;

  const handlePrimaryButtonClick = (transitAllow, transaction, ownListing, lock) => {
    if (inputOdometerInvalid) return;
    const dataObject = { dropOffOdometer: inputNumberOdometer, currentTransaction: transaction };
    // onPushEventGTM(transaction, transition);
    const isLockCar = lock;
    if(lock) {
      onTransit(
        transaction.id,
        transitionNames[0],
        dataObject,
        providerTripPhoto
          ? [...props.tripPhotos, ...props.allTripPhotos]
          : null,
        null,
        null,
        null,
        isLockCar
      );
    }
    else {
      const isUnlockCar = true;
      onTransit(
        transaction.id,
        transitionNames[0],
        dataObject,
        providerTripPhoto
          ? [...props.tripPhotos, ...props.allTripPhotos]
          : null,
        null,
        null,
        null,
        null,
        isUnlockCar
      );

    }
  };

  return (
    <div className={classes}>
      <div className={css.actionErrors}>
        {guestIdentityIsNotVerify}
        {transitErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        <PrimaryButton
          // id={'actionTransitButton'}
          inProgress={transitInProgress}
          disabled={transitInProgress || !allowUserTransit }
          // name={TRIP_PRIMARY_BUTTON_TRANSIT_NAME}
          onClick={() => {
            handlePrimaryButtonClick(transaction, transaction, ownListing, true);
          }}
        >
          <FormattedMessage id="TransactionPanel.lock" />
        </PrimaryButton>
        <PrimaryButton
          // id={'actionTransitButton'}
          inProgress={transitInProgress}
          disabled={transitInProgress || !allowUserTransit }
          // name={TRIP_PRIMARY_BUTTON_TRANSIT_NAME}
          onClick={() => {
            handlePrimaryButtonClick(transaction, transaction, ownListing, false);
          }}
        >
          <FormattedMessage id="TransactionPanel.unlock" />
        </PrimaryButton>
        <PrimaryButton
          // id={'actionTransitButton'}
          // inProgress={transitInProgress}
          // disabled={transitInProgress || !allowUserTransit }
          // name={TRIP_PRIMARY_BUTTON_TRANSIT_NAME}
          // onClick={() => {
          //   handlePrimaryButtonClick(transaction, transaction, ownListing, false);
          // }}
        >
          <FormattedMessage id="TransactionPanel.locate" />
        </PrimaryButton>
      </div>

    </div>
  );
};
