import React from 'react';
import styles from './Stepper.module.css'; // Import the CSS module
import checkMark from '../../assets/checkMark.svg'

const Stepper = ({ steps, currentStep }) => {

  const progressWidth = ((currentStep) / (steps.length - 1)) * 100;

  const checkMarkImage = <img src={checkMark} alt='check mark icon' />

  return (
    <>
      <div className={styles.stepperHeader}>
        <div className={styles.stepperProgress}></div>
        <div className={styles.stepperProgressActive} style={{ width: `${progressWidth}%` }}></div>
        {steps.map((step, index) => (
          <div
            key={index}
            className={`${styles.step} ${index === currentStep ? styles.active : ''} ${index < currentStep ? styles.completed : ''}`}
          >
            {index < currentStep ? checkMarkImage : index + 1}
          </div>
        ))}
      </div>
    </>
  );
};

export default Stepper;
