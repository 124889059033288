import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { VERIFICATION_STATUS_VERIFIED } from '../../util/types';
import { PrimaryButton, SecondaryButton } from '../../components';
import { checkIsOldCar } from '../../util/dates';
import { getTransitButtonLabelId } from './transitionHelper';
import {
  isNeedCustomerDropOffAction,
  isNeedCustomerPickUpAction,
  isNeedProviderDropOffAction,
  isNeedProviderPickUpAction,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  txIWaitingConfirmPickup,
} from '../../util/transaction';

import css from './TripPanel.css';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import {
  EVENT_CONVERSION_BOOKING_DECLINED,
  EVENT_CONVERSION_BOOKING_REQUEST_ACCEPTED,
} from '../../util/conversions/conversionsConstants';
import TripInstructionsSlider from './TripInstructionsSlider';
import { resetSteps } from "../../components/PickAndDropPanel/ProgressBar/ProgressBar.duck"
import { useDispatch } from 'react-redux';
const API_URL = `${process.env.REACT_APP_API_SERVER_URL}/api/pdf/fill-insurance-form`;

const fillInsuranceForm = ({
  userId,
  listingId,
  guestName,
  pickUpDate,
  dropOffDate,
  plateNumber,
  chasisNumber,
  isOldCar,
  transactionId,
}) => {
  return fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      listingId,
      guestName,
      pickUpDate,
      dropOffDate,
      plateNumber,
      chasisNumber,
      isOldCar,
      transactionId,
    }),
  });
};

const ButtonsSection = ({
  declineInProgress,
  declineButtonName,
  transaction,
  acceptInProgress,
  acceptSaleError,
  declineSaleError,
  acceptButtonName,
  currentUser,
  onDeclineSale,
  declineSaleGTM,
  onAcceptSale,
  acceptSaleGTM,
  currentPhotos,
  activePhotoSection,
  exteriorPhotos,
  selfieWithId,
  interiorPhotos,
  odometerPhotos,
  fuelGaugePhotos,
  pickupProviderPhoto,
  fuelReceiptPhotos,
  transitionPickUpMaybe,
  transitionMaybe,
  onTransit,
  showSaleButtons,
  isShuEnabledCar,
  showPickUpSection,
  transitInProgress,
  allowUserTransit,
  emptyPhoto,
  inputOdometerInvalid,
  isCustomer,
  isProvider,
  allTripPhotos,
  showDropOffSection,
  isPickUp,
  isDropOff,
  isDrivelahGo,
  onPushEventGTMPickUpDropOff,
  ownListing,
  onUploadInteriorPhotoToMetadata,
  interiorPhotoDlGo,
  uploadInteriorPhotoProgress,
  uploadInteriorSuccess,
  isPickupRequested,
  shouldShowPickUpSection,
  shouldShowDropOffSection,
  distanceFromPickUp,
  showStartTripStep,
  showStartTripButton,
  isNewPickUpDropSection,
  cleanlinessScore,
  isLimitedUser,
  transitionAdminMaybe,
  setShowViewDetails,
  showViewDetails,
  loading = false,
  showEndTrip
}) => {
  const buttonsDisabled = acceptInProgress || declineInProgress;
  const customerPickUpAction = showPickUpSection && isCustomer && isNeedCustomerPickUpAction(transaction);
  const customerDropOffAction = showDropOffSection && isCustomer && isNeedCustomerDropOffAction(transaction);
  const providerPickUpAction = showPickUpSection && isProvider && isNeedProviderPickUpAction(transaction);
  const providerDropOffAction = showDropOffSection && isProvider && isNeedProviderDropOffAction(transaction);
  const customerPickUpInteriorAction = showPickUpSection && isCustomer && isDrivelahGo && isNeedCustomerDropOffAction(transaction);

  const dispatch = useDispatch()
  const validTransaction = transaction && transaction.customer;
  const acceptAllow =
    validTransaction &&
    (transaction.customer.attributes.profile.publicData.guestIdentityVerificationStatus ===
      VERIFICATION_STATUS_VERIFIED ||
      transaction.customer.attributes.profile.publicData.hostIdentityVerificationStatus ===
        VERIFICATION_STATUS_VERIFIED);

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;
  const guestIdentityIsNotVerify = !acceptAllow ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.guestIdentityIsNotVerify" />
    </p>
  ) : null;

  const numberActivePhotoSection =
    activePhotoSection && activePhotoSection.filter(i => i === true).length;
  const isActivePhotoSection = isCustomer ? numberActivePhotoSection > 0 : true;
  const allCurrentTripPhotos = [...currentPhotos, ...allTripPhotos];
  const currentExteriorPhotos = allCurrentTripPhotos.filter(p => p.type === 'exteriorPhotos');
  const currentInteriorPhotos = allCurrentTripPhotos.filter(p => p.type === 'interiorPhotos');
  const currentOdometerPhotos = allCurrentTripPhotos.filter(p => p.type === 'odometerPhotos');
  const currentFuelGaugePhotos = allCurrentTripPhotos.filter(p => p.type === 'fuelGaugePhotos');
  const currentSelfieWithId = allCurrentTripPhotos.filter(p => p.type === 'selfieWithId');
  const currentFuelReceiptPhotos = allCurrentTripPhotos.filter((p) => p.type === 'fuelReceiptPhotos')

  console.log("allCurrentTripPhotos", allCurrentTripPhotos)
  let validPhotoSection = false;
  if(!isDrivelahGo && !isNewPickUpDropSection
    && (currentExteriorPhotos && currentExteriorPhotos.length >= 4)
    && (currentInteriorPhotos && currentInteriorPhotos.length >=1)
    && (currentOdometerPhotos && currentOdometerPhotos.length >=1)
    && (currentFuelGaugePhotos && currentFuelGaugePhotos.length >=1)
  ) {
    validPhotoSection = true;
  }

  //based on new design
  if(!isDrivelahGo && isNewPickUpDropSection
    && (currentExteriorPhotos && currentExteriorPhotos.length >= 4)
    && (currentOdometerPhotos && currentOdometerPhotos.length >=1)
    && (currentFuelGaugePhotos && currentFuelGaugePhotos.length >=1)
  ) {
    validPhotoSection = true;
  }


  if(isDrivelahGo && isPickUp && !isNewPickUpDropSection
    && (currentExteriorPhotos && currentExteriorPhotos.length >= 4)
    && (currentSelfieWithId && currentSelfieWithId.length >=1)) {
    validPhotoSection = true;
  }

  //based on new design
  if(isDrivelahGo && isPickUp && isNewPickUpDropSection
    && (currentExteriorPhotos && currentExteriorPhotos.length >= 4)) {
    validPhotoSection = true;
  }

  if(isDrivelahGo && isPickupRequested
    && (currentOdometerPhotos && currentOdometerPhotos.length >=1)
    && (currentFuelGaugePhotos && currentFuelGaugePhotos.length >=1)
  ) {
    validPhotoSection = true;
  }

  if(isLimitedUser && isDrivelahGo && isPickUp && !isNewPickUpDropSection && currentExteriorPhotos && currentExteriorPhotos.length >= 4) {
    validPhotoSection = true;
  }

  if (
    isDrivelahGo &&
    isDropOff &&
    currentExteriorPhotos && currentExteriorPhotos.length >= 4 &&
    currentInteriorPhotos && currentInteriorPhotos.length >= 1 &&
    currentOdometerPhotos && currentOdometerPhotos.length >= 1 &&
    currentFuelGaugePhotos && currentFuelGaugePhotos.length >= 1
  ) {
    validPhotoSection = true;
  }

  if (
    isDrivelahGo &&
    showPickUpSection &&
    isDropOff &&
    currentInteriorPhotos && currentInteriorPhotos.length >= 1 &&
    currentOdometerPhotos && currentOdometerPhotos.length >= 1 &&
    currentFuelGaugePhotos && currentFuelGaugePhotos.length >= 1
  ) {
    validPhotoSection = true;
  }

  const isHost = currentUser.id.uuid === transaction.provider.id.uuid;

  const isEligbleForStartCar =
    isShuEnabledCar &&
    (transitionMaybe === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE) ;

  let isStartCar = isHost && isEligbleForStartCar;

  if(isShuEnabledCar && !isHost && (transitionMaybe == TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE ||
    transitionMaybe == TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED)) {
      isStartCar = true;
  }

  const isEligibleForPushConfig =
    isShuEnabledCar &&
    (transitionMaybe == TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE ||
      transitionMaybe == TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED ||
      transitionMaybe == TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE ||
      transitionMaybe == TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED);
  const isShuCar = !isHost && isEligibleForPushConfig;
  const cleanlinessScoreData = cleanlinessScore || [];

  let isStopCar = ((TRANSITION_CONFIRM_DROP_OFF == transitionMaybe ) && isShuEnabledCar && isHost);

  if(isShuEnabledCar && !isHost && TRANSITION_REQUEST_DROP_OFF_DLGO == transitionMaybe) {
    isStopCar = true;
  }

  const   handlePrimaryButtonClick = (transaction, ownListing) => {
    console.log("Is start car", isStartCar)
    console.log("Is SHU PUSH CAR", isShuCar)

    const dataObject = {};
    onPushEventGTMPickUpDropOff(transaction, transitionMaybe);

    const getPreviousPhotos = (transaction) => {
      return transaction &&
      transaction.attributes &&
      transaction.attributes.protectedData &&
      transaction.attributes.protectedData.photoObjects  || []
    }

    const previousPhotos = getPreviousPhotos(transaction)
    const getTypePhotos = (type) => {
      return (previousPhotos || []).find((photos) => {
        return (photos && photos.length && photos[0][type] )
      }) || []
    }

    const allPhotoKeys = ['exteriorPhotos', 'interiorPhotos', 'odometerPhotos', 'fuelGaugePhotos', 'pickupProviderPhoto', 'fuelReceiptPhotos']

    const sections = isProvider
      ? ['providerPickup']
      : isDrivelahGo && isPickUp
      ? ['exterior', 'selfieWithId', 'interior', 'odometer', 'fuel']
      : ['exterior', 'interior', 'odometer', 'fuel'];

    if (!isProvider && !isPickUp){
      sections.push('fuelReceipt');
    }

    let currentPhotos = [];
    activePhotoSection.map((section, index) => {
      if (section === true) {
        if (sections[index] === 'exterior') {
          currentPhotos = [...currentPhotos, exteriorPhotos];
        }
        if (sections[index] === 'selfieWithId') {
          currentPhotos = [...currentPhotos, selfieWithId];
        }
        if (sections[index] === 'interior') {
          currentPhotos = [...currentPhotos, interiorPhotos];
        }
        if (sections[index] === 'odometer') {
          currentPhotos = [...currentPhotos, odometerPhotos];
        }
        if (sections[index] === 'fuel') {
          currentPhotos = [...currentPhotos, fuelGaugePhotos];
        }
        if (sections[index] === 'providerPickup') {
          currentPhotos = [...currentPhotos, pickupProviderPhoto];
        }
        if (sections[index] === 'fuelReceipt'){
          currentPhotos = [...currentPhotos, fuelReceiptPhotos]
        }
      }
    });

    // const allPhotos = currentPhotos.length > 0 ? currentPhotos : allTripPhotos;
    // let allPhotosDropOff = isDropOff
    //   ? isCustomer
    //     ? [...allTripPhotos, ...allPhotos]
    //     : allPhotos
    //   : allPhotos;
  
    const combinedPhotos = allPhotoKeys.map((photoKey) => {
        const previous =  getTypePhotos(photoKey);
        const current = currentPhotos.find((record) => record && record[0] && record[0][photoKey]) || []
        return [...previous , ...current]
    }).filter((record) => Boolean(record) && Boolean(record.length));
    
    const allPhotosDropOff = combinedPhotos;
    if (
      txIWaitingConfirmPickup(transaction) &&
      (transitionMaybe === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE ||
      transitionMaybe === TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE ||
      transitionMaybe === TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED ) &&
      ownListing &&
      ownListing.attributes.publicData.insurance === 'private'
    ) {
      const { provider, customer, booking } = transaction;
       fillInsuranceForm({
        userId: provider.id.uuid,
        listingId: ownListing.id.uuid,
        guestName: customer.attributes.profile.displayName,
        pickUpDate: moment(booking.attributes.start).format('DD/MM/YYYY'),
        dropOffDate: moment(booking.attributes.end).format('DD/MM/YYYY'),
        plateNumber: ownListing.attributes.publicData.license_plate_number,
        chasisNumber: ownListing.attributes.privateData.chasisNumber,
        isOldCar: checkIsOldCar(ownListing),
        transactionId: transaction.id.uuid,
      })
        .then(res => res.json())
        .then(data => 
          {
          onTransit(
            transaction.id,
            isLimitedUser && transitionAdminMaybe ? transitionAdminMaybe: transitionMaybe,
            dataObject,
            [...allPhotosDropOff],
            data,
            isShuCar,
            isStartCar,
            null,
            null,
            null,
            null,
            isStopCar,
            cleanlinessScoreData
            ).then(data => {
              shouldShowPickUpSection(false);
              shouldShowDropOffSection(false);
            });
        }
      );
    } else {
         onTransit(
          transaction.id,
          transitionMaybe,
          dataObject,
          [...allPhotosDropOff],
          null,
          isShuCar,
          isStartCar,
          null,
          null,
          null,
          null,
          isStopCar,
          cleanlinessScoreData
        ).then(data => {
          shouldShowPickUpSection(false);
          shouldShowDropOffSection(false);
        });
    }
    
  };
  return (
    <div className={`${css.buttonSectionContainer} ${isCustomer ? css.startTripBoxWrapper : ''}`} >
      <div className={`${css.buttonSectionInner} ${css.adjustButtonPadding}`}>
        {isProvider && showSaleButtons ? (
          <div className={css.buttonSectionText}>
            <span>
              Declining too many bookings can have a negative impact on your positioning on search.
            </span>
          </div>
        ) : null}

        {/* <div className={css.buttonSectionText}>
            {guestIdentityIsNotVerify}
            {acceptErrorMessage}
            {declineErrorMessage}
          </div> */}
        {isProvider && showSaleButtons ? (
          <div className={css.buttonSectionWrapper}>
            <PrimaryButton
              className={css.buttonDisabled}
              inProgress={declineInProgress}
              disabled={buttonsDisabled}
              id={declineButtonName}
              name={declineButtonName}
              onClick={() => {
                onDeclineSale(transaction.id);
                const properties = createRawPropertiesForGTM({ props: currentUser });
                createConversionEvents(properties, EVENT_CONVERSION_BOOKING_DECLINED, 'click');
                declineSaleGTM();
              }}
            >
              <FormattedMessage id="TransactionPanel.declineButton" />
            </PrimaryButton>
            <SecondaryButton
              inProgress={acceptInProgress}
              disabled={buttonsDisabled || !acceptAllow}
              className={css.buttonAccepted}
              id={acceptButtonName}
              name={acceptButtonName}
              onClick={() => {
                if (acceptAllow) {
                  onAcceptSale(transaction.id, currentUser);
                  const properties = createRawPropertiesForGTM({ props: currentUser });
                  createConversionEvents(properties, EVENT_CONVERSION_BOOKING_REQUEST_ACCEPTED, 'click');
                  acceptSaleGTM();
                }
              }}
            >
              <FormattedMessage id="TransactionPanel.acceptButton" />
            </SecondaryButton>
          </div>
        ) : null}

        {customerPickUpInteriorAction &&
        !(interiorPhotoDlGo.length > 0) &&
        !uploadInteriorSuccess ? (
          <div className={css.buttonSectionWrapper}>
            <PrimaryButton
              className={css.buttonAccepted}
              inProgress={uploadInteriorPhotoProgress}
              disabled={transitInProgress || !validPhotoSection || uploadInteriorPhotoProgress}
              id={declineButtonName}
              name={declineButtonName}
              onClick={() => {
                const sections = isProvider
                  ? ['providerPickup']
                  : ['exterior', 'selfieWithId', 'interior', 'odometer', 'fuel'];
                let currentPhotos = [];
                activePhotoSection.map((section, index) => {
                  if (section === true) {
                    if (sections[index] === 'interior') {
                      currentPhotos = [...currentPhotos, interiorPhotos];
                    }
                    if (sections[index] === 'odometer') {
                      currentPhotos = [...currentPhotos, odometerPhotos];
                    }
                    if (sections[index] === 'fuel') {
                      currentPhotos = [...currentPhotos, fuelGaugePhotos];
                    }
                  }
                });
                onUploadInteriorPhotoToMetadata(transaction.id, [...currentPhotos]);
                shouldShowPickUpSection(false);
                shouldShowDropOffSection(false);
              }}
            >
              <FormattedMessage id="TransactionPanel.sendInteriorPhotos" />
            </PrimaryButton>
          </div>
        ) : null}

        { (customerPickUpAction || customerDropOffAction) && !isNewPickUpDropSection ? (
          <div className={ `${css.buttonSectionWrapper} ${ isLimitedUser ? css.isLimitedUserWrapper : '' }`}>
            <PrimaryButton
              id={'submitButtonId'}
              className={css.buttonAccepted}
              type="submit"
              inProgress={transitInProgress}
              disabled={transitInProgress || !validPhotoSection}
              onClick={() => {
                handlePrimaryButtonClick(transaction, ownListing);
              }}
            >
              <FormattedMessage id={isLimitedUser && transitionAdminMaybe ? getTransitButtonLabelId(transitionAdminMaybe) : getTransitButtonLabelId(transitionMaybe)} />
            </PrimaryButton>
          </div>
        ) : null}

        { (providerPickUpAction || providerDropOffAction) ? (
          <div className={css.buttonSectionWrapper}>
            <PrimaryButton
              id={'submitButtonId'}
              className={css.buttonAccepted}
              type="submit"
              inProgress={transitInProgress}
              disabled={transitInProgress || !isActivePhotoSection}
              onClick={() => {
                handlePrimaryButtonClick(transaction, ownListing);
              }}
            >
              <FormattedMessage id={isLimitedUser && transitionAdminMaybe ? getTransitButtonLabelId(transitionAdminMaybe) : getTransitButtonLabelId(transitionMaybe)} />
            </PrimaryButton>
          </div>
        ) : null}

       { showViewDetails || isCustomer && showStartTripButton ? (
          <>
          <div className={`${css.buttonSectionWrapper} ${css.footerContainerWrapper}`}>
           {!showViewDetails ? <div className={css.tripSliderWrapper}>
              <TripInstructionsSlider currentUser={currentUser} />
            </div> : <div className={css.tripSliderWrapper}>
            </div>}
            <div>
            <PrimaryButton
              id={'submitButtonId'}
              className={`${css.buttonAccepted} ${css.footerButton} ${css.disabled}`}
              type="submit"
              inProgress={transitInProgress}
              disabled={(!isPickUp && !showEndTrip) || !showViewDetails && !(distanceFromPickUp && distanceFromPickUp.withinRange || loading)}
              onClick={() => {
                  if (showViewDetails) {
                    setShowViewDetails();
                    shouldShowPickUpSection(true);
                  } else {
                    showStartTripStep(true)
                    dispatch(resetSteps());
                  }
                }
              }
            >
              { isPickUp ? showViewDetails ? "View details" : 'Start Trip' : 'End Trip' }
            </PrimaryButton>
            </div>
          </div>
          </>
        ) : null}

        { isNewPickUpDropSection ? (
          <div className={`${css.buttonSectionWrapper} ${css.buttonAlignment}`}>
            <PrimaryButton
              id={'submitButtonId'}
              className={`${css.buttonAccepted} ${css.pickAndDropBtn}` }
              type="submit"
              inProgress={transitInProgress}
              onClick={() => {
                handlePrimaryButtonClick(transaction, ownListing);
              }}
            >
              { isPickUp ? 'Continue to trip' : 'End trip' }
            </PrimaryButton>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ButtonsSection;
