import React, { useEffect, useState } from "react"
import { FormattedDate, FormattedMessage } from 'react-intl';
import { compose } from "redux";
import IdVerification from '../../components/PickAndDropPanel/PickAndDropSteps/IdentityVerification.js';
import css from "../../components/PickAndDropPanel/index.css"
import InspectExteriorComponent from "../../components/PickAndDropPanel/PickAndDropSteps/InspectExterior.js"
import InspectInteriorComponent  from "../../components/PickAndDropPanel/PickAndDropSteps/InspectInterior.js"
import OdometerReadingComponent from "../../components/PickAndDropPanel/PickAndDropSteps/OdometerReading.js"
import get from 'lodash/get';
import { RiCloseLargeFill } from 'react-icons/ri';
import ProgressBarComponent from "../../components/PickAndDropPanel/ProgressBar/ProgressBar.js";
import { useSelector, useDispatch } from 'react-redux';
import Feedback from "../../components/PickAndDropPanel/PickAndDropSteps/Feedback.js";
import { resetSteps, setPickTotalSteps, setDropTotalSteps} from '../../components/PickAndDropPanel/ProgressBar/ProgressBar.duck.js';
import StartTripComponent from "../../components/PickAndDropPanel/PickAndDropSteps/StartPickTrip.js";
import EndTripComponent from "../../components/PickAndDropPanel/PickAndDropSteps/EndDropTrip.js"

const PickupAndDropComponent = ({
    isPickUp,
    isDropUp,
    currentUser,
    onNoteChange,
    intl,
    isCustomer,
    transaction,
    showStartTripPanel,
    setActivePhotoSection,
    activePhotoSection,
    currentPhotos,
    allTripPhotos,
    onUploadInteriorPhotoToMetadata,
    shouldShowDropOffSection,
    shouldShowPickUpSection,
    uploadInteriorPhotoProgress,
    showPickUpSection,
    isDropOff,
    onPushEventGTMPickUpDropOff,
    ownListing,
    isShuEnabledCar,
    transitInProgress,
    transitionMaybe,
    onTransit,
    isProvider,
    isDrivelahGo,
    showDropOffSection,
    isPickupRequested,
    distanceFromPickUp,
    clearCurrentPhotos,
    timeZone,
    engineStart,
    pickUpOdometer,
    dropOffOdometer,
    exteriorPhotoFun,
    isLockboxActive,
    listing,
    // lockbox ,
    findDeviceByType
    //
}
) => {
    const progressBarState = useSelector(state => state && state.ProgressBarReducer) || {};

    const { dropStep, pickStep, verificationStatus, isProgressBarEnabled } = progressBarState;

    const [interiorPhotos, setInteriorPhotos] = useState([]);
    const [odometerPhotos, setOdometerPhotos] = useState([]);
    const [fuelGaugePhotos, setFuelGaugePhotos] = useState([]);
    const [cleanlinessValue, setCleanlinessValue] = useState({});
    const [fuelReceiptPhotos, setFuelReceiptPhotos] = useState([]);
    const [exteriorPhotos, setExteriorPhotos] = useState([]);
    const dispatch = useDispatch();
    const { displayStart, displayEnd } = get(transaction, 'booking.attributes', {});
    const timeZoneMaybe = timeZone ? { timeZone } : null;
    const intialStep = isPickUp ? pickStep : dropStep;
    const [activeTab, setActiveTab] = useState(intialStep);
    const requireVerificationOnTrip = get(currentUser, 'attributes.profile.publicData.requireVerificationOnTrip');
    const onFidoCheckId =
    transaction &&
    transaction.attributes &&
    transaction.attributes.metadata &&
    transaction.attributes.metadata.onFidoCheckId;
    const onFidoFaceReport = get(currentUser, 'attributes.profile.metadata.onfidoFaceReport');
    const lockbox = get(transaction, 'listing.attributes.publicData.lockbox');

    const DROP_OFF_STEPS = 3;
    const PICKUP_WITHOUT_ID_VERIFICATION_STEPS = 4;
    const PICKUP_STEPS = 5;

    const dateFormatOptions = {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    };

    const FormattedDate =({value, dateFormatOptions, timeZoneMaybe})=> {
        return <FormattedDate value={value} {...dateFormatOptions} {...timeZoneMaybe} />
    }

    useEffect(() => {
        setActiveTab(intialStep);
        console.log(verificationStatus, "verificationStatus")
    }, [intialStep, verificationStatus]);

    const isRequiredFaceVerification = () => {
        return requireVerificationOnTrip || requireVerificationOnTrip == undefined
      }

    const isFaceVerificationInitiated = () => {
        return !!onFidoCheckId
      }

      console.log(isRequiredFaceVerification(), "isRequiredFaceVerification() && !isFaceVerificationInitiated()")

    useEffect(() => {
        if (isPickUp) {
          if (isRequiredFaceVerification() && !isFaceVerificationInitiated() && verificationStatus !== "clear" ) {
            dispatch(setPickTotalSteps(PICKUP_STEPS))
          }
          else {
            dispatch(setPickTotalSteps(PICKUP_WITHOUT_ID_VERIFICATION_STEPS))
          }
        }
        else {
            dispatch(setDropTotalSteps(DROP_OFF_STEPS))
        }
    }, []);

    const pickUpSteps = [
        isRequiredFaceVerification() && !isFaceVerificationInitiated() && verificationStatus !== "clear" ? (
        <IdVerification
            key={0}
            isPickUp={isPickUp}
            isDropUp={isDropUp}
            currentUser = {currentUser}
            transaction = {transaction}
            onTransit = {onTransit}
        />
        ) : null,
         <InspectExteriorComponent
            key={1}
            isPickUp={isPickUp}
            isDropUp={isDropUp}
            currentUser={currentUser}
            isCustomer={isCustomer}
            intl={intl}
            transaction={transaction}
            onNoteChange={onNoteChange}
            setActivePhotoSection = {setActivePhotoSection}
            setExteriorPhotos = {setExteriorPhotos}
            exteriorPhotos = {exteriorPhotos}
            exteriorPhotoFun = {exteriorPhotoFun}
        />,

        <InspectInteriorComponent
            key={2}
            isPickUp={isPickUp}
            isDropUp={isDropUp}
            currentUser={currentUser}
            isCustomer={isCustomer}
            intl={intl}
            onNoteChange={onNoteChange}
            isDrivelahGo={isDrivelahGo}
            lockbox={lockbox}
            transaction={transaction}
            setActivePhotoSection = {setActivePhotoSection}
            setFuelGaugePhotos = {setFuelGaugePhotos}
            fuelGaugePhotos = {fuelGaugePhotos}
            isLockboxActive = {isLockboxActive}
        />,

        <OdometerReadingComponent
            key={3}
            isPickUp={isPickUp}
            isDropUp={isDropUp}
            currentUser={currentUser}
            isDrivelahGo={isDrivelahGo}
            isCustomer={isCustomer}
            intl={intl}
            onNoteChange={onNoteChange}
            setActivePhotoSection = {setActivePhotoSection}
            setOdometerPhotos = {setOdometerPhotos}
            odometerPhotos = {odometerPhotos}
            pickUpOdometer={pickUpOdometer}
            dropOffOdometer={dropOffOdometer}
        />,

        <Feedback
            key={4}
            isPickUp={isPickUp}
            currentUser={currentUser}
            isCustomer={isCustomer}
            intl={intl}
            setInteriorPhotos = {setInteriorPhotos}
            setActivePhotoSection = {setActivePhotoSection}
            interiorPhotos = {interiorPhotos}
            setCleanlinessValue = { setCleanlinessValue }
            isDrivelahGo = {isDrivelahGo}
        />,

        <StartTripComponent
            key={5}
            isPickUp={isPickUp}
            currentUser={currentUser}
            isCustomer={isCustomer}
            intl={intl}
            setInteriorPhotos = {setInteriorPhotos}
            setActivePhotoSection = {setActivePhotoSection}
            interiorPhotos = {interiorPhotos}
            odometerPhotos = {odometerPhotos}
            fuelGaugePhotos = {fuelGaugePhotos}
            exteriorPhotos = {exteriorPhotos}
            transaction={transaction}
            activePhotoSection={activePhotoSection}
            currentPhotos={currentPhotos}
            allTripPhotos={allTripPhotos}
            onUploadInteriorPhotoToMetadata={onUploadInteriorPhotoToMetadata}
            shouldShowDropOffSection={shouldShowDropOffSection}
            shouldShowPickUpSection={shouldShowPickUpSection}
            uploadInteriorPhotoProgress={uploadInteriorPhotoProgress}
            transitInProgress={transitInProgress}
            showPickUpSection={showPickUpSection}
            onPushEventGTMPickUpDropOff={onPushEventGTMPickUpDropOff}
            ownListing={ownListing}
            transitionMaybe={transitionMaybe}
            isShuEnabledCar={isShuEnabledCar}
            isProvider={isProvider}
            isDrivelahGo={isDrivelahGo}
            isDropOff={isDropOff}
            onTransit={onTransit}
            distanceFromPickUp={distanceFromPickUp}
            displayTime={displayEnd}
            dateFormatOptions={dateFormatOptions}
            timeZoneMaybe={timeZoneMaybe}
            isPickupRequested={isPickupRequested}
            cleanlinessScore = {cleanlinessValue}
            isLockboxActive  = {isLockboxActive}
            listing = {listing}
            findDeviceByType = {findDeviceByType}
        />
    ]

    const pickUpstepTabs = pickUpSteps
        .filter(component => component !== null)
        .reduce((acc, component, index) => {
            if (component) {
                acc[index + 1] = component;
            }
            return acc;
    }, {});


    const dropOffSteps = {
        1: <InspectExteriorComponent
            isPickUp={isPickUp}
            isDropUp={isDropUp}
            currentUser={currentUser}
            isCustomer={isCustomer}
            intl={intl}
            onNoteChange={onNoteChange}
            setActivePhotoSection = {setActivePhotoSection}
            setExteriorPhotos = {setExteriorPhotos}
            exteriorPhotos = {exteriorPhotos}
            exteriorPhotoFun = {exteriorPhotoFun}
           />,

        2: <InspectInteriorComponent
            isPickUp={ isPickUp }
            isDropUp={isDropUp}
            currentUser={currentUser}
            isCustomer={isCustomer}
            intl={intl}
            onNoteChange={onNoteChange}
            isDrivelahGo={isDrivelahGo}
            lockbox ={lockbox}
            transaction={transaction}
            setActivePhotoSection = {setActivePhotoSection}
            setFuelGaugePhotos = {setFuelGaugePhotos}
            setFuelReceiptPhotos={setFuelReceiptPhotos}
         />,

        3: <OdometerReadingComponent
            isPickUp={ isPickUp }
            isDropUp={isDropUp}
            currentUser={currentUser}
            isCustomer={isCustomer}
            isDrivelahGo={isDrivelahGo}
            intl={intl}
            onNoteChange={onNoteChange}
            setActivePhotoSection = {setActivePhotoSection}
            setOdometerPhotos = {setOdometerPhotos}
            pickUpOdometer={pickUpOdometer}
            dropOffOdometer={dropOffOdometer}
            />,

        4: <EndTripComponent
            isPickUp={isPickUp}
            currentUser={currentUser}
            isCustomer={isCustomer}
            intl={intl}
            setInteriorPhotos = {setInteriorPhotos}
            setActivePhotoSection = {setActivePhotoSection}
            interiorPhotos = {interiorPhotos}
            odometerPhotos = {odometerPhotos}
            fuelGaugePhotos = {fuelGaugePhotos}
            exteriorPhotos = {exteriorPhotos}
            transaction = {transaction}
            activePhotoSection = {activePhotoSection}
            currentPhotos = {currentPhotos}
            allTripPhotos = {allTripPhotos}
            onUploadInteriorPhotoToMetadata={onUploadInteriorPhotoToMetadata}
            shouldShowDropOffSection = {shouldShowDropOffSection}
            shouldShowPickUpSection = { shouldShowPickUpSection }
            uploadInteriorPhotoProgress = {uploadInteriorPhotoProgress}
            transitInProgress={transitInProgress}
            showPickUpSection = {showPickUpSection}
            onPushEventGTMPickUpDropOff={onPushEventGTMPickUpDropOff}
            ownListing = {ownListing}
            transitionMaybe = {transitionMaybe}
            isShuEnabledCar = {isShuEnabledCar}
            isProvider = {isProvider}
            isDrivelahGo = {isDrivelahGo}
            isDropOff = {isDropOff}
            onTransit = {onTransit}
            showDropOffSection = {showDropOffSection}
            distanceFromPickUp = {distanceFromPickUp}
            displayTime={displayEnd}
            dateFormatOptions={dateFormatOptions}
            timeZoneMaybe={timeZoneMaybe}
            isPickupRequested = {isPickupRequested}
            isLockBox={!!lockbox}
            engineStart={engineStart}
            fuelReceiptPhotos = {fuelReceiptPhotos}
            isLockboxActive  = {isLockboxActive}
            listing = {listing}
            findDeviceByType = {findDeviceByType}
        />
    };

    const stepTabs = isPickUp ? pickUpstepTabs : dropOffSteps;

    const handleClose =()=> {
        dispatch(resetSteps());
        showStartTripPanel();
        clearCurrentPhotos();
    }

    return (
        <div>
            <div className={`${css.mt} `}>
            <div className={css.stepContainer}>
                <div className={css.responsiveCloseIcon}>
                    <RiCloseLargeFill className={css.closeIcon} onClick={() => handleClose() }/>
                    { isProgressBarEnabled && (
                        <div className={css.progressBarContainer}>
                             <ProgressBarComponent  isPickUp={isPickUp}/>
                        </div>
                    )}
                </div>
            </div>
              {stepTabs[activeTab]}
            </div>
        </div>
    )
}

const PickupAndDropPage = compose()(PickupAndDropComponent)

export default PickupAndDropPage;
