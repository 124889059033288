import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { FieldPhoneNumberInput, Form, PrimaryButton } from '../../components';
import config from '../../config';

import css from './PhoneNumberForm.css';
import last from 'lodash/last';


class PhoneNumberFormComponent extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showVerificationEmailSentMessage: false,
      phoneCode: last(config.custom.phoneCode).key,
    };
    this.submittedValues = {};

  }




  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            form,
            rootClassName,
            className,
            savePhoneNumberError,
            currentUser,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            values,
            modifyStep,
            handleSteps,
            step,
            setSubmitRef
          } = fieldRenderProps;
          const { phoneNumber, phoneCode } = values;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          const { profile } = user.attributes;


          // React.useEffect(() => {
          //   modifyStep && handleSteps(step, "Phone Number")
          // }, [])





          let phoneVerifiedInfo = (
            <span className={css.pendingEmailUnverified}>
              <FormattedMessage id="PhoneNumberForm.phoneUnverified" />
            </span>
          );

          if (
            user &&
            user.attributes.profile &&
            user.attributes.profile.protectedData &&
            user.attributes.profile.protectedData.phoneNumberVerified
          ) {
            phoneVerifiedInfo = (
              <span className={css.emailVerified}>
                <FormattedMessage id="PhoneNumberForm.phoneVerified" />
              </span>
            );
          }

          // phone
          const protectedData = profile.protectedData || {};
          const currentPhoneNumber = protectedData.phoneNumber;

          // has the phone number changed
          const phoneNumberChanged = currentPhoneNumber !== phoneCode + phoneNumber;

          const phonePlaceholder = intl.formatMessage({
            id: 'PhoneNumberForm.phonePlaceholder',
          });
          const phoneLabel = intl.formatMessage({ id: 'PhoneNumberForm.phoneLabel' });





          let genericError = null;

          if (savePhoneNumberError) {
            genericError =
              savePhoneNumberError.message === 'phoneNumberVerificationForm.usedPhone' ||
                savePhoneNumberError.message === 'Phone used' ? (
                <span className={css.error}>
                  <FormattedMessage id="phoneNumberVerificationForm.usedPhone" />
                </span>
              ) : (
                <span className={css.error}>
                  <FormattedMessage id="PhoneNumberForm.genericPhoneNumberFailure" />
                </span>
              );
          }

          const classes = classNames(rootClassName || css.root, className);
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid ||
            pristineSinceLastSubmit ||
            inProgress
          // ||
          // !(phoneNumberChanged);

          const countryLabel = this.props.intl.formatMessage({
            id: 'ModalMissingInformation.mobilePhoneInputCountryLabel',
          });

          const confirmClasses = classNames(css.confirmChangesSection, {
            [css.confirmChangesSectionVisible]: true,
          });

          const guidance = (
            <div className={css.verificationIndication}>
              <p>
                <FormattedMessage id="PhoneNumberForm.guide" />
              </p>
            </div>
          );

          setSubmitRef.current = form.submit;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <FormSpy
                onChange={input => {
                  if (!input.values.phoneCode) {
                    input.values.phoneCode = last(config.custom.phoneCode).key;
                  }
                  this.setState({
                    phoneCode: input.values.phoneCode || this.state.phoneCode,
                  });
                }}
                subscription={{ values: true }}
              />
              {guidance}
              <div className={css.halfInputs}>
                {/* <FieldSelect
                  id="phoneCode"
                  name="phoneCode"
                  label={countryLabel}
                  className={css.newInputCountry}
                >
                  {config.custom.phoneCode.map((option, index) => (
                    <option value={option.key} key={option.key}>
                      {option.label}
                    </option>
                  ))}
                </FieldSelect> */}
                <div className={css.phoneNumberFieldWrapper}>
                  <label className={css.phoneNumberLabel}>{phoneLabel}</label>
                  <div className={css.phoneNumberWrapper}>
                    <div className={css.newInputPhoneCode}>{this.state.phoneCode}</div>
                    <FieldPhoneNumberInput
                      className={css.newInput}
                      name="phoneNumber"
                      id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                      placeholder={phonePlaceholder}
                    />
                  </div>

                </div>
                {/* {phoneVerifiedInfo} */}
              </div>

              <div className={css.bottomWrapper}>
                {genericError}
                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={pristineSinceLastSubmit}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="PhoneNumberForm.saveChanges" />Hello 
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

PhoneNumberFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  saveEmailError: null,
  savePhoneNumberError: null,
  inProgress: false,
  sendVerificationEmailError: null,
  sendVerificationEmailInProgress: false,
  email: null,
  phoneNumber: null,
};

const { bool, func, string } = PropTypes;

PhoneNumberFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  onResendVerificationEmail: func.isRequired,
  ready: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  sendVerificationEmailInProgress: bool,
};

const PhoneNumberForm = compose(injectIntl)(PhoneNumberFormComponent);

PhoneNumberForm.displayName = 'PhoneNumberForm';

export default PhoneNumberForm;