import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import config from '../../config';
import { ensureCurrentUser } from '../../util/data';
import { get } from 'lodash';
import BLandingCss from './LandingPageNew.css';
import { Button, CopyToClipboard, NamedLink, SocialSharing } from '../../components';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import imageSource from '../../util/imageSource'

const InviteSection = ({ currentUser, intl, history, isMobileLayout }) => {
  const [isShareModalOpen, setIsShareModal] = useState(false);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [referalLink, setReferalLink] = useState('');
  const ensuredUser = ensureCurrentUser(currentUser);
  const getReferralCode = get(ensuredUser, 'attributes.profile.metadata.referralCode', '');
  const signupReferralLink = `${config.canonicalRootURL}/signup?code=${getReferralCode}`;

  const sharingMsg = intl.formatMessage(
    { id: 'ReferAHostPage.sharingMsg' },
    { referalLink, myCode: getReferralCode }
  );

  const whatsappSharingMsg = intl.formatMessage(
    { id: 'ReferAHostPage.whatsappSharingMsg' },
    { referalLink, myCode: getReferralCode }
  );

  const loadFbSdk = () => {
    if (window) {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: config.facebookAppId,
          cookie: true, // enable cookies to allow the server to access
          // the session
          autoLogAppEvents: true,
          xfbml: true, // parse social plugins on this page
          version: 'v2.7',
        });
      };

      // Load the SDK asynchronously
      (function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  };

  const twitterLink =
    typeof window !== 'undefined'
      ? `https://twitter.com/intent/tweet?text=${window.encodeURI(sharingMsg)}`
      : '';

  const whatsAppLink = isMobileLayout
    ? `https://api.whatsapp.com/send?text=${whatsappSharingMsg}`
    : `https://web.whatsapp.com/send?text=${whatsappSharingMsg}`;

  const onShareToMessenger = link => {
    if (!window) return;
    var desc = 'your caption here';
    var title = 'your title here';
    return isMobileLayout
      ? window.open(`fb-messenger://share/?link=${link}&app_id=${config.facebookAppId}`)
      : window.FB.ui({
          method: 'send',
          link: `${link}`,
          description: link,
          action_properties: JSON.stringify({
            object: {
              'og:title': title,
              'og:description': desc,
            },
          }),
        });
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      loadFbSdk();
    }
  }, []);

  useEffect(() => {
    if (navigator.share) {
      setIsShareModal(true);
    } else {
      setIsShareModal(false);
    }
  }, []);

  useEffect(() => {
    generateReferalLink();
  }, [currentUser]);

  console.log('I am REFERAL LINK invite section >>', referalLink);


  const onShareToFacebookTimeline = (message, url) => {
    if (!window) return;

    window.FB.ui(
      {
        method: 'share',
        quote: message,
        href: url,
      },
      function(response) {
        console.log('Success!', response);
      }
    );
  };
  const shareFunctionality = e => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Share',
          text: sharingMsg,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      setOpenShareDialog(true);
    }
  };

  const _redirectToReferralPage = () => {
    if (!currentUser) {
      history.push(createResourceLocatorString('ReferralPage', routeConfiguration(), {}, {}));
    }
  };

  const generateReferalLink = () => {
    if(get(currentUser, 'attributes.profile.metadata.intercomUserStat.number_of_live_listings', 0) > 0) {
      console.log('I am host >>');
      setReferalLink(`${config.canonicalRootURL}/u/${get(currentUser, 'id.uuid', '')}?code=${getReferralCode}`)
    } else {
      console.log('I am guest >>');
      setReferalLink(`${config.canonicalRootURL}/rent-cars-with-drive-mate?code=${getReferralCode}`)
    }
  }

  const socialSharingLinks = [
    // {
    //   id: 0,
    //   siteName: 'Facebook',
    //   siteLogo: facebook,
    //   sharingUrl: signupReferralLink,
    //   sharingMsg,
    //   callback: (sharingMsg, signupReferralLink) => onShareToMessenger(signupReferralLink),
    // },
    {
      id: 1,
      siteName: 'Instagram',
      // siteLogo: instagram,
      siteLogo: imageSource.landingPageImages.instagramSquare,
      sharingMsg,
      sharingUrl: null,
      callback: () => {},
    },
    {
      id: 2,
      siteName: 'Messenger',
      // siteLogo: messenger,
      siteLogo: imageSource.landingPageImages.messenger,
      sharingUrl: null,
      callback: () => onShareToMessenger(),
    },
    {
      id: 3,
      siteName: 'Twitter',
      // siteLogo: twitter,
      siteLogo: imageSource.landingPageImages.twitterSquare,
      sharingMsg,
      sharingUrl: twitterLink,
      callback: () => {},
    },
    {
      id: 4,
      siteName: 'Whatsapp',
      // siteLogo: whatsapp,
      siteLogo: imageSource.landingPageImages.whatsappSquare,
      sharingMsg,
      sharingUrl: whatsAppLink,
      callback: () => {},
    },
  ];

  const copyButton = (
    <Button type="button" className={BLandingCss.copyShareButton}>
      <div className={BLandingCss.copyIconThumb}>
        {/* <img src={copyIcon} alt="Copy" /> */}
        <img src={imageSource.landingPageImages.copy} alt="Copy" />
      </div>
      <div>Copy link to share</div>
    </Button>
  );

  return (
    <div className={classNames(BLandingCss.landingContainer, BLandingCss.inviteSection)}>
      <div className={BLandingCss.landingRow}>
        <div
          className={classNames(
            BLandingCss.column12,
            BLandingCss.gridBox,
            BLandingCss.gridHorizontal,
            BLandingCss.gridImageRight
          )}
        >
          <div
            className={classNames(
              BLandingCss.gridBoxInner,
              BLandingCss.sectionWithBg,
              !currentUser ? BLandingCss.referralWrapper : ''
            )}
            onClick={() => _redirectToReferralPage()}
          >
            <div className={BLandingCss.inviteContent}>
              <span className={BLandingCss.title}>Refer friends and other car share service users to earn $50</span>
              <p>
              Share your unique link with friends and contacts. They'll get $25 credits when they join Drive mate. You’ll get $50 credits if they rent.
              </p>
              {ensuredUser ? (
                <>
                  {!isMobileLayout && (
                    <div className={BLandingCss.copyReferralCodeSection}>
                      <CopyToClipboard
                        text={copyButton}
                        textToCopy={referalLink}
                        highlightText="Url copied"
                        className={BLandingCss.copyReferralCode}
                      />
                    </div>
                  )}
                  <div className={BLandingCss.shareIcons}>
                    {isShareModalOpen ? (
                      <a
                        className={classNames(BLandingCss.logo, BLandingCss.shareMoreOptions)}
                        onClick={shareFunctionality}
                        target="_blank"
                      >
                        Share
                      </a>
                    ) : (
                      <>
                        {/* <a
                          className={BLandingCss.logo}
                          onClick={() => onShareToFacebookTimeline(sharingMsg, signupReferralLink)}
                          target="_blank"
                        >
                          <img src={facebook} alt="Facebook" />
                        </a> */}
                        <a
                          className={BLandingCss.logo}
                          onClick={() => onShareToMessenger(signupReferralLink)}
                          target="_blank"
                        >
                          <img src={imageSource.landingPageImages.messenger} alt="Messenger" />
                        </a>

                        <a className={BLandingCss.logo} href={twitterLink} target="_blank">
                          <img src={imageSource.landingPageImages.twitterSquare} alt="Twitter-icon" />
                        </a>

                        <a className={BLandingCss.logo} href={whatsAppLink} target="_blank">
                          <img src={imageSource.landingPageImages.whatsappSquare} alt="Whatsapp-icon" />
                        </a>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <NamedLink
                  type="button"
                  name="ReferralPage"
                  className={BLandingCss.copyShareButton}
                >
                  <div>Learn more</div>
                </NamedLink>
              )}
            </div>
            <div className={BLandingCss.thumbImg}>
              <img src={imageSource.landingPageImages.inviteFriends} alt="invite-friends" />

            </div>
          </div>
        </div>
      </div>
      <SocialSharing
        socialSharingLinks={socialSharingLinks}
        openShareDialog={openShareDialog}
        setOpenShareDialog={setOpenShareDialog}
      />
    </div>
  );
};

export default InviteSection;
