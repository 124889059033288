import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './StartTripPanel.css';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import {
  EVENT_CONVERSION_BOOKING_CANCELLED,
  EVENT_CONVERSION_BOOKING_DECLINED,
  EVENT_CONVERSION_BOOKING_REQUEST_WITHDRAWN,
  EVENT_CONVERSION_COMPLETED_TRIP,
} from '../../util/conversions/conversionsConstants';
import helpImg from '../../assets/newPickupAndDropoff/FAQ.svg';
import arrowImg from '../../assets/arrow-down-black.svg'

import {
  NamedLink,
} from '../../components';

export const HEADING_ENQUIRED = 'enquired';
export const HEADING_PAYMENT_PENDING = 'pending-payment';
export const HEADING_PAYMENT_EXPIRED = 'payment-expired';
export const HEADING_REQUESTED = 'requested';
export const HEADING_ACCEPTED = 'accepted';
export const HEADING_DECLINED = 'declined';
export const HEADING_ONGOING = 'ongoing';
export const HEADING_CANCELED = 'canceled';
export const HEADING_DELIVERED = 'deliveded';
export const HEADING_WITHDRAWN = 'withdrawn';
export const HEADING_WITHDRAWN_BY_ADMIN = 'withdraw_by_admin';
export const HEADING_REQUEST_UPDATE_BOOKING = 'request-update-booking'
export const HEADING_ACCEPTED_UPDATE_BOOKING = 'accepted-update-booking'
export const HEADING_DECLINED_UPDATE_BOOKING = 'declined-update-booking'
let mounted = false;
const ListingDeletedInfoMaybe = props => {
  return props.listingDeleted ? (
    <p className={css.transactionInfoMessage}>
      <FormattedMessage id="TransactionPanel.messageDeletedListing" />
    </p>
  ) : null;
};

const HeadingCustomer = props => {
  const { className, id, values, listingDeleted, listingTitle, status, isCustomer, showDropOffSection, shouldShowPickUpSection, shouldShowDropOffSection, isPickUp, 
    setShowViewDetails} = props;

  return (
    <React.Fragment>
      <div className={`${css.titleContainer} ${ isCustomer ? css.titleBtnContainer : ''}`}>
        { isCustomer ? (<>
          <div>
          <div className={css.bookingStatusText} onClick={() => {
            shouldShowPickUpSection(false)
            shouldShowDropOffSection(false)
            setShowViewDetails()
          }
            }>
            <span>&#8249; back</span>
          </div>
            {/* <p className={css.bookingStatusText}>
                <NamedLink name="InboxPage" params={{ tab: 'orders' }}>
                 <img src={arrowImg} className={css.backIcon}/>BACK
                </NamedLink>
                
            </p> */}
            <h2 className={css.listingTitle}>{ isPickUp ? 'Start trip' : 'End trip'}</h2>
            {/* <h2 className={css.listingTitle}>Start trip</h2> */}

          </div>
          <div className={css.helpBtn}>
            <button className={css.needHelpBtn}><img src={helpImg} alt='#' />Need help?</button>
          </div>
          </>)
          : (<>
          <p className={css.bookingStatusText}>
            <FormattedMessage id="TransactionPanel.bookingStatus" values={{ status: status }} />
          </p>
          <h2 className={css.listingTitle}>{listingTitle}</h2>
        </>)}
      </div>
      <ListingDeletedInfoMaybe listingDeleted={listingDeleted} />
    </React.Fragment>
  );
};

const CustomerBannedInfoMaybe = props => {
  return props.isCustomerBanned ? (
    <p className={css.transactionInfoMessage}>
      <FormattedMessage id="TransactionPanel.customerBannedStatus" />
    </p>
  ) : null;
};

const HeadingProvider = props => {
  const { className, id, values, isCustomerBanned, children, listingTitle, status } = props;
  return (
    <React.Fragment>
      <div className={css.titleContainer}>
        <p className={css.bookingStatusText}>
          <FormattedMessage id="TransactionPanel.bookingStatus" values={{ status: status }} />
        </p>
        <h2 className={css.listingTitle}>{listingTitle}</h2>
      </div>
      <CustomerBannedInfoMaybe isCustomerBanned={isCustomerBanned} />
    </React.Fragment>
  );
};

// Functional component as a helper to choose and show Order or Sale heading info:
// title, subtitle, and message
const StartTripPanelHeading = props => {
  const {
    className,
    rootClassName,
    panelHeadingState,
    customerName,
    providerName,
    listingId,
    listingTitle,
    listingDeleted,
    isCustomerBanned,
    listingLink,
    showDropOffSection,
    shouldShowPickUpSection,
    shouldShowDropOffSection,
    isPickUp,
    setShowViewDetails
  } = props;

  const isCustomer = props.transactionRole === 'customer';

  const defaultRootClassName = isCustomer ? css.headingOrder : css.headingSale;
  const titleClasses = classNames(rootClassName || defaultRootClassName, className);

  if (!mounted) {
    const properties = createRawPropertiesForGTM({
      props,
    });
    switch (panelHeadingState) {
      case HEADING_DELIVERED:
        createConversionEvents(properties, EVENT_CONVERSION_COMPLETED_TRIP);
        break;
      case HEADING_WITHDRAWN:
        createConversionEvents(properties, EVENT_CONVERSION_BOOKING_REQUEST_WITHDRAWN);
        break;
      case HEADING_DECLINED:
        createConversionEvents(properties, EVENT_CONVERSION_BOOKING_DECLINED);
        break;
      case HEADING_CANCELED:
        createConversionEvents(properties, EVENT_CONVERSION_BOOKING_CANCELLED);
        break;
      default:
        break;
    }
    mounted = true;
  }

  switch (panelHeadingState) {
    case HEADING_ENQUIRED:
      return isCustomer ? (
        <HeadingCustomer
          listingDeleted={listingDeleted}
          listingTitle={listingTitle}
          status="enquired"
          isCustomer={isCustomer}
          showDropOffSection = {showDropOffSection}
          shouldShowPickUpSection = {shouldShowPickUpSection}
          shouldShowDropOffSection = {shouldShowDropOffSection}
          isPickUp = {isPickUp}
          setShowViewDetails={setShowViewDetails}
        />
      ) : (
        <HeadingProvider
          isCustomerBanned={isCustomerBanned}
          listingTitle={listingTitle}
          status="enquired"
          showDropOffSection = {showDropOffSection}
          shouldShowPickUpSection = {shouldShowPickUpSection}
          shouldShowDropOffSection = {shouldShowDropOffSection}

        />
      );
    case HEADING_PAYMENT_PENDING:
      return isCustomer ? (
        <HeadingCustomer
          listingDeleted={listingDeleted}
          listingTitle={listingTitle}
          status="pending"
          isCustomer={isCustomer}
          showDropOffSection = {showDropOffSection}
          shouldShowPickUpSection = {shouldShowPickUpSection}
          shouldShowDropOffSection = {shouldShowDropOffSection}
          isPickUp = {isPickUp}
          setShowViewDetails={setShowViewDetails}
        />
      ) : (
        <HeadingProvider
          isCustomerBanned={isCustomerBanned}
          listingTitle={listingTitle}
          status="pending"
        />
      );
    case HEADING_PAYMENT_EXPIRED:
      return isCustomer ? (
        <HeadingCustomer
          listingDeleted={listingDeleted}
          listingTitle={listingTitle}
          status="payment expired"
          isCustomer={isCustomer}
          showDropOffSection = {showDropOffSection}
          shouldShowPickUpSection = {shouldShowPickUpSection}
          shouldShowDropOffSection = {shouldShowDropOffSection}
          isPickUp = {isPickUp}
          setShowViewDetails={setShowViewDetails}
        />
      ) : (
        <HeadingProvider
          isCustomerBanned={isCustomerBanned}
          listingTitle={listingTitle}
          status="payment expired"
        />
      );
    case HEADING_REQUESTED:
      return isCustomer ? (
        <HeadingCustomer
          listingDeleted={listingDeleted}
          listingTitle={listingTitle}
          isCustomer={isCustomer}
          status="pending"
          showDropOffSection = {showDropOffSection}
          shouldShowPickUpSection = {shouldShowPickUpSection}
          shouldShowDropOffSection = {shouldShowDropOffSection}
          isPickUp = {isPickUp}
          setShowViewDetails={setShowViewDetails}
        />
      ) : (
        <HeadingProvider
          isCustomerBanned={isCustomerBanned}
          listingTitle={listingTitle}
          status="pending"
        />
      );
    case HEADING_ACCEPTED:
    case HEADING_REQUEST_UPDATE_BOOKING:
    case HEADING_DECLINED_UPDATE_BOOKING:
    case HEADING_ACCEPTED_UPDATE_BOOKING:
      return isCustomer ? (
        <HeadingCustomer
          listingDeleted={listingDeleted}
          listingTitle={listingTitle}
          isCustomer={isCustomer}
          status="confirmed"
          showDropOffSection = {showDropOffSection}
          shouldShowPickUpSection = {shouldShowPickUpSection}
          shouldShowDropOffSection = {shouldShowDropOffSection}
          isPickUp = {isPickUp}
          setShowViewDetails={setShowViewDetails}

        />
      ) : (
        <HeadingProvider
          isCustomerBanned={isCustomerBanned}
          listingTitle={listingTitle}
          status="confirmed"
        />
      );
    case HEADING_WITHDRAWN:
      return isCustomer ? (
        <HeadingCustomer
          listingDeleted={listingDeleted}
          listingTitle={listingTitle}
          isCustomer={isCustomer}
          status="with drawn"
          showDropOffSection = {showDropOffSection}
          shouldShowPickUpSection = {shouldShowPickUpSection}
          shouldShowDropOffSection = {shouldShowDropOffSection}
          isPickUp = {isPickUp}
          setShowViewDetails={setShowViewDetails}
        />
      ) : (
        <HeadingProvider
          isCustomerBanned={isCustomerBanned}
          listingTitle={listingTitle}
          status="with drawn"
        />
      );
    case HEADING_WITHDRAWN_BY_ADMIN:
      return isCustomer ? (
        <HeadingCustomer
          listingDeleted={listingDeleted}
          listingTitle={listingTitle}
          isCustomer={isCustomer}
          status="with drawn by admin"
          showDropOffSection = {showDropOffSection}
          shouldShowPickUpSection = {shouldShowPickUpSection}
          shouldShowDropOffSection = {shouldShowDropOffSection}
          isPickUp = {isPickUp}
          setShowViewDetails={setShowViewDetails}
        />
      ) : (
        <HeadingProvider
          isCustomerBanned={isCustomerBanned}
          listingTitle={listingTitle}
          status="with drawn by admin"
        />
      );
    case HEADING_ONGOING:
      return isCustomer ? (
        <HeadingCustomer
          listingDeleted={listingDeleted}
          listingTitle={listingTitle}
          isCustomer={isCustomer}
          status="ongoing"
          showDropOffSection = {showDropOffSection}
          shouldShowPickUpSection = {shouldShowPickUpSection}
          shouldShowDropOffSection = {shouldShowDropOffSection}
          isPickUp = {isPickUp}
          setShowViewDetails={setShowViewDetails}
        />
      ) : (
        <HeadingProvider
          isCustomerBanned={isCustomerBanned}
          listingTitle={listingTitle}
          status="ongoing"
        />
      );
    case HEADING_DECLINED:
      return isCustomer ? (
        <HeadingCustomer
          listingDeleted={listingDeleted}
          isCustomer={isCustomer}
          listingTitle={listingTitle}
          status="declined"
          showDropOffSection = {showDropOffSection}
          shouldShowPickUpSection = {shouldShowPickUpSection}
          shouldShowDropOffSection = {shouldShowDropOffSection}
          setShowViewDetails={setShowViewDetails}
        />
      ) : (
        <HeadingProvider
          isCustomerBanned={isCustomerBanned}
          listingTitle={listingTitle}
          status="declined"
        />
      );
    case HEADING_CANCELED:
      return isCustomer ? (
        <HeadingCustomer
          listingDeleted={listingDeleted}
          listingTitle={listingTitle}
          isCustomer={isCustomer}
          status="cancelled"
          showDropOffSection = {showDropOffSection}
          shouldShowPickUpSection = {shouldShowPickUpSection}
          shouldShowDropOffSection = {shouldShowDropOffSection}
          isPickUp = {isPickUp}
          setShowViewDetails={setShowViewDetails}
        />
      ) : (
        <HeadingProvider
          isCustomerBanned={isCustomerBanned}
          listingTitle={listingTitle}
          status="cancelled"
        />
      );
    case HEADING_DELIVERED:
      return isCustomer ? (
        <HeadingCustomer
          listingDeleted={listingDeleted}
          isCustomerBanned={isCustomerBanned}
          listingTitle={listingTitle}
          isCustomer={isCustomer}
          status="delivered"
          showDropOffSection = {showDropOffSection}
          shouldShowPickUpSection = {shouldShowPickUpSection}
          shouldShowDropOffSection = {shouldShowDropOffSection}
          setShowViewDetails={setShowViewDetails}
        />
      ) : (
        <HeadingProvider
          isCustomerBanned={isCustomerBanned}
          listingTitle={listingTitle}
          status="delivered"
          
        />
      );
    default:
      console.warn('Unknown state given to panel heading.');
      return null;
  }
};

export default StartTripPanelHeading;
