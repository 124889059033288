import classNames from 'classnames';
import { get } from 'lodash';
import { func, string } from 'prop-types';
import React, { Component, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import Slider from 'react-slick';
import { NamedLink, ResponsiveImage } from '../../components';
import config from '../../config';
import { lazyLoadWithDimensions, withViewport } from '../../util/contextHelpers';
import { formatMoney } from '../../util/currency';
import { ensureListing, getListingMonthlyFlexPrice } from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { propTypes } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import Explanation from '../BookingBreakdown/Explaination';
import css from './LongTermListingCard.css';
import { isFuelTypeIsPetrol } from '../../util/helpers';
import { categories } from '../../marketplace-custom-config';
import { categoryLabel } from '../../containers/ListingPage/ListingPage.helper';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const priceData = (price, intl, perMonthPrice = false, listing) => {
  if (price && price.currency === config.currency) {
    let priceMaybe = getListingMonthlyFlexPrice(listing);
    let formattedPrice = formatMoney(intl, priceMaybe);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const DisinfectedLabel = props => {
  return (
    <div className={css.cardWrapperLabelDisinfected}>
      <div className={css.cardNumberDays}>
        <div className={css.cardNumber}> 60 </div>
        <div className={css.cardDay}> days</div>
      </div>
      <div className={css.cardTextLabel}>
        <span> Disinfected </span>
      </div>
    </div>
  );
};

class ListingImage extends Component {
  render() {
    return (
      <>
        <ResponsiveImage {...this.props} />
        {this.props.isNewCar && <span className={css.newLabel}> 50% off </span>}
        {this.props.isDisinfected && <DisinfectedLabel />}
      </>
    );
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

const TripPrice = ({price, inProgress, intl}) => {
  if (!price && !inProgress){
    return null;
  }

  if (inProgress){
    return (
      <div className={css.cardTripPriceLoading}>
        <span>...</span>
      </div>
    )
  }

  const { formattedPrice } = priceData(price, intl);

  return (
    <div className={css.cardTripPrice}>
      <FormattedMessage
        id={'ListingCard.tripPrice'}
        values={{
          price: formattedPrice,
          element: (
            <span className={css.cardTripPriceUnits}>
              {intl.formatMessage({id: "ListingCard.tripPriceUnits"})}
            </span>
          )
        }}
      />
    </div>
  )
}

export const LongTermListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    location,
    onSelectCard,
    showMonthlyPrice,
    currentUser,
    tripPrice,
    tripPriceInProgress
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData, metadata = {} } = currentListing.attributes;
  const isLatestCar = metadata.isNewCar;
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const slug = createSlug(title);
  const allImages = currentListing.images;
  const { isNewCar, isDisinfected } = metadata;


  let unitTranslationKey = showMonthlyPrice
    ? 'ListingCard.monthly' : 'ListingCard.weekly'

  let { formattedPrice } = priceData(price, intl, showMonthlyPrice, listing);
  let hourlyPriceSeen = false;
  const modifiedCategory = category => {
    if (category) {
      if(category.includes('ADMIN') || category.includes('Admin'))
        return ''
      const tempCategory = String(category);
      return tempCategory
        .split('_')
        .filter(Boolean)
        .map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
    }
    return category;
  };

  const { transmissions, fuelType, drivenKm, millagePerDay, yearOfManufacture, category } = publicData || {};
  // Attach `Brand new` tag, if yearOfManufacture is same as current year.
  const isBrandNewCar = yearOfManufacture && parseInt(yearOfManufacture || '0000') === new Date().getFullYear() ? true : false;
  const carCategory = modifiedCategory(categoryLabel(categories, category))
  let options = [
    ...config.custom.transmissions,
    { key: 'category', label: carCategory},
    { key: 'latest', label: yearOfManufacture },
    { key: 'isBrandNewCar', label: `Brand new` },
    ...config.custom.fuelTypes,
    // ...config.custom.drivenKm,
  ];
  const selectedOptions = [
    transmissions && transmissions.length > 0 && transmissions[0] === 'manual' ? transmissions[0] : '',
    carCategory && 'category' || "",
    isLatestCar && 'latest',
    isBrandNewCar && 'isBrandNewCar',
    fuelType || '',
    // drivenKm || '',
    // millagePerDay || '',
  ];
  const millagePerDayObject = {
    key: 'miles_per_day',
    label: `${millagePerDay} kms/day`,
  };
  options = useMemo(
    () =>
      options.filter(option => {
        return selectedOptions.filter(select => select === option.key).length > 0;
      }),
    [options, selectedOptions, millagePerDayObject]
  );
  let optionsToDisplay = options;

  // Handle petrol type listings
  if (optionsToDisplay && optionsToDisplay.length) {
    optionsToDisplay = optionsToDisplay.map((item) => {
      if (isFuelTypeIsPetrol(item && item.key ? item.key : '')) {
        return { key: 'petrol', label: 'Petrol' }
      } else {
        return item;
      }
    });
  }

  const listingAddress = get(listing, 'attributes.publicData.listingTimezone'); // TODO: We need to change this
  if (listingAddress) {
    let country = listingAddress.split('/')[0];
    let city = listingAddress.split('/')[1] || country;
    optionsToDisplay[optionsToDisplay.length] = {
      "key": "pub_listingTimezone",
      "label": city
    };
  }

  const sliderConfig = {
    autoplay: false,
    dots: isMobile ? true : false,
    arrows: isMobile ? false : true,
    infinite: true,
    autoplaySpeed: 5000,
  };

  return (
    <NamedLink
      className={classes}
      name={'LongTermRentalListingPage'}
      onClick={() => onSelectCard(currentListing)}
      params={{ id, slug }}
      to={{
        search: location && location.search,
      }}
    >
      <div className={css.cardOuter}>
        <div
          className={css.cardThreeToTwoWrapper}
          onMouseEnter={() => {
            setActiveListing(currentListing.id);
          }}
          onMouseLeave={() => setActiveListing(null)}
        >
          <div className={css.cardAspectWrapper}>
            {allImages && allImages.length > 0 &&
              <Slider {...sliderConfig}>
                {allImages.map(l => (
                  <LazyImage
                    rootClassName={css.cardRootForImage}
                    alt={title}
                    image={l}
                    variants={['landscape-crop', 'landscape-crop2x']}
                    sizes={renderSizes}
                    isDisinfected={isDisinfected}
                    isNewCar={isNewCar && !showMonthlyPrice}
                  />
                ))}
              </Slider>
            }
          </div>
        </div>
        <div className={css.cardListingInfoOuter}>
          {/* <div className={css.cardOwnerUsername}>{authorName}'s</div> */}
          <div className={css.cardLinkText}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.cardLongWord,
            })}
          </div>
          <div className={css.cardFeatureInfo}>
            <ul className={css.cardFeatureInfoList}>
              {optionsToDisplay &&
                optionsToDisplay.length > 0 &&
                optionsToDisplay.map((option, i) => {
                  return <li>{option.label}</li>;
                })}
            </ul>
          </div>
          {/* <div className={css.cardExtraInfo}>
            <div className={css.cardLogoWrapper}>
              {isSuperHost && <SuperHostLogo />}
              {isDrivelahGo && (
                <span className={css.cardDrivelahGoLogo}>
                  <DrivelahGoLogo />
                </span>
              )}
              {instantBooking && !showMonthlyPrice && <InstantBookingMark />}
            </div>
          </div> */}
        </div>
        <div className={css.cardListingRatingsAndPrice}>
          <div className={`${css.cardListingPrice} ${css.PriceInfo}`}>
            <TripPrice
              price={tripPrice}
              inProgress={tripPriceInProgress}
              intl={intl}
            />
            <label>from</label>
            <FormattedMessage
              id={'ListingCard.price'}
              values={{ price: formattedPrice, unit: <FormattedMessage id={unitTranslationKey} /> }}
            />
            {hourlyPriceSeen ? (
              <Explanation text="Estimated price, full price breakdown available on listing page." />
            ) : null}
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

LongTermListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
  onSelectCard: () => {},
};

LongTermListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
  onSelectCard: func,
};

export default injectIntl(withViewport(LongTermListingCardComponent));
