import React, { Component } from 'react';
import { bool, number, object, string } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import config from '../../config';

import { types as sdkTypes } from '../../util/sdkLoader';
import { StartTripDynamicMap, isMapsLibLoaded, StaticMap } from './GoogleMap';

import css from './StartTripMap.css';
import { enabled } from 'cookie_js';

const { LatLng } = sdkTypes;

export class StartTripMap extends Component {
  render() {
    const {
      className,
      rootClassName,
      mapRootClassName,
      address,
      center,
      obfuscatedCenter,
      zoom,
      mapsConfig,
      useStaticMap,
      maxZoom,
      hideAddress,
      dynamicLocation,
      dynamicMarkerIconURL,
      distanceFromPickUp,
      isCustomer,
      isPickUp
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const mapClasses = mapRootClassName || css.mapRoot;

    const mapFuzzyEnebled = hideAddress ? true : (hideAddress== undefined ? mapsConfig.fuzzy.enabled: false )


    if (mapFuzzyEnebled && !obfuscatedCenter) {
      throw new Error(
        'Map: obfuscatedCenter prop is required when config.maps.fuzzy.enabled === true'
      );
    }
    if (!mapFuzzyEnebled && !center) {
      throw new Error('Map: center prop is required when config.maps.fuzzy.enabled === false');
    }

    const location = mapFuzzyEnebled ? obfuscatedCenter : center;

    console.log("location", location)
      const {lat, lng} = dynamicLocation

      return !isMapsLibLoaded() ? (
        <div className={classes} />
      ) : useStaticMap ? (
        <StaticMap center={location} zoom={zoom} address={address} mapsConfig={mapsConfig} />
      ) : (
        <StartTripDynamicMap
          maxZoom={maxZoom}
          containerElement={<div className={classes} />}
          mapElement={<div className={mapClasses} />}
          containerClassName={classes}
          mapClassName={mapClasses}
          center={location}
          zoom={zoom}
          address={address}
          mapsConfig={mapsConfig}
          dynamicMarkerIconURL={dynamicMarkerIconURL}
          dynamicCenter={dynamicLocation}
          distanceFromPickUp={distanceFromPickUp}
          dynamicMarkerEnabled={isCustomer}
          isPickUp = {isPickUp}
        />
      );

  }
}

StartTripMap.defaultProps = {
  className: null,
  rootClassName: null,
  mapRootClassName: null,
  address: '',
  zoom: config.maps.fuzzy.enabled ? config.maps.fuzzy.defaultZoomLevel : 11,
  mapsConfig: config.maps,
  useStaticMap: false,
};

StartTripMap.propTypes = {
  className: string,
  rootClassName: string,
  mapRootClassName: string,
  address: string,
  center: propTypes.latlng,
  obfuscatedCenter: propTypes.latlng,
  zoom: number,
  mapsConfig: object,
  useStaticMap: bool,
};

export default StartTripMap;
