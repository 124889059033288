import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { uploadFiles } from '../../util/fileUpload';
import { FormattedMessage } from '../../util/reactIntl';
import { IconCamera, IconClose, IconSpinner } from '../../components/index';
import css from '../../components/TripPanel/SectionsCarPhotos.css';

const ACCEPT_TYPE = 'image/jpeg, image/png, image/gif, image/webp, image/bmp, image/svg+xml, image/tiff, image/heif, image/heic';

const UploadSection = forwardRef(({
  intl,
  isCustomer,
  isPickUp,
  currentUser,
  onNoteChange,
  description,
  setIsUploaded,
  setUploading,
  setLoading,
  type,
  note,
  setPhotosData,  
  setActivePhotoSection,
  id,
  setIsExpectedLength,
  setLastImageUrl,
  exteriorPhotoFun
}, ref) => {
  const [uploadFileError, setUploadFileError] = useState(false);
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [filesName , setFilesName] = useState()
  const inputElement = useRef(null);

  useImperativeHandle(ref, () => ({
    handleClickUpload() {
      inputElement.current.click();
    }
  }));

  useEffect(() => {
    if(uploadedPhotos.length) {
      handleAddPhoto(uploadedPhotos);
      if (setLastImageUrl && uploadedPhotos.length > 0) {
        setLastImageUrl(filesName);
      }
    }
  },[uploadedPhotos])

  const uploadFilesHandler = async (files) => {

    if (files.length) {
      setIsUploaded(true)
      setLoading(true);
      setUploadFileError(false);
      try {
        const uploadPromises = files.map(file => {
          const formData = new FormData();
          formData.append('file', file.file, file.file.name);
          if(setLastImageUrl) {
            setFilesName(file.file.name)
          }
          formData.append('userId', currentUser.id.uuid);
          formData.append('tripPhotos', true);
          return uploadFiles(formData).then(response => {
            if (response.status !== 200) {
              throw new Error('Upload failed');
            }
            return response.json();
          });
        });
        const responses = await Promise.all(uploadPromises);
        const newPhotos = responses.map((item, index) => ({
          fileUrl: item.url,
          id: id,
          note: item.note,
          type: type,
          isPickUp: true,
          timestamp: new Date().getSeconds() + index,
          isJustUploaded: true,
        }));
        setUploadedPhotos(prevPhotos => [...prevPhotos, ...newPhotos]);
        setActivePhotoSection(newPhotos)
        setLoading(false);
        if(type == "exteriorPhotos") exteriorPhotoFun(newPhotos)
      } catch (error) {
        console.error('Upload error:', error);
        setUploadFileError(true);
        setLoading(false);
        setIsUploaded(false)
      }
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files).filter(file => 
      file.type.startsWith('image/')
    );
  
    if (selectedFiles.length) {
      uploadFilesHandler(selectedFiles.map((file, index) => ({ index, file })));
    } else {
      setUploadFileError(true);
    }
  };

  const handleAddPhoto = () => {
        const allPhotos = []
        uploadedPhotos.map(i => {
        const data = {
        fileUrl: i.fileUrl,
        note: note,
        timestamp: new Date().getTime(),
        isCustomer: isCustomer,
        isPickUp: isPickUp,
        [type]: true,
      };
      allPhotos.push(data);
    });
    console.log("isPickUpUpload section", isPickUp)
    setPhotosData(allPhotos)
  }

  const handleRemovePhoto = (timestamp) => {
    setUploadedPhotos(prevPhotos => prevPhotos.filter(photo => photo.timestamp !== timestamp));
  };

  useEffect(() => {
    uploadedPhotos.length ? setIsUploaded(true) : setIsUploaded(false);
    if (setIsExpectedLength) {
      setIsExpectedLength(uploadedPhotos.length)
    }
  }, [uploadedPhotos.length])

  const uploadTextLabel = <FormattedMessage id="SectionUploadCarStatus.remarks" />;
  const uploadPlaceholder = intl.formatMessage({ id: 'SectionUploadCarStatus.uploadPlaceholder' });

  return (
    <div>
      <div className={ `${css.pickUpPhotosHolder} ${css.startTripPickUpPhotosHolder}`}>
        <div>
          <div className={css.uploadBox} onClick={() => ref.current.handleClickUpload()}>
          </div>
          <input
            accept={ACCEPT_TYPE}
            className={css.inputField}
            type="file"
            name="uploadPhotoStatus"
            id="uploadPhotoStatus"
            multiple
            onChange={handleFileChange}
            ref={inputElement}
          />
        </div>
        {isCustomer && isPickUp && <div className={css.pickUpReading}>{description}</div>}
        {!setLastImageUrl && uploadedPhotos.length > 0 && (
          <div className={classNames(css.photosContainer, css.pickUpPhotosContainer)}>
            {uploadedPhotos.map((photo, index) => (
              <div className={css.photoWrapper} key={index}>
                <div className={css.photoWrapperInner}>
                  {photo.isJustUploaded && (
                    <div
                      className={css.removePhotoBtn}
                      onClick={() => handleRemovePhoto(photo.timestamp)}
                    >
                      <IconClose size={'small'} />
                    </div>
                  )}
                  <img
                    src={photo.fileUrl}
                    className={css.tripPhoto}
                    onClick={() => window.open(photo.fileUrl, '_blank')}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {uploadFileError && (
        <p className={css.uploadError}>
          <FormattedMessage id="SectionUploadCarStatus.uploadError" />
        </p>
      )}
    </div>
  );
});

UploadSection.propTypes = {
  intl: PropTypes.object.isRequired,
  isCustomer: PropTypes.bool.isRequired,
  isPickUp: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  onNoteChange: PropTypes.func,
  description: PropTypes.string,
  setIsUploaded: PropTypes.any
};

export default UploadSection;
