import React from 'react';
import { IconLogo } from '../../components';
import appStoreBadge from '../../assets/b-landing/appstore-badge.svg';
import googlePlayBadge from '../../assets/b-landing/google-play-badge.png';
import classNames from 'classnames';
import css from './LandingPageNew.css';
import imageSource from '../../util/imageSource';

const SectionGrids = () => {
  return (
    <div className={css.gridBoxContainer}>
      <div
        className={classNames(css.column12, css.gridBox, css.gridHorizontal, css.gridImageRight)}
      >
        <div className={css.gridBoxInner}>
          <div className={css.gridThumb}>
            <div className={css.thumbContent}>
              <IconLogo />
            </div>
          </div>
          <div className={css.gridContent}>
            <span className={css.title}>Have you got our apps?</span>
            <p>Available on both iOS and Android, download on your phone now.</p>
            <ul className={css.badgeListing}>
              <li className={css.heroContentText}>
                <a
                  target="_blank"
                  href="https://apps.apple.com/au/app/drive-mate-share-rent-cars/id1534972421"
                  className={css.appButton}
                >
                  <img src={imageSource.bLanding.appStoreBadge} alt="" />

                </a>
              </li>
              <li className={css.heroContentText}>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.drivelah.drivemate"
                  className={css.appButton}
                >
                  <img src={imageSource.bLanding.googlePlayBadge} alt="" />

                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGrids;
