import React, { useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import {
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE,
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR,
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE,
  ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_ACCEPT,
  TRANSITION_ACCEPT_BY_ADMIN,
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_ACCEPTED_PENDING_STATE,
  TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED,
  TRANSITION_ADMIN_CANCEL_AFTER_UPDATE_BOOKING,
  TRANSITION_ADMIN_CANCEL_NON_REFUND,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED,
  TRANSITION_ADMIN_CONFIRM_DROP_OFF,
  TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_ADMIN_REFUND,
  TRANSITION_ADMIN_WITHDRAW,
  TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_AUTO_COMPLETE,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED,
  TRANSITION_CONFIRM_PAYMENT_UNVERIFIED,
  TRANSITION_CONFIRM_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
  TRANSITION_CUSTOMER_CANCEL_AFTER_UPDATE_BOOKING,
  TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_REFUNDABLE,
  TRANSITION_DECLINE,
  TRANSITION_DISPUTE,
  TRANSITION_DROP_OFF_CONFIRMED_AFTER_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_ENQUIRE,
  TRANSITION_EXPIRE,
  TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_EXPIRE_CONFIRM_PICK_UP,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PAYMENT,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REQUEST_DROP_OFF,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR,
  TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
  TRANSITION_PROVIDER_CANCEL_AFTER_UPDATE_BOOKING,
  TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE,
  TRANSITION_PROVIDER_CANCEL_REFUNDABLE,
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_REQUEST_DROP_OFF_DLGO,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_DROP_OFF_REQUESTED,
  TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_PICK_UP_CONFIRMED,
  TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE,
  TRANSITION_TRIP_PICK_UP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_REFUNDABLE,
  TRANSITION_TRIP_PICKUP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE,
  TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT,
  TRANSITION_UNVERIFIED_DECLINED,
  TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE,
  TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE_INSTANT,
  TRANSITION_UNVERIFIED_EXPIRE,
  TRANSITION_UNVERIFIED_EXPIRE_INSTANT,
  TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED,
  TRANSITION_UNVERIFIED_VERIFICATION_DECLINE,
  TRANSITION_UNVERIFIED_VERIFICATION_EXPIRE,
  TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED,
  TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_DECLINE,
  TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_EXPIRE,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_ADMIN,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT,
  TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_ADMIN,
  TRANSITION_UNVERIFIED_WITHDRAW,
  TRANSITION_UNVERIFIED_WITHDRAW_INSTANT,
  TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT,
  TRANSITION_WITHDRAW,
  txIsPickedUp,
  txIsShu,
} from '../../util/transaction';
import {
  TRANSITION_LTF_CONFIRM_PAYMENT,
  TRANSITION_LTF_DECLINE,
  TRANSITION_LTF_EXPIRE,
  TRANSITION_LTF_EXPIRE_PAYMENT,
  TRANSITION_LTF_REQUEST_PICK_UP,
  txIsPickedUpLTF,
} from '../../util/transactionLongTermFirst';
import { NamedLink, ReviewRating } from '../../components';
import tick from '../../assets/tripPage/tick.svg';
import { getPickUpTransition } from './PickupButtonsMaybe';
import { formatMoney } from '../../util/currency';

import css from './TripPanel.css';

export const checkTimeToShowDropOff = (transaction, timeZone) => {
  const isHourlyBooking = get(transaction, 'attributes.protectedData.hourlyBooking');
  const displayEnd = get(transaction, 'booking.attributes.displayEnd');
  const displayEndDateForUser = moment(displayEnd).tz(timeZone);

  const dateShowDropOff = isHourlyBooking
    ? displayEndDateForUser.clone().set('minute', displayEndDateForUser.minutes() - 15)
    : displayEndDateForUser.clone().set('minute', displayEndDateForUser.minutes() - 30);
  const timeToShowDropOff = moment(dateShowDropOff).clone();
  const isTimeToShowTransitionDropOff = moment()
    .tz(timeZone)
    .isSameOrAfter(timeToShowDropOff);

  return isTimeToShowTransitionDropOff;
};

export const checkDropOffTimeAfterPickUpOneHour = transaction => {
  const transitions = get(transaction, 'attributes.transitions', []);
  const pickUpTransition = transitions.find(transition =>
    [
      TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
      TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
      TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
    ].includes(transition.transition)
  );

  const createdAt = pickUpTransition && pickUpTransition.createdAt;

  let dateShowDropOff = createdAt && new Date(createdAt);

  dateShowDropOff =
    dateShowDropOff && dateShowDropOff.setMinutes(dateShowDropOff.getMinutes() + 60);

  const timeToShowDropOff = new Date(dateShowDropOff);

  const isTimeToShowTransitionDropoff = new Date() > timeToShowDropOff;
  return isTimeToShowTransitionDropoff;
};

const ItemStatus = ({
  title,
  cbFunction,
  buttonText,
  withoutButton,
  reviewRaiting,
  statusText,
  isDispute,
  customClassName,
}) => {
  return (
    <div
      className={classNames(css.bookingStatusContainer, {
        [css.customContainerClassName]: customClassName,
      })}
    >
      <div className={css.statusTitle}>
        <div className={css.bookingStatusWrapper}>
          {title}
          {(buttonText === 'View details' || reviewRaiting) && !isDispute ? (
            <div className={css.tickContainer}>
              <img src={tick} alt="tick" className={css.tick} />
            </div>
          ) : null}
        </div>
        {withoutButton ? null : customClassName ? (
          <span className={css.pickupLinkNoText} onClick={e => cbFunction(e)}>{buttonText}</span>
        ) : (
          <span className={css.pickupLink} onClick={e => cbFunction(e)}>
            {buttonText}
          </span>
        )}
        {reviewRaiting ? (
          <ReviewRating
            reviewStarClassName={css.reviewStar}
            className={css.reviewStars}
            rating={reviewRaiting}
          />
        ) : null}
      </div>
      {statusText && <p className={css.statusText}>{statusText}</p>}
    </div>
  );
};

const BookingStatus = ({
  lastBookingUpdate,
  transaction: rawTransaction,
  shouldShowPickUpSection,
  shouldShowDropOffSection,
  timeZone,
  isCustomer,
  isProvider,
  providerName,
  customerName,
  otherUser,
  currentUserRole,
  isLongTerm,
  openModalReview,
  isMobileLayout,
  openDisputeModal,
  isDrivelahGo,
  bookingUpdateTxs,
  intl,
  shouldshowNewPickUpDropOffSection,
  transitionAdminMaybe,
  isLimitedUser,
  activePickUpBooking,
  activeDropOffBooking,
  ishowViewDetailButton
}) => {
  const [showPickUpSection, setShowPickUpSection] = useState(false);
  const [showDropOffSection, setShowDropOffSection] = useState(false);

  const transaction = rawTransaction;


  // isLongTerm
  //   ? rawTransaction && rawTransaction.currentChildTransaction
  //   : rawTransaction;
  const lastTransition = transaction && transaction.attributes.lastTransition;
  const allTransitions = transaction && transaction.attributes.transitions;

  const updatedBookingEnd = lastBookingUpdate ? moment(lastBookingUpdate.end).toDate() : null;
  const updatedBookingStart = lastBookingUpdate ? moment(lastBookingUpdate.start).toDate() : null;
  const { guestIdentityVerificationStatus } =
    (transaction && transaction.customer && transaction.customer.attributes.profile.publicData) ||
    {};

  const isDispute =
    allTransitions && allTransitions.filter(i => i.transition === TRANSITION_DISPUTE).length > 0;
  let statusTitle, statusText, additionalText;

  const reviews = get(transaction, 'reviews');
  const customerReview = reviews && reviews.filter(i => i.attributes.type === 'ofProvider');
  const providerReview = reviews && reviews.filter(i => i.attributes.type === 'ofCustomer');

  const dateFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  };

  const isHourlyBooking = get(transaction, 'attributes.protectedData.hourlyBooking');
  const { displayStart, displayEnd } = get(transaction, 'booking.attributes', {});
  const realStartDate = moment(displayStart).toDate();
  const timeZoneMaybe = timeZone ? { timeZone } : null;
  const isTimeToShowTransitionPickup = moment()
    .tz(timeZone)
    .isSameOrAfter(
      moment(displayStart)
        .tz(timeZone)
        .add(isHourlyBooking ? -15 : -60, 'minutes')
    );

  const lastUpdateBookingTx = bookingUpdateTxs && bookingUpdateTxs[bookingUpdateTxs.length - 1];

  const payoutTotal = get(lastUpdateBookingTx, 'attributes.payoutTotal', null);

  const formattedPayoutTotal = payoutTotal && formatMoney(intl, payoutTotal, 1);

  // const isTimeToShowTransitionPickup = true;

  const isTimeToHideTransitionPickup = moment().isSameOrAfter(moment(displayEnd));
  const isRequestPickupTransition =
    allTransitions &&
    allTransitions.find(
      i =>
        i.transition === TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED ||
        i.transition === TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE
    );
  const isRequestDropoffTransition =
    allTransitions &&
    allTransitions.find(
      i =>
        i.transition === TRANSITION_REQUEST_DROP_OFF ||
        i.transition === TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU
    );

  const date = <FormattedDate value={realStartDate} {...dateFormatOptions} {...timeZoneMaybe} />;

  const onOpenModalReview = e => {
    e.preventDefault();
    openModalReview();
  };

  const handleDispute = e => {
    e.preventDefault();
    openDisputeModal();
  };

  const isShowDropoffBasOnDropOffTime = checkTimeToShowDropOff(transaction, timeZone);
  const isTransitionAccept =
    lastTransition === TRANSITION_ACCEPT ||
    lastTransition === TRANSITION_ACCEPT_BY_ADMIN ||
    lastTransition === TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE ||
    lastTransition === TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR ||
    lastTransition === TRANSITION_CONFIRM_PAYMENT_INSTANT ||
    lastTransition === TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT ||
    lastTransition === TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED;
  const isPickupRequestSend =
    lastTransition === TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED ||
    lastTransition === TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE;
  const isPickupConfirm =
    lastTransition === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE ||
    lastTransition === TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED ||
    lastTransition === TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE ||
    lastTransition === TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE ||
    lastTransition === TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF ||
    lastTransition === TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF ||
    lastTransition === TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF ||
    lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF ||
    lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE ||
    lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR ||
    lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE ||
    lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE ||
    lastTransition === TRANSITION_CONFIRM_PICK_UP_AFTER_ACCEPTED ||
    lastTransition === TRANSITION_TRIP_PICKUP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE ||
    lastTransition === TRANSITION_TRIP_PICK_UP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_REFUNDABLE;
  const isDropOffrequestSend =
    lastTransition === TRANSITION_REQUEST_DROP_OFF ||
    lastTransition === TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU;


  let isTimeToShowTransitionDropoff;
  if (
    lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE ||
    lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR ||
    lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE ||
    lastTransition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE
  ) {
    if (isShowDropoffBasOnDropOffTime) {
      isTimeToShowTransitionDropoff = true;
    } else {
      isTimeToShowTransitionDropoff = false;
    }
  } else {
    const isShowDropOffBaseOnPickUpTime = checkDropOffTimeAfterPickUpOneHour(transaction, timeZone)

    isTimeToShowTransitionDropoff = isShowDropoffBasOnDropOffTime;
  }

  const needUploadPhotoTransitions = [
    TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
    TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
    TRANSITION_LTF_REQUEST_PICK_UP,
    TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
    TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP,
    TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  ];
  const transitionMaybe = get(getPickUpTransition(transaction), `${currentUserRole}`, null);

  const photoObjects = get(transaction, 'attributes.protectedData.photoObjects');

  const progressBarClasses =
    photoObjects && photoObjects.length === 1
      ? css.progressBar25
      : photoObjects && photoObjects.length === 2
      ? css.progressBar50
      : photoObjects && photoObjects.length === 3
      ? css.progressBar75
      : photoObjects && photoObjects.length === 4
      ? css.progressBar100
      : null;

  const progressBarClassesDrivelahGo =
    photoObjects && photoObjects.length === 1
      ? css.progressBar50
      : photoObjects && photoObjects.length === 2
      ? css.progressBar100
      : null;

  const percentageText =
    photoObjects && photoObjects.length === 1
      ? '25% done'
      : photoObjects && photoObjects.length === 2
      ? '50% done'
      : photoObjects && photoObjects.length === 3
      ? '75% done'
      : photoObjects && photoObjects.length === 4
      ? null
      : null;

  const percentageTextDrivelahGo =
    photoObjects && photoObjects.length === 1
      ? '50% done'
      : photoObjects && photoObjects.length === 2
      ? null
      : null;

  const needUploadPickUpPhoto =
    (needUploadPhotoTransitions.includes(transitionMaybe) && isTimeToShowTransitionPickup) ||
    (isProvider && transitionMaybe && !needUploadPhotoTransitions.includes(transitionMaybe));

  const needAcceptPickUprequest = isProvider && isTimeToShowTransitionPickup && isPickupRequestSend;
  const needUploadDropOffPhoto = isCustomer && isTimeToShowTransitionDropoff && isPickupConfirm;
  const needAcceptDropOffRequest =
    isProvider && isTimeToShowTransitionDropoff && isDropOffrequestSend;
  const checkReviewRequest = lastTransition === 'transition/expire-review-period' || lastTransition === "transition/review-1-by-customer" || lastTransition === "transition/review-1-by-provider" || lastTransition === 'transition/review-2-by-customer' || lastTransition === 'transition/review-2-provider'

  const handlePickUpSection = e => {
    e.preventDefault();
    setShowPickUpSection(!showPickUpSection);
    shouldShowPickUpSection(!showPickUpSection);
    if((needUploadDropOffPhoto || isPickupRequestSend || txIsShu(transaction) || txIsPickedUp(transaction) || txIsPickedUpLTF(transaction)) && isCustomer || isDropOffrequestSend || checkReviewRequest )  {
      shouldshowNewPickUpDropOffSection(false)
     }
  };

  const handleDropOffSection = e => {
    e.preventDefault();
    setShowDropOffSection(!showDropOffSection);
    shouldShowDropOffSection(!showDropOffSection);

    if(!needUploadDropOffPhoto) {
      shouldshowNewPickUpDropOffSection(false)
    }
  };
  React.useEffect(() => {
    activePickUpBooking(needUploadPickUpPhoto);
    activeDropOffBooking(needUploadDropOffPhoto);
}, [needUploadPickUpPhoto, needUploadDropOffPhoto]);

  const StatusData = ({ title, text, subText }) => {
    let statusTitle = title;
    let statusText = text;

    return (
      <div className={css.bookingStatusContainer}>
        <h4 className={`${css.statusTitle} ${isLimitedUser && css.displayBlock}`}>
          {statusTitle}
          {(needUploadPickUpPhoto || needAcceptPickUprequest) && !ishowViewDetailButton ? (
            < >
            <div>
               { isLimitedUser && <span className={ `${css.pickupLink} ${css.pickupAdminLink}`} onClick={e => handlePickUpSection(e)}>
                  Pickup as Admin
              </span>
                }
              <span className={css.pickupLink} onClick={!isLimitedUser ?  (e) => handlePickUpSection(e) : null}>
                {isCustomer ? 'Complete Now' : 'Confirm Now'}
              </span>
              </div>
            </>
          ) : lastTransition === TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE ||
            lastTransition === TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED ? (
            <span className={css.pickupLink} onClick={e => handlePickUpSection(e)}>
              View details
            </span>
          ) : null}
          {needUploadDropOffPhoto || needAcceptDropOffRequest ? (
            <span className={css.pickupLink} onClick={e => handleDropOffSection(e)}>
              {isCustomer ? 'Complete Now' : 'Confirm Now'}
            </span>
          ) : lastTransition === TRANSITION_REQUEST_DROP_OFF ? (
            <span className={css.pickupLink} onClick={e => handleDropOffSection(e)}>
              View details
            </span>
          ) : null}
        </h4>
        <p className={css.statusText}>
          {statusText}
          {isTransitionAccept && subText && <span className={css.subText}>{subText}</span>}
        </p>

        {isCustomer ? (
          needUploadPickUpPhoto || needUploadDropOffPhoto ? (
            <div className={css.progressBarHolder}>
              <span className={css.progressBarOuter}>
                <span
                  className={
                    isDrivelahGo
                      ? classNames(css.progressBarInner, progressBarClassesDrivelahGo)
                      : classNames(css.progressBarInner, progressBarClasses)
                  }
                ></span>
                <span className={css.progressBarText}>
                  {isDrivelahGo ? percentageTextDrivelahGo : percentageText}
                </span>
              </span>
            </div>
          ) : null
        ) : null}
      </div>
    );
  };

  const ExtendTripBanner = () => {
    let statusTitle = <FormattedMessage id="ExtendTripBanner.statusTitle" />;
    let statusText = <FormattedMessage id="ExtendTripBanner.statusText" />;

    const isBookingCompleted =
      transaction.booking &&
      moment()
        .tz(timeZone)
        .isSameOrAfter(
          moment(transaction.booking.attributes.displayEnd)
            .add(6, 'hours')
            .tz(timeZone)
        );

    if (isBookingCompleted) return null;

    return (
      <div className={css.extendTripBannerContainer}>
        <h4 className={css.statusTitleExtendTrip}>
          {statusTitle}
          <NamedLink name="EditTripPage" params={{ id: transaction.id.uuid }}>
            <span className={css.pickupLinkExtendTrip}>
              <FormattedMessage id="ExtendTripBanner.actionButtonText" />
            </span>
          </NamedLink>
        </h4>
        <p className={css.statusTextExtendTrip}>{statusText}</p>
      </div>
    );
  };

  //15 minutes before start trip
  if (needUploadPickUpPhoto && isCustomer) {
    statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestPickUp" />;
    statusText = <FormattedMessage id="TransactionPanel.requestPickUpText" />;
    return <StatusData title={statusTitle} text={statusText} />;
  }

  if (needAcceptPickUprequest) {
    statusTitle = isMobileLayout ? (
      <FormattedMessage id="TransactionPanel.recivedPickupRequestTitleMobile" />
    ) : (
      <FormattedMessage id="TransactionPanel.recivedPickupRequestTitle" />
    );
    statusText = <FormattedMessage id="TransactionPanel.recivedPickupRequestText" />;
    return <StatusData title={statusTitle} text={statusText} />;
  }

  if (needUploadDropOffPhoto && isCustomer) {
    statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestEndTrip" />;
    statusText = <FormattedMessage id="TransactionPanel.requestDropOffText" />;
    return (
      <div>
        <StatusData title={statusTitle} text={statusText} />
        {isCustomer ? <ExtendTripBanner /> : null}
        <ItemStatus
          title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
          cbFunction={handlePickUpSection}
          buttonText={'View details'}
          // customClassName={isCustomer}
        />
      </div>
    );
  }

  if (needAcceptDropOffRequest) {
    statusTitle = <FormattedMessage id="TransactionPanel.confirmDropOffRequestTitle" />;
    statusText = <FormattedMessage id="TransactionPanel.confirmDropOffRequestText" />;
    return (
      <div>
        <StatusData title={statusTitle} text={statusText} />
        <ItemStatus
          title={<FormattedMessage id="TransactionPanel.bookingDispute" />}
          cbFunction={handleDispute}
          buttonText={'Report Dispute'}
        />
        <ItemStatus
          title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
          cbFunction={handlePickUpSection}
          buttonText={'View details'}
          // customClassName={isCustomer}
        />
      </div>
    );
  }

  if (
    lastTransition === TRANSITION_CONFIRM_PAYMENT ||
    lastTransition === TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED ||
    lastTransition === TRANSITION_ACCEPTED_PENDING_STATE ||
    lastTransition === TRANSITION_LTF_CONFIRM_PAYMENT
  ) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.bookingRequestSent" />
    ) : (
      <FormattedMessage id="TransactionPanel.bookingRequestRecived" />
    );
    statusText = isCustomer ? (
      <FormattedMessage id="TransactionPanel.requestSentText" />
    ) : (
      <FormattedMessage
        id="TransactionPanel.requestRecivedText"
        values={{ userName: customerName }}
      />
    );
    return <StatusData title={statusTitle} text={statusText} />;
  }

  if (
    lastTransition === TRANSITION_CONFIRM_PAYMENT_UNVERIFIED ||
    lastTransition === TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED
  ) {
    statusTitle = isCustomer ? (
      guestIdentityVerificationStatus && guestIdentityVerificationStatus === 'pending' ? (
        <FormattedMessage id="TransactionPanel.unverifiedBookingRequestSentPending" />
      ) : (
        <FormattedMessage id="TransactionPanel.unverifiedBookingRequestSentNotSubmitted" />
      )
    ) : (
      <FormattedMessage id="TransactionPanel.unverifiedBookingRequestSent" />
    );
    statusText = isCustomer ? (
      guestIdentityVerificationStatus && guestIdentityVerificationStatus === 'pending' ? (
        <FormattedMessage id="TransactionPanel.unverifiedRequestSentTextPending" />
      ) : (
        <FormattedMessage id="TransactionPanel.unverifiedBookingRequestTextSentNotSubmitted" />
      )
    ) : (
      <FormattedMessage
        id="TransactionPanel.requestRecivedText"
        values={{ userName: customerName }}
      />
    );
    return <StatusData title={statusTitle} text={statusText} />;
  }

  if (
    lastTransition === TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT ||
    lastTransition === TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_ADMIN ||
    lastTransition === TRANSITION_UNVERIFIED_VERIFICATION_SENT_ADMIN ||
    lastTransition === TRANSITION_UNVERIFIED_VERIFICATION_SENT
  ) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.unverifiedBookingVerificationPending" />
    ) : (
      <FormattedMessage id="TransactionPanel.unverifiedBookingVerificationPending" />
    );
    statusText = isCustomer ? (
      <FormattedMessage id="TransactionPanel.unverifiedRequestVerficationText" />
    ) : (
      <FormattedMessage
        id="TransactionPanel.requestRecivedText"
        values={{ userName: customerName }}
      />
    );
    return <StatusData title={statusTitle} text={statusText} />;
  }

  if (
    lastTransition === TRANSITION_CONFIRM_PAYMENT_INSTANT ||
    lastTransition === TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT ||
    lastTransition === TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED ||
    lastTransition === TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR ||
    lastTransition === TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE ||
    lastTransition === TRANSITION_ACCEPT ||
    lastTransition === TRANSITION_ACCEPT_BY_ADMIN
  ) {
    statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestConfirmed" />;
    statusText = isCustomer ? (
      <FormattedMessage id="TransactionPanel.requestConfirmedText" />
    ) : (
      <FormattedMessage id="TransactionPanel.requestConfirmedTextProvider" />
    );
    additionalText = date;

    return <StatusData title={statusTitle} text={statusText} subText={additionalText} />;
  }

  if (isCustomer && isPickupRequestSend) {
    statusTitle = <FormattedMessage id="TransactionPanel.sendPickupRequestTitle" />;
    statusText = <FormattedMessage id="TransactionPanel.sendPickupRequestText" />;
    return (
      <div>
        <ItemStatus
          title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
          cbFunction={handlePickUpSection}
          buttonText={'View details'}
          statusText={statusText}
          // customClassName={isCustomer}
        />
      </div>
    );
  }

  if (lastTransition === TRANSITION_ENQUIRE) {
    statusTitle = <FormattedMessage id="TransactionPanel.enquire" />;
    statusText = isCustomer ? (
      <FormattedMessage id="TransactionPanel.enquireText" />
    ) : (
      <FormattedMessage
        values={{ userName: customerName }}
        id="TransactionPanel.enquireTextProvider"
      />
    );
    additionalText = date;

    return <StatusData title={statusTitle} text={statusText} subText={additionalText} />;
  }

  if (txIsShu(transaction) || txIsPickedUp(transaction) || txIsPickedUpLTF(transaction)) {
    statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestOngoing" />;
    statusText = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.requestOngoingText"
        values={{
          bookingEnd: (
            <span className={css.nowrap}>
              <FormattedDate value={displayEnd} {...dateFormatOptions} {...timeZoneMaybe} />
            </span>
          ),
          newRowText: (
            <div>
              <FormattedMessage id="TransactionPanel.requestOngoingNewRowText" />
            </div>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="TransactionPanel.requestOngoingTextProvider" />
    );
    return (
      <div>
        <StatusData title={statusTitle} text={statusText} />
        {isCustomer ? <ExtendTripBanner /> : null}
        <ItemStatus
          title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
          cbFunction={handlePickUpSection}
          buttonText={'View details'}
          // customClassName={isCustomer}
        />
      </div>
    );
  }

  if (isCustomer && lastTransition === TRANSITION_REQUEST_DROP_OFF) {
    statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestReview" />;
    return (
      <div>
        {/* <StatusData title={statusTitle} /> */}
        <ItemStatus
          title={<FormattedMessage id="TransactionPanel.bookingRequestDropOff" />}
          cbFunction={handleDropOffSection}
          buttonText={'View details'}
          isDispute={isDispute}
        />
        <ItemStatus
          title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
          cbFunction={handlePickUpSection}
          buttonText={'View details'}
          // customClassName={isCustomer}
        />
      </div>
    );
  }

  if (
    lastTransition === TRANSITION_CONFIRM_DROP_OFF ||
    lastTransition === TRANSITION_REQUEST_DROP_OFF_DLGO ||
    lastTransition === TRANSITION_ADMIN_CONFIRM_DROP_OFF ||
    lastTransition === TRANSITION_DISPUTE ||
    lastTransition === TRANSITION_AUTO_COMPLETE ||
    (lastTransition === TRANSITION_REVIEW_1_BY_CUSTOMER && isProvider) ||
    (lastTransition === TRANSITION_REVIEW_1_BY_PROVIDER && isCustomer) ||
    lastTransition === TRANSITION_EXPIRE_CONFIRM_PICK_UP ||
    lastTransition === TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE ||
    lastTransition === TRANSITION_EXPIRE_REQUEST_DROP_OFF ||
    lastTransition === TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU ||
    lastTransition === TRANSITION_DROP_OFF_CONFIRMED_AFTER_ADMIN_ACCESS_FOR_SHU ||
    lastTransition === TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE ||
    lastTransition === TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE ||
    lastTransition === TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_PICK_UP_CONFIRMED ||
    lastTransition === TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_DROP_OFF_REQUESTED
  ) {
    statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestReview" />;
    return (
      <div>
        <ItemStatus
          title={<FormattedMessage id="TransactionPanel.bookingRequestReview" />}
          cbFunction={onOpenModalReview}
          buttonText={'Give Review'}
        />
        {isRequestDropoffTransition ? (
          <ItemStatus
            title={<FormattedMessage id="TransactionPanel.bookingRequestDropOff" />}
            cbFunction={handleDropOffSection}
            buttonText={'View details'}
            isDispute={isDispute}
          />
        ) : null}
        {isRequestPickupTransition ? (
          <ItemStatus
            title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
            cbFunction={handlePickUpSection}
            buttonText={'View details'}
            // customClassName={isCustomer}
          />
        ) : null}
      </div>
    );
  }

  if (
    (lastTransition === TRANSITION_REVIEW_1_BY_CUSTOMER && isCustomer) ||
    (lastTransition === TRANSITION_REVIEW_1_BY_PROVIDER && isProvider) ||
    lastTransition === TRANSITION_REVIEW_2_BY_PROVIDER ||
    lastTransition === TRANSITION_REVIEW_2_BY_CUSTOMER ||
    lastTransition === TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD ||
    lastTransition === TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD ||
    lastTransition === TRANSITION_EXPIRE_REVIEW_PERIOD
  ) {
    statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestReview" />;
    const customerRating =
      customerReview && customerReview[0] && customerReview[0].attributes.rating;
    const providerRating =
      providerReview && providerReview[0] && providerReview[0].attributes.rating;
    return (
      <div>
        <ItemStatus
          title={<FormattedMessage id="TransactionPanel.bookingRequestReview" />}
          cbFunction={onOpenModalReview}
          buttonText={'Give Review'}
          withoutButton={true}
          reviewRaiting={isCustomer ? customerRating : providerRating}
        />
        {isRequestDropoffTransition ? (
          <ItemStatus
            title={<FormattedMessage id="TransactionPanel.bookingRequestDropOff" />}
            cbFunction={handleDropOffSection}
            buttonText={'View details'}
            isDispute={isDispute}
          />
        ) : null}
        {isRequestPickupTransition ? (
          <ItemStatus
            title={<FormattedMessage id="TransactionPanel.bookingRequestPickUp" />}
            cbFunction={handlePickUpSection}
            buttonText={'View details'}
            // customClassName={isCustomer}
          />
        ) : null}
      </div>
    );
  }

  if (
    lastTransition === TRANSITION_DECLINE ||
    lastTransition === TRANSITION_UNVERIFIED_EXPIRE_INSTANT ||
    lastTransition === TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE ||
    lastTransition === TRANSITION_UNVERIFIED_DECLINED ||
    lastTransition === TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE_INSTANT ||
    lastTransition === TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_DECLINE ||
    // lastTransition === TRANSITION_WITHDRAW ||
    lastTransition === TRANSITION_ADMIN_WITHDRAW ||
    lastTransition === TRANSITION_UNVERIFIED_VERIFICATION_DECLINE ||
    lastTransition === TRANSITION_LTF_DECLINE
  ) {
    statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestDecline" />;
    statusText = isCustomer ? (
      <p>
        <FormattedMessage id="TransactionPanel.bookingRequestDeclineText" />
        <a href="/s">
          <FormattedMessage id="TransactionPanel.searchLink" />
        </a>
      </p>
    ) : null;
    return <StatusData title={statusTitle} text={statusText} />;
  }
  if (
    lastTransition === TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE ||
    lastTransition === TRANSITION_UNVERIFIED_DECLINED ||
    lastTransition === TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE_INSTANT ||
    lastTransition === TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_DECLINE ||
    lastTransition === TRANSITION_UNVERIFIED_VERIFICATION_DECLINE
  ) {
    statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestUnverifiedDecline" />;
    statusText = isCustomer ? (
      <p>
        <FormattedMessage id="TransactionPanel.bookingRequestUnverifiedDeclineText" />
        <a href="/s">
          <FormattedMessage id="TransactionPanel.searchLink" />
        </a>
      </p>
    ) : null;
    return <StatusData title={statusTitle} text={statusText} />;
  }

  if (
    lastTransition === TRANSITION_EXPIRE_PAYMENT ||
    lastTransition === TRANSITION_EXPIRE ||
    lastTransition === TRANSITION_LTF_EXPIRE ||
    lastTransition === TRANSITION_LTF_EXPIRE_PAYMENT
  ) {
    statusTitle = <FormattedMessage id="TransactionPanel.bookingExpire" />;

    return <StatusData title={statusTitle} />;
  }

  if (
    lastTransition === TRANSITION_UNVERIFIED_EXPIRE_INSTANT ||
    lastTransition === TRANSITION_UNVERIFIED_EXPIRE ||
    lastTransition === TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_EXPIRE ||
    lastTransition === TRANSITION_UNVERIFIED_VERIFICATION_EXPIRE
  ) {
    statusTitle = <FormattedMessage id="TransactionPanel.bookingUnverifiedExpire" />;
    statusText = isCustomer ? (
      <p>
        <FormattedMessage id="TransactionPanel.bookingRequestUnverifiedExpireText" />
      </p>
    ) : null;
    return <StatusData title={statusTitle} text={statusText} />;
  }

  if (
    lastTransition === TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE ||
    lastTransition === TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR ||
    lastTransition === TRANSITION_PROVIDER_CANCEL_REFUNDABLE
  ) {
    statusTitle = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.bookingRequestCancelProviderForCustomer"
        values={{ providerName }}
      />
    ) : (
      <FormattedMessage id="TransactionPanel.bookingRequestCancelProviderForProvider" />
    );
    statusText = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.bookingCancelTextProviderForOther"
        values={{ userType: 'provider' }}
      />
    ) : (
      <FormattedMessage id="TransactionPanel.bookingCancelTextProviderForYou" />
    );
    return <StatusData title={statusTitle} text={statusText} />;
  }

  if (
    lastTransition === TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE ||
    lastTransition === TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE ||
    lastTransition === TRANSITION_CUSTOMER_CANCEL_REFUNDABLE ||
    lastTransition === TRANSITION_WITHDRAW ||
    lastTransition === TRANSITION_UNVERIFIED_WITHDRAW ||
    lastTransition === TRANSITION_UNVERIFIED_WITHDRAW_INSTANT ||
    lastTransition === TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR
  ) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.bookingRequestCancelCustomerForCustomer" />
    ) : (
      <FormattedMessage
        id="TransactionPanel.bookingRequestCancelCustomerForProvider"
        values={{ customerName }}
      />
    );
    statusText = isCustomer ? (
      <FormattedMessage id="TransactionPanel.bookingCancelTextProviderForYou" />
    ) : (
      <FormattedMessage
        id="TransactionPanel.bookingCancelTextProviderForOther"
        values={{ userType: 'customer' }}
      />
    );
    return <StatusData title={statusTitle} />;
  }
  if (lastTransition === TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.bookingUpdateExpireGuest" />
    ) : (
      <FormattedMessage id="TransactionPanel.bookingUpdateExpireHost" />
    );
    statusText = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.bookingUpdateExpireTextGuest"
        values={{ providerName }}
      />
    ) : (
      <FormattedMessage id="TransactionPanel.bookingUpdateExpireTextHost" />
    );

    return <StatusData title={statusTitle} text={statusText} />;
  }
  if (lastTransition === TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.bookingUpdateExpireBeforeDropOffGuest" />
    ) : (
      <FormattedMessage id="TransactionPanel.bookingUpdateExpireBeforeDropOffHost" />
    );
    statusText = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.bookingUpdateExpireBeforeDropOffTextGuest"
        values={{ providerName }}
      />
    ) : (
      <FormattedMessage id="TransactionPanel.bookingUpdateExpireBeforeDropOffTextHost" />
    );

    return (
      <>
        <StatusData title={statusTitle} text={statusText} />
        {isCustomer ? <ExtendTripBanner /> : null}
      </>
    );
  }
  if (lastTransition === TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.returnBackAfterExpireUpdateBookingBeforePickUpStatusGuest" />
    ) : (
      <FormattedMessage id="TransactionPanel.returnBackAfterExpireUpdateBookingBeforePickUpStatusHost" />
    );
    statusText = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.returnBackAfterExpireUpdateBookingBeforePickUpTextGuest"
        values={{ providerName }}
      />
    ) : (
      <FormattedMessage id="TransactionPanel.returnBackAfterExpireUpdateBookingBeforePickUpTextHost" />
    );

    return <StatusData title={statusTitle} text={statusText} />;
  }

  if (
    lastTransition === TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP ||
    lastTransition === TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP ||
    lastTransition === TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP ||
    lastTransition === TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP
  ) {
    statusTitle = <FormattedMessage id="TransactionPanel.bookingUpdateAccept" />;
    statusText = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.bookingUpdateAcceptBeforePickUpGuest"
        values={{
          bookingStart: (
            <span className={css.nowrap}>
              <FormattedDate
                value={updatedBookingStart}
                {...dateFormatOptions}
                {...timeZoneMaybe}
              />
            </span>
          ),
          bookingEnd: (
            <span className={css.nowrap}>
              <FormattedDate value={updatedBookingEnd} {...dateFormatOptions} {...timeZoneMaybe} />
            </span>
          ),
        }}
      />
    ) : (
      <FormattedMessage
        id="TransactionPanel.bookingUpdateAcceptBeforePickUpHost"
        values={{
          bookingStart: (
            <span className={css.nowrap}>
              <FormattedDate
                value={updatedBookingStart}
                {...dateFormatOptions}
                {...timeZoneMaybe}
              />
            </span>
          ),
          bookingEnd: (
            <span className={css.nowrap}>
              <FormattedDate value={updatedBookingEnd} {...dateFormatOptions} {...timeZoneMaybe} />
            </span>
          ),
        }}
      />
    );

    return <StatusData title={statusTitle} text={statusText} />;
  }

  if (
    lastTransition === TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF ||
    lastTransition === TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF
  ) {
    statusTitle = <FormattedMessage id="TransactionPanel.bookingUpdateAccept" />;
    statusText = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.bookingUpdateAcceptBeforePickUpGuest"
        values={{
          bookingStart: (
            <span className={css.nowrap}>
              <FormattedDate
                value={updatedBookingStart}
                {...dateFormatOptions}
                {...timeZoneMaybe}
              />
            </span>
          ),
          bookingEnd: (
            <span className={css.nowrap}>
              <FormattedDate value={updatedBookingEnd} {...dateFormatOptions} {...timeZoneMaybe} />
            </span>
          ),
        }}
      />
    ) : (
      <FormattedMessage
        id="TransactionPanel.bookingUpdateAcceptBeforePickUpHost"
        values={{
          bookingStart: (
            <span className={css.nowrap}>
              <FormattedDate
                value={updatedBookingStart}
                {...dateFormatOptions}
                {...timeZoneMaybe}
              />
            </span>
          ),
          bookingEnd: (
            <span className={css.nowrap}>
              <FormattedDate value={updatedBookingEnd} {...dateFormatOptions} {...timeZoneMaybe} />
            </span>
          ),
        }}
      />
    );

    return (
      <>
        <StatusData title={statusTitle} text={statusText} />
        {isCustomer ? <ExtendTripBanner /> : null}
      </>
    );
  }
  if (
    lastTransition === TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF ||
    lastTransition === TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF ||
    lastTransition === TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF ||
    lastTransition === TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF
  ) {
    statusTitle = (
      <FormattedMessage id="TransactionPanel.returnBackAfterAcceptUpdateBookingBeforeDropOff" />
    );
    statusText = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.bookingUpdateAcceptBeforePickUpGuest"
        values={{
          bookingStart: (
            <span className={css.nowrap}>
              <FormattedDate
                value={updatedBookingStart}
                {...dateFormatOptions}
                {...timeZoneMaybe}
              />
            </span>
          ),
          bookingEnd: (
            <span className={css.nowrap}>
              <FormattedDate value={updatedBookingEnd} {...dateFormatOptions} {...timeZoneMaybe} />
            </span>
          ),
        }}
      />
    ) : (
      <FormattedMessage
        id="TransactionPanel.bookingUpdateAcceptBeforePickUpHost"
        values={{
          bookingStart: (
            <span className={css.nowrap}>
              <FormattedDate
                value={updatedBookingStart}
                {...dateFormatOptions}
                {...timeZoneMaybe}
              />
            </span>
          ),
          bookingEnd: (
            <span className={css.nowrap}>
              <FormattedDate value={updatedBookingEnd} {...dateFormatOptions} {...timeZoneMaybe} />
            </span>
          ),
        }}
      />
    );

    return (
      <>
        <StatusData title={statusTitle} text={statusText} />
        {isCustomer ? <ExtendTripBanner /> : null}
      </>
    );
  }
  if (
    lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF ||
    lastTransition === TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF
  ) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.returnBackAfterCancelUpdateBookingBeforeDropOffGuest" />
    ) : (
      <FormattedMessage id="TransactionPanel.adminСancelUpdateBookingBeforeDropOffAfterAcceptedStatusHost" />
    );
    statusText = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.returnBackAfterCancelUpdateBookingBeforeDropOffTextGuest"
        values={{ providerName }}
      />
    ) : (
      <FormattedMessage id="TransactionPanel.adminCancelUpdateBookingAfterAcceptedNonRefundableTextHost" />
    );

    return (
      <>
        <StatusData title={statusTitle} text={statusText} />
        {isCustomer ? <ExtendTripBanner /> : null}
      </>
    );
  }
  if (lastTransition === TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.returnBackAfterCancelUpdateBookingBeforeDropOffGuest" />
    ) : (
      <FormattedMessage id="TransactionPanel.returnBackAfterCancelUpdateBookingBeforeDropOffHost" />
    );
    statusText = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.returnBackAfterCancelUpdateBookingBeforeDropOffTextGuest"
        values={{ providerName }}
      />
    ) : (
      <FormattedMessage id="TransactionPanel.returnBackAfterCancelUpdateBookingBeforeDropOffTextHost" />
    );

    return (
      <>
        <StatusData title={statusTitle} text={statusText} />
        {isCustomer ? <ExtendTripBanner /> : null}
      </>
    );
  }
  if (
    lastTransition === TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED ||
    lastTransition === TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF
  ) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.adminСancelUpdateBookingBeforeDropOffAfterAcceptedStatusGuest" />
    ) : (
      <FormattedMessage id="TransactionPanel.adminСancelUpdateBookingBeforeDropOffAfterAcceptedStatusHost" />
    );
    statusText = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.adminСancelUpdateBookingBeforeDropOffAfterAcceptedTextGuest"
        values={{ providerName }}
      />
    ) : (
      <FormattedMessage id="TransactionPanel.adminСancelUpdateBookingBeforeDropOffAfterAcceptedTextHost" />
    );

    return (
      <>
        <StatusData title={statusTitle} text={statusText} />
        {isCustomer ? <ExtendTripBanner /> : null}
      </>
    );
  }
  if (lastTransition === TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.bookingUpdateCancelGuest" />
    ) : (
      <FormattedMessage id="TransactionPanel.bookingUpdateCancelHost" />
    );
    statusText = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.bookingUpdateCancelBeforePickUpGuest"
        values={{ providerName }}
      />
    ) : (
      <FormattedMessage id="TransactionPanel.bookingUpdateCancelBeforePickUpHost" />
    );

    return <StatusData title={statusTitle} text={statusText} />;
  }
  if (lastTransition === TRANSITION_PROVIDER_CANCEL_AFTER_UPDATE_BOOKING) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.tripModificationCancelStatusCustomer" />
    ) : (
      <FormattedMessage id="TransactionPanel.tripModificationCancelStatusProvider" />
    );
    statusText = isCustomer ? (
      <FormattedMessage id="TransactionPanel.tripModificationCancelledByProviderGuest" />
    ) : (
      <FormattedMessage id="TransactionPanel.tripModificationCancelledByProviderHost" />
    );

    return <StatusData title={statusTitle} text={statusText} />;
  }
  if (
    lastTransition ===
      TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP ||
    lastTransition === TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE
  ) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.adminCancelUpdateBookingAfterAcceptedNonRefundableStatusGuest" />
    ) : (
      <FormattedMessage id="TransactionPanel.adminCancelUpdateBookingAfterAcceptedNonRefundableStatusHost" />
    );
    statusText = isCustomer ? (
      <FormattedMessage id="TransactionPanel.adminCancelUpdateBookingAfterAcceptedNonRefundableTextGuest" />
    ) : (
      <FormattedMessage id="TransactionPanel.adminCancelUpdateBookingAfterAcceptedNonRefundableTextHost" />
    );

    return <StatusData title={statusTitle} text={statusText} />;
  }
  if (lastTransition === TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.adminCancelUpdateBookingAfterAwaitUpdateBookingBeforePickUpStatusGuest" />
    ) : (
      <FormattedMessage id="TransactionPanel.adminCancelUpdateBookingAfterAwaitUpdateBookingBeforePickUpStatusHost" />
    );
    statusText = isCustomer ? (
      <FormattedMessage id="TransactionPanel.adminCancelUpdateBookingAfterAwaitUpdateBookingBeforePickUpTextGuest" />
    ) : (
      <FormattedMessage id="TransactionPanel.adminCancelUpdateBookingAfterAwaitUpdateBookingBeforePickUpTextGuest" />
    );

    return <StatusData title={statusTitle} text={statusText} />;
  }
  if (lastTransition === TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF) {
    statusTitle = (
      <FormattedMessage id="TransactionPanel.adminAcceptUpdateBookingBeforeDropOffStatus" />
    );
    statusText = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.adminAcceptUpdateBookingBeforeDropOffTextGuest"
        values={{
          bookingStart: (
            <span className={css.nowrap}>
              <FormattedDate
                value={updatedBookingStart}
                {...dateFormatOptions}
                {...timeZoneMaybe}
              />
            </span>
          ),
          bookingEnd: (
            <span className={css.nowrap}>
              <FormattedDate value={updatedBookingEnd} {...dateFormatOptions} {...timeZoneMaybe} />
            </span>
          ),
        }}
      />
    ) : (
      <FormattedMessage
        id="TransactionPanel.adminAcceptUpdateBookingBeforeDropOffTextHost"
        values={{
          bookingStart: (
            <span className={css.nowrap}>
              <FormattedDate
                value={updatedBookingStart}
                {...dateFormatOptions}
                {...timeZoneMaybe}
              />
            </span>
          ),
          bookingEnd: (
            <span className={css.nowrap}>
              <FormattedDate value={updatedBookingEnd} {...dateFormatOptions} {...timeZoneMaybe} />
            </span>
          ),
        }}
      />
    );

    return (
      <>
        <StatusData title={statusTitle} text={statusText} />
        {isCustomer ? <ExtendTripBanner /> : null}
      </>
    );
  }
  if (
    lastTransition ===
    TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP
  ) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.adminAcceptUpdateBookingAfterAwaitUpdateBookingBeforePickUpStatusGuest" />
    ) : (
      <FormattedMessage id="TransactionPanel.adminAcceptUpdateBookingAfterAwaitUpdateBookingBeforePickUpStatusHost" />
    );
    statusText = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.adminCancelUpdateBookingAfterAcceptedNonRefundableTextGuest"
        values={{
          bookingStart: (
            <span className={css.nowrap}>
              <FormattedDate
                value={updatedBookingStart}
                {...dateFormatOptions}
                {...timeZoneMaybe}
              />
            </span>
          ),
          bookingEnd: (
            <span className={css.nowrap}>
              <FormattedDate value={updatedBookingEnd} {...dateFormatOptions} {...timeZoneMaybe} />
            </span>
          ),
        }}
      />
    ) : (
      <FormattedMessage
        id="TransactionPanel.adminCancelUpdateBookingAfterAcceptedNonRefundableTextHost"
        values={{
          bookingStart: (
            <span className={css.nowrap}>
              <FormattedDate
                value={updatedBookingStart}
                {...dateFormatOptions}
                {...timeZoneMaybe}
              />
            </span>
          ),
          bookingEnd: (
            <span className={css.nowrap}>
              <FormattedDate value={updatedBookingEnd} {...dateFormatOptions} {...timeZoneMaybe} />
            </span>
          ),
        }}
      />
    );

    return <StatusData title={statusTitle} text={statusText} />;
  }
  if (lastTransition === TRANSITION_CUSTOMER_CANCEL_AFTER_UPDATE_BOOKING) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.tripModificationCancelStatusCustomer" />
    ) : (
      <FormattedMessage id="TransactionPanel.tripModificationCancelStatusProvider" />
    );
    statusText = isCustomer ? (
      <FormattedMessage id="TransactionPanel.tripModificationCancelledByCustomerGuest" />
    ) : (
      <FormattedMessage
        id="TransactionPanel.tripModificationCancelledByCustomerHost"
        values={{ customerName }}
      />
    );

    return <StatusData title={statusTitle} text={statusText} />;
  }
  if (lastTransition === TRANSITION_ADMIN_CANCEL_AFTER_UPDATE_BOOKING) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.tripModificationCancelStatusByAdminGuest" />
    ) : (
      <FormattedMessage id="TransactionPanel.tripModificationCancelStatusByAdminHost" />
    );
    statusText = isCustomer ? (
      <FormattedMessage id="TransactionPanel.tripModificationCancelledByAdminGuest" />
    ) : (
      <FormattedMessage id="TransactionPanel.tripModificationCancelledByAdminHost" />
    );

    return <StatusData title={statusTitle} text={statusText} />;
  }
  if (
    lastTransition === TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR ||
    lastTransition === TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE ||
    lastTransition === TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE
  ) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.tripModificationRequestCustomer" />
    ) : (
      <FormattedMessage id="TransactionPanel.tripModificationRequest" />
    );
    statusText = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.bookingUpdateRequest"
        values={{
          providerName,
        }}
      />
    ) : lastUpdateBookingTx &&
      lastUpdateBookingTx.attributes.lastTransition ===
        TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT ? (
      <FormattedMessage
        id="TransactionPanel.bookingUpdateRequestForProvider"
        values={{
          customerName,
          bookingStart: (
            <span className={css.nowrap}>
              <FormattedDate
                value={updatedBookingStart}
                {...dateFormatOptions}
                {...timeZoneMaybe}
              />
            </span>
          ),
          bookingEnd: (
            <span className={css.nowrap}>
              <FormattedDate value={updatedBookingEnd} {...dateFormatOptions} {...timeZoneMaybe} />
            </span>
          ),
          payoutTotal: <span className={css.boldText}>{formattedPayoutTotal}</span>,
        }}
      />
    ) : (
      <FormattedMessage
        id="TransactionPanel.bookingUpdateRequestForProviderWithoutMoney"
        values={{
          customerName,
          bookingStart: (
            <span className={css.nowrap}>
              <FormattedDate
                value={updatedBookingStart}
                {...dateFormatOptions}
                {...timeZoneMaybe}
              />
            </span>
          ),
          bookingEnd: (
            <span className={css.nowrap}>
              <FormattedDate value={updatedBookingEnd} {...dateFormatOptions} {...timeZoneMaybe} />
            </span>
          ),
        }}
      />
    );
    return <StatusData title={statusTitle} text={statusText} />;
  }
  if (lastTransition === TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF) {
    statusTitle = <FormattedMessage id="TransactionPanel.tripExtensionRequestStatus" />;
    statusText = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.tripExtensionRequestTextGuest"
        values={{
          providerName,
        }}
      />
    ) : (
      <FormattedMessage
        id="TransactionPanel.tripExtensionRequestTextHost"
        values={{
          customerName,
          bookingStart: (
            <span className={css.nowrap}>
              <FormattedDate
                value={updatedBookingStart}
                {...dateFormatOptions}
                {...timeZoneMaybe}
              />
            </span>
          ),
          bookingEnd: (
            <span className={css.nowrap}>
              <FormattedDate value={updatedBookingEnd} {...dateFormatOptions} {...timeZoneMaybe} />
            </span>
          ),
        }}
      />
    );
    return <StatusData title={statusTitle} text={statusText} />;
  }

  //show after decline edit trip by host
  if (lastTransition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP) {
    statusTitle = isCustomer ? (
      <FormattedMessage id="TransactionPanel.bookingUpdateCancelGuest" />
    ) : (
      <FormattedMessage id="TransactionPanel.bookingUpdateCancelHost" />
    );
    statusText = isCustomer ? (
      <FormattedMessage
        id="TransactionPanel.returnBackAfterCancelBeforePickUpGuest"
        values={{ providerName }}
      />
    ) : (
      <FormattedMessage id="TransactionPanel.returnBackAfterCancelBeforePickUpHost" />
    );

    return <StatusData title={statusTitle} text={statusText} />;
  }
  if (
    lastTransition === TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_REFUNDABLE ||
    lastTransition === TRANSITION_ADMIN_CANCEL_NON_REFUND ||
    lastTransition === TRANSITION_ADMIN_REFUND ||
    lastTransition === TRANSITION_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED
  ) {
    statusTitle = <FormattedMessage id="TransactionPanel.bookingRequestCancel" />;
    return <StatusData title={statusTitle} />;
  }
  if (isProvider && lastTransition === TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED) {
    statusTitle = <FormattedMessage id="TransactionPanel.recivedPickupRequestTitleMobile" />;
    statusText = <FormattedMessage id="TransactionPanel.recivedPickupRequestText" />;
    return <StatusData title={statusTitle} text={statusText} />;
  } else {
    return <StatusData title={'statusTitle'} text={'statusText'} />;
  }
};

export default BookingStatus;
