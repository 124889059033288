import React, { useEffect, useState } from "react";
import css from './TripPanel.css';
import Button from '../Button/Button';
import get from 'lodash/get';
import { getLockboxCode } from '../../util/notification';
import moment from 'moment-timezone';
import lockImg from '../../assets/Lock.svg';
import { Modal } from '../../components';

const LockBoxSection = ({ listing, isTabletLayout = false, isPikUp = false, isOpenModal = false, onConfirm, onClose, findDeviceByType}) => {
    const [lockBoxCode, setLockBoxCode] = useState([]);
    const [expireTime, setExpireTime] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const lockBoxData = findDeviceByType(get(listing, 'attributes.publicData.deviceManagement', {}), 'lockbox');
    const listingId = listing?.id?.uuid;

    const formatDate = (date) => moment(date).format('DD.MM [at] h:mm a');

    useEffect(() => {
        if (lockBoxData?.lockbox?.code && lockBoxCode.length === 0) {
            setLockBoxCode(lockBoxData.lockbox.code.split(''));
            setExpireTime(formatDate(lockBoxData.lockbox.dateValid));
        }
    }, [lockBoxData, lockBoxCode]);

    const handleGenerateCode = async (isForce = false) => {
        setLoading(true);
        try {
            const getCode = await getLockboxCode(listingId, isForce);
            if (getCode) {
                setLockBoxCode(getCode.code.split(''));
                setExpireTime(formatDate(getCode.dateValid));
            }
        } catch (error) {
            console.error("Error generating lockbox code:", error);
        } finally {
            setLoading(false);
        }
    };

    const lockBoxContent = (
        <div>
            <h3 className={`${css.importantLinksTitle} ${css.title}`}>
                {isTabletLayout ? 'Access lockbox' : 'Get access to lockbox'}
            </h3>
            {lockBoxCode.length === 0 ? (
                <p>Generate the code for your lockbox here.</p>
            ) : (
                <>
                    <div className={css.lockBoxKeys}>
                        <p>1. On the lockbox keypad, press ENT button.</p>
                        <p>2. Enter the code mentioned below.</p>
                        <p>3. Press ENT once again and access the keys.</p>
                    </div>
                    <div className={css.unlockCodeWrapper}>
                        {lockBoxCode.map((number, index) => (
                            <span key={index} className={(lockBoxCode.length > 8) ? css.unlockcodeNo : (lockBoxCode.length > 6 ? css.unlockcode : '')}>{number}</span>
                        ))}
                    </div>
                    <div className={css.updateCodeWrapper}>
                        <p>Code valid till {expireTime}.</p>
                        <a onClick={() => handleGenerateCode(true)}>Update and get a new code</a>
                    </div>
                </>
            )}
            {lockBoxCode.length === 0 && !isTabletLayout && (
                <Button onClick={() => handleGenerateCode(false)} className={css.button} disabled={loading}>
                    {loading ? 'Generating...' : 'Generate code'}
                </Button>
            )}
        </div>
    );

    const lockBoxContentWithOutModal = (
        <>
            <div className={css.lockBoxContanier}>
                {!isTabletLayout && lockBoxContent}
                <div className={css.lockBoxMobileCard}>
                    {!isOpen && isTabletLayout && (
                        <div className={css.lockBoxMobileScreenContainer}>
                            <h3 className={css.importantLinksTitle}>Access lockbox</h3>
                            <Button onClick={() => {
                                if (lockBoxCode.length === 0) handleGenerateCode();
                                setIsOpen(true);
                            }} className={css.button} disabled={loading}>
                                {loading ? <span>Loading...</span> : <><img src={lockImg} alt="" />Get code</>}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <Modal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                cancelButtonId="cancelModal"
                containerClassName={css.lockBoxcontainer}
            >
                {lockBoxContent}
            </Modal>
        </>
    );

    const lockBoxContentWithModal = (
        <div>
            <h3 className={`${css.importantLinksTitle} ${css.title}`}>Access lockbox</h3>
            {lockBoxCode.length === 0 ? (
                <p>Generate the code for your lockbox here.</p>
            ) : (
                <div>
                    <div className={css.lockBoxKeys}>
                        <p>1. On the lockbox keypad, press ENT button.</p>
                        <p>2. Enter the code mentioned below.</p>
                        <p>3. Press ENT once again and access the keys.</p>
                    </div>
                    <div className={`${css.unlockCodeWrapper} ${css.lockboxKeyNumber}`}>
                        {lockBoxCode.map((number, index) => (
                            <span key={index} className={(lockBoxCode.length > 8) ? css.unlockcodeNo : (lockBoxCode.length > 6 ? css.unlockcode : css.defulatNumber)}>{number}</span>
                        ))}
                    </div>
                    <div className={css.lockBoxLink}>
                        <span>Having trouble? <a>Contact our team right away.</a></span>
                    </div>
                </div>
            )}
            <Button className={css.unlockedBtn} onClick={lockBoxCode.length === 0 ? handleGenerateCode : () => onConfirm()} disabled={loading}>
                {loading ? 'Generating...' : lockBoxCode.length === 0 ? 'Generate code' : 'Unlocked the car? Continue to next step'}
            </Button>
        </div>
    );

    return (
        <>
            {!isPikUp ? lockBoxContentWithOutModal : (
                <Modal
                    isOpen={isOpenModal}
                    containerClassName={css.modal}
                    onClose={onClose}
                >
                    {lockBoxContentWithModal}
                </Modal>
            )}
        </>
    );
};

export default LockBoxSection;