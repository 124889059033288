import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '..';
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Page,
  PrivacyPolicy,
} from '../../components';
import config from '../../config';

import css from './PrivacyPolicyPage.css';
import { withViewport } from '../../util/contextHelpers';
import { withRouter } from 'react-router-dom';

const PrivacyPolicyPageComponent = props => {
  const { scrollingDisabled, intl, history, location } = props;

  const tabs = [
    {
      text: intl.formatMessage({ id: 'PrivacyPolicyPage.rentalAgreementTabTitle' }),
      selected: false,
      linkProps: {
        name: 'RentalAgreementPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'PrivacyPolicyPage.termsOfServiceTabTitle' }),
      selected: false,
      linkProps: {
        name: 'TermsOfServicePage',
      },
    },
    {
      text: intl.formatMessage({ id: 'PrivacyPolicyPage.tosTabTitle' }),
      selected: true,
      linkProps: {
        name: 'PrivacyPolicyPage',
      },
    },
    {
      text: intl.formatMessage({ id: 'DataDeletionInstruction.tosTabTitle' }),
      selected: false,
      linkProps: {
        name: 'DataDeletionInstruction',
      },
    },
  ];
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'PrivacyPolicyPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'PrivacyPolicyPage.schemaDescription' });
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: schemaTitle,
  };
  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled} schema={schema} description={schemaDescription}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="RentalAgreementPage"
            history={history}
            location={location}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.heading}>
              {/* <FormattedMessage id="PrivacyPolicyPage.heading" /> */}
            </h1>
            <PrivacyPolicy
              history={history}
              location={location}/>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const { bool, object } = PropTypes;

PrivacyPolicyPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  history: object.isRequired,
  location: object.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const PrivacyPolicyPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(PrivacyPolicyPageComponent);

export default PrivacyPolicyPage;
