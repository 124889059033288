import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { bool, func, shape, string } from 'prop-types';
import React, { useRef } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import {
  Button,
  FieldSelect,
  FieldTextInput,
  Form,
  LocationAutocompleteInputField,
} from '../../components';
import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import setFieldTouched from '../../util/setFiledTouched';
import { propTypes } from '../../util/types';
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
  required,
} from '../../util/validators';
import css from './EditListingLocationForm.css';
import clockRegular from '../../assets/updatelisting/clock-regular.svg';
import { drivelahApiPost } from '../../util/apiHelper';

const identity = v => v;

export const EditListingLocationFormComponent = props => {
  const keepLocation = useRef({});
  return (
    <FinalForm
      {...props}
      mutators={{ setFieldTouched }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          form,
          submitButtonId,
        } = formRenderProps;

        if (values.floorUnit) {
          values.floorUnit.replace(/[^\d|-]/g, '');
          if (values.floorUnit.length > 2 && values.floorUnit[2] !== '-') {
            let tempVal = values.floorUnit[0] + values.floorUnit[1] + '-';
            tempVal = tempVal + values.floorUnit.slice(2, values.floorUnit.length);
            values.floorUnit = tempVal;
          }
        }

        const titleRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
        const addressPlaceholderMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressPlaceholder',
        });
        const addressRequiredMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressRequired',
        });
        const addressNotRecognizedMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressNotRecognized',
        });

        const optionalText = intl.formatMessage({
          id: 'EditListingLocationForm.optionalText',
        });

        const stateMessage = intl.formatMessage(
          { id: 'EditListingLocationForm.state' },
          { optionalText: optionalText }
        );
        const statePlaceholderMessage = intl.formatMessage({
          id: 'EditListingLocationForm.statePlaceholder',
        });

        const streetNumberMessage = intl.formatMessage({
          id: 'EditListingLocationForm.streetNumber',
        });
        const streetNumberPlaceholderMessage = intl.formatMessage({
          id: 'EditListingLocationForm.streetNumberPlaceholder',
        });

        const cityMessage = intl.formatMessage({ id: 'EditListingLocationForm.city' });
        const cityPlaceholderMessage = intl.formatMessage({
          id: 'EditListingLocationForm.cityPlaceholder',
        });

        const countryMessage = intl.formatMessage({ id: 'EditListingLocationForm.country' });
        const countryPlaceholderMessage = intl.formatMessage({
          id: 'EditListingLocationForm.countryPlaceholder',
        });

        const streetMessage = intl.formatMessage({ id: 'EditListingLocationForm.streetName' });
        const streetPlaceholderMessage = intl.formatMessage({
          id: 'EditListingLocationForm.streetNamePlaceholder',
        });

        const blockMessage = intl.formatMessage({ id: 'EditListingLocationForm.blockNo' });
        const blockPlaceholderMessage = intl.formatMessage({
          id: 'EditListingLocationForm.blockNoPlaceholder',
        });

        const floorMessage = intl.formatMessage({ id: 'EditListingLocationForm.unitNumber' });
        const floorPlaceholderMessage = intl.formatMessage({
          id: 'EditListingLocationForm.floorPlaceholder',
        });

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessage = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingLocationForm.updateFailed" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingLocationForm.showListingFailed" />
          </p>
        ) : null;

        const cityRequiredMessage = intl.formatMessage({
          id: 'EditListingLocationForm.cityRequired',
        });

        const streetNumberRequiredMessage = intl.formatMessage({
          id: 'EditListingLocationForm.streetNumberRequired',
        });

        const suburbLabel = intl.formatMessage({ id: 'EditListingLocationForm.suburb' });
        const suburbPlaceholder = intl.formatMessage({
          id: 'EditListingLocationForm.suburbPlaceholder',
        });
        const suburbRequired = intl.formatMessage({
          id: 'EditListingLocationForm.suburbRequired',
        });

        const countryRequiredMessage = intl.formatMessage({
          id: 'EditListingLocationForm.countryRequired',
        });

        const postalCodeLabel = intl.formatMessage({ id: 'PayoutDetailsForm.postalCodeLabel' });
        const postalCodePlaceholder = intl.formatMessage({
          id: 'PayoutDetailsForm.postalCodePlaceholder',
        });
        const postalCodeRequired = intl.formatMessage({
          id: 'PayoutDetailsForm.postalCodeRequired',
        });
        const parkingTypeLabel = intl.formatMessage({
          id: 'EditListingLocationForm.parkingTypeLabel',
        });
        const parkingTypeRequired = intl.formatMessage({
          id: 'EditListingLocationForm.parkingTypeRequires',
        });
        const parkingTypePlaceholder = intl.formatMessage({
          id: 'EditListingLocationForm.parkingTypePlaceholder',
        });

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = disabled || submitInProgress;

        const userData = get(props,"userDetails.attributes","");

        // Calling API on the click on Next Button in "About" section while Listing a car for the first time.
        const onNextClickHandler = () => {

          const isDataSent = JSON.parse(localStorage.getItem('isCreateHubspotLeadOnListingPage'))

          // Calling API only if user has no listing.
          if (!isDataSent && (!get(userData, "profile.metadata.intercomUserStat.first_listing_creation_date_at", null))) {

            const query = 'jiraModule/create-hubspot-contact';

            const userDetailsToSent = {
              email: get(userData, "email", ""),
              name: get(userData, "profile.displayName", ""),
              phone: get(userData, "profile.protectedData.phoneNumber", ""),
              original_source_drill_down_1: "web",
              original_source_drill_down_2: "listing_draft"
            };

            drivelahApiPost(query, userDetailsToSent)
              .then(res => {
                console.log("Response >>>", res)
                localStorage.setItem('isCreateHubspotLeadOnListingPage', JSON.stringify(true));
              })
              .catch(err => console.log("Error >>>", err));
          }
        };

        return (
          <Form
            className={classes}
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              if (!invalid) handleSubmit(e);
              const formState = form.getState();
              const { errors } = formState || {};
              Object.keys(errors).forEach(key => form.mutators.setFieldTouched(key, 'true'));
            }}
          >
            {errorMessage}
            {errorMessageShowListing}

            <div className={css.displayInlineContainer}>
              <div className={classNames(css.column, css.column12)}>
                <LocationAutocompleteInputField
                  className={css.streetName}
                  rootClassName={classNames(css.streetName, css.newInput)}
                  inputClassName={css.locationAutocompleteInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  autoFocus
                  name="location"
                  label={streetMessage}
                  placeholder={streetPlaceholderMessage}
                  useDefaultPredictions={false}
                  format={identity}
                  valueFromForm={values.location}
                  validate={composeValidators(
                    autocompleteSearchRequired(addressRequiredMessage),
                    autocompletePlaceSelected(addressNotRecognizedMessage)
                  )}
                />
              </div>

              <Field
                name="location"
                render={({ input }) => {
                  const isSelected = !!get(input, 'value.selectedPlace');
                  const isChanged = !isEqual(
                    keepLocation.current,
                    get(input, 'value.selectedPlace', {})
                  );
                  if (isSelected && isChanged) {
                    const address_components = get(input, 'value.place.address_components', []);

                    const streetNumber = address_components.find(c =>
                      c.types.includes('street_number')
                    );

                    const suburb = address_components.find(c =>
                      c.types.includes('locality')
                    );

                    const postalCode = address_components.find(c =>
                      c.types.includes('postal_code')
                    );
                    const state = address_components.find(c =>
                      c.types.includes('administrative_area_level_1')
                    );
                    const city = address_components.find(
                      c =>
                        c.types.includes('administrative_area_level_2') ||
                        c.types.includes('colloquial_area')
                    );
                    if (streetNumber) {
                      form.batch(() => {
                        form.change('streetNumber', streetNumber.short_name);
                      });
                    }
                    if (suburb) {
                      form.batch(() => {
                        form.change('suburb', suburb.short_name);
                      });
                    }
                    if (postalCode) {
                      form.batch(() => {
                        form.change('postalCode', postalCode.short_name);
                      });
                    }
                    if (state) {
                      form.batch(() => {
                        form.change('state', camelCase(state.long_name));
                      });
                    }
                    if (city) {
                      form.batch(() => {
                        form.change('city', city.short_name);
                      });
                    }
                  }
                  keepLocation.current = input.value.selectedPlace;
                  return null;
                }}
              />

              <div className={classNames(css.column, css.column4)}>
                <FieldTextInput
                  className={classNames(css.streetNumber, css.newInput)}
                  type="text"
                  name="streetNumber"
                  id="streetNumber"
                  label={streetNumberMessage}
                  placeholder={streetNumberPlaceholderMessage}
                  validate={composeValidators(required(streetNumberRequiredMessage))}
                />
              </div>

              <div className={classNames(css.column, css.column4)}>
                <FieldTextInput
                  className={classNames(css.floor, css.newInput)}
                  type="text"
                  name="unitNumber"
                  id="unitNumber"
                  label={floorMessage}
                  placeholder={floorPlaceholderMessage}
                />
              </div>
              <div className={classNames(css.column, css.column4)}>
                <FieldTextInput
                  className={classNames(css.city, css.newInput)}
                  type="text"
                  name="city"
                  id="city"
                  label={cityMessage}
                  placeholder={cityPlaceholderMessage}
                  validate={composeValidators(required(cityRequiredMessage))}
                />
              </div>
              <div className={classNames(css.column, css.column4)}>
                <FieldTextInput
                  className={classNames(css.city, css.newInput)}
                  type="text"
                  name="suburb"
                  id="suburb"
                  label={suburbLabel}
                  placeholder={suburbPlaceholder}
                  readOnly={true}
                  validate={composeValidators(required(suburbRequired))}
                />
              </div>
              <div className={classNames(css.column, css.column6)}>
                <FieldTextInput
                  className={classNames(css.building, css.newInput)}
                  name="state"
                  id="state"
                  label={stateMessage}
                  placeholder={statePlaceholderMessage}
                  validate={composeValidators(required(postalCodeRequired))}
                />
              </div>
              <div className={classNames(css.column, css.column6)}>
                <FieldTextInput
                  className={classNames(css.floor, css.newInput)}
                  type="text"
                  name="postalCode"
                  id="postalCode"
                  label={postalCodeLabel}
                  placeholder={postalCodePlaceholder}
                  validate={composeValidators(required(postalCodeRequired))}
                />
              </div>
              <div className={classNames(css.column, css.column6)}>
                <FieldSelect
                  className={classNames(css.building, css.newInput)}
                  name="parkingType"
                  id="parkingType"
                  label={parkingTypeLabel}
                  validate={composeValidators(required(parkingTypeRequired))}
                >
                  <option disabled value="">
                    {parkingTypePlaceholder}
                  </option>
                  {config.custom.parkingType.map(s => (
                    <option key={s.key} value={s.key}>
                      {s.label}
                    </option>
                  ))}
                </FieldSelect>
              </div>
            </div>

            <div className={css.stickyButtons}>
              <div className={css.stickButtonsContainer}>
                <div className={css.stickButtonsDescription}>
                  <span className={css.descIcon}>
                    <img src={clockRegular} alt="" />
                  </span>
                  <p>
                    <strong>Glad to get you started:</strong> Listing process usually take around
                    7-10 mins to complete.
                  </p>
                </div>
                <div className={css.stickButton}>
                  <Button
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={submitReady}
                    id={submitButtonId}
                    onClick = {onNextClickHandler}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  submitButtonId: string,
};

export default compose(injectIntl)(EditListingLocationFormComponent);
