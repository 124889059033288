import React from 'react';
import css from './StartTripPanel.css';
import locationImage from '../../assets/newPickupAndDropoff/mapsk.png';

import { PrimaryButton } from '..';

export default function NeedUserPermission({ getDistanceFromPickUp, listingForMap, setLocationPermission}) {

  const handleClick = () => {
    const { geolocation } = listingForMap.attributes;
    getDistanceFromPickUp(geolocation);
  };

  React.useEffect(() => {
    const handlePermission = async () => {

      try {
        const permissionStatus = await navigator.permissions.query({ name: 'geolocation' })
        setLocationPermission(permissionStatus.state);
        permissionStatus.onchange = () => {
          setLocationPermission(permissionStatus.state);

          handleClick();
        };
      } catch (error) {
        console.error('Error querying geolocation permission:', error);
      }
    };

    handlePermission();

    return () => {
      const permissionStatus = navigator.permissions.query({ name: 'geolocation' });
      if (permissionStatus) {
        permissionStatus.onchange = '';
      }
    };
  }, []);

  return (
    <div className={css.locationMap} style={{ backgroundImage: `url(${locationImage})` }}>
      <div className={css.messageWrapper}>
        <span>
          We need location access to ensure you are at the correct location to start the trip. Please allow location access in your browser settings.
        </span>
      </div>
      <PrimaryButton onClick={() => handleClick()} className={css.buttonAccepted}>
        Give permission
      </PrimaryButton>
    </div>
  );
}
