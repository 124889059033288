import React, { Fragment, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import css from './LongTermRentalListingPage.css';
import { default as RangeSlider } from 'react-rangeslider';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage } from '../../util/reactIntl';
import { Button, Form } from '../../components';
import LineItemBasePriceMaybe from './LongTermPriceBreakdown/LineItemBasePriceMaybe';
import LineItemInsuranceMaybe from './LongTermPriceBreakdown/LineItemInsuranceMaybe';
import LineItemMileageMaybe from './LongTermPriceBreakdown/LineItemMileageMaybe';
import LineAdditionalDriverMaybe from './LongTermPriceBreakdown/LineAdditionalDriverMaybe';
import LineItemTotalPrice from './LongTermPriceBreakdown/LineItemTotalPrice';
import { formatMoney } from '../../util/currency';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

const ConfigureRent = props => {
  const { getProtectionValue, getMileageValue, getAdditionaDriverValue, currentUser } = props;
  const [value1, setValue1] = useState(110);
  const [value2, setValue2] = useState(110);
  const [mileageValues, setMileageValues] = useState(0);
  const [protectionValue, setProtectionValue] = useState(0);
  const basicProtectionRef = useRef(null);
  const guestIdentityVerificationStatus = get(currentUser, "attributes.profile.publicData.guestIdentityVerificationStatus");

  const mileageSliderLabels = {
    0: '500kms',
    40: '700kms',
    75: '900kms',
    99: 'Unlimited',
  };

  useEffect(() => {
    if (basicProtectionRef.current) {
      basicProtectionRef.current.click();
    }
  }, []);

  const handle1Change = value1 => {
    setValue1(value1);
  };

  const handleValue2Change = mileage => {
    let mileageValue;
    let getMileage;
    switch (mileage) {
      case 35:
        mileageValue = 40;
        getMileage = 700;
        break;
      case 70:
        mileageValue = 75;
        getMileage = 900;
        break;
      case 99:
        mileageValue = 99;
        getMileage = 'Unlimited';
        break;
      default:
        mileageValue = 0;
        getMileage = 500;
        break;
    }
    setMileageValues(mileageValue);
    getMileageValue(getMileage);
  };

  const _handleProtectionChange = e => {
    setProtectionValue(e.target.value);
    const value =
      e.target.value === '0'
        ? 'Basic protection'
        : e.target.value === '120'
        ? 'Premium Protection'
        : e.target.value === '300'
        ? 'Maximum Protection'
        : '';
    getProtectionValue(value);
  };

  const _handleToggleChange = e => {
    getAdditionaDriverValue(e.target.checked ? 'Yes' : 'No');
  };

  const redirect = () => {
    if (typeof window !== 'undefined')
    return guestIdentityVerificationStatus === "confirmed" ? window.location.pathname + "/select-pickup-dates" : window.location.pathname + "/verification";
  };

  return (
    <div>
          <Fragment>
              <FinalForm
                {...props}
                render={formRenderProps => {
                  const { handleSubmit, listing, intl, values, setTotalPrice, totalPrice } = formRenderProps;
                  const additionalDriverCost = values.additionalDriver ? 50 : 0;
                  const bookingObj = JSON.stringify({ mileageValues, totalPrice, protectionValue })
                  if(typeof window !== 'undefined')
                    window.localStorage.setItem(listing.attributes.publicData.listingId, bookingObj)
                  return (
                    <Form onSubmit={handleSubmit}>
                      <div className={css.contentContainer}>
                        <div className={`${css.row} ${css.selectMileageWrapper}`}>
                          <div className={`${css.column70} ${css.selectMileage}`}>
                            <div className={classNames(css.rentalCard, css.rentalCardMobile)}>
                              <div className={css.mainContent}>
                                <div className={css.selection}>
                                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h3>Select mileage/month</h3>
                                    {mileageValues ? <span style={{ floatRight: true }}
                                                           className={css.displayInDesktop}>${mileageValues}/week</span> : ''}

                                  </div>

                                  <div className={css.sliderfill}>
                                    {' '}
                                    <RangeSlider
                                      min={0}
                                      max={99}
                                      step={35}
                                      labels={mileageSliderLabels}
                                      tooltip={false}
                                      value={mileageValues}
                                      onChange={handleValue2Change}
                                      className={css.sliderCss}
                                    />
                                  </div>
                                  <p style={{
                                    fontSize: 12,
                                    lineHeight: 'normal',
                                    marginTop: '20px',
                                  }}>
                                    If you exceed your weekly mileage limit, then you will be
                                    charged
                                    $0.3/km
                                    for
                                    excess kms driven.
                                  </p>
                                </div>
                                <div className={css.border}></div>
                                {/* <div className={css.selection}>
                                  <h3>Select duration</h3>
                                  <div className={css.sliderfill}>
                                    {' '}
                                    <RangeSlider
                                      min={100}
                                      max={1000}
                                      value={value1}
                                      onChange={handle1Change}
                                      className={css.sliderCss}
                                    />
                                  </div>
                                </div> */}
                                <div className={css.border}></div>
                                <div className={css.selection}>
                                  <h3>Choose your protection</h3>
                                  <div className={classNames(css.customButtons, css.fields)}>
                                    <div className={css.customRadio}>
                                      <div className={css.flexRow}>
                                        <div>
                                          <input
                                            type="radio"
                                            value={'0'}
                                            onChange={_handleProtectionChange}
                                            name="protection"
                                            id="basic"
                                            ref={basicProtectionRef}
                                          />
                                          <label htmlFor="basic">Basic Protection</label>
                                        </div>
                                        <span
                                          className={css.displayInDesktop}>Included in price</span>
                                      </div>
                                      <span>$3000 excess + roadside assistance</span>
                                      <span className={css.displayInMobile}>Included in price</span>
                                    </div>
                                    <div className={css.customRadio}>
                                      <div className={css.flexRow}>
                                        <div>
                                          <input
                                            type="radio"
                                            value={30}
                                            onChange={_handleProtectionChange}
                                            name="protection"
                                            id="premium"
                                          />
                                          <label htmlFor="premium">Premium Protection</label>
                                        </div>
                                        <span className={css.displayInDesktop}>$30/week</span>
                                      </div>
                                      <span>$1500 excess</span>
                                      <span className={css.displayInMobile}>$30/week</span>
                                    </div>

                                    <div className={css.customRadio}>
                                      <div className={css.flexRow}>
                                        <div>
                                          <input
                                            type="radio"
                                            value={50}
                                            onChange={_handleProtectionChange}
                                            name="protection"
                                            id="maximum"
                                          />
                                          <label htmlFor="maximum">Maximum Protection</label>
                                        </div>
                                        <span className={css.displayInDesktop}>$50/week</span>
                                      </div>
                                      <span>$500 excess</span>
                                      <span className={css.displayInMobile}>$50/week</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={`${css.column30} ${css.priceWrapper}`}>
                            <div className={classNames(css.rentalCard, css.rentalCardMobile)}>
                              <div className={css.mainContent}>
                                <div className={css.priceBreakdown}>
                                  <h3>Price breakdown</h3>
                                  <div className={css.border} style={{
                                    marginBottom: "10px"
                                  }}></div>
                                  <div className={css.prices}>
                                    <LineItemBasePriceMaybe
                                      listing={listing}
                                      additionalCost={mileageValues}
                                      intl={intl}
                                    />
                                    <LineItemMileageMaybe
                                      listing={listing}
                                      additionalCost={mileageValues}
                                      intl={intl}
                                    />
                                    <LineItemInsuranceMaybe
                                      listing={listing}
                                      insuranceCost={protectionValue}
                                      intl={intl}
                                    />
                                    <LineAdditionalDriverMaybe
                                      listing={listing}
                                      additionalDriverCost={additionalDriverCost}
                                      intl={intl}
                                    />
                                    {/* <LineProcessingFeesMaybe listing={listing} intl={intl} /> */}
                                  </div>
                                  <LineItemTotalPrice
                                    additionalCost={mileageValues}
                                    insuranceCost={parseInt(protectionValue) || 0}
                                    additionalDriverCost={additionalDriverCost}
                                    listing={listing}
                                    intl={intl}
                                    setTotalPrice={setTotalPrice}
                                  />
                                   <div className={css.border}></div>
                                   <div className={css.monthlyPriceWrapper}>
                                      <h3>Monthly price</h3>
                                      <span>{totalPrice && formatMoney(intl, totalPrice, 1)}</span>
                                   </div>
                                  <div className={css.message} >
                                    Processing fees of 4% will apply on top of the total price.
                                  </div>
                                </div>
                               
                                <div className={css.rentalButton}>
                                  <div 
                                  // style={{
                                  //   padding: "20px",
                                  // }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <span className={css.head}>{totalPrice && formatMoney(intl, totalPrice, 1)}</span>
                                      <span style={{
                                        alignSelf: 'end',
                                        fontSize: "16px",
                                        fontWeight: 500,
                                        color: "#4A4A4A"
                                      }}>/week</span>
                                    </div>
                                  </div>
                                  <div 
                                  // style={{
                                  //   margin: "20px",
                                  //   alignSelf: 'center'
                                  // }}
                                  >
                                    
                                    <Link to ={{
                                      pathname: redirect(),
                                      state: {
                                       totalPrice, listing, 
                                      }
                                    }}>
                                      <Button rootClassName={css.button}>
                                        <FormattedMessage id="RentalPage.Iaminterested" />
                                      </Button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              />
          </Fragment>
    </div>
  );
};

export default ConfigureRent;
