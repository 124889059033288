/**
 * Note: This form is using card from Stripe Elements https://stripe.com/docs/stripe-js#elements
 * Card is not a Final Form field so it's not available trough Final Form.
 * It's also handled separately in handleSubmit function.
 */
import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl,
  intlShape,
} from '../../util/reactIntl';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensurePaymentMethodCard } from '../../util/data';
import {
  EVENT_BOOK_ENTERED_ADDRESS,
  EVENT_BOOK_ENTERED_CARD_DETAILS,
} from '../../util/gtm/gtmConstants';
import {
  FieldCheckbox,
  FieldTextInput,
  Form,
  IconSpinner,
  PrimaryButton,
  SavedCardDetails,
  StripePaymentAddress,
} from '../../components';
import css from './StripePaymentForm.css';
import stripeIconImage from '../../assets/stripeIcon.svg';
import privacyIcon from '../../assets/lock-solid.svg';
import LineItemTotalPriceNew from '../../components/BookingBreakdown/LineItemTotalPriceNew';
import * as validators from '../../util/validators';
/**
 * Translate a Stripe API error object.
 *
 * To keep up with possible keys from the Stripe API, see:
 *
 * https://stripe.com/docs/api#errors
 *
 * Note that at least at moment, the above link doesn't list all the
 * error codes that the API returns.
 *
 * @param {Object} intl - react-intl object from injectIntl
 * @param {Object} stripeError - error object from Stripe API
 *
 * @return {String} translation message for the specific Stripe error,
 * or the given error message (not translated) if the specific error
 * type/code is not defined in the translations
 *
 */
const stripeErrorTranslation = (intl, stripeError) => {
  const { message, code, type } = stripeError;

  if (!code || !type) {
    // Not a proper Stripe error object
    return intl.formatMessage({ id: 'StripePaymentForm.genericError' });
  }

  const translationId =
    type === 'validation_error'
      ? `StripePaymentForm.stripe.validation_error.${code}`
      : `StripePaymentForm.stripe.${type}`;

  return intl.formatMessage({
    id: translationId,
    defaultMessage: message,
  });
};

const stripeElementsOptions = {
  fonts: [
    {
      family: 'sofiapro',
      fontSmoothing: 'antialiased',
      src:
        'local("sofiapro"), local("SofiaPro"), local("Sofia Pro"), url("https://assets-sharetribecom.sharetribe.com/webfonts/sofiapro/sofiapro-medium-webfont.woff2") format("woff2")',
    },
  ],
};

const cardStyles = {
  base: {
    fontFamily: '"sofiapro", Helvetica, Arial, sans-serif',
    fontSize: '18px',
    fontSmoothing: 'antialiased',
    lineHeight: '24px',
    letterSpacing: '-0.1px',
    color: '#4A4A4A',
    '::placeholder': {
      color: '#B2B2B2',
    },
  },
};

const OneTimePaymentWithCardElement = props => {
  const {
    cardClasses,
    formId,
    handleStripeElementRef,
    hasCardError,
    error,
    label,
    intl,
    isPayForFuel,
    isLongTermRental,
    showCheckBoxInfo = true
  } = props;

  const labelText =
    label || intl.formatMessage({ id: 'StripePaymentForm.saveAfterOnetimePayment' });
  return (
    <React.Fragment>
      <label className={css.paymentLabel} htmlFor={`${formId}-card`}>
        <FormattedMessage id="StripePaymentForm.paymentCardDetails" />
      </label>
      <div className={cardClasses} id={`${formId}-card`} ref={handleStripeElementRef} />
      {hasCardError ? <span className={css.error}>{error}</span> : null}
     {showCheckBoxInfo && <div className={css.saveForLaterUse}>
        <FieldCheckbox
          className={css.saveForLaterUseCheckbox}
          textClassName={css.saveForLaterUseLabel}
          id="saveAfterOnetimePayment"
          name="saveAfterOnetimePayment"
          label={labelText}
          value="saveAfterOnetimePayment"
          useSuccessColor
          checked={isPayForFuel || isLongTermRental ? true : undefined}
        />
        <span className={css.saveForLaterUseLegalInfo}>
          <FormattedMessage id="StripePaymentForm.saveforLaterUseLegalInfo" />
        </span>
  </div>}
    </React.Fragment>
  );
};

const PaymentMethodSelector = props => {
  const {
    cardClasses,
    formId,
    changePaymentMethod,
    defaultPaymentMethod,
    handleStripeElementRef,
    hasCardError,
    error,
    paymentMethod,
    setIsUsingMastercard,
    isUsingMastercard,
    intl,
    isPayForFuel,
    currentUser,
    isLongTermRental,
    isUpdateBooking
  } = props;
  const last4Digits = defaultPaymentMethod.attributes.card.last4Digits;
  const labelText = intl.formatMessage(
    { id: 'StripePaymentForm.replaceAfterOnetimePayment' },
    { last4Digits }
  );

  if (paymentMethod === 'defaultCard') {
    const { brand } = defaultPaymentMethod.attributes.card;
    if (!isUsingMastercard && brand === 'mastercard') setIsUsingMastercard(true);
    if (isUsingMastercard && brand !== 'mastercard') setIsUsingMastercard(false);
  }

  return (
    <React.Fragment>
      <h3 className={css.paymentHeading}>
        <FormattedMessage id="StripePaymentForm.payWithHeading" />
      </h3>
      <SavedCardDetails
        className={css.paymentMethodSelector}
        card={defaultPaymentMethod.attributes.card}
        currentUser={currentUser}
        onChange={changePaymentMethod}
        isUpdateBooking={isUpdateBooking}
      />
      {paymentMethod === 'replaceCard' ? (
        <OneTimePaymentWithCardElement
          cardClasses={cardClasses}
          formId={formId}
          handleStripeElementRef={handleStripeElementRef}
          hasCardError={hasCardError}
          error={error}
          label={labelText}
          intl={intl}
          isPayForFuel={isPayForFuel}
          isLongTermRental={isLongTermRental}
        />
      ) : null}
    </React.Fragment>
  );
};

const getPaymentMethod = (selectedPaymentMethod, hasDefaultPaymentMethod) => {
  return selectedPaymentMethod == null && hasDefaultPaymentMethod
    ? 'defaultCard'
    : selectedPaymentMethod == null
    ? 'onetimeCardPayment'
    : selectedPaymentMethod;
};

const initialState = {
  error: null,
  cardValueValid: false,
  // The mode can be 'onetimePayment', 'defaultCard', or 'replaceCard'
  // Check SavedCardDetails component for more information
  paymentMethod: null,
  pushedEnterCardGTM: false,
  pushedEnterAddressGTM: false,
};

/**
 * Payment form that asks for credit card info using Stripe Elements.
 *
 * When the card is valid and the user submits the form, a request is
 * sent to the Stripe API to handle payment. `stripe.handleCardPayment`
 * may ask more details from cardholder if 3D security steps are needed.
 *
 * See: https://stripe.com/docs/payments/payment-intents
 *      https://stripe.com/docs/elements
 */
class StripePaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleCardValueChange = this.handleCardValueChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.paymentForm = this.paymentForm.bind(this);
    this.initializeStripeElement = this.initializeStripeElement.bind(this);
    this.handleStripeElementRef = this.handleStripeElementRef.bind(this);
    this.changePaymentMethod = this.changePaymentMethod.bind(this);
    this.finalFormAPI = null;
    this.cardContainer = null;
  }

  componentDidMount() {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for StripePaymentForm');
    }

    if (config.stripe.publishableKey) {
      const {
        onStripeInitialized,
        hasHandledCardPayment,
        defaultPaymentMethod,
        loadingData,
      } = this.props;
      this.stripe = window.Stripe(config.stripe.publishableKey);
      onStripeInitialized(this.stripe);

      if (!(hasHandledCardPayment || defaultPaymentMethod || loadingData)) {
        this.initializeStripeElement();
      }
    }
  }

  componentWillUnmount() {
    if (this.card) {
      this.card.removeEventListener('change', this.handleCardValueChange);
      this.card.unmount();
      this.card = null;
    }
  }

  // onChangeBillDetail = value => {
  //   const { pushedEnterAddressGTM } = this.state;
  //   const { pushEventEnterFormGTM } = this.props;
  //   if (!pushedEnterAddressGTM) {
  //     const { modified = {} } = value;
  //     const { addressLine1, addressLine2, city, country, state } = modified;
  //     if (addressLine1 || addressLine2 || city || country || state) {
  //       pushEventEnterFormGTM(EVENT_BOOK_ENTERED_ADDRESS);
  //       this.setState(oldState => ({
  //         ...oldState,
  //         pushedEnterAddressGTM: true,
  //       }));
  //     }
  //   }
  // };

  initializeStripeElement(element) {
    const elements = this.stripe.elements(stripeElementsOptions);

    if (!this.card) {
      this.card = elements.create('card', { style: cardStyles });
      this.card.mount(element || this.cardContainer);
      this.card.addEventListener('change', this.handleCardValueChange);
      // EventListener is the only way to simulate breakpoints with Stripe.
      window.addEventListener('resize', () => {
        if (this.card) {
          if (window.innerWidth < 1024) {
            this.card.update({ style: { base: { fontSize: '18px', lineHeight: '24px' } } });
          } else {
            this.card.update({ style: { base: { fontSize: '20px', lineHeight: '32px' } } });
          }
        }
      });
    }
  }

  changePaymentMethod(changedTo) {
    if (this.card && changedTo === 'defaultCard') {
      this.card.removeEventListener('change', this.handleCardValueChange);
      this.card.unmount();
      this.card = null;
    }
    this.setState({ paymentMethod: changedTo });
  }

  handleStripeElementRef(el) {
    this.cardContainer = el;
    if (this.stripe && el) {
      this.initializeStripeElement(el);
    }
  }

  handleCardValueChange(event) {
    const { intl, setIsUsingMastercard, isUsingMastercard, pushEventEnterFormGTM } = this.props;
    const { pushedEnterCardGTM } = this.state;
    const { error, complete } = event;
    if (!pushedEnterCardGTM) {
      pushEventEnterFormGTM(EVENT_BOOK_ENTERED_CARD_DETAILS);
      this.setState(state => ({
        ...state,
        pushedEnterCardGTM: true,
      }));
    }
    if (event.brand && event.brand === 'mastercard' && !isUsingMastercard) {
      setIsUsingMastercard(true);
    }
    if (event.brand && event.brand !== 'mastercard' && isUsingMastercard) {
      setIsUsingMastercard(false);
    }
    const postalCode = event.value.postalCode;
    if (this.finalFormAPI) {
      this.finalFormAPI.change('postal', postalCode);
    }

    this.setState(prevState => {
      return {
        error: error ? stripeErrorTranslation(intl, error) : null,
        cardValueValid: complete,
      };
    });
  }
  handleSubmit(values) {
    const {
      onSubmit,
      inProgress,
      formId,
      hasHandledCardPayment,
      defaultPaymentMethod,
      isPayForFuel,
      isLongTermRental,
    } = this.props;
    const { initialMessage } = values;
    const { cardValueValid, paymentMethod } = this.state;
    const billingDetailsKnown = hasHandledCardPayment || defaultPaymentMethod;
    const onetimePaymentNeedsAttention = !billingDetailsKnown && !cardValueValid;

    const ensurePaymentMethodCardId = ensurePaymentMethodCard(defaultPaymentMethod).id

    if (inProgress || onetimePaymentNeedsAttention) {
      // Already submitting or card value incomplete/invalid
      return;
    }
    if (isPayForFuel || isLongTermRental || !ensurePaymentMethodCardId) {
      values.saveAfterOnetimePayment = ['saveAfterOnetimePayment'];
    }

    const params = {
      message: initialMessage ? initialMessage.trim() : null,
      card: this.card,
      formId,
      formValues: values,
      paymentMethod: getPaymentMethod(
        paymentMethod,
        ensurePaymentMethodCardId
      ),
    };
    onSubmit(params);
  }

  paymentForm(formRenderProps) {
    const {
      className,
      rootClassName,
      inProgress: submitInProgress,
      loadingData,
      formId,
      paymentInfo,
      authorDisplayName,
      showInitialMessageInput,
      intl,
      initiateOrderError,
      handleCardPaymentError,
      confirmPaymentError,
      invalid,
      handleSubmit,
      form,
      hasHandledCardPayment,
      defaultPaymentMethod,
      isInstantBooking,
      setIsUsingMastercard,
      isUsingMastercard,
      submitButtonId,
      isLongTermRental,
      isPayForFuel,
      initialValues,
      isDelivery,
      isDeliveryState,
      onChangeDelivery,
      currentUser,
      transaction,
      isUpdateBooking
    } = formRenderProps;
    this.finalFormAPI = form;

    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(defaultPaymentMethod);
    const billingDetailsNeeded = !(hasHandledCardPayment || confirmPaymentError);
    const billingDetailsKnown = hasHandledCardPayment || ensuredDefaultPaymentMethod;
    const onetimePaymentNeedsAttention = !billingDetailsKnown && !this.state.cardValueValid;
    const submitDisabled = invalid || onetimePaymentNeedsAttention || submitInProgress;
    const hasCardError = this.state.error && !submitInProgress;
    const hasPaymentErrors = handleCardPaymentError || confirmPaymentError;
    const classes = classNames(rootClassName || css.root, className);
    const cardClasses = classNames(css.card, {
      [css.cardSuccess]: this.state.cardValueValid,
      [css.cardError]: hasCardError,
    });

    // TODO: handleCardPayment can create all kinds of errors.
    // Currently, we provide translation support for one:
    // https://stripe.com/docs/error-codes

    const piAuthenticationFailure = 'payment_intent_authentication_failure';
    const paymentErrorMessage =
      handleCardPaymentError && handleCardPaymentError.code === piAuthenticationFailure
        ? intl.formatMessage({ id: 'StripePaymentForm.handleCardPaymentError' })
        : handleCardPaymentError
        ? handleCardPaymentError.message
        : confirmPaymentError
        ? intl.formatMessage({ id: 'StripePaymentForm.confirmPaymentError' })
        : intl.formatMessage({ id: 'StripePaymentForm.genericError' });

    const billingDetailsNameLabel = intl.formatMessage({
      id: 'StripePaymentForm.billingDetailsNameLabel',
    });

    const billingDetailsNamePlaceholder = intl.formatMessage({
      id: 'StripePaymentForm.billingDetailsNamePlaceholder',
    });

    const messagePlaceholder = intl.formatMessage(
      { id: 'StripePaymentForm.messagePlaceholder' },
      { name: authorDisplayName }
    );

    const messageOptionalText = intl.formatMessage({
      id: 'StripePaymentForm.messageOptionalText',
    });

    const initialMessageLabel = intl.formatMessage(
      { id: 'StripePaymentForm.messageLabel' },
      { messageOptionalText: messageOptionalText }
    );

    const deliveryLabel = intl.formatMessage({ id: 'StripePaymentForm.deliveryLabel' });

    // Asking billing address is recommended in PaymentIntent flow.
    // In CheckoutPage, we send name and email as billing details, but address only if it exists.
    const billingAddress = (
      <StripePaymentAddress
        intl={intl}
        form={form}
        fieldId={formId}
        card={this.card}
        initialValues={initialValues}
      />
    );

    const hasStripeKey = config.stripe.publishableKey;
    const showPaymentMethodSelector = ensuredDefaultPaymentMethod.id;
    const selectedPaymentMethod = getPaymentMethod(
      this.state.paymentMethod,
      showPaymentMethodSelector
    );
    const showOnetimePaymentFields = ['onetimeCardPayment', 'replaceCard'].includes(
      selectedPaymentMethod
    );

    const cardHolderNameRequired = validators.required(
      intl.formatMessage({
        id: 'StripePaymentAddress.cardHolderNameRequired',
      })
    );

    return hasStripeKey ? (
      <Form className={classes} onSubmit={handleSubmit}>
        {/* <FormSpy subscription={{ modified: true }} onChange={this.onChangeBillDetail} /> */}
        <div className={css.cardOuter}>
          {billingDetailsNeeded && !loadingData ? (
            <React.Fragment>
              {showPaymentMethodSelector ? (
                <PaymentMethodSelector
                  cardClasses={cardClasses}
                  formId={formId}
                  defaultPaymentMethod={ensuredDefaultPaymentMethod}
                  changePaymentMethod={this.changePaymentMethod}
                  handleStripeElementRef={this.handleStripeElementRef}
                  hasCardError={hasCardError}
                  error={this.state.error}
                  paymentMethod={selectedPaymentMethod}
                  setIsUsingMastercard={setIsUsingMastercard}
                  isUsingMastercard={isUsingMastercard}
                  intl={intl}
                  isPayForFuel={isPayForFuel}
                  isLongTermRental={isLongTermRental}
                  isUpdateBooking={isUpdateBooking}
                />
              ) : (
                <React.Fragment>
                  {/* <h3 className={css.paymentHeading}>
                    <FormattedMessage id="StripePaymentForm.paymentHeading" />
                  </h3> */}
                  <OneTimePaymentWithCardElement
                    cardClasses={cardClasses}
                    formId={formId}
                    handleStripeElementRef={this.handleStripeElementRef}
                    hasCardError={hasCardError}
                    error={this.state.error}
                    intl={intl}
                    isPayForFuel={isPayForFuel}
                    isLongTermRental={isLongTermRental}
                    showCheckBoxInfo={false}
                  />
                </React.Fragment>
              )}
              {!showOnetimePaymentFields ? (
                <div className={css.stripeHeading}>
                  <p>
                    <span className={css.privacyIcon}>
                      <img src={privacyIcon} alt="Powered by Stripe" />
                    </span>
                    <FormattedHTMLMessage id="StripePaymentForm.stripeHeading" />
                  </p>
                  <span className={css.stripeIcon}>
                    <img src={stripeIconImage} alt="Powered by Stripe" />
                  </span>
                </div>
              ) : (
                ''
              )}
              {showOnetimePaymentFields ? (
                <div className={css.paymentAddressField}>
                  <h3 className={css.billingHeading}>
                    <FormattedMessage id="StripePaymentForm.billingDetails" />
                  </h3>

                  <FieldTextInput
                    className={classNames(css.field, css.formInputNew)}
                    type="text"
                    id="name"
                    name="name"
                    autoComplete="cc-name"
                    validate={cardHolderNameRequired}
                    label={billingDetailsNameLabel}
                    placeholder={billingDetailsNamePlaceholder}
                    
                  />

                  {billingAddress}
                  <div className={css.stripeHeadingNewAddress}>
                    <p>
                      <span className={css.privacyIcon}>
                        <img src={privacyIcon} alt="Powered by Stripe" />
                      </span>
                      <FormattedHTMLMessage id="StripePaymentForm.stripeHeading" />
                    </p>
                    <span className={css.stripeIcon}>
                      <img src={stripeIconImage} alt="Powered by Stripe" />
                    </span>
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          ) : loadingData ? (
            <p className={css.spinner}>
              <IconSpinner />
            </p>
          ) : null}
          {isLongTermRental && (
            <div className={css.longTermHintText}>
              <FormattedMessage id="StripePaymentForm.longTermRentalHint" />
            </div>
          )}
          {initiateOrderError ? (
            <span className={css.errorMessage}>{initiateOrderError.message}</span>
          ) : null}
        </div>
        {/*<div className={css.cardOuter}>*/}
        {/*  <h3 className={css.discountHeading}>Apply discount</h3>*/}
        {/*  <div className={css.discountBlock}>*/}
        {/*    <ul>*/}
        {/*      <li>*/}
        {/*        <div className={css.customRadio}>*/}
        {/*          <input type="radio" id="promoCode" name="discountSelection" />*/}
        {/*          <label htmlFor="promoCode">Promo Code</label>*/}
        {/*        </div>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <div className={css.customRadio}>*/}
        {/*          <input type="radio" id="promotionalCredits" name="discountSelection" />*/}
        {/*          <label htmlFor="promotionalCredits">Apply your promotional credits</label>*/}
        {/*        </div>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <div className={css.customRadio}>*/}
        {/*          <input type="radio" id="walletBalance" name="discountSelection" />*/}
        {/*          <label htmlFor="walletBalance">Use wallet balance</label>*/}
        {/*        </div>*/}
        {/*      </li>*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {showInitialMessageInput ? (
          <div className={css.cardOuter}>
            <div>
              <h3 className={css.messageHeading}>Add a message for the host</h3>
              <FieldTextInput
                type="textarea"
                id={`${formId}-message`}
                name="initialMessage"
                placeholder={messagePlaceholder}
                className={classNames(css.message, css.formInputNew)}
              />
            </div>
          </div>
        ) : null}

        <div className={css.submitSection}>
          <div className={css.submitSectionInner}>
            {transaction ? (
              <div className={css.submitSectionPrice}>
                <span className={css.totalAmount}>
                  <LineItemTotalPriceNew
                    currentUser={currentUser}
                    transaction={transaction}
                    isProvider={false}
                    intl={intl}
                  />
                </span>
                Total Amount
              </div>
            ) : null}
            <div className={css.submitSectionPrice}>
              <div className={css.submitContainer}>
                {hasPaymentErrors ? (
                  <span className={css.errorMessage}>
                    <FormattedMessage
                      id="StripePaymentForm.stripe.cardError"
                      values={{ message: paymentErrorMessage }}
                    />
                  </span>
                ) : null}
                {/* {!isInstantBooking ? <p className={css.paymentInfo}>{paymentInfo}</p> : null} */}
                <PrimaryButton
                  id={submitButtonId}
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  {/*{billingDetailsNeeded ? (*/}
                  {/*  <FormattedMessage id="StripePaymentForm.submitPaymentInfo" />*/}
                  {/*) : (*/}
                  {/*  <FormattedMessage id="StripePaymentForm.submitConfirmPaymentInfo" />*/}
                  {/*)}*/}
                  {isInstantBooking ? (
                    <FormattedMessage id="StripePaymentForm.submitPaymentInfoInstant" />
                  ) : this.props.isAddons ? (
                    <FormattedMessage id="StripePaymentForm.bookNow" />
                  ) : (
                    <FormattedMessage id="StripePaymentForm.submitPaymentInfo" />
                  )}
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </Form>
    ) : (
      <div className={css.missingStripeKey}>
        <FormattedMessage id="StripePaymentForm.missingStripeKey" />
      </div>
    );
  }

  render() {
    const { onSubmit, ...rest } = this.props;
    return <FinalForm onSubmit={this.handleSubmit} {...rest} render={this.paymentForm} />;
  }
}

StripePaymentForm.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
  loadingData: false,
  showInitialMessageInput: true,
  hasHandledCardPayment: false,
  defaultPaymentMethod: null,
  initiateOrderError: null,
  handleCardPaymentError: null,
  confirmPaymentError: null,
  isInstantBooking: false,
};

StripePaymentForm.propTypes = {
  className: string,
  rootClassName: string,
  inProgress: bool,
  loadingData: bool,
  initiateOrderError: object,
  handleCardPaymentError: object,
  confirmPaymentError: object,
  formId: string.isRequired,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  paymentInfo: string.isRequired,
  authorDisplayName: string.isRequired,
  showInitialMessageInput: bool,
  hasHandledCardPayment: bool,
  defaultPaymentMethod: propTypes.defaultPaymentMethod,
  isInstantBooking: string,
  isLongTermRental: bool,
  isPayForFuel: bool,
};

export default injectIntl(StripePaymentForm);
