import React, { useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import css from './ModalTripInstructions.css';
import  Modal  from '../../../Modal/Modal'
import get from 'lodash/get';
import { formatMoney } from '../../../../util/currency';
import { LINE_ITEM_IS_EXCESS_REDUCTION, LINE_ITEM_IS_EXCESS_REDUCTION2 } from '../../../../util/types';

const ModalTripInstructions = props => {
  const { isOpen, onClose, onManageDisableScrolling, keyRules, brandModel, fuelValue, registrationNumber, listing, transaction, intl } = props;
  const millagePerDay = get(listing, 'attributes.publicData.millagePerDay');
  const pickupDropoffInstructions = get(listing, 'attributes.publicData.pickupDropoffInstructions');
  const carGuide = get(listing, 'attributes.publicData.carGuide');
  const parkingInstructions = get(listing, 'attributes.publicData.parkingInstructions')
  const parkingInfo = get(listing, 'attributes.publicData.parkingInfo');
  const [protection, setProtection] = useState(null);
  let excessReductionLineItem = null;
  let excessReduction2LineItem = null;
  excessReductionLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_IS_EXCESS_REDUCTION && !item.reversal
  );

  excessReduction2LineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_IS_EXCESS_REDUCTION2 && !item.reversal
  );

  const protectAddsOn = {
    'max_protection': {
      content: "Maximum Protection",
      value: excessReduction2LineItem ? formatMoney(intl, excessReduction2LineItem.lineTotal, 1) : 'N/A',
    },
    "premium_protection": {
      content: "Premium Protection",
      value: excessReductionLineItem ? formatMoney(intl, excessReductionLineItem.lineTotal, 1) : 'N/A',
    }
  };

  const parseInstructions = instructions => {
    if (!instructions) return '-';
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = instructions.split(urlRegex);
    return parts.map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a key={index} href={part} style={{ color: '#0aa3ad' }} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  useEffect(() => {
    if (excessReductionLineItem) {
      setProtection('premium_protection');
    } else if (excessReduction2LineItem) {
      setProtection('max_protection');
    } else {
      setProtection(null);
    }
  }, [excessReductionLineItem, excessReduction2LineItem]);


  return (
    <Modal
      isOpen={isOpen}
      containerClassName={css.modal}
      onManageDisableScrolling={onManageDisableScrolling}
      onClose={onClose}
    >
      {' '}
      <>
          <div className={`${css.modalInfoTripContainer}  ${css.displayFlex} ${css.justifyContentBetween}`}>
            <div className={`${css.tripModalContainer} ${css.instructionsWrapper}`}>
             <div className={`${css.tripBox} ${css.borderBottom}`}> <h3>Key information for your trip</h3></div>
             <div className={`${css.tripBox} ${css.borderBottom}`}>
                <h5>Enabled add-ons on this trip</h5>
                <div className={`${css.boxPadding} ${css.displayFlex} ${css.justifyContentBetween}`}>
                   <span>Total Mileage</span>
                   <span>{millagePerDay + " kms/day"}</span>
                 </div>
                 <div className={`${css.displayFlex} ${css.justifyContentBetween}`}>
                   <span>Protection plan</span>
                   <span>-</span>
                 </div>
                <div className={`${css.displayFlex} ${css.justifyContentBetween}`}>
                   <span>Fuel Package</span>
                   <span>-</span>
                 </div>
                 {protection && <div className={`${css.displayFlex} ${css.justifyContentBetween}`}>
                   <span>{protectAddsOn[protection].content}</span>
                   <span>{protectAddsOn[protection].value}</span>
                 </div>}
             </div>
             <div className={`${css.tripBox} ${css.borderBottom}`}>
                <h5>About the car</h5>
                <ol>
                  <li>Brand/Model: {brandModel}</li>
                  <li>Fuel:  {fuelValue}</li>
                  <li>Registration Number:  {registrationNumber}</li>
                </ol>
             </div>
             <div className={`${css.tripBox} ${css.borderBottom}`}>
                <h5>Key Rules</h5>
                <ol>
                  {keyRules.map((rule, index) => (
                    <li key={index}>{rule}</li>
                  ))}
                </ol>
                {/* <span><a href=''>Read More</a></span> */}
              </div>

              <div className={`${css.tripBox} ${css.borderBottom}`}>
                <h5>Parking instructions</h5>
                <span>{parseInstructions(parkingInstructions)}</span>
              </div>

              <div className={`${css.tripBox} ${css.borderBottom}`}>
                <h5>Pickup and return instructions</h5>
                <span>{parseInstructions(pickupDropoffInstructions)}</span>
              </div>

              <div className={`${css.tripBox} ${css.borderBottom}`}>
                <h5>Car Guide</h5>
                <span>{parseInstructions(carGuide)}</span>
              </div>
            </div>
          </div>
        </>
    </Modal>
  );
};

ModalTripInstructions.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onManageDisableScrolling: () => {},
};

ModalTripInstructions.prototype = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onManageDisableScrolling: func.isRequired,
};

export default ModalTripInstructions;
