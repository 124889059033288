import React, { Fragment, memo, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import {
  checkCode,
  estimateBreakdown,
  fetchTimeSlots,
  getDistanceToUser,
  resetCode,
  sendEnquiry,
} from './LongTermRentalListingPage.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import {
  getListingFromState,
  getOwnListingFromState,
  listingImages,
  priceData,
} from './LongTermRentalListingPage.helper';
import { types as sdkTypes } from '../../util/sdkLoader';
import { NotFoundPage, TopbarContainer } from '..';
import {
  createSlug,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  parse,
} from '../../util/urlHelpers';
import {
  Button,
  InsurancePanelNewLongTerm,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
  ModalSharingListing,
  Page,
  RentalAgreement,
  NamedLink,
} from '../../components';
import css from './LongTermRentalListingPage.css';
import config from '../../config';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionRulesMaybe from './SectionRulesMaybe';
import {
  EVENT_SEARCH_CLICKED_PIC_LISTING,
  EVENT_SEARCH_READ_INSURANCE,
  VIEW_PHOTO_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import { initiateEventFromListing } from '../../util/gtm/gtmHelpers';
import {
  checkOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import get from 'lodash/get';
import { sendGAEvent } from '../../util/googleAnalytics';
import { openMissingInfomationModal } from '../../ducks/user.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import { formatMoney } from '../../util/currency';
import { withViewport } from '../../util/contextHelpers';
import ConfigureRent from './ConfigureRent';
import CarGrid from './CarGrid';
import LongTermCarCard from './LongTermCarCard/LongTermCarCard';
import ConfigureRentModal from './ConfigureRentModal';
import { RentForm } from '../../forms';
import { searchListings } from '../LongTermRentalSearch/LongTermRentalSearch.duck';
import { unionWith } from 'lodash';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import AlertBox from '../../components/AlertBox/AlertBox';
import FlexPlusFooter from '../LandingPageFlex/FlexPlusFooter';
import ExitModalFlexPlus from './ExitModalFlexPlus';
import classNames from 'classnames';
import { Money } from 'sharetribe-flex-sdk/src/types';
import { Link } from 'react-router-dom';


const { UUID } = sdkTypes;

const RESULT_PAGE_SIZE = 50;

const keyFeaturesConfig = config.custom.keyFeatures.filter(k => k.key !== 'pet_friendly');
const keyRulesConfig = config.custom.keyRules;

const noIndexListings = [
  '5fd0746b-5c34-49a8-adad-d719d39f5670',
  '6030e480-54db-45b7-af65-57ea8f8b0848',
];

export const LongTermRentalListingPageComponent = memo(props => {
  const {
    params: rawParams,
    getListing,
    getOwnListing,
    showListingError,
    scrollingDisabled,
    intl,
    currentUser,
    onManageDisableScrolling,
    location,
    isAuthenticated,
    estimatedTx,
    viewport,
    listings,
  } = props;

  const isMobileLayout = viewport.width < 768;
  const queryMaybe = parse(location.search);

  const isSearchingLongTerm = useSelector(state => state.SearchPage.isSearchingLongTerm);
  const shouldShowPricePerMonth = queryMaybe.pub_longTermRental || isSearchingLongTerm;

  const [imageCarouselOpen, setImageCarouselOpen] = useState(false);
  const [isAlertBox, setAlertBox] = useState(false);
  const [isOpenRentalAgreement, setIsOpenRentalAgreement] = useState(false);
  const [isOpenCarCardModal, setIsOpenCarCardModal] = useState(false);
  const [isRentFormModalOpen, setIsRentFormModalOpen] = useState(false);
  const [protectionValue, setProtectionValue] = useState('Basic protection');
  const [mileageValue, setMileageValue] = useState(0);
  const [additionaDriverValue, setAdditionaDriverValue] = useState('No');
  const [formStatus, setFormStatus] = useState({ success: false });
  const [showShareModal, setShowShareModal] = useState(false);
  const [isLongTerm, setIsLongTerm] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [modalDisplay, setModalDisplay] = useState(false);
  const [blackout, setBlackout] = useState(false);


  const closeModal = () => {
    console.log('close modal called');
    setModalDisplay(false);
    setBlackout(false);
  };

  const handleExitEvent = e => {
    if (!e.toElement && !e.relatedTarget) {
      document.removeEventListener('mouseout', handleExitEvent);
      document.removeEventListener('beforeunload', handleExitEvent);
      window.removeEventListener('popstate', handleExitEvent);
      setModalDisplay(true);
      setBlackout(true);
    } else {
      setModalDisplay(false);
      setBlackout(false);
    }
  };

  const modalRef = React.createRef();

  React.useEffect(() => {
    document.addEventListener('mouseout', handleExitEvent);

    if (isMobileLayout) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', handleExitEvent);
      document.addEventListener('beforeunload', handleExitEvent);
    }
    return () => {
      document.removeEventListener('mouseout', handleExitEvent);
      document.removeEventListener('beforeunload', handleExitEvent);
      window.removeEventListener('popstate', handleExitEvent);
    };
  }, []);
  const handleOpenViewPhotos = () => setImageCarouselOpen(() => true);
  const handleCloseViewPhotos = () => setImageCarouselOpen(() => false);

  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant ? getOwnListing(listingId) : getListing(listingId);

  useEffect(() => {
    window.localStorage.setItem('currentFlexPlusListing', `https://drivelah.sg${window.location.pathname}`);
  }, []);


  useEffect(() => {
    sendGAEvent({
      eventCategory: 'Transaction',
      eventAction: 'View A Listing',
    });
    loadFbSdk();
    setTimeout(() => {
      calculateDistanceToUser();
    }, 3000);
  }, [currentListing.id]);

  useEffect(() => {
    const isLongTermTransaction = get(estimatedTx, 'attributes.protectedData.isLongTermRental');
    if (isLongTermTransaction !== isLongTerm) setIsLongTerm(isLongTermTransaction);
  }, [estimatedTx]);

  const generateListingLink = () => {
    const routes = routeConfiguration();

    const link =
      currentListing && currentListing.id
        ? createResourceLocatorString(
          'ListingPage',
          routes,
          { id: currentListing.id.uuid, slug: createSlug(currentListing.attributes.title) },
          {},
        )
        : '/';
    return `${config.canonicalRootURL}${link}`;
  };

  const loadFbSdk = () => {
    if (window) {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: config.facebookAppId,
          cookie: true, // enable cookies to allow the server to access
          // the session
          autoLogAppEvents: true,
          xfbml: true, // parse social plugins on this page
          version: 'v2.7',
        });
      };

      // Load the SDK asynchronously
      (function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  };

  const calculateDistanceToUser = () => {
    const { currentUser, params: rawParams, getDistanceToUser, location } = props;
    const searchParams = parse(location.search);
    const { origin } = searchParams || {};

    if (currentListing.id) {
      const userLocation = get(
        currentUser || {},
        'attributes.profile.protectedData.location.selectedPlace.origin',
      );
      const [lat, lng] = origin ? origin.split(',') : [];

      getDistanceToUser({
        userLocation:
          lat & lng
            ? { lat, lng }
            : userLocation
              ? { lat: userLocation.lat, lng: userLocation.lng }
              : null,
        listingLocation: {
          lat:
            (currentListing.attributes.geolocation && currentListing.attributes.geolocation.lat) ||
            null,
          lng:
            (currentListing.attributes.geolocation && currentListing.attributes.geolocation.lng) ||
            null,
        },
        listingId: currentListing.id.uuid,
      });
    }
  };

  const openRentModal = () => {

    if (isOpenCarCardModal) {
      if (isMobileLayout) {
        setIsRentFormModalOpen(true);
      }
      setIsOpenCarCardModal(false);
    } else {
      setIsOpenCarCardModal(true);
    }
  };

  const handleViewPhotosClick = (e, isButtonClicked) => {
    e.stopPropagation();
    handleOpenViewPhotos();
    if (isButtonClicked) {
      initiateEventFromListing({
        props: props,
        listing: currentListing,
        buttonId: VIEW_PHOTO_BUTTON_ID,
        event: EVENT_SEARCH_CLICKED_PIC_LISTING,
        isHost: isOwnListing,
      });
    } else {
      initiateEventFromListing({
        props: props,
        listing: currentListing,
        event: EVENT_SEARCH_CLICKED_PIC_LISTING,
        isHost: isOwnListing,
      });
    }
  };

  if (showListingError && showListingError.status === 404) {
    return <NotFoundPage />;
  }

  if (showListingError) {
    const errorTitle = intl.formatMessage({
      id: 'ListingPage.errorLoadingListingTitle',
    });

    return (
      <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.errorWrapper}>
            <p className={css.errorText}>
              <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
            </p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <FlexPlusFooter />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }

  if (!currentListing.id) {
    const loadingTitle = intl.formatMessage({
      id: 'ListingPage.loadingListingTitle',
    });
    return (
      <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <p className={css.loadingText}>
              <FormattedMessage id="ListingPage.loadingListingMessage" />
            </p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <FlexPlusFooter />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }

  const onOpenSharingModal = e => {
    e.preventDefault();
    e.stopPropagation();
    setShowShareModal(() => true);
  };

  const onCloseSharingModal = e => {
    e.preventDefault();
    e.stopPropagation();
    setShowShareModal(() => false);
  };

  const onShareToFacebookTimeline = message => {
    if (!window) return;
    const link = generateListingLink();

    window.FB.ui(
      {
        method: 'share',
        quote: message,
        href: link,
      },
      function(response) {
      },
    );
  };

  const onShareToMessenger = () => {
    if (!window) return;
    const link = generateListingLink();
    window.FB.ui({
      method: 'send',
      link: link,
    });
  };

  const {
    price = null,
    title = '',
    publicData = {},
    description = '',
  } = currentListing.attributes;
  const { longTermRental, flexPlusPrice } = publicData;
  const flexPlusBasePrice = new Money(flexPlusPrice, config.currency);

  console.log("flexPlusPrice", flexPlusPrice);
  const { formattedPrice, priceTitle } = priceData(
    price,
    intl,
    longTermRental && shouldShowPricePerMonth,
    currentListing,
  );

  const ensuredDescription = description === config.custom.defaultDescription ? '' : description;
  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, siteTitle },
  );
  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const listingType = isDraftVariant ? LISTING_PAGE_PARAM_TYPE_DRAFT : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'description';
  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const {
    brandName,
    transmissions,
    fuelType,
    drivenKm,
    peopleNumber,
    keyFeatures: selectedFeatures,
    keyRules: selectedKeyRules,
  } = publicData || {};

  const shareableLink = generateListingLink();
  console.log("shareableLink", shareableLink);

  const onReadInsurance = () => {
    initiateEventFromListing({
      props: props,
      listing: currentListing,
      event: EVENT_SEARCH_READ_INSURANCE,
      isHost: checkOwnListing(currentUser, currentListing),
    });
  };

  const _handleModalClose = () => {
    setIsRentFormModalOpen(false);
    if (isMobileLayout) {
      setIsOpenCarCardModal(true);
    }
  };

  const handleSubmitSearch = () => {
    setIsRentFormModalOpen(false);
  };

  const _handleMobileModal = value => {

    setIsRentFormModalOpen(true);
  };

  const handleSuccessEvent = () => {
    setAlertBox(true);
  };

  const nonIndexListing = listingId ? noIndexListings.includes(listingId.uuid) : false;
  const getDescription = get(currentListing, 'attributes.description', '');

  return (
    <Page
      title={schemaTitle}
      author={authorDisplayName}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={ensuredDescription}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      removePageIndexing={nonIndexListing}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ItemPage',
        description: ensuredDescription,
        name: schemaTitle,
        image: schemaImages,
      }}
    >
      <LayoutSingleColumn className={css.pageRoot}>
        <LayoutWrapperTopbar>
          {isAlertBox ? <AlertBox title="Request sent successfully."
                                  message={'Our team has received your details and will get in touch with you asap'}
                                  type="success" /> : ''}
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          {isAlertBox ? <AlertBox title="Request sent successfully."
                                  message={'Our team has received your details and will be getting in touch with you asap'}
                                  type="success" /> : ''}
          <CarGrid
            title={title}
            listing={currentListing}
            isOwnListing={isOwnListing}
            editParams={{
              id: listingId.uuid,
              slug: listingSlug,
              type: listingType,
              tab: listingTab,
            }}
            imageCarouselOpen={imageCarouselOpen}
            onImageCarouselClose={handleCloseViewPhotos}
            handleViewPhotosClick={handleViewPhotosClick}
            onManageDisableScrolling={onManageDisableScrolling}
            onOpenSharingModal={onOpenSharingModal}
            viewPhotoButtonId={VIEW_PHOTO_BUTTON_ID}
          />
          <div className={css.contentContainer}>
            <div className={css.row}>
              <div className={css.column70}>
                <div className={css.mainContent}>
                  <Fragment>
                    <SectionDescriptionMaybe
                      authorDisplayName={authorDisplayName}
                      description={ensuredDescription}
                      brandName={brandName}
                      transmissions={transmissions}
                      fuelType={fuelType}
                      drivenKm={drivenKm}
                      seats={peopleNumber}
                    />
                    <SectionFeaturesMaybe
                      id={'ListingPage.keyFeatures'}
                      title={'ListingPage.keyFeaturesTitle'}
                      options={keyFeaturesConfig}
                      selectedOptions={selectedFeatures}
                    />
                    <SectionRulesMaybe
                      id={'ListingPage.keyRulesTitle'}
                      title={'ListingPage.keyRulesTitle'}
                      keyRules={keyRulesConfig}
                      selectedOptions={selectedKeyRules}
                    />
                    {getDescription !== '' && (
                      <div className={css.aboutSection}>
                        <h2>About the car</h2>
                        <p>{getDescription}</p>
                      </div>
                    )}
                  </Fragment>
                </div>

                <div className={css.includedInSubscription}>
                  <h2>What's included in the subscription</h2>  
                  <ul>
                    <li>500 kms per week (Additional kms can be bought)</li>
                    <li>Unused kms roll over</li>
                    <li>Unlimited car swaps</li>
                    <li>Comprehensive insurance</li>
                    <li>Roadside assistance</li>
                    <li>Maintenance</li>
                    <li>GST</li>
                    <li>24x7 customer support</li>
                    <li>Delivery & pick up of car available on request</li>
                  </ul>
                </div>
                {/* <div className={css.hostedBy}>
                </div> */}
                <InsurancePanelNewLongTerm
                  onReadInsurance={onReadInsurance}
                  listing={currentListing}
                  showInsurance={true}
                  insuranceType={publicData.insurance}
                  onManageDisableScrolling={onManageDisableScrolling}
                />
              </div>

              <div className={`${css.column30} ${css.priceWrapper}`}>
                <div className={`${classNames(css.rentalCard, css.rentalCardMobile)} ${css.buttonHide}`} style={{
                  border: '1px solid #e6e6e6',
                }}>
                  <div style={{padding: "20px"}}>
                    <span className={css.rentalButtonHeadline}>Starting From</span>
                    <div style={{display: "flex"}}>
                      <span className={css.head}>{formatMoney(intl, flexPlusBasePrice,1)}</span>
                      <span style={{alignSelf: 'end', fontSize: "16px", fontWeight: 500, color: "#4A4A4A" }}>/week</span>
                    </div>
                  </div>
                  <div className={css.border}></div>
                  <div className={css.rentalButton} style={{
                    margin: "20px",
                  }}>
                    <NamedLink className={css.button} name="FlexPlusPaymentPlan" params={{ id: listingId.uuid, slug: listingSlug }}>
                      <Button>
                        <FormattedMessage id="RentalPage.Iaminterested" />
                      </Button>
                    </NamedLink>
                  </div>
                </div>
                {/*<ConfigureRent*/}
                {/*  intl={intl}*/}
                {/*  listing={currentListing}*/}
                {/*  onSubmit={() => setIsRentFormModalOpen(true)}*/}
                {/*  setTotalPrice={setTotalPrice}*/}
                {/*  getProtectionValue={setProtectionValue}*/}
                {/*  getMileageValue={setMileageValue}*/}
                {/*  getAdditionaDriverValue={setAdditionaDriverValue}*/}
                {/*/>*/}
              </div>
            </div>
          </div>
          {listings && listings.length > 0 ? (
            <div className={css.contentContainer}>
              <LongTermCarCard
                listings={listings}
                currentListingId={currentListing.id.uuid}
                intl={intl}
                viewport={viewport}
              />
            </div>
          ) : null}
          {!isRentFormModalOpen && (
            <div className={`${css.fixedBottomBtn} ${css.footerSubmitButton}`}>
              <div className={css.monthWisePrice}>
                <span className={css.monthlyPrice}>
                  {totalPrice && formatMoney(intl, totalPrice, 1)}
                  <span>/week</span>
                </span>
              </div>
              <Link to ={{
                pathname: location.pathname + "/payment-plan"}}>
                <Button rootClassName={css.button}>
                  <FormattedMessage id="RentalPage.Iaminterested" />
                </Button>
              </Link>

            </div>
          )}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <FlexPlusFooter />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
      <ModalSharingListing
        containerClassName={css.missingInformationModal}
        onManageDisableScrolling={onManageDisableScrolling}
        showShareModal={showShareModal}
        onShareFacebookTimeline={onShareToFacebookTimeline}
        onShareFacebookMessenger={onShareToMessenger}
        onClose={onCloseSharingModal}
        shareableLink={shareableLink}
      />
      <Modal
        id="rentalAgreementModal"
        isOpen={isOpenRentalAgreement}
        onClose={() => setIsOpenRentalAgreement(() => false)}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <RentalAgreement />
      </Modal>
      <div className={css.rentalModal}>
        {isOpenCarCardModal && (
          <Modal
            containerClassName={css.rentalCarModalContainer}
            isOpen={isOpenCarCardModal}
            onClose={() => setIsOpenCarCardModal(() => false)}
            onManageDisableScrolling={onManageDisableScrolling}
            scrollLayerClassName={css.rentalModalScrollLayer}
            closeButtonClassName={css.rentalModalcloseButton}
            contentClassName={css.rentalModalcontent}
          >
            <ConfigureRentModal
              listing={currentListing}
              intl={intl}
              openRentModal={openRentModal}
              isOpenCarCardModal={isOpenCarCardModal}
              setTotalPrice={setTotalPrice}
              onSubmit={_handleMobileModal}
              isRentFormModalOpen={isRentFormModalOpen}
              getProtectionValue={setProtectionValue}
              getMileageValue={setMileageValue}
              getAdditionaDriverValue={setAdditionaDriverValue}
            />
          </Modal>
        )}
      </div>
      <Modal
        id="EditListingWizard.payoutModal"
        isOpen={isRentFormModalOpen}
        onClose={() => _handleModalClose(false)}
        disableCloseBtn={false}
        onManageDisableScrolling={onManageDisableScrolling}
        className={css.searchContactModal}
        scrollLayerClassName={css.searchContactModalSLayer}
        containerClassName={css.searchContactModalContainer}
        contentClassName={css.searchContactModalContent}
        isClosedClassName={css.searchContactModalIsClosed}
        closeButtonClassName={css.searchContactModalCloseButton}
        closeTextClassName={css.searchContactModalCloseText}
        closeIconClassName={css.searchContactModalCloseIcon}
        customContainerClassName={css.searchContactModalCustomContainer}
      >
        <div className={css.searchContactModalInner}>
          <div className={css.modalHeader}>
            <h3>Rent {title}</h3>
          </div>
          <div className={css.searchContactRightContent}>
            {formStatus && formStatus.success ? (
              <p>{formStatus.message}</p>
            ) : (
              <p>
                Glad you like this car. Please fill up the form below and our team will get in touch
                with you ASAP to close out all the details and get your rental started.
              </p>
            )}

            {formStatus && !formStatus.success && (
              <RentForm
                isAuthenticated={isAuthenticated}
                onSubmit={handleSubmitSearch}
                handleSuccessEvent={handleSuccessEvent}
                handleModalClose={e => _handleModalClose(e)}
                className={css.searchForm}
                formStatus={formStatus}
                setFormStatus={setFormStatus}
                isRentFormModalOpen={isRentFormModalOpen}
                intl={intl}
                totalPrice={totalPrice}
                formatMoney={formatMoney}
                listingDetails={{
                  name: title,
                  flexId: listingId && listingId.uuid,
                  insurancePlan: protectionValue,
                  mileage: mileageValue,
                  additionalDriver: additionaDriverValue,
                  totalPrice: totalPrice && formatMoney(intl, totalPrice, 1),
                }}
              />
            )}
          </div>
        </div>
      </Modal>
      <ExitModalFlexPlus
        isMobileLayout={isMobileLayout}
        handleModal={modalDisplay}
        setHandleModal={setModalDisplay}
        modalRef={modalRef}
        closeModal={closeModal}
        currentUser={currentUser}
      />
    </Page>
  );
}, isEqual);

LongTermRentalListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  timeSlots: [],
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  categoriesConfig: config.custom.categories,
  amenitiesConfig: config.custom.amenities,
  keyFeaturesConfig: config.custom.keyFeatures,
  keyRulesConfig: config.custom.keyRules,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    enquiryModalOpenForListingId,
    distanceToUser,
    timeSlots,
    estimateBreakdownInProgress,
    estimatedTx,
    checkedCode,
    checkCodeInProgress,
    checkCodeErorr,
    sameCategoryListings,
    estimateError,
    monthlyTimeSlots,
    fetchTimeSlotsInProgress,
  } = state.ListingPage;
  const { currentPageResultIds, searchMapListingIds } = state.SearchPage;
  const { currentUser } = state.user;
  const { pagination, searchParams } = state.SearchPage;
  const getListing = getListingFromState(state);
  const getOwnListing = getOwnListingFromState(state);
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid),
  );

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    listings: pageListings,
    mapListings,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    distanceToUser,
    timeSlots,
    monthlyTimeSlots,
    estimateBreakdownInProgress,
    estimatedTx,
    checkedCode,
    checkCodeInProgress,
    checkCodeErorr,
    sameCategoryListings,
    estimateError,
    fetchTimeSlotsInProgress,
    pagination,
    searchParams,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  getDistanceToUser: (listingLocation, userLocation) =>
    dispatch(getDistanceToUser(listingLocation, userLocation)),
  onSendEnquiry: (listingId, message, query) => dispatch(sendEnquiry(listingId, message, query)),
  onOpenMissingInfomationModal: status => dispatch(openMissingInfomationModal(status)),
  onCheckingVoucher: ({ code, valueId, data }) => dispatch(checkCode({ code, valueId, data })),
  onResetCode: () => dispatch(resetCode()),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onEstimateBreakdown: params => dispatch(estimateBreakdown(params)),
});

const LongTermRentalListingPage = compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
)(LongTermRentalListingPageComponent);

LongTermRentalListingPage.loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  let { sort: sortParameter } = queryParams;

  const { page = 1, sort = sortParameter, address, origin, pub_fuelType, ...rest } = queryParams;
  let fuelTypeMaybe = {};
  if (pub_fuelType && typeof pub_fuelType === 'string' && pub_fuelType.length) {
    fuelTypeMaybe =
      pub_fuelType === 'petrol' ?
        { pub_fuelType: 'petrol,premiumUnleaded_95Petrol,premiumUnleaded_98Petrol,regularUnleadedPetrol,E10_UnleadedPetrol' } :
        { pub_fuelType };
  }

  return searchListings({
    ...rest,
    ...fuelTypeMaybe,
    page,
    pub_flexPlusEnabled: true,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 10,
  });
};

export default LongTermRentalListingPage;
