import React, { Component } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { uploadFiles } from '../../util/fileUpload';
import { FormattedMessage } from '../../util/reactIntl';
import { IconCamera, IconClose, IconSpinner } from '../index';
import tick from '../../assets/tripPage/tick.svg';
import disabledTick from '../../assets/tripPage/tick-disabled.svg';
import css from '../../components/TripPanel/SectionsCarPhotos.css';

const ACCEPT_TYPE = 'image/*';

/**
 * isPickUp: boolean
 * Show that modal is pickup modal
 * isShowUpload: boolean
 * Show that transaction is show upload or not
 */

class UploadSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      currentIsUploading: [],
      index: 0,
      uploadFileError: false,
      uploadedPhoto: [],
    };
    this.uploaded = [];
  }

  // uploadFirstFile = ({ file, index }, currentUser) => {
  //   if (file) {
  //     const { id } = currentUser;
  //     const formData = new FormData();
  //     formData.append('file', file, file.name);
  //     formData.append('userId', id.uuid);
  //     formData.append('tripPhotos', true);

  //     return uploadFiles(formData)
  //       .then(response => {
  //         if (response.status !== 200) {
  //           return Promise.reject(response);
  //         }
  //         return response.json();
  //       })
  //       .then(data => {
  //         this.props.setData({
  //           fileUrl: data.directLink,
  //         });
  //         this.uploaded[index] = data;
  //         return data;
  //       });
  //   }
  // };

  uploadOtherFiles = (files, currentUser) => {
    const uploadSingleFile = (file, index) => {
      const fileData = files;

      if (!fileData) return Promise.resolve({});
      const { id } = currentUser;
      if(file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('userId', id.uuid);
        formData.append('tripPhotos', true);
        return uploadFiles(formData)
          .then(response => {
            if (response.status !== 200) {
              return Promise.reject(response);
            }
            return response.json();
          })
          // .then(data => {
          //   this.uploaded[index] = data;
          //   return uploadSingleFile();
          // });
      }

    };

    const promises = [];
    files.forEach((file) => {
      promises.push(
        uploadSingleFile(file.file, file.index)
      );
    });
    return Promise.all(promises);
  };

  handleUploadFiles = (files, currentUser) => {
    if (files.length) {
      this.setState({
        isUploading: true,
        uploadFileError: false,
        currentIsUploading: [
          ...this.state.currentIsUploading,
          { id: this.props.index, status: true },
        ],
      });
      // return this.uploadFirstFile(files[0], currentUser)
      //   .then(() => {
          return this.uploadOtherFiles(files, currentUser)
        // })
        .then((response) => {
          const uploadedPhoto = response.map(item => {
            return {
              fileUrl: item.url,
              note: item.note,
              type: this.props.type,
              id: this.props.id,
              isPickUp: true,
              timestamp: new Date().getSeconds(),
              isJustUploaded: true,
            }
          })

          this.props.setActivePhotoSection(uploadedPhoto);

          this.setState({
            isUploading: false,
            uploadedPhoto,
            currentIsUploading: [
              ...this.state.currentIsUploading,
              {
                id: this.props.index,
                status: true,
              },
            ],
          }, () => {
            this.handleAddPhoto();
          });
        })
        .catch(e => {
          console.log('ERROR', e);
          this.setState({ uploadFileError: true, isUploading: false });
        });
    }
    return Promise.resolve({});
  };

  handleAddPhoto = currentIndex => {
    const {
      fileUrl,
      note,
      tripPhotos,
      isCustomer,
      isPickUp,
      type,
      files,
      setData,
      existedPhotos,
    } = this.props;
    const {uploadedPhoto} = this.state

    const allPhotos = []
    uploadedPhoto.map(i => {
      const data = {
      fileUrl: i.fileUrl,
      note: note || (tripPhotos[0] && tripPhotos[0].note) || existedPhotos[0] && existedPhotos[0].note,
      timestamp: new Date().getTime(),
      isCustomer: isCustomer,
      isPickUp: isPickUp,
      [type]: true,
    };

    allPhotos.push(data);

  })
  this.props.handleAddPhoto(allPhotos);
    // const data = {
    //   fileUrl: fileUrl,
    //   note: note || (tripPhotos[0] && tripPhotos[0].note) || existedPhotos[0] && existedPhotos[0].note,
    //   timestamp: new Date().getTime(),
    //   isCustomer: isCustomer,
    //   isPickUp: isPickUp,
    //   [type]: true,
    // };

    // if (this.state.index + 1 === files.length) {
    //   this.props.handleAddPhoto(data);
    //   this.inputElement.value = null;
    //   setData({
    //     fileUrl: null,
    //     note: '',
    //     file: null,
    //   });
    //   this.setState({ index: 0 });
    // } else {
    //   const nextImgFile = files.item(this.state.index + 1);
    //   let reader = new FileReader();
    //   let nextImg;
    //   reader.onload = function(e) {
    //     const blob = new Blob([new Uint8Array(e.target.result)], {type: nextImgFile.type });
    //     nextImg = URL.createObjectURL(blob);
    //   };
    //   setData({
    //     fileUrl:
    //       this.uploaded[this.state.index + 1] && this.uploaded[this.state.index + 1].directLink,
    //     note: note,
    //     file: nextImg,
    //   });
    //   this.setState({ index: this.state.index + 1 });
    // }
  };

  handleRemovePhoto = timestamp => {
    this.props.handleRemovePhoto(timestamp);
  };

  handleClickUpload = () => {
    this.inputElement.click();
  };

  handleUploadPhoto = file => {
    const { currentUser } = this.props;

    if (!file) {
      return;
    }
    let data = new FormData();
    data.append('file', file, file.name);
    data.append('userId', currentUser && currentUser.id && currentUser.id.uuid);
    data.append('tripPhotos', true);
    this.setState({
      isUploading: true,
    });

    uploadFiles(data)
      .then(response => {
        const code = response.status;
        if (code !== 200) {
          return null;
        }
        return response.json();
      })
      .then(jsonResponse => {
        if (!jsonResponse.url) {
          this.setState({
            isUploading: false,
          });
        } else {
          this.props.setData({ fileUrl: jsonResponse.directLink });
          this.setState({
            isUploading: false,
          });
        }
      })
      .catch(e => {
        this.setState({
          isUploading: false,
        });
      });
  };

  render() {
    const {
      isShowUpload,
      isCustomer,
      isPickUp,
      setData,
      files,
      file,
      fileUrl,
      note,
      tripPhotos,
      existedPhotos,
      pickUpReading,
      dropOffReading,
      index,
      intl,
      type,
      onNoteChange,
      title
    } = this.props;

    const customerTripPhotos = existedPhotos.filter(
      photo => !!photo.isCustomer && photo[type] === true
    );
    const providerTripPhotos = existedPhotos.filter(
      photo => !photo.isCustomer && photo[type] === true
    );
    const showYourPhotos = isCustomer ? customerTripPhotos : providerTripPhotos;
    const hasJustUploadedTripPhotos = tripPhotos.filter(photo => isPickUp ? !!photo.isPickUp : !photo.isPickUp);
    const pickUpPhotos = showYourPhotos && showYourPhotos.length > 0 ? [...showYourPhotos] : [...hasJustUploadedTripPhotos];

    const uploadTextLabel = <FormattedMessage id="SectionUploadCarStatus.remarks" />;
    const uploadPlaceholder = intl.formatMessage({
      id: 'SectionUploadCarStatus.uploadPlaceholder',
    });

    const noteChange = (e) => {
      onNoteChange(e.target.value, type);
    }
    const formatPercentage = (value) => {
      const num = Number(value);
      return Number.isInteger(num) ? `${num}%` : `${num.toFixed(1)}%`;
  };
    const noteText = tripPhotos[0]?.note || '';
    const existedPhotosText = existedPhotos[0]?.note || '';
    const displayNote = noteText ? `${title === 'Fuel Gauge Photo' ? formatPercentage(noteText) : noteText}` : '';
    const displayExistedPhotosText = existedPhotosText ? `${title === 'Fuel Gauge Photo' ? formatPercentage(existedPhotosText) : existedPhotosText}` : '';
    return (
      <div className={css.content}>
        <div className={css.pickUpPhotosHolder}>
          { !isCustomer && <div className={classNames(css.uploadContainer, css.pickUpPhotosUploadContainer)}>
            <div className={css.uploadBox} onClick={this.handleClickUpload}>
              <div className={css.uploadBoxInner}>
                {/*{file && <img src={file} className={css.imageFromFileWrapper} />}*/}
                {file && this.state.isUploading && (
                  <div className={css.uploadLoading}>
                    <IconSpinner />
                  </div>
                )}
                {<IconCamera rootClassName={css.uploadIcon} />}
              </div>
            </div>
            <input
              accept={ACCEPT_TYPE}
              className={css.inputField}
              type="file"
              name="uploadPhotoStatus"
              id="uploadPhotoStatus"
              multiple={true}
              onChange={e => {
                if (e.target.files[0]) {
                  // this.handleUploadPhoto(e.target.files[0])
                  const files = Array.from(e.target.files).map((file, index) => ({ index, file }));
                  this.handleUploadFiles(files, this.props.currentUser);
                  setData({
                    file: Object.values(e.target.files).map(i => URL.createObjectURL(i)),
                    files: e.target.files,
                  });
                }
                // setTimeout(() => this.handleAddPhoto(index), 3000);
              }}
              ref={input => (this.inputElement = input)}
            />
          </div>
          }
          
          {isCustomer && isPickUp && <div className={css.pickUpReading}>{pickUpReading}</div>}
          {isCustomer && !isPickUp && <div className={css.pickUpReading}>{dropOffReading}</div>}
          <div className={classNames(css.photosContainer, css.pickUpPhotosContainer)}>
            {pickUpPhotos.map(photo => (
              <div className={css.photoWrapper} key={photo.timestamp}>
                <div className={css.photoWrapperInner}>
                  {photo.isJustUploaded && (
                    <div
                      className={css.removePhotoBtn}
                      onClick={() => this.handleRemovePhoto(photo.timestamp)}
                    >
                      <IconClose size={'small'} />
                    </div>
                  )}
                  <img
                    src={photo.fileUrl}
                    className={css.tripPhoto}
                    onClick={() => window.open(photo.fileUrl, '_blank')}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <label className={css.inputTextLabel}>{uploadTextLabel}</label>
          <input
            type="text"
            className={css.inputText}
            id="noteCarPhotoStatus"
            name="noteCarPhotoStatus"
            onChange={e => setData({ note: e.target.value })}
            onBlur={noteChange}
            value={
              note ||
              displayNote ||
              displayExistedPhotosText
            }
            placeholder={uploadPlaceholder}
            ref={input => (this.inputNote = input)}
            disabled={isCustomer}
          />
        </div>
        {this.state.uploadFileError ? (
          <p className={css.uploadError}>
            <FormattedMessage id="SectionUploadCarStatus.uploadError" />
          </p>
        ) : null}
      </div>
    );
  }
}

class SectionCarPhotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      file: null,
      fileUrl: '',
      note: '',
    };
  }

  handleUploadPhoto = data => {
    this.props.setTripPhotos([...this.props.tripPhotos, ...data ]);
  };

  handleRemovePhoto = timestamp => {
    const newTripPhotos = this.props.tripPhotos.filter(photo => photo.timestamp !== timestamp);
    this.props.setTripPhotos([...newTripPhotos]);
  };

  render() {
    const {
      rootClassName,
      className,
      isCustomer,
      isProvider,
      isPickUp,
      isShowUpload,
      intl,
      onManageDisableScrolling,
      currentUser,
      tripPhotos,
      existedPhotos,
      inputOdometerInvalid,
      pickUpReading,
      dropOffReading,
      onClick,
      holderClassName,
      title,
      description,
      type,
      onNoteChange,
      activeSection,
      id
    } = this.props;

    const customerTripPhotos = existedPhotos.filter(
      photo => !!photo.isCustomer && photo[type] === true
    );
    const providerTripPhotos = existedPhotos.filter(
      photo => !photo.isCustomer && photo[type] === true
    );
    const showYourPhotos = isCustomer ? customerTripPhotos : providerTripPhotos;
    const hasJustUploadedTripPhotos = tripPhotos.filter(photo => isPickUp ? !!photo.isPickUp : !photo.isPickUp);
    const pickUpPhotos = showYourPhotos && showYourPhotos.length > 0 ? [...showYourPhotos] : [...hasJustUploadedTripPhotos];

    console.log('PICKUP PHOTOS>>', pickUpPhotos);

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classNames(classes, holderClassName)}>
        <div onClick={onClick} className={classNames(css.sectionPhotoHolder)}>
          <div className={css.tickContainer}>
            <img src={(activeSection || pickUpPhotos.length > 0) ? tick : disabledTick} alt="tick" className={css.tick} />
          </div>

          <div>
            <h2 className={css.photoTitle}>{title}</h2>
            <p className={css.photoDescription}>{description}</p>
          </div>
        </div>
        <UploadSection
          isShowUpload={isShowUpload}
          isCustomer={isCustomer}
          isProvider={isProvider}
          isPickUp={isPickUp}
          onManageDisableScrolling={onManageDisableScrolling}
          setData={data => this.setState(data)}
          files={this.state.files}
          file={this.state.file}
          fileUrl={this.state.fileUrl}
          note={this.state.note}
          setActivePhotoSection={this.props.setActivePhotoSection}
          handleAddPhoto={this.handleUploadPhoto}
          handleRemovePhoto={this.handleRemovePhoto}
          currentUser={currentUser}
          tripPhotos={tripPhotos.map(item => {
            return { ...item, isJustUploaded: true };
          })}
          existedPhotos={existedPhotos.filter(i => type in i)}
          inputOdometerInvalid={inputOdometerInvalid}
          pickUpReading={pickUpReading}
          dropOffReading={dropOffReading}
          intl={intl}
          type={type}
          onNoteChange={onNoteChange}
          id={id}
          title = {title}
        />
      </div>
    );
  }
}

SectionCarPhotos.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionCarPhotos.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCarPhotos;
