import React, { useEffect, useRef, useState } from 'react';
import css from './SwipeTimeSelector.css';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import classNames from 'classnames';
import { DEFAULT_TIMEZONE, getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { parse } from '../../util/urlHelpers';

const moment = extendMoment(Moment);
const createData = (
  timeZone,
  date,
  isPickupDate,
  selectedDates,
  selectedTimes,
  excludeEnd = false
) => {
  const TODAY = moment().tz(timeZone);
  if (!date) return [];
  let start;
  // If start date and end date are same, apply EndTime to be started after the time of the StartTime
  if (!isPickupDate) {
    const getStartDate = selectedDates && selectedDates.startDate;
    const getEndDate = selectedDates && selectedDates.endDate;
    if (moment(getEndDate).isSame(getStartDate, 'day')) {
      let startTimeValue = selectedTimes && selectedTimes.startTime;
      start = moment(startTimeValue)
        .clone()
        .add(2, 'hours')
    } else {
      start = moment(date).startOf('day');
    }
  } else {
    start =
      TODAY.format('DD/MM/YYYY') === moment(date).format('DD/MM/YYYY')
        ? TODAY.clone()
            .add(1, 'hours')
            .startOf('hours')
        : moment(date).startOf('day');
  }
  const end = start
    .clone()
    .add(1, 'days')
    .startOf('day');
  return Array.from(
    moment.range(start, end).by('minutes', {
      step: 30,
      excludeEnd,
    })
  );
};
const findClosestTimeIndex = (timeData, targetTime, localTimeZone) => {
  if (!timeData.length) return 0;
  
  const targetMinutes = targetTime.hours() * 60 + targetTime.minutes();
  let closestIndex = 0;
  let smallestDiff = Infinity;
  
  timeData.forEach((time, index) => {
    const currentTime = time.tz(localTimeZone, true);
    const currentMinutes = currentTime.hours() * 60 + currentTime.minutes();
    const diff = Math.abs(currentMinutes - targetMinutes);
    
    if (diff < smallestDiff) {
      smallestDiff = diff;
      closestIndex = index;
    }
  });
  
  return closestIndex;
};
const findIndexOfTime = (timeData, time, localTimeZone) => {
  const selectedTimeIndex = timeData.findIndex((t) => {
    const timeMap = t.tz(localTimeZone, true).format('h:mm:a');
    const selectedTime = time && time.tz(localTimeZone, true).format('HHmm');
    return timeMap === selectedTime;
  });

  if (selectedTimeIndex === -1 && time) {
    return findClosestTimeIndex(timeData, time, localTimeZone);
  }
  
  return selectedTimeIndex;
};
const SwipeTimeSelector = ({
  timezone,
  date,
  onSelect,
  setSelectedTimes,
  selectedTimes,
  setSelectedDates,
  isPickupDate,
  selectedDates,
}) => {
  const timeZone = timezone || DEFAULT_TIMEZONE;
  const localTimeZone = getDefaultTimeZoneOnBrowser();
  const timeData = date
    ? createData(timeZone, date, isPickupDate, selectedDates, selectedTimes)
    : createData(timeZone, moment(), isPickupDate, selectedDates, selectedTimes);
  const [selectedDate, setSelectedDate] = useState(date);
  const [selectedDateAvailableTimes, getSelectedDateAvailableTimes] = useState(timeData);
  const [selectedPickerTime, getSelectedPickerTime] = useState();
  const location = useLocation();
  const prevDate = useRef();

  useEffect(() => {
    if (selectedDate !== prevDate.current) {
      prevDate.current = selectedDate;
    }
  }, [selectedDate]);

  const updateDropoffTime = (startTime) => {
    if (selectedDates?.startDate && selectedDates?.endDate && 
        moment(selectedDates.startDate).isSame(selectedDates.endDate, 'day')) {
      const newEndTime = moment(startTime).add(2, 'hours');
      setSelectedTimes(prev => ({
        startTime,
        endTime: newEndTime
      }));
    }
  };

  const _handleTimeSelect = () => {
    onSelect(selectedPickerTime);
  };

  return (
    <div className={css.root}>
      <div className={css.selectedTimePreviewWrapper}>
        <div className={css.selectedTimePreview}>
          <span className={css.selectedDatePreview}>{date && date.format('DD MMM')}</span>
          <span className={css.selectedTimePill}>
            {selectedPickerTime && selectedPickerTime.format('hh:mm a')}
          </span>
        </div>
      </div>
      <div
        className={css.swiperTimePicker}
        key={moment(date).tz(localTimeZone).format()}
      >
        <Swiper
          spaceBetween={0}
          slidesPerView={13}
          centeredSlides={true}
          initialSlide={findIndexOfTime(
            timeData,
            isPickupDate ? selectedTimes.startTime : selectedTimes.endTime,
            localTimeZone
          )}
          onInit={() => {
            if (isPickupDate) {
              if (selectedTimes?.startTime) {
                const index = findIndexOfTime(timeData, selectedTimes.startTime, localTimeZone);
                getSelectedPickerTime(timeData[index]);
              }
            } else {
              if (selectedTimes?.endTime) {
                const index = findIndexOfTime(timeData, selectedTimes.endTime, localTimeZone);
                getSelectedPickerTime(timeData[index]);
              }
            }
          }}
          onSlideChange={e => {
            const selectedTime = timeData[e.activeIndex];
            getSelectedPickerTime(selectedTime);
            
            if (isPickupDate) {
              setSelectedTimes(prev => {
                if (selectedDates?.startDate && selectedDates?.endDate && 
                    moment(selectedDates.startDate).isSame(selectedDates.endDate, 'day')) {
                      const exactDropoffTime = moment(selectedTime)
                    .clone()
                    .add(2, 'hours');
                  const dropoffTimeData = createData(
                    timeZone,
                    selectedDates.endDate,
                    false,
                    selectedDates,
                    { ...prev, startTime: selectedTime }
                  );
                  const closestIndex = findClosestTimeIndex(
                    dropoffTimeData,
                    exactDropoffTime,
                    localTimeZone
                  );
                  
                  return {
                    startTime: selectedTime,
                    endTime: dropoffTimeData[closestIndex]
                  };
                } else {
                  return {
                    ...prev,
                    startTime: selectedTime
                  };
                }
              });
            }
            
             else {
              setSelectedTimes(prev => {
                if (selectedDates?.startDate && selectedDates?.endDate && 
                    moment(selectedDates.startDate).isSame(selectedDates.endDate, 'day')) {
                      const minEndTime = moment(prev.startTime)
                      .clone()
                      .add(2, 'hours');
                    
                    if (moment(selectedTime).isBefore(minEndTime)) {
                      setSelectedDates(prev => ({ ...prev, endDate: null }));
                      return { ...prev, endTime: null };
                    }
                  }
                  return { ...prev, endTime: selectedTime };
              });
            }
          }}
        >
          {timeData.map(d => (
            <SwiperSlide key={d.valueOf()}>
              <div className={classNames(css.time)}>
                <span className={css.timeStick}></span>
                <span className={css.timeChip}>{d.tz(localTimeZone, true).format('h:mm:a')}</span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={css.submitFooter}>
        <div className={classNames(css.searchBtn)} onClick={_handleTimeSelect}>
          Save &amp; Continue
        </div>
      </div>
    </div>
  );
};

export default SwipeTimeSelector;
