import React, { useEffect, useState } from 'react';
import css from './LongTermRentalListingPage.css';
import { IconClose, Modal, SecondaryButton } from '../../components';
import exitModalMobile from '../../assets/exitModalMobile.png';
import exitModalImage from '../../assets/exit-modal-flex-plus.png';
import exitModalMobile2x from '../../assets/exitModalMobile-2x.png';
import exitModalImage2 from '../../assets/exit-modal-flex-plus-2x.png';
import classNames from 'classnames';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import { getCountryCode } from '../../util/helpers';
import { drivelahApiPost } from '../../util/apiHelper';
import AlertBox from '../../components/AlertBox/AlertBox';
import get from 'lodash/get';
import { updateUserData } from '../../ducks/user.duck';
import { useDispatch } from 'react-redux';

const ImageComponent = ({ src1x, src2x }) => {
  return (
    <div className={css.imageComponent}>
      <img
        alt="Normal Image1213s"
        srcSet={`${src1x} 1x, ${src2x} 2x`}
        className={css.imageComponentImage}
      />
    </div>
  );
};

function ExitModalFlexPlus({ isMobileLayout, handleModal, setHandleModal, modalRef, closeModal, currentUser}) {
  const dispatch = useDispatch()
  const [phoneNumber, setPhoneNumber] = useState('');
  const [fullName, setFullName] = useState('');
  const [countrycode, setCountryCode] = useState('+61');
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [showNameError, setNameError] = useState(false);
  const [userId, setUserId] = useState('')

  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exitCount, setExitCount] = useState(0);

  const currentUserId = get(currentUser, "id.uuid", null)
  const userPhoneNumber = get(currentUser, "attributes.profile.protectedData.phoneNumber", null)
  const userCountryCode = get(currentUser, "attributes.profile.protectedData.phoneCode", null)
  const userFirstName = get(currentUser, "attributes.profile.firstName", "")
  const userLastName = get(currentUser, "attributes.profile.lastName", "")
  const userFullName = userFirstName + " " + userLastName

  console.log('userFullName', userFullName);
  console.log("currentUser id", currentUserId);
  console.log("current user phone number", userPhoneNumber);
  console.log("current user country code", userCountryCode);
  console.log("currentuser", currentUser);

  useEffect(() => {
    currentUserId && setUserId(currentUserId)
    setFullName(userFullName)
    userCountryCode && setCountryCode(userCountryCode)
    userPhoneNumber && setPhoneNumber(userPhoneNumber)
  }, [currentUser]);

  useEffect(() => {
    if(handleModal && userId && (fullName !== "" || fullName !== " ") && phoneNumber && countrycode) {
      console.log('function called for submit', handleModal);
      handleExitModalSubmit()
    } else {
      console.log("function should not be called", handleModal);
    }
  }, [handleModal]);


  useEffect(() => {
    let currentCount = window.localStorage.getItem('exitFlexModalCount') || 0;
    // window.localStorage.setItem('exitFlexModalCount', currentCount)
    setExitCount(currentCount);
    console.log('exitModal cuurent', currentCount, exitCount);
  }, []);

  const showErrorAlert = (
    <AlertBox
      title="Oops! Phone Error"
      message={'Please enter correct number'}
      type="error"
    />
  );

  const showNameAlert = (
    <AlertBox
      title="Oops! Name Error"
      message={'Please enter name'}
      type="error"
    />
  );

  const showSuccessAlert = (
    <AlertBox
      title="Thank you for your interest."
      message={'Someone from our team will get in touch with you in the next few hours.'}
      type="success"
    />
  );

  function handleExitModalSubmit(e, values) {

    if(fullName === '') {
      setNameError(true);
      setTimeout(() => {
        setNameError(false)
      }, 1000)
      return
    }
    if (phoneNumber === '' || phoneNumber.length < 8) {
      setShowPhoneError(true);
      setTimeout(() => {
        setShowPhoneError(false)
      }, 1000)
      return
    }

    setIsLoading(true);
    e && e.preventDefault();

    const query = 'jiraModule/create-hubspot-contact';
    const ticketTitle = `Exit popup lead - (${countrycode})${phoneNumber}`;
    const ticketDescription = `The user was viewing this listing: ${process.env.REACT_APP_CANONICAL_ROOT_URL}${window.location.pathname}. \nThey were dropping off and provided their phone number at the time of exit. Give them a call to identify their requirements.`;

    const payload = {
      title: ticketTitle,
      phone: countrycode + phoneNumber,
      description: ticketDescription,
      country: 'Australia',
      name: fullName
    };

    console.log("API called with payload", payload);
    // try {
    //   drivelahApiPost(query, payload).then(res => {
    //     if (res) {
    //       setShowSuccessToast(true);
    //       setTimeout(() => {
    //         setIsLoading(false);
    //         setHandleModal(false);
    //       }, 1000);
    //     }
    //   });
    // } catch (e) {
    //   setIsLoading(false);
    // }

    //Update user protectedData if phone number is not there
    try{
      if (userId && !userPhoneNumber) {
        console.log("update user phone number", phoneNumber, countrycode);
        dispatch(updateUserData({
          protectedData: {
            phoneNumber: phoneNumber,
            phoneCode: countrycode
          }
        }))
      }
    } catch (e) {
      console.log("Error updating user phone details", e);
    }
  }

  return (
    <Modal
      id="ClosePopUpModal"
      containerClassName={css.exitModalContainer}
      isOpen={(exitCount < 3) && handleModal && (!userPhoneNumber || (userFullName === "" || userFullName === " "))}
      disableCloseBtn={true}
      onManageDisableScrolling={false}
      forceClose={false}
      closeButtonClassName={css.closeButtonClassName}
      ref={modalRef}
    >
      {showPhoneError && showErrorAlert}
      {showNameError && showNameAlert}
      {showSuccessToast && showSuccessAlert}
      <div className={css.modalContainerContent}>
        <div className={css.closeButtonClassName}>
          <div style={{ cursor: 'pointer' }} onClick={() => {
            setExitCount(exitCount + 1);
            window.localStorage.setItem('exitFlexModalCount', parseInt(exitCount) + 1);
            closeModal()
          }}>
            <IconClose size={isMobileLayout ? "small" : "large"}/>
          </div>
        </div>
        <div className={css.section1}>
          <ImageComponent
            src1x={isMobileLayout ? exitModalMobile : exitModalImage}
            src2x={isMobileLayout ? exitModalMobile2x : exitModalImage2}
            className={css.imageComponent}
          />
        </div>
        <div className={css.section2}>
            <span className={css.exitModalHeading}>
                Wait! Discover Perfect Car Deals
            </span>
          <div>
            <p className={css.para}>
              Before you leave, drop your number. Get exclusive access to our best rental cars at
              unbeatable prices.
            </p>
          </div>
          <div>
            <p className={css.fieldLabel}>Full Name*</p>
            <div style={{ display: 'flex', marginTop: '6px' }}>
              <div className={css.borderBox} style={{
                width: '220px',
              }}>
                <input
                  placeholder={'John Doe'}
                  style={{
                    padding: '15px',
                  }}
                  type={'text'}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
          <div>
            <p className={css.fieldLabel}>Contact Number</p>
            <div style={{ display: 'flex', marginTop: '6px' }}>
              <div className={css.borderBox} style={{
                width: '73px',
              }}>
                <select
                  name="country"
                  className={classNames(css.field, css.formInputNew)}
                  defaultValue={'+61'}
                  style={{
                    padding: '15px',
                    margin: '1px',
                  }}
                  value={countrycode}
                  onChange={(e) => setCountryCode(e.target.value)}
                >
                  {config.custom.phoneCode.map((option, index) => (
                    <option value={option.key} key={option.key}>
                      {option.key}
                    </option>
                  ))}
                </select>
              </div>
              <div className={css.borderBox} style={{
                width: '137px',
                marginLeft: '10px',
              }}>
                <input
                  placeholder={'1234 1234'}
                  style={{
                    padding: '15px',
                  }}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  maxLength={12}
                />
              </div>
            </div>
          </div>
          <div style={{
            marginTop: '10px',
          }}>
            <SecondaryButton
              className={css.exitModalButton}
              type="submit"
              onClick={(e) => handleExitModalSubmit(e)}
            >
              <div className={css.buttonTxt}>
                <FormattedMessage id="LongTermRentalListing.exitModalButtonText" />
              </div>
            </SecondaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ExitModalFlexPlus;
