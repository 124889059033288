import React, { useRef } from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';

import { connect } from 'react-redux';
import HeroSection from './BAHHeroSection';
import WhyDriveLahSection from './BAHWhyDriveLah';
import IntroSection from './BAHIntroSection';
import BenifitsSection from './BAHBenifitsSection';
import FAQSection from './BAHFAQ';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import BAHostTable from './BAHostTable';
import { initiateListACarEventFromTab, pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import {
  EVENT_BROWSE_USEDRENTALCAL,
  EVENT_LISTACAR_CLICKED_LIST_A_CAR,
  LISTACAR_CREATE_NEW_BUTTON_ID,
} from '../../util/gtm/gtmConstants';
import css from './BecomeAHostPage.css';

const BecomeAHostPage = props => {
  const { isAuthenticated, onManageDisableScrolling } = props;
  const targetRef = useRef(null);
  const onUseRentalCalculator = () => {
    pushGTMBrowseEvent({
      props,
      event: EVENT_BROWSE_USEDRENTALCAL,
    });
  }

  const handleGetToCalculatorSection = () => {
    window.scrollTo(0, targetRef.current.offsetTop - 72);
  };

  const onListYourCar = () => {
    initiateListACarEventFromTab({
      props,
      eventButtonParam:
        [EVENT_LISTACAR_CLICKED_LIST_A_CAR, LISTACAR_CREATE_NEW_BUTTON_ID],
    });
  }
  // prettier-ignore
  return (
      <StaticPage
        title="Host today. Become a host & earn 20% more sharing your car | Drive Mate car sharing Australia"
        schema={{
          '@context': 'http://schema.org',
          '@type': 'BecomeAHost',
          description: 'Be in full control of your pricing, availability, and accepting or declining requests. Choose car sharing Australia with Drive Mate for 20% higher earnings. Start earning extra cash today!',
          name: 'Become A Host',
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain className={css.staticPageWrapper}>
          <div ref={targetRef}>
            <HeroSection
              onUseRentalCalculator={onUseRentalCalculator}
              onListYourCar={onListYourCar}
              listYourCarButtonId={LISTACAR_CREATE_NEW_BUTTON_ID}
              />
<BAHostTable/>
              </div>
              
            <WhyDriveLahSection isAuthenticated={isAuthenticated}/>
            <IntroSection onManageDisableScrolling={onManageDisableScrolling}/>
            <BenifitsSection onManageDisableScrolling={onManageDisableScrolling}/>
            <FAQSection getToCalculatorSection={handleGetToCalculatorSection}/>
            {/* <ReadyToShareSection /> */}
          </LayoutWrapperMain>

          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </StaticPage>
    );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  return {
    isAuthenticated,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disabled) =>
    dispatch(manageDisableScrolling(componentId, disabled)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BecomeAHostPage);
