import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { FieldTextInput, Form, NamedLink, PrimaryButton } from '../../components';
import facebookLogo from '../../assets/facebook.png';
import googleLogo from '../../assets/google_logo.png';
import appleLogo from '../../assets/apple-logo.svg';
import SignUpField from './SignUpField';
import { gSend, GTAG_ACTIONS, GTAG_METHODS } from '../../util/gtag';

import css from './SignupForm.css';
import AppleSignin from 'react-apple-signin-auth';
import config from '../../config';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => {

   const onSubmit = (values) => {
    const trimmedValues = {
        ...values,
        fname: values.fname.trim(),
        lname: values.lname.trim(),
    };
    props.submitForm(trimmedValues);
};


   return (
    <FinalForm
    onSubmit={onSubmit}
    {...props}
    initialValues={{ email: props.initEmail, ...props.initialValues }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        facebook,
        google,
        invalid,
        values,
        intl,
        onOpenTermsOfService,
        submitButtonId,
        touched,
        errors,
        handleChange,
        location
      } = fieldRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };

      const termsLink = (
        <NamedLink newTab={true} name="TermsOfServicePage">
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </NamedLink>
      );

      const policyLink = (
        <NamedLink newTab={true} name="PrivacyPolicyPage">
          <FormattedMessage id="SignupForm.privacyPolicyLinkText" />
        </NamedLink>
      );

      const getLocationState = location ? {
        state: location.state
      } : {}

      return (
        <>
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <FieldTextInput
                className={css.formInput}
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                disabled={!!props.initEmail}
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <SignUpField
                intl={intl}
                formId={formId}
                values={values}
                touched={touched}
                errors={errors}
              />
            </div>

            <div className={css.bottomWrapper}>
              <PrimaryButton
                className={css.signInButton}
                id={submitButtonId}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
              <p className={css.bottomWrapperText}>
                <span className={css.termsText}>
                  <FormattedMessage
                    id="SignupForm.termsAndConditionsAcceptText"
                    values={{ termsLink, policyLink }}
                  />
                </span>
              </p>
            </div>
          </Form>
          <div className={css.loginWith}>
            <span className={css.text}>
              <h3>
                <span>or sign up with</span>
              </h3>
            </span>
            <div className={classNames(css.loginUsing, css.facebook)}>
              <a onClick={facebook}>
                <span>
                  <img src={facebookLogo} alt={'facebook logo'} />
                </span>
                <span className={classNames(css.btnText, css.facebook)}>Sign in with Facebook</span>
              </a>
            </div>
            <div className={classNames(css.loginUsing, css.google, css.googleBorder)}>
              <a onClick={google}>
                <span>
                  <img src={googleLogo} alt={'google logo'} />
                </span>
                <span className={classNames(css.btnText, css.google)}>Sign in with Google</span>
              </a>
            </div>
            <AppleSignin
              authOptions={config.appleAuthOptions}
              uiType="dark"
              className="apple-auth-btn"
              noDefaultStyle={false}
              buttonExtraChildren="Continue with Apple"
              skipScript={false}
              onSuccess={_response => {
                gSend(GTAG_ACTIONS.ACTION_SIGNUP, { method: GTAG_METHODS.APPLE_SIGNUP });
              }}
              render={(props) => (
                <div className={classNames(css.loginUsing, css.apple)} {...props}>
                  <a>
                    <span>
                      <img src={appleLogo} alt={'apple logo'} />
                    </span>
                    <span className={classNames(css.btnText, css.apple)}>Sign in with Apple</span>
                  </a>
                </div>
              )}
            />
            <div className={css.signupButton} onClick={() => handleChange(true)}>
              Already have an account?
              <NamedLink name="LoginPage" onClick={() => handleChange(true)} to={getLocationState}>
                <a> Log In</a>
              </NamedLink>
            </div>
          </div>
        </>
      );
    }}
  />
   )
};

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func, string } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,
  facebook: func,
  onOpenTermsOfService: func.isRequired,
  google: func,
  signUpCode: string,

  initEmail: string,
  submitButtonId: string,
  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
