import moment from "moment";

export const generateTimeSlots = () => {
  const slots = [];
  for (let hour = 8; hour <= 20; hour++) {
    const hourStr = hour < 10 ? `0${hour}` : `${hour}`;
    const amPmHour = hour <= 12 ? hour : hour - 12;
    const amPmStr = hour < 12 ? 'am' : 'pm';

    slots.push(`${amPmHour}:00 ${amPmStr}`);
    slots.push(`${amPmHour}:30 ${amPmStr}`);
  }
  return slots;
};


export const calculateDaysBetween = (slotState) => {
  const { pickup, dropoff } = slotState;

  if (
    pickup.date && pickup.month && pickup.year &&
    dropoff.date && dropoff.month && dropoff.year
  ) {
    const pickupDate = new Date(
      `${pickup.year}-${pickup.month}-${pickup.date}`
    );
    const dropoffDate = new Date(
      `${dropoff.year}-${dropoff.month}-${dropoff.date}`
    );

    const timeDiff = dropoffDate - pickupDate;

    const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    return dayDiff;
  } else {
    return "Incomplete date information";
  }
};

export const formatSlotToHumanReadable = (slot) => {
  const dateTimeString = `${slot.year}-${slot.month}-${slot.date} ${slot.time}`;
  return moment(dateTimeString, 'YYYY-M-D hh:mm a').format('lll');
};