import merge from 'lodash/merge';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { changePhoneNumber, sendOTP, USED_PHONE } from '../../util/identityVerificationHelpers';

import { currentUserShowSuccess, openMissingInfomationModal } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SAVE_CONTACT_DETAILS_REQUEST = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_REQUEST';
export const SAVE_CONTACT_DETAILS_SUCCESS = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_SUCCESS';
export const SAVE_EMAIL_ERROR = 'app/ContactDetailsPage/SAVE_EMAIL_ERROR';
export const SAVE_PHONE_NUMBER_ERROR = 'app/ContactDetailsPage/SAVE_PHONE_NUMBER_ERROR';

export const SAVE_CONTACT_DETAILS_CLEAR = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_CLEAR';

// ================ Reducer ================ //

const initialState = {
  saveEmailError: null,
  savePhoneNumberError: null,
  saveContactDetailsInProgress: false,
  contactDetailsChanged: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_CONTACT_DETAILS_REQUEST:
      return {
        ...state,
        saveContactDetailsInProgress: true,
        saveEmailError: null,
        savePhoneNumberError: null,
        contactDetailsChanged: false,
      };
    case SAVE_CONTACT_DETAILS_SUCCESS:
      return { ...state, saveContactDetailsInProgress: false, contactDetailsChanged: true };
    case SAVE_EMAIL_ERROR:
      return { ...state, saveContactDetailsInProgress: false, saveEmailError: payload };
    case SAVE_PHONE_NUMBER_ERROR:
      return { ...state, saveContactDetailsInProgress: false, savePhoneNumberError: payload };

    case SAVE_CONTACT_DETAILS_CLEAR:
      return {
        ...state,
        saveContactDetailsInProgress: false,
        saveEmailError: null,
        savePhoneNumberError: null,
        contactDetailsChanged: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveContactDetailsRequest = () => ({ type: SAVE_CONTACT_DETAILS_REQUEST });
export const saveContactDetailsSuccess = () => ({ type: SAVE_CONTACT_DETAILS_SUCCESS });
export const saveEmailError = error => ({
  type: SAVE_EMAIL_ERROR,
  payload: error,
  error: true,
});
export const savePhoneNumberError = error => ({
  type: SAVE_PHONE_NUMBER_ERROR,
  payload: error,
  error: true,
});

export const saveContactDetailsClear = () => ({ type: SAVE_CONTACT_DETAILS_CLEAR });

// ================ Thunks ================ //

/**
 * Make a phone number update request to the API and return the current user.
 */
const requestSavePhoneNumber = params => (dispatch, getState, sdk) => {
  const phoneNumber = params.phoneCode + params.phoneNumber;

  return sdk.currentUser
    .show()
    .then(response => {
      const userId = response.data.data.id.uuid;
      const oldPhoneNumber = !!response.data.data.attributes.profile.protectedData.phoneNumber ? response.data.data.attributes.profile.protectedData.phoneNumber : "";
      return changePhoneNumber(userId, phoneNumber, oldPhoneNumber)
        .then((resp) => {
          return resp.json();
        })
        .then((jsonData) => {

          if (jsonData.message === USED_PHONE) {
            const error = {
              type: 'error',
              name: 'Phone used',
              message: 'phoneNumberVerificationForm.usedPhone',
              status: 400,
              statusText: 'Bad request',
            }
            dispatch(savePhoneNumberError(storableError(error)));
            throw new Error(error.name);
          }


          if (jsonData.message !== "ok") {
            const error = {
              type: 'error',
              name: 'cannot_change_phone',
              message: 'cannot_change_phone',
              status: 400,
              statusText: 'Bad request',
            }
            dispatch(savePhoneNumberError(storableError(error)));
            throw error;
          }

          return sendOTP(userId, phoneNumber)
        })
        .then(response => {
          return response.json();
        })
        .then(jsonData => {
          if (jsonData.message === USED_PHONE) {
            const error = {
              type: 'error',
              name: 'Phone used',
              message: 'phoneNumberVerificationForm.usedPhone',
              status: 400,
              statusText: 'Bad request',
            }
            dispatch(savePhoneNumberError(storableError(error)));
            throw new Error(error.name);
          }

          if (jsonData.message !== "code_valid" && jsonData.message !== "ok") {
            const error = {
              type: 'error',
              name: 'cannot_change_phone',
              message: 'cannot_change_phone',
              status: 400,
              statusText: 'Bad request',
            }
            dispatch(savePhoneNumberError(storableError(error)));
            throw error;
          }

          const phoneNumberVerified = false;
          const phoneObj = {
            phoneCode: params.phoneCode,
            rawNumber: params.phoneNumber
          }

          return sdk.currentUser
            .updateProfile(
              { protectedData: { phoneNumber, phoneNumberVerified, phoneObj } },
              {
                expand: true,
                include: ['profileImage'],
                'fields.image': ['variants.square-small', 'variants.square-small2x'],
              }
            )
            .then(response => {
              const entities = denormalisedResponseEntities(response);
              if (entities.length !== 1) {
                throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
              }

              const currentUser = entities[0];
              return currentUser;
            })

        })
    })
    .catch(e => {
      dispatch(savePhoneNumberError(storableError(e)));
      // pass the same error so that the SAVE_CONTACT_DETAILS_SUCCESS
      // action will not be fired
      throw e;
    });

};




/**
 * Save phone number and update the current user.
 */
const savePhoneNumber = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestSavePhoneNumber(params))
      .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveContactDetailsSuccess());
        dispatch(openMissingInfomationModal(true));
      })
      // error action dispatched in requestSavePhoneNumber
      .catch(e => null)
  );
};

/**
 * Save email and phone number and update the current user.
 */
const saveEmailAndPhoneNumber = params => (dispatch, getState, sdk) => {
  const { phoneCode, phoneNumber, currentPassword } = params;

  // order of promises: 1. email, 2. phone number
  const promises = [

    dispatch(requestSavePhoneNumber({ phoneCode, phoneNumber })),
  ];

  return Promise.all(promises)
    .then(values => {
      // Array of two user objects is resolved
      // the first one is from the email update
      // the second one is from the phone number update

      const saveEmailUser = values[0];
      const savePhoneNumberUser = values[1];


      // merge the protected data from the user object returned
      // by the phone update operation
      const protectedData = savePhoneNumberUser.attributes.profile.protectedData;
      const phoneNumberMergeSource = { attributes: { profile: { protectedData } } };

      const currentUser = merge(saveEmailUser, phoneNumberMergeSource);
      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveContactDetailsSuccess());
      dispatch(openMissingInfomationModal(true));

    })
    .catch(e => null);
};

/**
 * Update contact details, actions depend on which data has changed
 */
export const saveContactDetails = params => (dispatch, getState, sdk) => {
  dispatch(saveContactDetailsRequest());

  const { phoneCode, phoneNumber, currentPhoneNumber, currentPhoneCode } = params;

  const phoneNumberChanged = (phoneCode + phoneNumber) !== (currentPhoneCode + currentPhoneNumber);

  if (phoneNumberChanged) {
    console.log('HURREYY I ENTER HERE >>', params);
    
    return dispatch(savePhoneNumber({ phoneCode, phoneNumber }));
  }
};
