import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { get } from 'lodash';
import { bool, func, number, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';
import moment from 'moment-timezone';
import { locationRadiusBounds } from '../../util/googleMaps';
import { FilterButton, SelectMultipleFilter } from '../../components';
import routeConfiguration from '../../routeConfiguration';
import { parseDateFromISO8601 } from '../../util/dates';
import { createResourceLocatorString } from '../../util/routes';
import { propTypes } from '../../util/types';
import css from './LongTermSearchFilters.css';
import arrowDown from '../../assets/arrow-down-black.svg';
import { isMobile } from 'react-device-detect';
import LongTermSortBy from '../LongTermSortBy/LongTermSortBy';
import config from '../../config';
import SelectRadioFilter from '../SelectRadioFilter/SelectRadioFilter';
import { flexPlusPriceRange } from '../../marketplace-custom-config';

// Distance for home delivery in meters
const LIMIT_DISTANCE = 5200;

// Dropdown container can have a positional offset (in pixels)
const FILTER_DROPDOWN_OFFSET = -14;
const RADIX = 10;

// resolve initial value for a single value filterLongTermSortBy
const initialValue = (queryParams, paramName) => {
  return queryParams[paramName];
};

// resolve initial values for a multi value filter
const initialValues = (queryParams, paramName) => {
  return !!queryParams[paramName] ? queryParams[paramName].split(',') : [];
};

const initialFlexPlusPriceRangeValue = (queryParams, paramName) => {
  const price = queryParams[paramName];
  const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];

  return !!price && valuesFromParams.length === 2
    ? [price] : [];
};

const initialDateRangeValue = (queryParams, paramName) => {
  const dates = queryParams[paramName];
  const hours = queryParams['hours'];
  const rawHoursValues = hours ? hours.split(',') : [];
  const rawValuesFromParams = !!dates ? dates.split(',') : [];
  const valuesFromParams = rawValuesFromParams.map(v => parseDateFromISO8601(v));
  const initialValues =
    !!dates && valuesFromParams.length === 2
      ? {
          dates: { startDate: valuesFromParams[0], endDate: valuesFromParams[1] },
        }
      : { dates: null };
  if (hours && rawHoursValues.length === 2) {
    initialValues.times = {
      pickupTime: moment(rawHoursValues[0], 'HH:mm').format('hh:mm a'),
      dropOffTime: moment(rawHoursValues[1], 'HH:mm').format('hh:mm a'),
    };
  } else {
    initialValues.times = null;
  }
  return initialValues;
};
const LongTermSearchFiltersComponent = props => {
  const {
    rootClassName,
    className,
    urlQueryParams,
    listingsAreLoaded,
    resultsCount,
    categoryFilter,
    cityFilter,
    amenitiesFilter,
    dateRangeFilter,
    keywordFilter,
    history,
    intl,
    sort,
    isPHVFilter,
    isAgeOfCar,
    currentUser,
    canDriveToMalaysiaFilter,
    openFilterModal,
    brandNamesFilter,
    typeOfFuelFilter,
    priceFilter,
    flexPlusPriceFilter,
    isMobileLayout,
  } = props;

  console.log("flexPlusPriceFilter", flexPlusPriceFilter)
  console.log("priceFilter", priceFilter);

  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = classNames(rootClassName || css.root, { [css.longInfo]: hasNoResult }, className);
  const [openSlider, setOpenSlider] = useState(false);
  const [value1, setValue1] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(500);

  useEffect(() => {
    // dasd
  });



  const categoryLabel = intl.formatMessage({
    id: 'SearchFilters.categoryLabel',
  });
  const cityLabel = intl.formatMessage({
    id: 'SearchFilters.cityLabel',
  });
  const initialCategory = categoryFilter
    ? initialValues(urlQueryParams, categoryFilter.paramName)
    : null;
  const initialCity = cityFilter
    ? initialValues(urlQueryParams, cityFilter.paramName)
    : null;
  const initialBrandNames = brandNamesFilter
    ? initialValues(urlQueryParams, brandNamesFilter.paramName)
    : null;
  const initialTypeOfFuelNames = typeOfFuelFilter
    ? initialValues(urlQueryParams, typeOfFuelFilter.paramName)
    : null;

  console.log("initialBrandNames", initialBrandNames);
  console.log("initialTypeOfFuelNames", initialTypeOfFuelNames);
  console.log("initialCategory", initialCategory);
  console.log("flexPlusPriceFilter", flexPlusPriceFilter, urlQueryParams);
  const initialFlexPlusPriceRange = initialFlexPlusPriceRangeValue(urlQueryParams, flexPlusPriceFilter.paramName);
  console.log("initialFlexPlusPriceRange", initialFlexPlusPriceRange);

  const initialKeyword = keywordFilter
    ? initialValue(urlQueryParams, keywordFilter.paramName)
    : null;

  const isKeywordFilterActive = !!initialKeyword;

  const handleSelectOptions = (urlParam, options) => {
    let params = {};
    console.log("urlParam", urlParam);
    console.log("options", options);
    if (urlParam && urlParam === 'pub_listingTimezone') {
      delete urlQueryParams.pub_listingTimezone;
      delete urlQueryParams.address;
      delete urlQueryParams.bounds;
      const getCityInCapAndSmall = (city) => {
        // params = CITY_SEARCH_BOUNDS[city] || {};
        const cities = config && config.custom && config.custom.cities.length ? config.custom.cities.filter((city) => city.enabled) : [];
        if (cities && cities.length) {
          for (let i = 0; i < cities.length; i++) {
            if (cities[i].key === city) {
              city = `${city}`;
              break;
            }
          }
        }

        return city;
      };
      options = options && options.length ? options.map((item) => { return getCityInCapAndSmall(item); }) : [];
    }

    // let urlParams = urlParam === 'pub_listingTimezone' ?  {} : { [urlParam]: options.join(',') };
    let urlParams = { [urlParam]: options && options.join ? options.join(',') : '' };
    const queryParams =
      options && options.length > 0
        ? {
          ...urlQueryParams,
          ...urlParams,
          ...params
        }
        : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString('LongTermRentalSearch', routeConfiguration(), {}, queryParams)
    );
  };

  const handleSelectOption = (urlParam, option) => {
    const queryParams = option
      ? { ...urlQueryParams, [urlParam]: option }
      : omit(urlQueryParams, urlParam);

    if (urlParam === 'pub_delivery') {
      const userLocation = get(
        currentUser,
        'attributes.profile.protectedData.location.selectedPlace.origin'
      );
      const newBounds = userLocation && locationRadiusBounds(userLocation, LIMIT_DISTANCE);
      newBounds && (queryParams.bounds = newBounds);
    }

    history.push(
      createResourceLocatorString('LongTermRentalSearch', routeConfiguration(), {}, queryParams)
    );
  };

  const handlePrice = (urlParam, option) => {
    const queryParams = option
      ? { ...urlQueryParams, [urlParam]: option }
      : omit(urlQueryParams, urlParam);
    history.push(
      createResourceLocatorString('LongTermRentalSearch', routeConfiguration(), {}, queryParams)
    );
  };

  const openRangeSilder = () => {
    setOpenSlider(!openSlider);
  };

  const formatDollar = value1 => '$' + value1;

  const handleChange = value1 => {
    setValue1(value1);
  };
  const handleInput = e => {
    setMinValue(e.minValue);
    setMaxValue(e.maxValue);
  };

  const categoryFilterElement = categoryFilter ? (
    <SelectMultipleFilter
      id={'SearchFilters.categoryFilter'}
      name="categories"
      urlParam={categoryFilter.paramName}
      label={categoryLabel}
      onSubmit={handleSelectOptions}
      showAsPopup
      options={categoryFilter.options}
      initialValues={initialCategory}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      canApply={true}
    />
  ) : null;

  const cityFilterElement = cityFilter ? (
    <SelectMultipleFilter
      id={'SearchFilters.cityFilter'}
      name="cities"
      urlParam={cityFilter.paramName}
      label={cityLabel}
      onSubmit={handleSelectOptions}
      showAsPopup
      options={cityFilter.options}
      initialValues={initialCity}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      canApply={true}
    />
  ) : null;

  const brandNamesFilterElement = brandNamesFilter ? (
    <SelectMultipleFilter
      id={'SearchFilters.brandNamesFilter2'}
      name="brandNames"
      urlParam={brandNamesFilter.paramName}
      label="Brand"
      onSubmit={handleSelectOptions}
      showAsPopup
      liveEdit
      options={brandNamesFilter.options}
      initialValues={initialBrandNames}
      clearButtonClass={css.clearbutton}
      plainClassName={css.plainClassName}
      threeColumns={true}
      className={css.fullHeight}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      intl={intl}
      // twoColumns={true}
    />
  ) : null;

  const priceFilterElement = brandNamesFilter ? (
    <SelectRadioFilter
      id={'flexPlusPrice.price'}
      name="flexPlusPrice"
      urlParam={flexPlusPriceFilter.paramName}
      label="Price"
      onSubmit={handlePrice}
      showAsPopup
      liveEdit
      options={flexPlusPriceRange}
      initialValues={initialFlexPlusPriceRange}
      clearButtonClass={css.clearbutton}
      plainClassName={css.plainClassName}
      threeColumns={true}
      className={css.fullHeight}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      intl={intl}
      // twoColumns={true}
    />
  ) : null;

  const typeOfFuelFilterElement = typeOfFuelFilter ? (
    <SelectMultipleFilter
      id={'SearchFilters.typeOfFuelFilter'}
      name="brandNames"
      urlParam={typeOfFuelFilter.paramName}
      label="Fuel type"
      onSubmit={handleSelectOptions}
      showAsPopup
      liveEdit
      options={typeOfFuelFilter.options}
      initialValues={initialTypeOfFuelNames}
      clearButtonClass={css.clearbutton}
      threeColumns={true}
      className={css.fullHeight}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      intl={intl}
      twoColumns={true}
    />
  ) : null;

  const ageOfCarFilterElement = isAgeOfCar ? (
    <FilterButton
      urlParam={isAgeOfCar.paramName}
      label="Younger than 5 years"
      onSubmit={handleSelectOption}
      value={initialValue(urlQueryParams, isAgeOfCar.paramName)}
      hintText={null}
    />
  ) : null;

  const phvFilterFilterElement = isPHVFilter ? (
    <FilterButton
      urlParam={isPHVFilter.paramName}
      label="Allowed PHV"
      onSubmit={handleSelectOption}
      value={initialValue(urlQueryParams, isPHVFilter.paramName)}
      hintText={null}
    />
  ) : null;

  const driveToMalaysiaFilterElement = canDriveToMalaysiaFilter ? (
    <FilterButton
      urlParam={canDriveToMalaysiaFilter.paramName}
      label={intl.formatMessage({ id: 'SearchFilters.canDriveToMalaysiaFilter' })}
      onSubmit={handleSelectOptions}
      value={initialValue(urlQueryParams, canDriveToMalaysiaFilter.paramName)}
      hintText={null}
      showAsPopup
    />
  ) : null;

  const handleSortBy = (urlParam, values) => {
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);
    if (values === 'relevance') {
      queryParams.sort = 'relevance';
    }
    history.push(
      createResourceLocatorString('LongTermRentalSearch', routeConfiguration(), {}, queryParams)
    );
  };

  const sortConfig = {
    // Enable/disable the sorting control in the SearchPage
    active: true,

    // Internal key for the relevance option, see notes below.
    relevanceKey: 'ageOfCar',

    options: [
      // The relevance is only used for keyword search, but the
      // parameter isn't sent to the Marketplace API. The key is purely
      // for handling the internal state of the sorting dropdown.
      { key: 'ageOfCar', label: 'Age of car' },
      { key: '-pub_flexPlusPrice', label: 'Lowest price' },
      { key: 'pub_flexPlusPrice', label: 'Highest price' },
    ],
  };

  const sortBy = sortConfig.active ? (
    <LongTermSortBy
      sort={sort}
      showAsPopup
      sortConfig={sortConfig}
      isKeywordFilterActive={isKeywordFilterActive}
      onSelect={handleSortBy}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  ) : null;

  const onSubmit = (urlParam, option) => {
    // e.preventDefault();

    // query parameters after selecting the option
    // if no option is passed, clear the selection for the filter
    const queryParams = option
      ? { ...urlQueryParams, [urlParam]: option }
      : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString('LongTermRentalSearch', routeConfiguration(), {}, queryParams)
    );
  };
  const _handleFilterChange = (urlParam, option) => {
    // e.preventDefault();

    // query parameters after selecting the option
    // if no option is passed, clear the selection for the filter
    const queryParams = option
      ? { ...urlQueryParams, [urlParam]: option.key }
      : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString('LongTermRentalSearch', routeConfiguration(), {}, queryParams)
    );
  };

  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        <div className={classNames(css.filtersAndSortWrap, css.row)}>
          <div className={classNames(css.filters, css.column80)}>
            <div className={css.filterWrapper}>
              <div className={css.filterSlection}>
              {isMobile ?
                <button
                  className={css.filterButtons}
                  onClick={() => {
                    openRangeSilder();
                    openFilterModal(true);
                  }}
                >
                  {isMobile ? 'Filter' : 'Price'}
                  <img src={arrowDown} alt="arrow" />
                </button>: ''}
              </div>
              {!isMobile && (
                <>
                  {categoryFilterElement}
                  {typeOfFuelFilterElement}
                  {cityFilterElement}
                  {/* {phvFilterFilterElement} */}
                  {/* {ageOfCarFilterElement}*/}
                  {brandNamesFilterElement}
                  {priceFilterElement}
                </>
              )}
            </div>
          </div>
          <div className={classNames(css.sorts, css.column20)}>{sortBy}</div>
        </div>
      </div>
    </div>
  );
};

LongTermSearchFiltersComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchingInProgress: false,
  categoryFilter: null,
  amenitiesFilter: null,
  priceFilter: null,
  flexPlusPriceFilter: null,
  dateRangeFilter: null,
  isSearchFiltersPanelOpen: false,
  toggleSearchFiltersPanel: null,
  searchFiltersPanelSelectedCount: 0,
};

LongTermSearchFiltersComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchingInProgress: bool,
  onManageDisableScrolling: func.isRequired,
  categoriesFilter: propTypes.filterConfig,
  amenitiesFilter: propTypes.filterConfig,
  priceFilter: propTypes.filterConfig,
  flexPlusPriceFilter: propTypes.filterConfig,
  dateRangeFilter: propTypes.filterConfig,
  isSearchFiltersPanelOpen: bool,
  toggleSearchFiltersPanel: func,
  searchFiltersPanelSelectedCount: number,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const LongTermSearchFilters = compose(withRouter, injectIntl)(LongTermSearchFiltersComponent);

export default LongTermSearchFilters;
