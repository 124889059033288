import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { get, omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import {
  Button,
  Form,
  SelectMultipleFilter,
  SelectSingleFilter,
  ToggleSwitchFilter,
} from '../../components';
import css from '../../components/LongTermSearchFilters/LongTermSearchFilters.css';
import routeConfiguration from '../../routeConfiguration';
import { locationRadiusBounds } from '../../util/googleMaps';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import setFieldTouched from '../../util/setFiledTouched';
import config from '../../config';
import { default as RangeSlider } from 'react-rangeslider';
import { flexPlusPriceRange } from '../../marketplace-custom-config';
import SelectRadioFilter from '../../components/SelectRadioFilter/SelectRadioFilter';


// Distance for home delivery in meters
const LIMIT_DISTANCE = 5200;
const MAX_MOBILE_SCREEN_WIDTH = 768;

const FILTER_DROPDOWN_OFFSET = -14;
const RADIX = 10;
const LongTermSearchFilterModal = props => {
  const {
    rootClassName,
    className,
    urlQueryParams,
    history,
    currentUser,
    intl,
    secondaryFilters,
    primaryFilters,
    closeSearchModal,
  } = props;

  const { categoryFilter, cityFilter, drivelahGoFilter, isPHVFilter, isAgeOfCar, flexPlusPriceFilter } = primaryFilters;
  const { brandNamesFilter, typeOfFuelFilter, canDriveToMalaysiaFilter } = secondaryFilters;

  const classes = classNames(rootClassName || css.root, className);

  const [value1, setValue1] = useState(0);

  const handleChange = value1 => {
    setValue1(value1);
  };

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(2000);
  const handleInput = e => {
    setMinValue(e.minValue);
    setMaxValue(e.maxValue);
  };

  const handlePrice = urlParam => {
    const range = minValue && maxValue ? `${minValue},${maxValue}` : null;
    const queryParams = range
      ? { ...urlQueryParams, [urlParam]: range }
      : omit(urlQueryParams, urlParam);
    history.push(
      createResourceLocatorString('LongTermRentalSearch', routeConfiguration(), {}, queryParams)
    );
    closeSearchModal();
  };

  // resolve initial values for a multi value filter
  const initialValues = (queryParams, paramName) => {
    return !!queryParams[paramName] ? queryParams[paramName].split(',') : [];
  };

  // resolve initial value for a single value filter
  const initialValue = paramName => {
    return urlQueryParams[paramName];
  };

  const onSubmit = () => {

  };

  const initialPriceRangeValue = (queryParams, paramName) => {
    const price = queryParams[paramName];
    const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];

    return !!price && valuesFromParams.length === 2
      ? {
          minPrice: valuesFromParams[0],
          maxPrice: valuesFromParams[1],
          price,
        }
      : null;
  };

  const initialPriceRange = initialPriceRangeValue(urlQueryParams, 'price');

  const initialValueToggleSwitch = paramName => {
    const currentQueryParams = drivelahGoFilter;
    // const urlQueryParams = urlQueryParams;

    // const getQueryParam = queryParam => {
    //   return typeof queryParam === 'undefined' ? null : queryParam;
    // };

    // // initialValue for a select should come either from state.currentQueryParam or urlQueryParam
    // const hasCurrentQueryParam = typeof currentQueryParams[paramName] !== 'undefined';
    // return hasCurrentQueryParam
    //   ? getQueryParam(urlQueryParams[paramName])
    //   : getQueryParam(urlQueryParams[paramName]);
  };

  const handleSelectOptions = (urlParam, options) => {
    let params = {};
    if (urlParam && urlParam === 'pub_listingTimezone') {
      delete urlQueryParams.pub_listingTimezone;
      delete urlQueryParams.address;
      delete urlQueryParams.bounds;
      const getCityInCapAndSmall = (city) => {
        // params = CITY_SEARCH_BOUNDS[city] || {};
        const cities = config && config.custom && config.custom.cities.length ? config.custom.cities.filter((city) => city.enabled) : [];
        if (cities && cities.length) {
          for (let i = 0; i < cities.length; i++) {
            if (cities[i].key === city) {
              city = `${city},${cities[i].label}`;
              break;
            }
          }
        }

        return city;
      };
      options = options && options.length ? options.map((item) => { return getCityInCapAndSmall(item); }) : [];
    }

    // let urlParams = urlParam === 'pub_listingTimezone' ? {} : { [urlParam]: options.join(',') };
    let urlParams = { [urlParam]: options.join(',') };
    const queryParams =
      options && options.length > 0
        ? {
          ...urlQueryParams,
          ...urlParams,
          ...params
        }
        : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString('LongTermRentalSearch', routeConfiguration(), {}, queryParams)
    );
  };

  const _handleClearFilter = () => {
    history.push(createResourceLocatorString('LongTermRentalSearch', routeConfiguration(), {}, {}));
    closeSearchModal();
  };

  const handleSelectOption = (urlParam, option) => {
    const queryParams = option
      ? { ...urlQueryParams, [urlParam]: option }
      : omit(urlQueryParams, urlParam);

    if (urlParam === 'pub_delivery') {
      const userLocation = get(
        currentUser,
        'attributes.profile.protectedData.location.selectedPlace.origin'
      );
      const newBounds = userLocation && locationRadiusBounds(userLocation, LIMIT_DISTANCE);
      newBounds && (queryParams.bounds = newBounds);
    }

    history.push(
      createResourceLocatorString('LongTermRentalSearch', routeConfiguration(), {}, queryParams)
    );
  };

  const handleToggleSwitch = (urlParam, choice) => {
    const { urlQueryParams, history, currentUser } = props;
    // this.setState(prevState => {
    //   const prevQueryParams = prevState.currentQueryParams;
    //   const mergedQueryParams = { ...urlQueryParams, ...prevQueryParams };

    //   // query parameters after selecting options
    //   // if no option is passed, clear the selection from state.currentQueryParams
    //   const searchStep = window.innerWidth < MAX_MOBILE_SCREEN_WIDTH ? 'searchedResults' : null;
    //   const currentQueryParams = choice
    //     ? { ...mergedQueryParams, [urlParam]: choice, searchStep }
    //     : { ...mergedQueryParams, [urlParam]: null };

    //   history.push(
    //     createResourceLocatorString(
    //       'LongTermRentalSearch',
    //       routeConfiguration(),
    //       {},
    //       currentQueryParams
    //     )
    //   );
    //   return { currentQueryParams };
    // });
  };

  const canDriveToMalaysiaLabel = intl.formatMessage({
    id: 'SearchFilters.canDriveToMalaysiaLabel',
  });

  const initialCanDriveToMalaysiaFilter = canDriveToMalaysiaFilter
    ? initialValueToggleSwitch(canDriveToMalaysiaFilter.paramName)
    : null;

  const canDriveToMalaysiaFilterElement = canDriveToMalaysiaFilter ? (
    <ToggleSwitchFilter
      id={'SearchFilters.canDriveToMalaysiaFilter2'}
      name="canDriveToMalaysia"
      urlParam={canDriveToMalaysiaFilter.paramName}
      label={canDriveToMalaysiaLabel}
      onSubmit={handleToggleSwitch}
      liveEdit
      useLiveEdit={true}
      options={canDriveToMalaysiaFilter.options}
      initialValues={initialCanDriveToMalaysiaFilter}
      clearButtonClass={css.clearbutton}
      className={css.filterElement}
      intl={intl}
      rootClassName={css.rootClassName}
      plainClassName={css.plainClassName}
      labelClassName={classNames(css.labelClassName, css.labelClassNameWithLimit)}
      customSliderClassName={css.customSlider}
      toggleSwitchClassName={css.toggleSwitch}
    />
  ) : null;

  const categoryLabel = intl.formatMessage({
    id: 'SearchFiltersMobile.categoryLabel',
  });
  const cityLabel = intl.formatMessage({
    id: 'SearchFilters.cityLabel',
  });

  const initialCategory = categoryFilter ? initialValues(urlQueryParams, categoryFilter.paramName) : null;
  const initialCity = cityFilter ? initialValues(urlQueryParams, cityFilter.paramName) : null;
  
  const categoryFilterElement = categoryFilter ? (
    <SelectMultipleFilter
      id="SearchFiltersMobile.categoryFilter"
      name="category"
      urlParam={categoryFilter.paramName}
      label={categoryLabel}
      onSubmit={handleSelectOptions}
      liveEdit
      options={categoryFilter.options}
      initialValues={initialCategory}
      intl={intl}
      className={css.filterCategoryElement}
      labelClassName={css.labelClassName}
      rootClassName={css.rootClassName}
      clearButtonClass={css.clearbutton}
    />
  ) : null;

  const cityFilterElement = cityFilter ? (
    <SelectMultipleFilter
      id="SearchFiltersMobile.cityFilter"
      name="city"
      urlParam={cityFilter.paramName}
      label={cityLabel}
      onSubmit={handleSelectOptions}
      liveEdit
      options={cityFilter.options}
      initialValues={initialCity}
      intl={intl}
      className={css.filterCategoryElement}
      labelClassName={css.labelClassName}
      rootClassName={css.rootClassName}
      clearButtonClass={css.clearbutton}
    />
  ) : null;

  const brandNamesLabel = intl.formatMessage({
    id: 'SearchFilters.brandNamesLabel',
  });

  const initialBrandNames = brandNamesFilter ? initialValues(urlQueryParams, brandNamesFilter.paramName) : null;

  const brandNamesFilterElement = brandNamesFilter ? (
    <SelectMultipleFilter
      id={'SearchFilters.brandNamesFilter'}
      name="brandNames"
      urlParam={brandNamesFilter.paramName}
      label={brandNamesLabel}
      onSubmit={handleSelectOptions}
      liveEdit
      options={brandNamesFilter.options}
      initialValues={initialBrandNames}
      clearButtonClass={css.clearbutton}
      intl={intl}
      twoColums={true}
      className={css.filterCategoryElement}
      labelClassName={css.labelClassName}
      rootClassName={css.rootClassName}
    />
  ) : null;

  const typeOfFuelLabel = intl.formatMessage({
    id: 'SearchFilters.typeOfFuelLabel',
  });

  const initialTypeOfFuel = typeOfFuelFilter ? initialValues(urlQueryParams, typeOfFuelFilter.paramName) : null;
  const typeOfFuelFilterElement = typeOfFuelFilter ? (
    <SelectMultipleFilter
      id={'SearchFilters.typeOfFuelFilter2'}
      name="typeOfFuel"
      urlParam={typeOfFuelFilter.paramName}
      label={typeOfFuelLabel}
      onSubmit={handleSelectOptions}
      liveEdit
      options={typeOfFuelFilter.options}
      initialValues={initialTypeOfFuel}
      intl={intl}
      clearButtonClass={css.clearbutton}
      className={css.filterCategoryElement}
      labelClassName={css.labelClassName}
      rootClassName={css.rootClassName}
    />
  ) : null;

  const initialPHV = initialValue(isPHVFilter.paramName);
  const phvFilterLabel = intl.formatMessage({
    id: 'SearchFilters.phvFilterLabel',
  });
  const phvFilterElement = isPHVFilter ? (
    <SelectSingleFilter
      id={'SearchFilters.phvFilterElement'}
      name="phvFilterElement"
      urlParam={isPHVFilter.paramName}
      label={phvFilterLabel}
      onSelect={handleSelectOption}
      options={isPHVFilter.options}
      initialValue={initialPHV}
      clearButtonClass={css.clearbutton}
      className={css.filterCategoryElement}
      labelClassName={css.labelClassName}
      rootClassName={css.rootClassName}
    />
  ) : null;

  const initialFlexPlusPriceRangeValue = (queryParams, paramName) => {
    const price = queryParams[paramName];
    const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];
  
    return !!price && valuesFromParams.length === 2
      ? [price] : [];
  };

  const initialFlexPlusPriceRange = initialFlexPlusPriceRangeValue(urlQueryParams, flexPlusPriceFilter.paramName);


  const priceFilterElement = brandNamesFilter ? (
    <SelectRadioFilter
    id={'flexPlusPrice.price'}
    name="flexPlusPrice"
    urlParam={flexPlusPriceFilter.paramName}
    label="Price"
    onSubmit={handleSelectOption}
    liveEdit
    options={flexPlusPriceRange}
    initialValues={initialFlexPlusPriceRange}
    clearButtonClass={css.clearbutton}
    threeColumns={true}
    // className={`${${css.fullHeight}`}
    contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    rootClassName={css.rootClassName}
    labelClassName={css.labelClassName}
    className={`${css.filterCategoryElement} ${css.filterCheckbox}` }
    intl={intl}
    // twoColumns={true}
  />
  ) : null;

  const initialAgeOfCar = initialValue(isAgeOfCar.paramName);
  const ageOfCarilterLabel = 'Younger than 5 years';
  const ageOfCarFilterElement = isAgeOfCar ? (
    <SelectSingleFilter
      id={'SearchFilters.phvFilterElement'}
      name="phvFilterElement"
      urlParam={isAgeOfCar.paramName}
      label={ageOfCarilterLabel}
      onSelect={handleSelectOption}
      options={isAgeOfCar.options}
      initialValue={initialAgeOfCar}
      clearButtonClass={css.clearbutton}
      className={css.filterCategoryElement}
      labelClassName={css.labelClassName}
      rootClassName={css.rootClassName}
    />
  ) : null;

  // prettier-ignore
  return (
        <div className={classes}>
            <div className={css.rentalCard}>
                <FinalForm
                    {...props}
                    onSubmit={onSubmit}
                    mutators={{ ...arrayMutators, setFieldTouched }}
                    render={formRenderProps => {
                        const {
                            handleSubmit,
                        } = formRenderProps;
                        return (
                            <Form className={classes} onSubmit={handleSubmit}>
                                <h3 className={css.head}>Filter</h3>
                                <div className={css.mobileFilterWrapper}>
                                  {priceFilterElement}
                                </div>
                                <div className={css.border}></div>
                                <div className={css.mobileFilterWrapper}>
                                  {categoryFilterElement}
                                </div>

                                <div className={css.border}></div>
                                <div className={css.mobileFilterWrapper}>
                                    {cityFilterElement}
                                </div>
                                <div className={css.border}></div>
                                <div className={css.mobileFilterWrapper}>
                                  {brandNamesFilterElement}
                                </div>
                                <div className={css.border}></div>
                                <div className={css.mobileFilterWrapper}>
                                  {typeOfFuelFilterElement}
                                </div>
                                <div className={css.border}></div>
                                {/* <div className={css.mobileFilterWrapper}>
                                  {phvFilterElement}
                                </div> */}
                                {/* <div className={css.border}></div> */}
                                {/* <div className={css.mobileFilterWrapper}>
                                  {ageOfCarFilterElement}
                                </div> */}
                            </Form>
                        )
                    }}
                />
            </div>
            <div className={css.fixedBottomBtn}>
                <div className={css.monthWisePrice} onClick={_handleClearFilter}>
                    <a className={css.link}>Clear</a>
                </div>
                <Button rootClassName={css.button} onClick={() => handlePrice()}>
                    <FormattedMessage id="FilterModal.apply" />
                </Button>
            </div>
        </div>
    );
};

LongTermSearchFilterModal.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

LongTermSearchFilterModal.propTypes = {
  rootClassName: string,
  className: string,
};

export default memo(LongTermSearchFilterModal);
