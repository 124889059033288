import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { getListingDoneTrip, getUser } from '../../util/intercomHelper';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  loading: false,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      console.log("SET_INITIAL_STATE:,,,,");
      return { ...initialState };
    case SHOW_USER_REQUEST:
      console.log("SHOW_USER_REQUEST:,,,,loading: true");
      return { ...state, userShowError: null, userId: payload.userId, loading: true }; // Set loading to true and reset error
    case SHOW_USER_SUCCESS:
      console.log("SHOW_USER_SUCCESS:,,,loading: false", state)
      return { ...state, loading: false , userShowError: null }; // Set loading to false when success
    case SHOW_USER_ERROR:
      console.log("SHOW_USER_ERROR:,,,loading: false");  
      return { ...state, userShowError: payload, loading: false }; // Set loading to false on error

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],

        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryUserListings = userId => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId));
  return sdk.listings
    .query({
      author_id: userId,
      meta_live: true,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));

      const listingDoneTripPromise = listingRefs.map(({ id }) => getListingDoneTrip({ id: id.uuid }));
      return Promise.all(listingDoneTripPromise)
        .then(doneTripDatas => {
          doneTripDatas.forEach((doneTripData, index) => {
            response.data.data[index].attributes.doneTrips = doneTripData.doneTrips;
          })
          dispatch(addMarketplaceEntities(response));
          dispatch(queryListingsSuccess(listingRefs));
          return response;
        })
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

const fetchPageReviews = (userId, sdk) => page => {
  return sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
      page,
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      return reviews;
    })
}

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  return sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const { meta } = response.data;
      const { totalPages } = meta;
      const haveMoreReviews = totalPages > 1;
      if (haveMoreReviews) {
        const firstReviews = denormalisedResponseEntities(response);
        const prebuildReviewRequest = fetchPageReviews(userId, sdk);
        const requestReviews = [];
        for (let i = 2; i <= totalPages; i++) {
          requestReviews.push(prebuildReviewRequest(i));
        }
        return Promise.all(requestReviews)
          .then(reviewsArr =>
            reviewsArr.reduce((result, currentReviews) =>
              result.concat(currentReviews), [])
          )
          .then(remainingReviews => firstReviews.concat(remainingReviews));
      } else {
        return denormalisedResponseEntities(response);
      }
    })
    .then(reviews => {
      dispatch(queryReviewsSuccess(reviews))
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      return getUser({ id: response.data.data.id.uuid })
        .then(authorIntercom => {
          response.data.data.attributes.authorIntercomData = authorIntercom;
          dispatch(addMarketplaceEntities(response));
          dispatch(showUserSuccess());
          return response;
        })
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const loadData = userId => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId)),
    dispatch(queryUserReviews(userId)),
  ])
  .then(() => {
    dispatch(showUserSuccess()); 
  })
  .catch(e => {
    dispatch(showUserError(storableError(e))); 
  });
};
