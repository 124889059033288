import React, { useState } from "react";
import get from 'lodash/get';

import phoneIcon from "../../assets/newPickupAndDropoff/phone-solid.svg";
import commentIcon from "../../assets/newPickupAndDropoff/comment.png";
import ModalTripInstructions from "./Modals/ModalTripInstructions/ModalTripInstructions";
import NoAvatarIcon from '../Avatar/NoAvatarIcon';


import css from "./StartTripPanel.css";
import config from "../../config";

const getHostPhoneNumber = transaction => {
  if(transaction && transaction.customer &&
    transaction.customer.attributes  &&
    transaction.customer.attributes.profile &&
    transaction.customer.attributes.profile.publicData &&
    transaction.customer.attributes.profile.publicData.guestIdentityVerificationStatus === "confirmed" ) {
      return get(transaction, 'attributes.protectedData.hostPhoneNumber', null);
    }
  return null;
};


export default function TripInfo({ registrationNumber, fuelType, listing, transaction, isPickUp, intl}) {

  const fuelRulesConfig = config.custom.typeOfFuel;
  let fuelRecord = fuelRulesConfig.find((fuel) => {
    return fuel.key === fuelType;
  });
  const fuelValue = fuelRecord ? fuelRecord.label : fuelType;

  const brandModel = listing && listing.attributes ? listing.attributes.title : undefined;

  const provider = listing && listing.author && listing.author.attributes && listing.author.attributes.profile
    ? listing.author.attributes.profile.displayName
    : undefined;

  const keyRules = listing && listing.attributes && listing.attributes.publicData
    ? listing.attributes.publicData.keyRules
    : undefined;

  const keyFeatures = listing && listing.attributes && listing.attributes.publicData
    ? listing.attributes.publicData.keyFeatures
    : undefined;

  const profileImage = listing && listing.author && listing.author.profileImage && listing.author.profileImage.attributes && listing.author.profileImage.attributes.variants && listing.author.profileImage.attributes.variants["square-small"]
    ? listing.author.profileImage.attributes.variants["square-small"].url
    : undefined;

  const phoneNumber = getHostPhoneNumber(transaction);


  const scrollToChat =() => {
    const section = document.getElementById("trip-send-message-button-id");
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  const [isModalOpen, setIsModalOpen] = useState(false)


  return (
    <div>
      <div className={css.startTripRightWrapper}>
        <div className={css.startTripRightContainer}>
          <div className={css.instructionsWrapper}>
            <h5> { isPickUp ? 'Where to find the keys' : 'Return the key' }</h5>
            <span>
              { isPickUp ? 'Meet the host to get the keys. Contact host if there are any issues.' : 'Start the end trip process, the car will automatically lock once you end the trip.' }
            </span>
          </div>
          <div className={css.instructionsWrapper}>
            <div className={`${css.displayFlex} ${css.justifyContentBetween}`}>
              <div className={css.avatarinfoContainer}>
                <div className={css.avatarWrapperProviderDesktop}>
                  {profileImage ? (<img src={profileImage} className={css.userImage} alt="profile image"/>) :   <NoAvatarIcon />}

                </div>
                <div className={css.userNameContainer}>
                  <h5>Who to contact</h5>
                  <span>{provider}</span>
                </div>
              </div>
              <div className={css.contactWrapper}>
                <div className={`${css.contactContainer} ${css.contactBlueBg}`}>

                  <a
                    className={css.userTelephone}
                    href={`tel:${phoneNumber}`}
                  >
                  <img src={phoneIcon} alt="phone icon" />
                  </a>
                </div>

                <div className={`${css.contactContainer} ${css.contactBlueBg}`}>
                  <a onClick={scrollToChat}>
                    <img src={commentIcon} alt="comment icon" />
                  </a>

                </div>
              </div>
            </div>

          </div>
          { isPickUp ?
              <div className={`${css.instructionsWrapper} ${css.instructionLinkBtn}`}>
              <h5>Instructions from the host</h5>
              <span>
                <button onClick={() => setIsModalOpen(true)}>
                  View key instructions for starting trip
                </button>
              </span>
              </div>
            :

              <div className={css.instructionsWrapper}>
                <h5>Fuel</h5>
                <span>You Will be charged for fuel based on usage</span>
              </div>
          }
        </div>

      </div>
      { isPickUp && 
        <div className={css.startTripRightContainer}>
          <div className={css.instructionsWrapper}>
            <h5>Registration number of the car</h5>
            <span>{registrationNumber}</span>
          </div>
          <div className={css.instructionsWrapper}>
            <h5>Brand/Model</h5>
            <span>{brandModel}</span>
          </div>
          <div className={css.instructionsWrapper}>
            <h5>Fuel type</h5>
            <span>{fuelValue}</span>
          </div>
      </div>
      }
      <ModalTripInstructions
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        keyRules= {keyRules}
        brandModel= {brandModel}
        fuelValue = {fuelValue}
        registrationNumber= {registrationNumber}
        listing = {listing}
        intl={intl}
        transaction={transaction}
      />
    </div>
  );
}
