import React, { useState, useRef } from 'react';
import css from '../index.css';
import StartPickTripCss from "./StartPickTrip.css"
import { useDispatch, useSelector } from 'react-redux';
import { SetToGoContentText } from './constant'
import safety from '../../../assets/newPickupAndDropoff/settogo1.svg';
import clean from '../../../assets/newPickupAndDropoff/settogo2.svg';
import calender from '../../../assets/newPickupAndDropoff/settogo3.svg';
import IconSpinner from '../../IconSpinner/IconSpinner';
import ButtonsSection from '../../TripPanel/ButtonsSection';
import { FormattedDate, FormattedMessage } from 'react-intl';

const StartTripComponent = ({
  isPickUp,
  currentUser,
  isCustomer,
  onPushEventGTMPickUpDropOff,
  onPushPickUpAndDropOffEventGTM,
  transaction,
  currentPhotos,
  activePhotoSection,
  exteriorPhotos,
  interiorPhotos,
  odometerPhotos,
  fuelGaugePhotos,
  isShuEnabledCar,
  showPickUpSection,
  transitInProgress,
  transitionMaybe,
  onTransit,
  isProvider,
  allTripPhotos,
  isDropOff,
  isDrivelahGo,
  ownListing,
  onUploadInteriorPhotoToMetadata,
  shouldShowPickUpSection,
  shouldShowDropOffSection,
  uploadInteriorPhotoProgress,
  showDropOffSection,
  distanceFromPickUp,
  displayTime,
  dateFormatOptions,
  timeZoneMaybe,
  cleanlinessScore
}) => {

  const currentTextContent = SetToGoContentText.setToGoContent;

  return (
    <>
      <div className={css.stepContainer}>
        <div className={css.wrapper}>
          <div className={css.contentWrapper}>
            <h1>{currentTextContent.title}</h1>
            <p>{currentTextContent.message}</p>
          </div>
        </div>
        <div className={css.allSetInstructions}>
          <div className={css.textWrapper}>
            <img src={safety}/>
            <span>Drive safely and follow traffic rules</span>
          </div>
        </div>
        <div className={css.allSetInstructions}>
          <div className={css.textWrapper}>
            <img src={clean}/>
            <span>Keep the car clean and tidy</span>
          </div>
        </div>
        <div className={css.allSetInstructions}>
          <div className={css.textWrapper}>
            <img src={calender}/>
            <span className={StartPickTripCss.formatDate}>Your trip ends on<FormattedDate value={displayTime} {...dateFormatOptions} {...timeZoneMaybe}/>. Be on time to return the car</span>
          </div>
        </div>
      </div>
          { <ButtonsSection
          transaction={transaction}
          acceptButtonName={currentTextContent.buttonText}
          currentUser={currentUser}
          currentPhotos={currentPhotos}
          activePhotoSection={activePhotoSection}
          exteriorPhotos={exteriorPhotos}
          interiorPhotos={interiorPhotos}
          odometerPhotos={odometerPhotos}
          fuelGaugePhotos={fuelGaugePhotos}
          customerPickUpInteriorAction = {true}
          allTripPhotos = {allTripPhotos}
          onUploadInteriorPhotoToMetadata = {onUploadInteriorPhotoToMetadata} 
          shouldShowPickUpSection = {shouldShowPickUpSection}
          shouldShowDropOffSection = {shouldShowDropOffSection}
          uploadInteriorPhotoProgress = {uploadInteriorPhotoProgress}
          transitInProgress = {transitInProgress}
          showPickUpSection = {showPickUpSection}
          onPushPickUpAndDropOffEventGTM ={onPushPickUpAndDropOffEventGTM}
          ownListing = {ownListing}
          transitionMaybe = {transitionMaybe}
          isShuEnabledCar = {isShuEnabledCar}
          isProvider = {isProvider}
          isDrivelahGo = {isDrivelahGo}
          isDropOff = {isDropOff}
          onTransit = {onTransit}
          onPushEventGTMPickUpDropOff= {onPushEventGTMPickUpDropOff}
          isCustomer = {isCustomer}
          isPickUp = {isPickUp}
          showDropOffSection = {showDropOffSection}
          distanceFromPickUp = {distanceFromPickUp}
          isNewPickUpDropSection = {true}
          cleanlinessScore = {cleanlinessScore}
          />
         }
        {/* </div>
      </div> */}
    </>
  );
};

export default StartTripComponent;