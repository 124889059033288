/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import Decimal from 'decimal.js';
import isEqual from 'lodash/isEqual';
import { types as sdkTypes } from '../../util/sdkLoader';
import React, { memo } from 'react';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import {
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_INITIAL_PAYMENT_PROVIDER,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_UNITS,
  propTypes,
} from '../../util/types';
import css from './BookingBreakdown.css';

const { Money } = sdkTypes;

function getUnique(arr, comp) {
  // store the comparison  values in array
  const unique = arr
    .map(e => e[comp])

    // store the indexes of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the false indexes & return unique objects
    .filter(e => arr[e])
    .map(e => arr[e]);

  return unique;
}

const LineItemInitialPaymentItemsMaybe = memo(
  props => {
    const { transaction, intl, isProvider, isTripDetailsPage, onSetOpenDetails, isOpenDetails } = props;

    const initialPaymentLineItem =
      transaction &&
      transaction.attributes.lineItems.find(
        item => item.code === LINE_ITEM_INITIAL_PAYMENT && !item.reversal
      );

    const rawItems = [];

    const modifyTxTotal = transaction.attributes.lineItems.reduce((acc, curr) => {
      const noIcludedLineItems = [
        LINE_ITEM_UNITS,
        LINE_ITEM_INITIAL_PAYMENT,
        LINE_ITEM_INITIAL_PAYMENT_PROVIDER,
        LINE_ITEM_PROVIDER_COMMISSION,
      ];

      if (noIcludedLineItems.indexOf(curr.code) === -1) {
        acc += curr.lineTotal.amount;
      }

      return acc;
    }, 0);

    const modifiedTotal = new Money(modifyTxTotal, 'AUD');

    if (initialPaymentLineItem && !isTripDetailsPage) {
      rawItems.unshift({
        code: 'line-item/modified-trip-price',
        unitPrice: modifiedTotal,
        lineTotal: modifiedTotal,
        reversal: false,
      });
    }

    const items = getUnique(rawItems, 'code');

    const calculateAsNewBooking = transaction.attributes.protectedData.calculateAsNewBooking;

    if (!items || isProvider) return null;

    const { hourlyBooking } = transaction.attributes.protectedData || {};

    const unitMaybe = hourlyBooking ? 'hours' : 'days';
    return items.length > 0 ? (
      <React.Fragment>
        {items.map((item, i) => {
          const label = calculateAsNewBooking
              ? <FormattedMessage id="LineItemUnknownItemsMaybe.additionalDurationCharges" />
              : humanizeLineItemCode(item.code);
          const formattedTotal = formatMoney(intl, item.lineTotal, 1);
          const hasQuantity = !!item.quantity;
          const unitQuantity = hasQuantity
            ? item.quantity instanceof Decimal
              ? item.quantity.toString()
              : item.quantity
            : 0;
          return (
            <div key={`${i}-item.code`} className={css.lineItem}>
              <span className={css.itemLabel}>
                {label}
                {item.code === 'line-item/modified-trip-price' && (
                  <span className={css.plus} onClick={onSetOpenDetails}>
                    {isOpenDetails ? 'Hide details' : 'View details'}
                  </span>
                )}
                {hasQuantity ? (
                  <FormattedMessage
                    id={`LineItemUnknownItemsMaybe.${unitMaybe}.quantity`}
                    values={{
                      unitPrice: formatMoney(intl, item.unitPrice, 1),
                      quantity: unitQuantity,
                    }}
                  />
                ) : null}
              </span>

              <span className={css.itemValue}>{formattedTotal}</span>
            </div>
          );
        })}
      </React.Fragment>
    ) : null;
  });

LineItemInitialPaymentItemsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemInitialPaymentItemsMaybe;
