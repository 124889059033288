import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
} from '../../components';
import { connect } from 'react-redux';
import classNames from 'classnames';

import css from './SupportPage.css';
import { loginOrSignupClick } from '../TopbarContainer/Topbar.duck';

const SupportPage = props => {
  const {
    isAuthenticated,
    onLoginOrSignupClick,
  } = props;

  // prettier-ignore
  return (
    <StaticPage
      title="Support | Drive mate "
      schema={{
        '@context': 'http://schema.org',
        '@type': 'SupportPage',
        description: 'Support',
        name: 'Support page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <h1 className={css.aboutUs}>
                Support
              </h1>
              <h2 className={css.subtitle}>
                Listings
              </h2>
              <ul className={css.contentListing}>
                <li><a href="https://drive-mate.com.au/l/6416c207-1422-4cca-8471-a3b05f73c6e9" target="_blank">https://drive-mate.com.au/l/6416c207-1422-4cca-8471-a3b05f73c6e9</a></li>
                <li><a href="https://drive-mate.com.au/l/65adce6a-8e27-4f5b-b64e-5045c9f59ab6" target="_blank">https://drive-mate.com.au/l/65adce6a-8e27-4f5b-b64e-5045c9f59ab6</a></li>
                <li><a href="https://drive-mate.com.au/l/65dd2c61-9214-4c3a-9c3f-34711c3adc8b" target="_blank">https://drive-mate.com.au/l/65dd2c61-9214-4c3a-9c3f-34711c3adc8b</a></li>
                <li><a href="https://drive-mate.com.au/l/audi-q5-2021/6453854c-ff5a-4c39-b947-b2c7ed9d1c63" target="_blank">https://drive-mate.com.au/l/audi-q5-2021/6453854c-ff5a-4c39-b947-b2c7ed9d1c63</a></li>
                <li><a href="https://drive-mate.com.au/l/637ae6f6-4d04-4d3c-ad78-12c6724235a6" target="_blank">https://drive-mate.com.au/l/637ae6f6-4d04-4d3c-ad78-12c6724235a6</a></li>
                <li><a href="https://drive-mate.com.au/go/toyota-corolla-2011/63b002fc-f50b-4411-8193-b49294d600ac" target="_blank">https://drive-mate.com.au/go/toyota-corolla-2011/63b002fc-f50b-4411-8193-b49294d600ac</a></li>
                <li><a href="https://drive-mate.com.au/l/65c43a34-809c-4982-b82c-4dc823294bd7" target="_blank">https://drive-mate.com.au/l/65c43a34-809c-4982-b82c-4dc823294bd7</a></li>
                <li><a href="https://drive-mate.com.au/go/mitsubishi-mirage-2021/65727de8-6be3-4cca-b5f0-17a04025c63f/" target="_blank">https://drive-mate.com.au/go/mitsubishi-mirage-2021/65727de8-6be3-4cca-b5f0-17a04025c63f/</a></li>
                <li><a href="https://drive-mate.com.au/l/635b7e60-1b8e-490b-a50d-a129c92206eb" target="_blank">https://drive-mate.com.au/l/635b7e60-1b8e-490b-a50d-a129c92206eb</a></li>
                <li><a href="https://drive-mate.com.au/l/holden-cruze-2013/640d80f4-9eec-431f-952b-40a3dcea1007" target="_blank">https://drive-mate.com.au/l/holden-cruze-2013/640d80f4-9eec-431f-952b-40a3dcea1007</a></li>
                <li><a href="https://drive-mate.com.au/l/61b68a32-6ab8-4530-b12f-75494da0a2b8/" target="_blank">https://drive-mate.com.au/l/61b68a32-6ab8-4530-b12f-75494da0a2b8/</a></li>
                <li><a href="https://drive-mate.com.au/l/2020-honda-hr-v/637ae6f6-4d04-4d3c-ad78-12c6724235a6" target="_blank">https://drive-mate.com.au/l/2020-honda-hr-v/637ae6f6-4d04-4d3c-ad78-12c6724235a6</a></li>
                <li><a href="https://drive-mate.com.au/l/edgar-s-2016-toyota-prius/61962e9e-a815-4f89-a816-e38f693043e8" target="_blank">https://drive-mate.com.au/l/edgar-s-2016-toyota-prius/61962e9e-a815-4f89-a816-e38f693043e8</a></li>
                <li><a href="https://drive-mate.com.au/l/63da11fb-3f89-4a13-b692-97f06da34b40/" target="_blank">https://drive-mate.com.au/l/63da11fb-3f89-4a13-b692-97f06da34b40/</a></li>
                <li><a href="https://drive-mate.com.au/l/audi-q5-2019/64e9ba87-050e-4f88-8dd1-580060da8161" target="_blank">https://drive-mate.com.au/l/audi-q5-2019/64e9ba87-050e-4f88-8dd1-580060da8161</a></li>
                <li><a href="https://drive-mate.com.au/l/65a8df1d-48a6-460b-8ba6-b90890d125d7" target="_blank">https://drive-mate.com.au/l/65a8df1d-48a6-460b-8ba6-b90890d125d7</a></li>
                <li><a href="https://drive-mate.com.au/l/63dc7cba-1d13-4e94-874b-491abbe20a62" target="_blank">https://drive-mate.com.au/l/63dc7cba-1d13-4e94-874b-491abbe20a62</a></li>
                <li><a href="https://drive-mate.com.au/l/65bc42c2-57f3-4b7e-af8d-f2ec10a3c702" target="_blank">https://drive-mate.com.au/l/65bc42c2-57f3-4b7e-af8d-f2ec10a3c702</a></li>
                <li><a href="https://drive-mate.com.au/l/629c303e-92b3-4839-b19d-55a2de7ddbce/" target="_blank">https://drive-mate.com.au/l/629c303e-92b3-4839-b19d-55a2de7ddbce/</a></li>
                <li><a href="https://drive-mate.com.au/l/647e9e98-32f4-4e60-8aff-a4c4e4e73167" target="_blank">https://drive-mate.com.au/l/647e9e98-32f4-4e60-8aff-a4c4e4e73167</a></li>
                <li><a href="https://drive-mate.com.au/l/643a6c93-85c4-4d28-ae07-be5be74a0ef1" target="_blank">https://drive-mate.com.au/l/643a6c93-85c4-4d28-ae07-be5be74a0ef1</a></li>
                <li><a href="https://drive-mate.com.au/l/6205bd87-3612-4126-a6a9-f0ff1f4c6407/" target="_blank">https://drive-mate.com.au/l/6205bd87-3612-4126-a6a9-f0ff1f4c6407/</a></li>
                <li><a href="https://drive-mate.com.au/l/mitsubishi-triton-ute-2015-manual/64238722-6fa5-421e-b152-9260e4d1e331" target="_blank">https://drive-mate.com.au/l/mitsubishi-triton-ute-2015-manual/64238722-6fa5-421e-b152-9260e4d1e331</a></li>
                <li><a href="https://drive-mate.com.au/l/62b6a685-5d16-4a9c-a7af-120884a90ce2" target="_blank">https://drive-mate.com.au/l/62b6a685-5d16-4a9c-a7af-120884a90ce2</a></li>
                <li><a href="https://drive-mate.com.au/l/toyota-camry-2017/620177da-058e-49f3-ba36-3c962db628a2" target="_blank">https://drive-mate.com.au/l/toyota-camry-2017/620177da-058e-49f3-ba36-3c962db628a2</a></li>
                <li><a href="https://drive-mate.com.au/l/audi-q5-2021/64536691-42fd-40fd-8639-6b7ddcc89271" target="_blank">https://drive-mate.com.au/l/audi-q5-2021/64536691-42fd-40fd-8639-6b7ddcc89271</a></li>
                <li><a href="https://drive-mate.com.au/l/61bc6820-efd9-430d-a693-f5f7cf039505" target="_blank">https://drive-mate.com.au/l/61bc6820-efd9-430d-a693-f5f7cf039505</a></li>
                <li><a href="https://drive-mate.com.au/l/64a63b6e-0a72-4448-b2ac-0ded923a1eb5/" target="_blank">https://drive-mate.com.au/l/64a63b6e-0a72-4448-b2ac-0ded923a1eb5/</a></li>
                <li><a href="https://drive-mate.com.au/l/61c1ad42-b624-4169-8bbe-b2775cc16fa5" target="_blank">https://drive-mate.com.au/l/61c1ad42-b624-4169-8bbe-b2775cc16fa5</a></li>
                <li><a href="https://drive-mate.com.au/l/645ae60a-7ca1-4fec-a3a7-5a1a497c26f0/" target="_blank">https://drive-mate.com.au/l/645ae60a-7ca1-4fec-a3a7-5a1a497c26f0/</a></li>
                <li><a href="https://drive-mate.com.au/l/kia-carnival-2022/64535360-df19-4843-acb0-7b829cdcaa63" target="_blank">https://drive-mate.com.au/l/kia-carnival-2022/64535360-df19-4843-acb0-7b829cdcaa63</a></li>
                <li><a href="https://drive-mate.com.au/l/62710534-0277-4dea-a1e8-623d60c0c204" target="_blank">https://drive-mate.com.au/l/62710534-0277-4dea-a1e8-623d60c0c204</a></li>
                <li><a href="https://drive-mate.com.au/go/mitsubishi-outlander-2020/6555cd4e-c845-4b19-a96f-d9c514cb257b/" target="_blank">https://drive-mate.com.au/go/mitsubishi-outlander-2020/6555cd4e-c845-4b19-a96f-d9c514cb257b/</a></li>
                <li><a href="https://drive-mate.com.au/l/630d6a5b-f179-4cca-ba22-c542a0832992/" target="_blank">https://drive-mate.com.au/l/630d6a5b-f179-4cca-ba22-c542a0832992/</a></li>
                <li><a href="https://drive-mate.com.au/l/63d844b6-9f6d-4c06-8af6-8aec140627f9" target="_blank">https://drive-mate.com.au/l/63d844b6-9f6d-4c06-8af6-8aec140627f9</a></li>
                <li><a href="https://drive-mate.com.au/l/64538782-11cb-46a1-bc59-39d1f9cd44b4" target="_blank">https://drive-mate.com.au/l/64538782-11cb-46a1-bc59-39d1f9cd44b4</a></li>
                <li><a href="https://drive-mate.com.au/l/61a7236c-a184-4938-b8f0-0bf6a8434f88" target="_blank">https://drive-mate.com.au/l/61a7236c-a184-4938-b8f0-0bf6a8434f88</a></li>
                <li><a href="https://drive-mate.com.au/l/volvo-xc60/63646f34-e229-42a1-92a8-b3f851e12b71" target="_blank">https://drive-mate.com.au/l/volvo-xc60/63646f34-e229-42a1-92a8-b3f851e12b71</a></li>
                <li><a href="https://drive-mate.com.au/l/637ed802-f1ee-475a-9f44-0f80bfeadfcd" target="_blank">https://drive-mate.com.au/l/637ed802-f1ee-475a-9f44-0f80bfeadfcd</a></li>
                <li><a href="https://drive-mate.com.au/l/2005-honda-odyssey/61a7236c-a184-4938-b8f0-0bf6a8434f88" target="_blank">https://drive-mate.com.au/l/2005-honda-odyssey/61a7236c-a184-4938-b8f0-0bf6a8434f88</a></li>
                <li><a href="https://drive-mate.com.au/l/645393e6-e9e5-41b0-b25f-7b927067b653/" target="_blank">https://drive-mate.com.au/l/645393e6-e9e5-41b0-b25f-7b927067b653/</a></li>
                <li><a href="https://drive-mate.com.au/l/64d5ca7b-34cb-4c4d-8359-27454bd4d7fe" target="_blank">https://drive-mate.com.au/l/64d5ca7b-34cb-4c4d-8359-27454bd4d7fe</a></li>
                <li><a href="https://drive-mate.com.au/l/6453958d-8c90-4464-9abf-b2b0e93d5579" target="_blank">https://drive-mate.com.au/l/6453958d-8c90-4464-9abf-b2b0e93d5579</a></li>
                <li><a href="https://drive-mate.com.au/l/639d197d-2e06-4291-95b5-31dacb94ae94" target="_blank">https://drive-mate.com.au/l/639d197d-2e06-4291-95b5-31dacb94ae94</a></li>
                <li><a href="https://drive-mate.com.au/l/electric-kona-highlander---extended-range/64b74a0e-1d10-4513-95c5-beb369e2c60d" target="_blank">https://drive-mate.com.au/l/electric-kona-highlander---extended-range/64b74a0e-1d10-4513-95c5-beb369e2c60d</a></li>
                <li><a href="https://drive-mate.com.au/l/631e88c5-5c42-4a5a-9419-742074e2e98b" target="_blank">https://drive-mate.com.au/l/631e88c5-5c42-4a5a-9419-742074e2e98b</a></li>
                <li><a href="https://drive-mate.com.au/l/nissan-elgrand-2005/629c303e-92b3-4839-b19d-55a2de7ddbce" target="_blank">https://drive-mate.com.au/l/nissan-elgrand-2005/629c303e-92b3-4839-b19d-55a2de7ddbce</a></li>
                <li><a href="https://drive-mate.com.au/l/65929fd4-0633-4fe1-8d0b-b4ca147926a8" target="_blank">https://drive-mate.com.au/l/65929fd4-0633-4fe1-8d0b-b4ca147926a8</a></li>
                <li><a href="https://drive-mate.com.au/l/64ca08ae-a597-448e-b187-2193ba12871e" target="_blank">https://drive-mate.com.au/l/64ca08ae-a597-448e-b187-2193ba12871e</a></li>
                <li><a href="https://drive-mate.com.au/l/65acb9e4-3f2f-4fb6-8d05-aad91b422964" target="_blank">https://drive-mate.com.au/l/65acb9e4-3f2f-4fb6-8d05-aad91b422964</a></li>
                <li><a href="https://drive-mate.com.au/l/64b74a0e-1d10-4513-95c5-beb369e2c60d" target="_blank">https://drive-mate.com.au/l/64b74a0e-1d10-4513-95c5-beb369e2c60d</a></li>
                <li><a href="https://drive-mate.com.au/go/mitsubishi-mirage/65546690-44aa-4dac-ba08-9fc38e549dc2" target="_blank">https://drive-mate.com.au/go/mitsubishi-mirage/65546690-44aa-4dac-ba08-9fc38e549dc2</a></li>
                <li><a href="https://drive-mate.com.au/l/holden-viva/63f19bcd-0f22-4e47-84c2-002746e49bce" target="_blank">https://drive-mate.com.au/l/holden-viva/63f19bcd-0f22-4e47-84c2-002746e49bce</a></li>
                <li><a href="https://drive-mate.com.au/go/mitsubishi-mirage-2021/65727de8-6be3-4cca-b5f0-17a04025c63f" target="_blank">https://drive-mate.com.au/go/mitsubishi-mirage-2021/65727de8-6be3-4cca-b5f0-17a04025c63f</a></li>
                <li><a href="https://drive-mate.com.au/l/6476f31a-e6cb-4cbc-b6a5-b14bd3382c1d" target="_blank">https://drive-mate.com.au/l/6476f31a-e6cb-4cbc-b6a5-b14bd3382c1d</a></li>
                <li><a href="https://drive-mate.com.au/go/mitsubishi-mirage/6555c5bd-3e18-47ae-89af-cf52db90f145" target="_blank">https://drive-mate.com.au/go/mitsubishi-mirage/6555c5bd-3e18-47ae-89af-cf52db90f145</a></li>
                <li><a href="https://drive-mate.com.au/l/2007-toyota-camry/63fddf85-486f-42b3-bf9d-0ec7a443fe0b" target="_blank">https://drive-mate.com.au/l/2007-toyota-camry/63fddf85-486f-42b3-bf9d-0ec7a443fe0b</a></li>
                <li><a href="https://drive-mate.com.au/l/61d3a724-6451-4111-bfff-cc98091740ba/" target="_blank">https://drive-mate.com.au/l/61d3a724-6451-4111-bfff-cc98091740ba/</a></li>
                <li><a href="https://drive-mate.com.au/l/63993805-6005-47af-a082-6524d80d6d9f" target="_blank">https://drive-mate.com.au/l/63993805-6005-47af-a082-6524d80d6d9f</a></li>
                <li><a href="https://drive-mate.com.au/l/62b3d521-5e33-429d-a4b1-a28a5486e602" target="_blank">https://drive-mate.com.au/l/62b3d521-5e33-429d-a4b1-a28a5486e602</a></li>
                <li><a href="https://drive-mate.com.au/l/62b5d891-c5bc-466b-ba9a-7880655d9f77" target="_blank">https://drive-mate.com.au/l/62b5d891-c5bc-466b-ba9a-7880655d9f77</a></li>
                <li><a href="https://drive-mate.com.au/l/6555ccee-4cff-4727-a14e-7ea0f0011b2b" target="_blank">https://drive-mate.com.au/l/6555ccee-4cff-4727-a14e-7ea0f0011b2b</a></li>
                <li><a href="https://drive-mate.com.au/l/6339721b-cb13-41b3-83a4-b56c2b2c49c8" target="_blank">https://drive-mate.com.au/l/6339721b-cb13-41b3-83a4-b56c2b2c49c8</a></li>
                <li><a href="https://drive-mate.com.au/l/volkswagen-golf-2017/647d760e-16f8-43e4-bf7a-573e8ecd5781" target="_blank">https://drive-mate.com.au/l/volkswagen-golf-2017/647d760e-16f8-43e4-bf7a-573e8ecd5781</a></li>
                <li><a href="https://drive-mate.com.au/l/toyota-hilux---2018/634d0cb1-2d99-4c6a-b3ce-9dead0ce945b" target="_blank">https://drive-mate.com.au/l/toyota-hilux---2018/634d0cb1-2d99-4c6a-b3ce-9dead0ce945b</a></li>
                <li><a href="https://drive-mate.com.au/l/61a7236c-a184-4938-b8f0-0bf6a8434f88/" target="_blank">https://drive-mate.com.au/l/61a7236c-a184-4938-b8f0-0bf6a8434f88/</a></li>
                <li><a href="https://drive-mate.com.au/l/64e35c8f-a2b1-4bf7-89bf-58bf9e86b95e" target="_blank">https://drive-mate.com.au/l/64e35c8f-a2b1-4bf7-89bf-58bf9e86b95e</a></li>
                <li><a href="https://drive-mate.com.au/l/61d0185e-ce39-4e08-879a-985b35fe2dc8" target="_blank">https://drive-mate.com.au/l/61d0185e-ce39-4e08-879a-985b35fe2dc8</a></li>
                <li><a href="https://drive-mate.com.au/l/61f26c61-8ed5-450c-926c-c164948cc2d5/" target="_blank">https://drive-mate.com.au/l/61f26c61-8ed5-450c-926c-c164948cc2d5/</a></li>
                <li><a href="https://drive-mate.com.au/l/647d760e-16f8-43e4-bf7a-573e8ecd5781/" target="_blank">https://drive-mate.com.au/l/647d760e-16f8-43e4-bf7a-573e8ecd5781/</a></li>
                <li><a href="https://drive-mate.com.au/l/6582946c-23e6-4813-b727-2ce4df8a2d28" target="_blank">https://drive-mate.com.au/l/6582946c-23e6-4813-b727-2ce4df8a2d28</a></li>
                <li><a href="https://drive-mate.com.au/l/64a63b6e-0a72-4448-b2ac-0ded923a1eb5" target="_blank">https://drive-mate.com.au/l/64a63b6e-0a72-4448-b2ac-0ded923a1eb5</a></li>
                <li><a href="https://drive-mate.com.au/l/64a2638d-1bfa-413d-856d-ed1a8f23dc45/" target="_blank">https://drive-mate.com.au/l/64a2638d-1bfa-413d-856d-ed1a8f23dc45/</a></li>
                
              </ul>
              <h2 className={css.subtitle}>
                Blog
              </h2>
              <ul className={css.contentListing}>
                <li><a href="https://drive-mate.com.au/blog/tips-to-avoid-running-out-of-fuel-while-renting-a-car-clgw9hzhe884413tpbo193tkyy" target="_blank">https://drive-mate.com.au/blog/tips-to-avoid-running-out-of-fuel-while-renting-a-car-clgw9hzhe884413tpbo193tkyy</a></li>
                <li><a href="https://drive-mate.com.au/blog/tips-to-avoid-running-out-of-fuel-while-renting-a-car-clgw9hzhe884413tpbo193tkyy/" target="_blank">https://drive-mate.com.au/blog/tips-to-avoid-running-out-of-fuel-while-renting-a-car-clgw9hzhe884413tpbo193tkyy/</a></li>
                <li><a href="https://drive-mate.com.au/blog/hit-the-road-8-epic-destinations-for-a-road-trip-from-adelaide-clryo9esr003v2tt36a017rfr" target="_blank">https://drive-mate.com.au/blog/hit-the-road-8-epic-destinations-for-a-road-trip-from-adelaide-clryo9esr003v2tt36a017rfr</a></li>
                <li><a href="https://drive-mate.com.au/blog/10-best-waterfalls-for-your-summer-road-trip-in-new-south-wales-clsol8m1u000813pfvrpea6la" target="_blank">https://drive-mate.com.au/blog/10-best-waterfalls-for-your-summer-road-trip-in-new-south-wales-clsol8m1u000813pfvrpea6la</a></li>
                <li><a href="https://drive-mate.com.au/blog/unlock-the-culinary-secrets-of-nsw-with-drive-mate-clsx1x9w500091487tsgvljzc" target="_blank">https://drive-mate.com.au/blog/unlock-the-culinary-secrets-of-nsw-with-drive-mate-clsx1x9w500091487tsgvljzc</a></li>
                <li><a href="https://drive-mate.com.au/blog/exploring-south-australias-outback-unforgettable-adventures-await-clt62amu5002wj7j0rtc2yakg" target="_blank">https://drive-mate.com.au/blog/exploring-south-australias-outback-unforgettable-adventures-await-clt62amu5002wj7j0rtc2yakg</a></li>
                <li><a href="https://drive-mate.com.au/blog/8-epic-waterfalls-in-victoria-a-road-trip-adventure-with-drive-mate-clsc43zvr002m21s8ibe2gx81" target="_blank">https://drive-mate.com.au/blog/8-epic-waterfalls-in-victoria-a-road-trip-adventure-with-drive-mate-clsc43zvr002m21s8ibe2gx81</a></li>
                <li><a href="https://drive-mate.com.au/blog/8-epic-waterfalls-in-victoria-a-road-trip-adventure-with-drive-mate-clsc43zvr002m21s8ibe2gx81/" target="_blank">https://drive-mate.com.au/blog/8-epic-waterfalls-in-victoria-a-road-trip-adventure-with-drive-mate-clsc43zvr002m21s8ibe2gx81/</a></li>
              </ul>
             
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  return {
    isAuthenticated,
  };
};
const mapDispatchToProps = dispatch => ({
  onLoginOrSignupClick: () => dispatch(loginOrSignupClick()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportPage);
