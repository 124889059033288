import React, { useRef, useState } from 'react';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import {
  Button,
  FieldDateInput,
  FieldSelect,
  Form,
  LocationAutocompleteInputField,
} from '../../components';
import { emitter, Experiment, Variant } from '@marvelapp/react-ab-test';

import css from './TopbarSearchForm.css';
import moment from 'moment';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import config from '../../config';
import { DEFAULT_TIMEZONE, sameDay } from '../../util/dates';
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import { useDispatch } from 'react-redux';
import { initiateEventFromExpertPage, initiateExperimentEventFromListing } from '../../util/gtm/gtmHelpers';
import { EVENT_SEARCH_EXPERIMENT_A, EVENT_SEARCH_EXPERIMENT_B } from '../../util/gtm/gtmConstants';
import { getPUSHTELLSearchExperimentPage } from '../../util/emailNotify';
import get from 'lodash/get';

const generateTimeOptions = (timezone, isToday, limitTime = null) => {
  const now = moment(new Date()).tz(timezone);
  const currentMinutes = isToday ? now.hours() * 60 + now.minutes() : -1;
  const limitMoment = limitTime ? moment(limitTime, 'hh:mm a') : null;
  const currentTime = limitMoment ? limitMoment.hours() * 60 + limitMoment.minutes()+90 : -1;
  const mustBeLargerThanValue = Math.max(currentMinutes, currentTime);
  return config.custom.timeSetInput.filter(t => t.hour * 60 + t.minutes > mustBeLargerThanValue);
};

let EXPERIMENT_DATA = {};

const checkValidSearchDate = ({ pickUp, pickupTime, dropOff, dropOffTime }) => {
  if (pickUp && pickupTime && dropOff && dropOffTime) {
    const momentPickup = moment(pickupTime, 'hh:mm a');
    const momentDropOff = moment(dropOffTime, 'hh:mm a');
    const start = moment(pickUp.date)
      .startOf('date')
      .hour(momentPickup.hour())
      .minutes(momentPickup.minutes)
      .toDate();
    const end = moment(dropOff.date)
      .startOf('date')
      .hour(momentDropOff.hours())
      .minutes(momentDropOff.minutes())
      .toDate();
    if (end.getTime() > start.getTime()) return undefined;

    return 'Return date must be after Pick-up date';
  }
  return undefined;
};

const conjointDateAndTime = (date, time) => {
  const getDateOnly = moment(date).format('YYYY-MM-DD');
  const getTimeOnly = moment(time, ['h:mm A']).format('HH:mm');
  const setTime = moment(getDateOnly + ' ' + getTimeOnly)
    .tz(DEFAULT_TIMEZONE)
    .toDate();
  return setTime;
};

const TopbarSearchForm = props => {
  const {
    setSearchDateTime,
    rawInitialValues,
    isSearching,
    setIsSearching,
    location,
    ...rest
  } = props;
  const [startTimeSet, setStartTimeSet] = useState([]);
  const [endTimeSet, setEndTimeSet] = useState([]);
  const [mounted, setMounted] = useState(false);
  const [tempSearch, setTempSearch] = useState({});
  const prevValues = useRef(null);
  const [isLocationFetched, setIsLocationFetched] = useState(true);

  console.log(rawInitialValues);
  const timezone = rawInitialValues.searchLocation.selectedPlace.timezone || DEFAULT_TIMEZONE;
  const todayDate = moment(new Date()).tz(timezone);
  const generatePickUpTimeSlots = pickUpDate => {
    const _startTimeSet = generateTimeOptions(timezone, sameDay(pickUpDate, todayDate));
    setStartTimeSet(_startTimeSet);
    return _startTimeSet;
  };
  const dispatch = useDispatch();
  const generateDropOffTimeSlots = (dropOffDate, endLimit) => {
    const _endTimeSet = generateTimeOptions(timezone, sameDay(dropOffDate, todayDate), endLimit);
    setEndTimeSet(_endTimeSet);
    return _endTimeSet;
  };
  

  const CurrentLocationIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      style={{ marginRight: 10 }}
    >
      <defs></defs>
      <path
        fill="#026786"
        class="a"
        d="M13.9.111.9,6.108a1.515,1.515,0,0,0,.6,2.9H7v5.5a1.515,1.515,0,0,0,2.9.6l6-12.993a1.559,1.559,0,0,0-2-2Z"
        transform="translate(-0.007 -0.001)"
      ></path>
    </svg>
  );
  const handleExperiment = () => {
    try {
      let exp = getPUSHTELLSearchExperimentPage();
        if (!exp) {
          emitter.defineVariants(
            'Search Experiment',
            [EVENT_SEARCH_EXPERIMENT_A, EVENT_SEARCH_EXPERIMENT_B],
            [50, 50]
          );
          emitter.addPlayListener((experimentName, variantName) => {
            console.log('Experiment', experimentName, variantName);
            EXPERIMENT_DATA.experimentName = experimentName;
            EXPERIMENT_DATA.variantName = variantName;
          });
        }

        if (exp) {
          EXPERIMENT_DATA = {
            experimentName: 'Search Experiment',
            variantName: exp,
          };
        }
        if (EXPERIMENT_DATA) {
          initiateExperimentEventFromListing({
            props: props,
            event: 'dl_' + 'search_experiment' + exp,
            experimentData: EXPERIMENT_DATA,
          });
        }


    } catch (error) {
      console.log('LocalStorageError', error);
    }
  };
  return (
    <FinalForm
      {...rest}
      rawInitialValues={rawInitialValues}
      render={formRenderProps => {
        const {
          form,
          rawInitialValues,
          className,
          handleSubmit,
          values,
          valid,
          invalid,
          rootClassName,
        } = formRenderProps;
        const error = valid ? checkValidSearchDate(values) : undefined;
        if (!mounted) {
          if (rawInitialValues) {
            if (rawInitialValues.pickUp.date && rawInitialValues.dropOff.date) {
              if (!values.pickupTime && !values.dropOffTime) {
                generatePickUpTimeSlots(rawInitialValues.pickUp.date);
                generateDropOffTimeSlots(rawInitialValues.dropOff.date);
              }
            }
          }
          form.batch(() => {
            Object.entries(rawInitialValues).forEach(entry => {
              if (entry[0] === 'pickupTime') {
                form.change('pickupTime', entry[1]);
              } else if (entry[0] === 'dropOffTime') {
                form.change('dropOffTime', entry[1]);
              } else if (entry[0] === 'searchLocation') {
                if (rawInitialValues.searchLocation.mapSearch) {
                  form.change('searchLocation', {
                    search: 'Map Location',
                    selectedPlace: {},
                    mapSearch: true,
                  });
                } else {
                  form.change('searchLocation', {
                    search: entry[1].search,
                    selectedPlace: entry[1].selectedPlace,
                  });
                }
              } else {
                form.change(entry[0], entry[1]);
              }
              return form;
            });
          });
          setMounted(true);
        }
        if (
          rawInitialValues &&
          rawInitialValues.searchLocation &&
          values &&
          values.searchLocation
        ) {
          if (
            rawInitialValues.searchLocation.mapSearch !== values.searchLocation.mapSearch &&
            rawInitialValues.searchLocation.mapSearch
          ) {
            form.batch(() => {
              form.change('searchLocation', {
                search: 'Map Location',
                mapSearch: true,
              });
            });
          }
        }

        const classes = classNames(rootClassName, className);
        const buttonClassName = classNames(css.searchQuery);
        // let exp = getPUSHTELLSearchExperimentPage();
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ values: true, active: true }}
              onChange={({ values, active }) => {
                if (isEqual(prevValues.current, values)) {
                  return;
                }
                prevValues.current = values;
                if (active === 'pickUp' && values.pickUp && values.pickUp.date) {
                  let pickupTime, dropOff;

                  const _startTimeSet = generatePickUpTimeSlots(values.pickUp.date);
                  const currentPickupTimeIsInStartTimeSet = _startTimeSet.find(
                    item => item.key === values.pickupTime
                  );
                  const canSet10AM = _startTimeSet.find(t => t.key === '09:00 am');
                  const isFirstKey = canSet10AM
                    ? '09:00 am'
                    : _startTimeSet[0]
                    ? _startTimeSet[0].key
                    : null;

                  pickupTime = !values.pickupTime
                    ? currentPickupTimeIsInStartTimeSet
                      ? currentPickupTimeIsInStartTimeSet.key
                      : isFirstKey.key
                    : values.pickupTime;
                  form.batch(() => form.change('pickupTime', pickupTime));
                  // if (values.dropOff && values.dropOff.date) {
                  //   if (moment(values.pickUp.date).isSame(values.dropOff.date)) {
                  //     generateDropOffTimeSlots(values.pickUp.date);
                  //   }
                  // }

                  dropOff = moment(values.pickUp.date)
                    .add(3, 'days')
                    .toDate();

                  generateDropOffTimeSlots(dropOff);

                  const updatedTime = conjointDateAndTime(values.pickUp.date, pickupTime);
                  const updatedEndTime = conjointDateAndTime(dropOff, pickupTime);
                  setSearchDateTime(prev => ({
                    ...prev,
                    pickUp: { date: updatedTime },
                    pickupTime,
                    dropOff: { date: updatedEndTime },
                    dropOffTime: pickupTime,
                  }));
                  form.batch(() => form.change('dropOff', { date: dropOff }));
                  form.batch(() => form.change('dropOffTime', pickupTime));
                } else if (active === 'pickupTime') {
                  const updatedTime = conjointDateAndTime(values.pickUp.date, values.pickupTime);
                  form.batch(() => {
                    form.change('pickUp', {
                      date: updatedTime,
                    });
                  });
                  if (values.pickupTime) {
                    setSearchDateTime(prev => ({
                      ...prev,
                      pickUp: { date: updatedTime },
                      pickupTime: values.pickupTime,
                    }));
                  }
                  if (active === 'pickupTime' && values.pickUp && values.pickUp.date && sameDay(values.pickUp.date, values.dropOff.date)) {
                    const updatedDropOffTime = moment(values.pickupTime, 'hh:mm a')
                      .add(2, 'hours')
                      .format('hh:mm a');
                      const endLimit =
                    values.pickUp && sameDay(values.pickUp.date, values.dropOff.date)
                      ? values.pickupTime
                      : null;
                  const _endTimeSet = generateDropOffTimeSlots(values.pickUp.date, endLimit);

                    form.batch(() =>
                      form.change(
                        'dropOffTime',
                        _endTimeSet[0] 
                      )
                    );
                    form.batch(() => form.change('dropOffTime', updatedDropOffTime));
                  }
                } else if (active === 'dropOff') {
                  const endLimit =
                    values.pickUp && sameDay(values.pickUp.date, values.dropOff.date)
                      ? values.pickupTime
                      : null;
                  const _endTimeSet = generateDropOffTimeSlots(values.pickUp.date, endLimit);
                  const canSet10AMDropOff = _endTimeSet.find(t => t.key === '09:00 am');
                  const isSetToDefault = canSet10AMDropOff
                    ? '09:00 am'
                    : _endTimeSet[0]
                    ? _endTimeSet[0].key
                    : null;
                  form.batch(() =>
                    form.change(
                      'dropOffTime',
                      canSet10AMDropOff ? '09:00 am' : _endTimeSet[0] ? _endTimeSet[0].key : null
                    )
                  );
                  const defaultTime = _endTimeSet[0] ? _endTimeSet[0].key : null
                  // const canSet10AMDropOff = _endTimeSet.find(t => t.key === '09:00 am');
                  // const isSetToDefault = canSet10AMDropOff
                  //   ? '09:00 am'
                  //   : _endTimeSet[0]
                  //   ? _endTimeSet[0].key
                  //   : null;
                  // form.batch(() =>
                  //   form.change(
                  //     'dropOffTime',
                  //     canSet10AMDropOff ? '09:00 am' : _endTimeSet[0] ? _endTimeSet[0].key : null
                  //   )
                  // );
                  setSearchDateTime(prev => ({
                    ...prev,
                    dropOff: { date: values.dropOff.date },
                    dropOffTime: defaultTime,
                  }));
                } else if (active === 'dropOffTime') {
                  const updatedTime = conjointDateAndTime(values.dropOff.date, values.dropOffTime);
                  form.batch(() => {
                    form.change('dropOff', {
                      date: updatedTime,
                    });
                  });
                  if (values.dropOffTime) {
                    setSearchDateTime(prev => ({
                      ...prev,
                      dropOff: { date: updatedTime },
                      dropOffTime: values.dropOffTime,
                    }));
                  }
                }
              }}
            />
         


            {/* {!exp ? 
               <Experiment name="Search Experiment">
                  <Variant name={EVENT_SEARCH_EXPERIMENT_A}>
                   
                  </Variant>
                  <Variant name={EVENT_SEARCH_EXPERIMENT_B}>
                   
                  </Variant>
              </Experiment>: ''} */}

            <div className={classNames(css.fieldOuter, css.fieldLocationOuter)}>
              <span className={css.fieldLabel}>Where</span>
              <div style={{ width: '100%', flex: 1 }}>
                <Field
                  name="searchLocation"
                  className={css.fieldElement}
                  render={({ input, meta }) => {
                    const { onChange, onFocus, onBlur, ...restInput } = input;
                    // Merge the standard onChange function with custom behaviur. A better solution would
                    // be to use the FormSpy component from Final Form and pass this.onChange to the
                    // onChange prop but that breaks due to insufficient subscription handling.
                    // See: https://github.com/final-form/react-final-form/issues/159
                    const searchOnChange = value => {
                      console.log('I am search on change >>', value);
                      if (get(value, 'selectedPlace.place.formatted_address', '')) {
                        setIsLocationFetched(true);
                      } else {
                        setIsLocationFetched(false);
                      }
                      if (value.selectedPlace) {
                        setSearchDateTime(prev => ({
                          ...prev,
                          searchLocation: {
                            search: value && value.search,
                            selectedPlace: value && value.selectedPlace,
                            mapSearch: false,
                          },
                        }));
                      }
                      const tempValues = {
                        search: value && value.search,
                        selectedPlace: value && value.selectedPlace,
                        mapSearch: false,
                      };
                      onChange(value);
                      setTempSearch(tempValues);
                    };

                    const searchOnFocus = () => {
                      onChange({
                        search: '',
                        mapSearch:
                          rawInitialValues &&
                          rawInitialValues.searchLocation &&
                          rawInitialValues &&
                          rawInitialValues.searchLocation.mapSearch,
                        selectedPlace: {},
                        predictions: [],
                      });
                    };

                    const searchOnBlur = () => {
                      onChange(tempSearch);
                    };

                    const searchInput = {
                      ...restInput,
                      onChange: searchOnChange,
                      onFocus: searchOnFocus,
                      onBlur: searchOnBlur,
                    };
                    return (
                      
                      <LocationAutocompleteInputField
                        name="searchLocation"
                        className={css.locationInputRoot}
                        iconClassName={css.locationInputIcon}
                        inputClassName={css.locationInputInput}
                        predictionsClassName={css.locationInputPredictions}
                        predictionsAttributionClassName={css.locationInputPredictionAttribute}
                        suggestionsClass={css.searchLabels}
                        placeholder="Nearby"
                        input={searchInput}
                        customCurrentIcon={<CurrentLocationIcon />}
                        // focusInput={handleExperiment}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div className={css.fieldOuter}>
              <span className={css.fieldLabel}>From</span>
              <div className={css.field}>
                <FieldDateInput
                  useMobileMargins={false}
                  className={classNames(css.formInput, css.dateInput)}
                  name="pickUp"
                  id="pickUp"
                  displayFormat={'D MMM'}
                  placeholderText={'Select'}
                />
                <FieldSelect
                  useMobileMargins={false}
                  className={classNames(css.formInput)}
                  name="pickupTime"
                  id="pickupTime"
                >
                  <option selected defaultValue>
                    {'Select'}
                  </option>
                  {startTimeSet &&
                    startTimeSet.length > 0 &&
                    startTimeSet.map(op => (
                      <option value={op.key} key={`pick_up_${op.key}`}>
                        {op.label}
                      </option>
                    ))}
                </FieldSelect>
              </div>
            </div>
            <div className={css.fieldOuter}>
              <span className={css.fieldLabel}>To</span>
              <div className={css.field}>
                <FieldDateInput
                  useMobileMargins={false}
                  className={classNames(css.formInput, css.dateInput)}
                  name="dropOff"
                  id="dropOff"
                  displayFormat={'D MMM'}
                  placeholderText={'Select'}
                  isOutsideRange={day => {
                    const endOfRange = config.dayCountAvailableForBooking - 1;
                    return (
                      !isInclusivelyAfterDay(
                        day,
                        moment(values && values.pickUp && values.pickUp.date)
                      ) || !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
                    );
                  }}
                />
                <FieldSelect
                  disabled={!values.dropOff}
                  useMobileMargins={false}
                  className={classNames(css.formInput)}
                  name="dropOffTime"
                  id="dropOffTime"
                >
                  <option selected defaultValue>
                    {'Select'}
                  </option>
                  {endTimeSet &&
                    endTimeSet.length > 0 &&
                    endTimeSet.map(op => (
                      <option value={op.key} key={`drop_op_${op.key}`}>
                        {op.label}
                      </option>
                    ))}
                </FieldSelect>
              </div>
            </div>
            <div className={css.buttonWrapper}>
              <Button className={buttonClassName} type="submit" disabled={error || invalid || !isLocationFetched}>
                Submit
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default TopbarSearchForm;
