import React, { useState, useEffect } from 'react';
import css from './LongTermRentalListingPage.css';
import classNames from 'classnames';
import moment from 'moment';
import { formatSlotToHumanReadable } from './DatePickerWithTimeSlotsComponents/utils';
import dateCss from './DatePickerWithTimeSlots.css';
import { drivelahApiPost } from '../../util/apiHelper';
import { useHistory } from 'react-router-dom';
import {
  Button,
} from '../../components';
import { formatMoney } from '../../util/currency';
import IconSpinner from '../../components/IconSpinner/IconSpinner';

const DatePickerWithTimeSlots = ({currentListing, handleSuccessEvent, isRentFormModalOpen, intl}) => {
  console.log(currentListing, "currentListing")
  const listing  = currentListing.attributes &&  currentListing.attributes.publicData;
  const bookingDetails = JSON.parse(window.localStorage.getItem(listing.listingId) || '{}');


  const history = useHistory();

  // useEffect(() => {
  //   const url =  window.location.pathname
  //   const regex = /\/([a-f0-9\-]{36})\//;
  //   const match = url.match(regex);
  //   if (match) {
  //       const uuid = match[1];
  //       if(listing.listingId !== uuid) {
  //         const index = url.indexOf("/payment-plan");   
  //        const result = url.substring(0, index);  
  //        console.log(result, "result")     
  //         history.push(result)
  //      }
  //   }
  // }, []);

  const [slotState, setSlotState] = useState({
    pickup: {
      time: "",
      date: "",
      month: "",
      year: "",
    },
    dropoff: {
      time: "",
      date: "",
      month: "",
      year: "",
    }
  });
  const [currActiveTab, setCurrActiveTab] = useState('pickup');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [error, setError] = useState("");
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentDay, setCurrentDay] = useState(new Date().getDate());
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedMonth, setSetSelectdMonth] = useState(null);
  const [readableDate, setReadableDate] = useState(null);
  const [loading, setLoading] = useState(false);

  function formatDate(day, month) {
    const year = moment().year();

    const date = moment(`${year}-${month}-${day}`, 'YYYY-M-D');

    return date.format('Do MMMM');
  }
  
  const handleSlot =({ key, date, time, month, year })=> {
    setSlotState({
      ...slotState,
      [key]: {
        date: date || slotState[key].date,
        time: time || slotState[key].time,
        month: month || slotState[key].month,
        year: year || slotState[key].year,
      }
    })
  }

  const tabs = {
    pickup : {
      heading: "Preferred pick up date",
      subHeading: "Select your preferred pickup date for the car",
      tab: "Pickup"
    },
    dropoff: {
      heading: "Preferred drop off date",
      subHeading: "Select your preferred drop off date for the car",
      tab: "Dropoff"
    }
  }

  const handleSubmit = async () => {
    const total = bookingDetails.totalPrice 
    const totalPrice = total && formatMoney(intl, total, 1)
    try {
      // Retrieve and parse user data
      setLoading(true);
      const userData = sessionStorage.getItem('CURRENT_USER');
      const { attributes: { profile: { protectedData: { phoneNumber: phone }, firstName: firstName , lastName: lastName}, email } } = JSON.parse(userData);
      const name = `${firstName} ${lastName}`
      // Retrieve booking details
      // Prepare listing details
      const listingDetails = {
        name: currentListing.attributes && currentListing.attributes.title,
        flexId: listing.listingId,
        insurancePlan: bookingDetails.protectionValue || '',
        mileage: bookingDetails.mileageValues || '',
        additionalDriver: "No", 
        totalPrice
      };
      const pickUpTime = formatSlotToHumanReadable(slotState.pickup);
  
      // Prepare ticket details

      const ticketTitle = `${name} ${email} ${phone}`;
      const ticketDescription = ` Listing Name: ${listingDetails.name}, Listing Flex ID: ${listingDetails.flexId}, Insurance Plan Selected: ${listingDetails.insurancePlan}, Mileage Selected: ${listingDetails.mileage}, Additional Driver Selected: ${listingDetails.additionalDriver}, Total Price Shown to User: ${listingDetails.totalPrice}, PickUp date: ${pickUpTime}`;
      // Prepare payload
      const payload = {
        title: ticketTitle,
        phone,
        email,
        name,
        description: ticketDescription,
        country: 'Australia',
      };
  
      const query = 'jiraModule/create-hubspot-contact';
      const res = await drivelahApiPost(query, payload);
  
      if (res) {
        handleSuccessEvent();
        window.localStorage.removeItem(listing.listingId);
        const url = window.location.pathname;
        const result = url.split("/payment-plan")[0];
        history.push(result);
      }
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoading(false);
    }
  };
  

  
  const daysInMonth = (month, year) => new Date(year, month, 0).getDate();

  const handleDateClick = (date) => {
    if (
      (currentYear === new Date().getFullYear() &&
        currentMonth >= new Date().getMonth() + 1 &&
        date >= currentDay) ||
      currentYear > new Date().getFullYear() ||
      currentMonth > new Date().getMonth() + 1
    ) {
      setSelectedDate(date);
      setSetSelectdMonth(currentMonth);
      handleSlot({key: currActiveTab, date, month: currentMonth, year: currentYear });
      setError("");
    }
  };

  const handleTimeSlotClick = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
    handleSlot({key: currActiveTab, time: timeSlot});
    setError("");
  };


  const handlePrevMonth = () => {
    if (currentMonth > 1) {
      setCurrentMonth((prev) => prev - 1);
    } else {
      setCurrentMonth(12);
      setCurrentYear((prev) => prev - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth < 12) {
      setCurrentMonth((prev) => prev + 1);
    } else {
      setCurrentMonth(1);
      setCurrentYear((prev) => prev + 1);
    }
  };

  const handleBackButtonClick = () => {
    setCurrActiveTab("pickup");
  };
  
  useEffect(() => {
    if (slotState.pickup.date && slotState.pickup.time) {
      setIsDisabled(false)
    }
    
  }, [JSON.stringify(slotState)])
  useEffect(() => {
    if (slotState.pickup.month == currentMonth) {
      setSelectedDate(slotState.pickup.date);
    } else {
      setSelectedDate(null)
    }
  }, [currentDay, currentMonth])

  const renderCalendar = () => {
    const days = [];
    const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1).getDay();
    const daysInCurrentMonth = daysInMonth(currentMonth, currentYear);

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`}></div>);
    }

    for (let i = 1; i <= daysInCurrentMonth; i++) {
      const isPastDate =
        currentYear < new Date().getFullYear() ||
          (currentYear === new Date().getFullYear() &&
            currentMonth < new Date().getMonth() + 1) ||
          (currentYear === new Date().getFullYear() &&
            currentMonth === new Date().getMonth() + 1 &&
            i < currentDay);

        days.push(
          <div
            key={i}
            style={{
              width: "32px",
              height: "32px",
              padding: "5px",
              cursor: isPastDate ? "not-allowed" : "pointer",
              // borderRadius: '50%',
              backgroundColor: selectedDate === i ? "#00a3ad" : "transparent",
              color: isPastDate ? "#ccc" : selectedDate === i ? "white" : "black",
              transition: "background-color 0.3s, color 0.3s",
              pointerEvents: isPastDate ? "none" : "auto",
              fontFamily: "sofiapro",
              fontWeight: 500,
              fontSize: "16px",
              borderRadius: selectedDate === i && "999px",
              boxSizing: "border-box",
              marginInline: "auto",
            }}
            onClick={() => !isPastDate && handleDateClick(i)}
        >
            {i}
          </div>
        )
    }

    return (
      <div style={{    
        borderRight: "1px solid rgb(230, 230, 230)",
        minHeight: "100%"
      }}>
        <div
          style={{
            // width: "100%",
            maxWidth: "400px",
            // border: "1px solid #ddd",
            borderRadius: "8px",
            // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            // padding: "20px",
            // backgroundColor: "#fff",
            fontFamily: "sofiapro",
            fontWeight: 500,
            boxSizing: "content-box",
            padding: "20px"
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
              padding: "0 10px 0 10px"
            }}ust
          >
            <button
              onClick={handlePrevMonth}
              style={{
                cursor: "pointer",
                fontSize: "18px",
                background: "none", // Remove background
                border: "none", // Remove border
                color: "#026786", // Set arrow color
                padding: "0", // Remove padding
                margin: "0", // Remove margin
                fontWeight: "bold", // Make arrow bold
                outline: "none", // Remove outline on focus
              }}
            >
              {"<"}
            </button>
            <span
              style={{
                fontSize: "18px",
                top: "277px",
                left: "490px",
                // width: "96px",
                height: "24px",
                textAlign: "left",
                font: "normal normal normal 20px/24px Museo Sans Rounded, sans-serif",
                letterSpacing: "0px",
                color: "#0D6786",
                opacity: 1,
              }}
            >
              
                {new Date(currentYear, currentMonth - 1).toLocaleString("default", {
                  month: "long",
                })}
            </span>

            <button
              onClick={handleNextMonth}
              style={{
                cursor: "pointer",
                fontSize: "18px",
                background: "none", // Remove background
                border: "none", // Remove border
                color: "#026786", // Set arrow color
                padding: "0", // Remove padding
                margin: "0", // Remove margin
                fontWeight: "bold", // Make arrow bold
                outline: "none", // Remove outline on focus
              }}
            >
              {">"}{" "}
            </button>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(7, 1fr)",
              textAlign: "center",

              marginBottom: "20px",
              color: "#4A4A4A",

              height: "19px",

              font: "normal normal normal 16px/19px Museo Sans Rounded, sans-serif",
              letterSpacing: "0px",
              fontWeight: "300",
              opacity: 1,
              // gap: "30px",
            }}
          >
            {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
              <div key={index}>{day}</div>
            ))}
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(7, 1fr)",
              textAlign: "center",
              // gap: "5px",
            }}
          >
            {days}
          </div>
        </div>
      </div>
    );
  };

  const generateTimeSlots = () => {
    const slots = [];
    for (let hour = 8; hour <= 20; hour++) {
      const hourStr = hour < 10 ? `0${hour}` : `${hour}`;
      const amPmHour = hour <= 12 ? hour : hour - 12;
      const amPmStr = hour < 12 ? "am" : "pm";

      slots.push(`${amPmHour}:00 ${amPmStr}`);
      slots.push(`${amPmHour}:30 ${amPmStr}`);
    }
    return slots;
  };
  
  const renderTimeSlots = () => {
    const timeSlots = generateTimeSlots();

    return (
      <div 
      >
        <div
          className={dateCss.customScroll}
          style={{
            maxHeight: "220px",
            overflowY: "scroll",
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            gap: "10px",
            // padding: "5px",
            // scrollbarColor: "#0D6786 #E6E6E6",
            // scrollbarWidth: "thin",
          }}>
          {timeSlots.map((slot, index) => (
            <div
              key={index}
              style={{
                // padding: "10px 6px",
                border: "1px solid #E6E6E6",
                textAlign: "center",
                cursor: "pointer",
                borderRadius: "4px", 
                // backgroundColor: selectedTimeSlot === slot ? '#00a9e0' : '#f8f8f8',
                color: selectedTimeSlot === slot ? "white" : "black",
                transition: "background-color 0.3s, color 0.3s",
                fontFamily: "sofiapro",
                fontSize: "16px",
                width: "86px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedTimeSlot === slot && "#00a3ad"
              }}
              onClick={() => handleTimeSlotClick(slot)}
            >
              {slot}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div
      className={dateCss.selecteDateWrapper}
      style={{
        position: "relative",
        top: "0px",
        // left: "360px",
        width: "880px",
        height: "auto",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: "1px solid #E6E6E6",
        borderRadius: "8px",
        opacity: "1",
        marginInline: "auto",
        marginBlock: "30px", 
      }}
    >
      <h3
        style={{
          margin: "20px",
          textAlign: "left",
          fontFamily: "Museo Sans Rounded, sans-serif",
          fontWeight: 700,
          fontSize: "22px",
          color: "#026786",
        }}
      >
        {tabs[currActiveTab].subHeading}
      </h3>
      <div style={{ display: "flex", alignItems: "center" }}>
        {currActiveTab === 'dropoff' && (
          <button
            onClick={handleBackButtonClick}
            style={{
              background: 'none',
              border: 'none',
              color: currActiveTab === 'dropoff' && '#00a3ad',
              cursor: 'pointer',
            }}
          >
            {'Back'}
          </button>
        )}
      </div>
      <div
        className={dateCss.timeSlotWrapper}
        style={{
          display: "flex",
          justifyContent: "space-between",
          border: '1px solid #E6E6E6',
          borderLeft: 'none'
          // padding: "10px",
        }}
      >
        <div 
        className={dateCss.calenderWrapper}
        style={{
          width: "356px",
          height: "auto",
          top: "257px",
          left: "360px",
          boxSizing: "border-box"
        }}
        >
          {renderCalendar()}
        </div>
        <div
          style={{
            width: "auto",
            // height: "293px",
            top: "257px",
            left: "716px",
            backgroundColor: "#FFFFFF",
            opacity: 1,
            overflowY: "auto",
            padding: "20px",
          }}
        >
          <h4
            className={dateCss.timeSlotHeading}
            style={{
              // top: "277px",
              // left: "857px",
              // width: "342px",
              height: "24px",
              textAlign: "center",
              font: "normal normal normal 20px/24px Museo Sans Rounded, sans-serif",
              letterSpacing: "0px",
              color: "#0D6786",
              opacity: "1",
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: "0px",
              marginBottom: "20px",
            }}
          >
            {(selectedDate || slotState.pickup.date)
              ? `Time Slot (${formatDate(slotState.pickup.date || selectedDate, slotState.pickup.month || selectedMonth)})`
              : "Select a Time Slot"}
          </h4>
          <span
            style={{
              top: "321px",
              left: "736px",
              width: "472px",
              height: "219px",
              opacity: "1",
              top: "0px",
              marginTop: '0px',
  
  
            }}
          >
            {renderTimeSlots()}
            </span>
        </div>
      </div>
      {error && (
        <div style={{ color: "red", marginTop: "10px", fontSize: "14px" }}>
          {error}
        </div>
      )}
      <button
        className={`${dateCss.submitBtn} ${dateCss.pickUpSubmitbtn}`}
        style={{
          display: "block",
          width: "300px",
          height: "60px",
          marginTop: "20px",
          marginLeft: "20px",
          marginBottom: '20px',
          backgroundColor: isDisabled ? "#CCCCCC" : "#00A3AD",
          borderRadius: "4px",
          color: isDisabled ? "#666666" : "#FFFFFF",
          border: "none",
          cursor: isDisabled ? "not-allowed" : "pointer",
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "24px",
          opacity: isDisabled ? 0.6 : 1,
          font: "normal normal normal 20px/24px Museo Sans Rounded 700"
        }}
        onClick={isDisabled ? null : handleSubmit}
        disabled={isDisabled}
      >
        {loading ? "" : "Submit"}
        <div>
          {loading && (
            <div className={css.uploadLoading}>
              <IconSpinner />
            </div>
          )}
        </div>
      </button>

      {!isRentFormModalOpen && (
            <div className={`${css.fixedBottomBtn} ${css.footerSubmitButton}`}>
              <div className={css.monthWisePrice}>
                <span className={css.monthlyPrice}>
                  {bookingDetails.totalPrice && formatMoney(intl, bookingDetails.totalPrice, 1) || 0}
                  <span>/week</span>
                </span>
              </div>
              <Button rootClassName={css.button} onClick={isDisabled ? null : handleSubmit} disabled={isDisabled} >
                {loading ? "" : "Submit"}
                  {loading && (
                    <div className={css.uploadLoading}>
                      <IconSpinner />
                    </div>
                  )}
                </Button>
            </div>
          )}
    </div>
  );

};

export default DatePickerWithTimeSlots;
