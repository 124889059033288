import React from 'react';
import classNames from 'classnames';
import Goget from '../../assets/tableSectionImages/goget.png';
import Turo from '../../assets/tableSectionImages/Turo.png';
import CheckIcon from '../../assets/tableSectionImages/check-circle-solid.svg';
import IconCancel from '../../assets/tableSectionImages/Icon-cancel.svg';
import LeftBg from '../../assets/tableSectionImages/tableSectionLeftBg.svg';
import RightBg from '../../assets/tableSectionImages/tableSectionRightBg.svg';
import DriveMateLogo from '../../assets/tableSectionImages/DriveMateLogo.png';
import css from './BAHostTable.css';

const BAHostTable = () => {
  const tableContent = [
    {
      tableRowTitle: 'Commission',
      isIcon: false,
      isMinAge: false,
      isDriveMate: true,
      isTuro: true,
      turoComment: null,
      turoText:'25% plus GST',
      driveMateText: '25% inc GST',
      driveMateComment: null,
      isGoget: true,
      gogetComment: null,
      gogetText:'40% inc GST',
      isCarNextDoor: true,
      carNextDoorComment: null,
      carNextText: null,
      isAvis: false,
      avisComment: null,
      avisText: null,
    },
    {
      tableRowTitle: 'Deductible for Claims',
      isIcon: false,
      isMinAge: false,
      isDriveMate: true,
      driveMateText: '$0',
      driveMateComment: null,
      isTuro: true,
      turoComment: null,
      turoText:'$500',
      isGoget: true,
      gogetComment: null,
      gogetText: '$0',
      isCarNextDoor: true,
      carNextDoorComment: null,
      carNextText: null,
      isAvis: false,
      avisComment: null,
      avisText: 110,
    },
    {
      tableRowTitle: 'Pricing Structure',
      isIcon: true,
      isMinAge: false,
      isDriveMate: true,
      driveMateText: 'Daily, weekly and bi-monthly rates',
      driveMateComment: null,
      isTuro: true,
      turoComment: null,
      turoText:'Daily, 3+ days, weekly, monthly, bi-monthly rates',
      isGoget: true,
      gogetComment: null,
      gogetText: 'Hourly, daily, 3+ days or 12+ days, plus pay per km',
      isCarNextDoor: false,
      carNextDoorComment: null,
      carNextText: null,
      isAvis: false,
      avisComment: null,
      avisText: null,
    },
    {
      tableRowTitle: 'Included Mileage',
      isIcon: true,
      isMinAge: false,
      isDriveMate: true,
      driveMateText: '150 kms/day' ,
      driveMateComment: 'Excess charged at $0.35/km',
      isTuro: true,
      turoComment: '300 kms/day',
      turoText:'Excess charged per daily rate' ,
      isGoget: true,
      gogetComment: 'Includes fuel',
      gogetText: 'Pay per km',
      isCarNextDoor: false,
      carNextDoorComment: 'Lockbox',
      carNextText: null,
      isAvis: false,
      avisComment: false,
      avisText: null,
    },
    {
      tableRowTitle: 'Car Eligibility',
      isIcon: true,
      isMinAge: false,
      isDriveMate: true,
      driveMateText: '2008 or younger. Max 200k kms or Redbook value over $8k. Max $120k value. Other tbc case-by-case',
      driveMateComment: null,
      isGoget: false,
      isTuro: true,
      turoComment: null,
      turoText:'2013 or younger. Max 200k kms. Max $200k value' ,
      isGoget: true,
      gogetComment: null,
      gogetText: '2001 or younger. No kms limit. Max $50k value',
      isCarNextDoor: true,
      carNextDoorComment: null,
      carNextText: null,
      isAvis: false,
      avisComment: false,
      avisText: null,
    },
    {
      tableRowTitle: 'Guest Minimum Age',
      isIcon: true,
      isMinAge: false,
      isDriveMate: true,
      driveMateText: '18+ years',
      driveMateComment: 'With full license',
      isGoget: true,
      isTuro: true,
      turoComment: 'With full license',
      turoText:'21+ years' ,
      gogetComment: 'With full license. 21+ in NSW & VIC',
      gogetText: '20+ years',
      isCarNextDoor: true,
      carNextDoorComment: null,
      carNextText: null,
      isAvis: false,
      avisComment: false,
      avisText: null,
    },
    {
      tableRowTitle: 'Referral for New Borrowers',
      isIcon: false,
      isMinAge: true,
      isDriveMate: true,
      driveMateText: '$50',
      driveMateComment: null,
      turoComment: null,
      isGoget: true,
      isTuro: true,
      turoText:null,
      turoIcon:<img src={IconCancel} alt="Cancel Icon" />,
      gogetComment: null,
      gogetText: '$50',
      isCarNextDoor: true,
      carNextDoorComment: null,
      carNextText: null,
      isAvis: false,
      avisComment: false,
      avisText: null,
    },
    {
        tableRowTitle: 'Handover options',
        isIcon: false,
        isMinAge: true,
        isDriveMate: true,
        driveMateText: 'Key handover, Lockbox, keyless access via app',
        turoText:'Key handover',
        driveMateComment: null,
        turoComment: null,
        isGoget: true,
        isTuro: true,
        gogetComment: null,
        gogetText: 'Key handover, lockbox, keyless access via Otoplug',
        isCarNextDoor: true,
        carNextDoorComment: null,
        carNextText: null,
        isAvis: false,
        avisComment: false,
        avisText: null,
      },
      {
        tableRowTitle: 'Keyless Access Technology',
        isIcon: false,
        isMinAge: true,
        isDriveMate: true,
        driveMateText: null,
        driveMateComment: 'Current tech being upgraded / planned for Q4 2024',
        driveMateIcon: <img src={CheckIcon} alt="Check Icon" />,
        turoComment: null,
        turoIcon: <img src={IconCancel} alt="Cancel Icon" />,
        isGoget: true,
        isTuro: true,
        gogetComment: null,
        gogetText: null,
        gogetIcon: <img src={CheckIcon} alt="Check Icon" />,
        isCarNextDoor: true,
        carNextDoorComment: null,
        carNextText: null,
        isAvis: false,
        avisComment: false,
        avisText: null,
      },
      {
        tableRowTitle: 'Maximum Liability Protection',
        isIcon: false,
        isMinAge: true,
        isDriveMate: true,
        driveMateText: '$35 Million',
        driveMateComment: null,
        turoComment: null,
        turoText: '$20 Million',
        isGoget: true,
        isTuro: true,
        gogetComment: null,
        gogetText: '$30 Million',
        isCarNextDoor: true,
        carNextDoorComment: null,
        carNextText: null,
        isAvis: false,
        avisComment: false,
        avisText: null,
      },
      {
        tableRowTitle: 'Replacement/ Loss of Use During Repair',
        isIcon: false,
        isMinAge: true,
        isDriveMate: true,
        driveMateText: '$25 per day',
        driveMateComment: 'Up to 28 days',
        turoComment:'Up to 10 days',
        turoText: '$30 per day',
        isGoget: true,
        isTuro: true,
        gogetComment: 'Up to 15 days',
        gogetText: '$25 per day',
        isCarNextDoor: true,
        carNextDoorComment: null,
        carNextText: null,
        isAvis: false,
        avisComment: false,
        avisText: null,
      },
      {
        tableRowTitle: 'Fleet Insurance',
        tableRowComment:'For trips outside bookings',
        istableRow:true,
        isIcon: false,
        isMinAge: true,
        isDriveMate: true,
        driveMateText: null,
        driveMateComment: 'Planned for Q4’24)',
        driveMateIcon: <img src={CheckIcon} alt="Check Icon" />,
        turoComment: null,
        isGoget: true,
        isTuro: true,
        turoText: null,
        turoIcon: <img src={IconCancel} alt="Cancel Icon" />,
        gogetComment:'For $100/month',
        gogetIcon: <img src={CheckIcon} alt="Check Icon" />,
        gogetText: null,
        isCarNextDoor: true,
        carNextDoorComment: null,
        carNextText: null,
        isAvis: false,
        avisComment: false,
        avisText: null,
      },
      {
        tableRowTitle: 'Damage Reporting',
        isIcon: false,
        isMinAge: true,
        isDriveMate: true,
        driveMateText: 'Either 24 hours or 7 days',
        driveMateComment: '24 hours if parked within 5 km of host’s residence. 7 days for remote handover cars beyond this radius (currently under review)',
        turoComment: null,
        isGoget: true,
        isTuro: true,
        turoText: 'Within 24 hours',
        gogetComment: null,
        gogetText: 'Within 14 days',
        isCarNextDoor: true,
        carNextDoorComment: null,
        carNextText:false,
        isAvis: false,
        avisComment: false,
        avisText: null,
      },
      {
        tableRowTitle: 'Dispute Management',
        isIcon: false,
        isMinAge: true,
        isDriveMate: true,
        driveMateText: 'Fully managed by Drive mate',
        driveMateComment: null,
        turoComment: null,
        isGoget: false,
        isTuro: true,
        turoText: 'Hosts required to create invoices in Turo',
        gogetComment:null,
        gogetText: 'Fully managed by UCS',
        isCarNextDoor: false,
        carNextDoorComment: null,
        carNextText: null,
        isAvis: false,
        avisComment: false,
        avisText: null,
      },
      {
        tableRowTitle: 'Responsibility for Trip Photos',
        isIcon: false,
        isMinAge: true,
        isDriveMate: true,
        driveMateText: 'Managed by guest',
        driveMateComment: null,
        turoComment: null,
        isGoget: true,
        isTuro: true,
        turoText: 'Managed by host',
        gogetComment: null,
        gogetText: 'Managed by guest',
        isCarNextDoor: true,
        carNextDoorComment: null,
        carNextText: null,
        isAvis: false,
        avisComment: false,
        avisText: null,
      },
      {
        tableRowTitle: 'Auto-Acceptance of Bookings',
        isIcon: false,
        isMinAge: true,
        isDriveMate: true,
        driveMateText: 'Optional',
        driveMateComment: null,
        turoComment: null,
        isGoget: true,
        isTuro: true,
        turoText: 'Compulsory',
        gogetComment: null,
        gogetText: 'Compulsory',
        isCarNextDoor: true,
        carNextDoorComment: null,
        carNextText: null,
        isAvis: false,
        avisComment: false,
        avisText: null,
      },
      {
        tableRowTitle: 'Long-term rentals',
        isIcon: false,
        isMinAge: true,
        isDriveMate: true,
        driveMateText: null,
        driveMateComment: 'Flex+ offers 2+ monthly rental option',
        driveMateIcon: <img src={CheckIcon} alt="Check Icon" />,
        turoComment: null,
        turoText: null,
        turoIcon: <img src={CheckIcon} alt="Check Icon" />,
        isGoget: true,
        isTuro: true,
        gogetComment: null,
        gogetText: null,
        gogetIcon: <img src={IconCancel} alt="Cancel Icon" />,
        isCarNextDoor: true,
        carNextDoorComment: null,
        carNextText: null,
        isAvis: false,
        avisComment: false,
        avisText: null,
      },
  ];

  const handlePRNewswireClick = () => window.open('https://www.prnewswire.com/apac/news-releases/drive-mate-secures-strategic-investment-from-comfortdelgro-301971281.html', '_blank');

  return (
    <div className={css.tableSection}>
      <div className={css.tableBackground}>
        <div
        className={classNames(css.landingContainer)}
        style={{"position": "relative"}}
        >
          <h2 className={css.tableHead}>
            Drive mate offers more value and convenience than others - it’s a no-brainer!
          </h2>
        </div>
        <div className={css.leftImg}>
          <img src={LeftBg} alt="" />
        </div>
        <div className={css.rightImg}>
          <img src={RightBg} alt="" />
        </div>
      </div>
      <div
      className={classNames(css.landingContainer)}
      style={{"position": "relative"}}
      // style={{"border": "solid 2px red", "marginTop": "15%"}}
      >
    <div className={css.tableContent}>
          <table>
            <tr>
              <th>
                <div className={css.flexRow}></div>
              </th>
              <th>
                <div className={classNames(css.flexRow, css.rowHead)}>
                  <img src={DriveMateLogo} alt="" />
                </div>
              </th>
              <th>
                <div className={classNames(css.flexRow, css.rowHead)}>
                  <img src={Turo} alt="" />
                </div>
              </th>
              <th>
                <div className={classNames(css.flexRow, css.rowHead)}>
                  <img src={Goget} alt="" />
                </div>
              </th>

            </tr>
            {tableContent &&
              tableContent.length > 0 &&
              tableContent.map((content, index) => (
                <tr key={index}>

<td className={css.rowTitle}>
  {content.tableRowTitle === 'Fleet Insurance' && content.istableRow ? (
    <div className={classNames(css.flexRow, css.headerPointers,css.rowTitle)}>
      <span className={css.isIconWrapper}>
        {content.tableRowTitle}
        <i className={css.infoIcon}>?</i>
        <div className={css.info}>{content.tableRowComment}</div>
      </span>
    </div>
  ) : (
    content.tableRowTitle
  )}
</td>

      <td>
  <div className={css.flexRow}>
    <div className={css.iconTextWrapper}>
      {content.driveMateIcon}
      <span className={css.isIconWrapper}>
      <span className={css.literalText}>{content.driveMateText}</span>
        {content.driveMateComment && (
          <span className={css.infoWrapper}>
            <i className={css.infoIcon}>?</i>
            <div className={css.info}>{content.driveMateComment}</div>
          </span>
        )}
      </span>
    </div>
  </div>
</td>



<td>
  <div className={css.flexRow}>
    <div className={css.iconTextWrapper}>
      {content.turoIcon}
      <span className={css.isIconWrapper}>
      <span className={css.literalText}>{content.turoText}</span>
        {content.turoComment && (
          <span className={css.infoWrapper}>
            <i className={css.infoIcon}>?</i>
            <div className={css.info}>{content.turoComment}</div>
          </span>
        )}
      </span>
    </div>
  </div>
</td>
<td>
  <div className={css.flexRow}>
    <div className={css.iconTextWrapper}>
      {content.gogetIcon}
      <span className={css.isIconWrapper}>
      <span className={css.literalText}>{content.gogetText}</span>
        {content.gogetComment && (
          <span className={css.infoWrapper}>
            <i className={css.infoIcon}>?</i>
            <div className={css.info}>{content.gogetComment}</div>
          </span>
        )}
      </span>
    </div>
  </div>
</td>
 </tr>
              ))}

          </table>
          <div className={css.lastUpdateText}>
            <p>*Last updated: 26/08/2024</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BAHostTable;
