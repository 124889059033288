import React, { useState, useRef, useEffect } from 'react';
import ProgressBar from '../ProgressBar/ProgressBar';
import css from '../index.css';
import { useDispatch, useSelector } from 'react-redux';
import IconSpinner from '../../IconSpinner/IconSpinner';

import { nextPickStep, previousPickStep, nextDropStep, enableProgressBar, setVerificationStatus} from '../ProgressBar/ProgressBar.duck';
import { InspectExteriorContentText } from './constant';
import UploadSection from '../UploadSection';
import img from '../../../assets/newPickupAndDropoff/info.svg'
import dangerIcon from '../../../assets/newPickupAndDropoff/dangerIcon.svg'
import IdentityVerificationComponent from "./IdentityVerification"


const InspectExteriorComponent = ({ isPickUp, currentUser, intl, isCustomer, onNoteChange, exteriorPhotos, setActivePhotoSection, setExteriorPhotos, transaction, exteriorPhotoFun}) => {
  const [isUploaded, setIsUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRemarksEditing, setIsRemarksEditing] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [currentTextContent, setCurrentTextContent] = useState(InspectExteriorContentText(isPickUp).InspectExteriorView);
  const verificationStatus = useSelector(state => state.ProgressBarReducer.verificationStatus);
  const [isExpectedLength, setIsExpectedLength] = useState(0);

  const dispatch = useDispatch();
  const uploadSectionRef = useRef(null);
  const [verificationInprogress, setVerificationInprogress] = useState(false)

  useEffect(() => {
    if (exteriorPhotos.length) {
      const updatedPhotos = exteriorPhotos.map(photo => ({
        ...photo,
        note: remarks,
      }));
      setExteriorPhotos(updatedPhotos);
    }
  }, [remarks]);

  const handleNextStep = () => {
    switch (currentTextContent.buttonText) {
      case 'Upload Photos':
        handleAddMorePhotos();
        break;
        case 'Continue to next step':
          if (verificationStatus === "pending") {
            // Handle the verification in progress case
            dispatch(enableProgressBar(false))
            setVerificationInprogress(true);
          }
          else if (isPickUp && isExpectedLength < 4) {
            return;
          } else {
            isPickUp ? dispatch(nextPickStep()) : dispatch(nextDropStep());
          }
        break;
      default:
        console.warn('Unhandled button text:', currentTextContent.buttonText);
    }
  };

  const handleAddMorePhotos = () => {
    if (uploadSectionRef.current) {
      uploadSectionRef.current.handleClickUpload();
    }
  };

  const handleRemarksClick = () => {
    setIsRemarksEditing(true);
  };

  const handleInputChange = (e) => {
    setRemarks(e.target.value);
  };

  useEffect(() => {
    isUploaded ? setCurrentTextContent(InspectExteriorContentText(isPickUp).InspectExteriorViewWithPhotos) : setCurrentTextContent(InspectExteriorContentText(isPickUp).InspectExteriorView);
  }, [isUploaded])

  useEffect(() => {
    if (verificationStatus === "reject") {
      dispatch(previousPickStep());
      dispatch(enableProgressBar(false))
    }
  }, [verificationStatus]);

  useEffect(() => {
    console.log(isExpectedLength)
  }, [isExpectedLength])

  const WarningSection = ({ isUploaded, img }) => (
    <div className={`${css.stepContainer} ${css.worningContainer} ${css.adjustButtonPadding}`}>
      <div className={css.worningSection}>
       <div className={css.icon}><img src={img} alt="Warning icon" /></div>
      <div className={css.worningContext}>
          <p><b>{!isUploaded ? 'This is a critical step' : 'Have you captured all visible damages?'}</b></p>
          <p>Take your time to inspect any damages. Tyres, windshield, roof are often tricky areas which should be captured well. Add more images as needed.</p>
        </div>
      </div>
    </div>
  );

  const DangerSection = ({ img, imgCount }) => (
    <div className={`${css.stepContainer} ${css.dangerContainer} ${css.adjustButtonPadding}`}>
      <div className={css.dangerSection}>
       <div className={css.dangerIcon}><img  src={img} alt="Danger icon" /></div>
      <div className={css.dangerContext}>
          <p><b>{`You have only added ${imgCount} photos!`}</b></p>
          <p>So few photos often lead disputes towards the end as a lot of existing damages are left uncaptured. Please note you will liable for any damage reported by host which is not documented by you during pickup.</p>
        </div>
      </div>
    </div>
  );

  const ActionButton = ({text}) => (
    <div className={css.addMoreButtonWrapper}>
      <button className={css.addMoreButton}>{text}</button>
    </div>
  );

  return (
    <>
    {!verificationInprogress ?  (
      <>
        <div className={css.stepContainer}>
          <div className={css.wrapper}>
            <div className={css.contentWrapper}>
              <h1>{currentTextContent && currentTextContent.title ? currentTextContent.title : ''}</h1>
              <p>{currentTextContent && currentTextContent.message ? currentTextContent.message : ''}</p>
            </div>
            {!isUploaded &&
              (<div className={css.imgWrapper}>
                <img
                  src={currentTextContent.img}
                  alt={currentTextContent.title}
                />
              </div>
            )}
            <div className={css.uploadSection}>
                <UploadSection
                ref={uploadSectionRef}
                intl={intl}
                currentUser={currentUser}
                isPickUp={isPickUp}
                isCustomer={isCustomer}
                onNoteChange={onNoteChange}
                setIsUploaded={setIsUploaded}
                setLoading={setLoading}
                setPhotosData ={setExteriorPhotos}
                type={'exteriorPhotos'}
                setActivePhotoSection = {setActivePhotoSection}
                setIsExpectedLength = {setIsExpectedLength}
                exteriorPhotoFun = {exteriorPhotoFun}
                id={0}
              />
              </div>
              {isUploaded &&
                <div className={css.addMoreButtonContainer}>
                  {isRemarksEditing || remarks.length ? (
                    <input
                      type="text"
                      className={css.remarksInput}
                      placeholder='Enter your remarks'
                      value={remarks}
                      onChange={handleInputChange}
                      onBlur={() => setIsRemarksEditing(false)}
                      autoFocus
                    />
                  ) : (
                    <button className={css.remarksButton} onClick={handleRemarksClick}>
                      Have any remarks? Click here
                    </button>
                  )}
              <div className={css.addMoreButtonWrapper}>
                <button className={css.addMoreButton} onClick={() => handleAddMorePhotos()}>
                  Add more photos
                </button>
              </div>
              </div>
            }
          </div>
          {isPickUp && (isExpectedLength === 0 || isExpectedLength > 7) && (
            <div className={css.warningDesktopWrapper}>
              <WarningSection isUploaded={isUploaded && !loading} img={img} />
            </div>
          )}
          {isPickUp && isExpectedLength > 0 && isExpectedLength < 8 && (
            <div className={css.warningDesktopWrapper}>
              <DangerSection isUploaded={isUploaded} img={dangerIcon} imgCount={isExpectedLength} />
            </div>
          )}
          {/* {!isPickUp && <ActionButton text={currentTextContent.otherText} />} */}
        </div>

        {/* mobile view */}
        <div className={css.footerContainer}>
          <div className={`${css.footerWrapper} ${css.stepContainer}`}>
            <div className={css.footerProgressBar}>
              <ProgressBar isPickUp={isPickUp} />
            </div>
            <button
              className={css.footerButton}
              onClick={handleNextStep}
              disabled={loading || (isExpectedLength < 4 && currentTextContent && currentTextContent.buttonText === 'Continue to next step')}
            >
              {loading ? "Uploading..." : (currentTextContent && currentTextContent.buttonText ? currentTextContent.buttonText : '')}
              <div>
                {loading && (
                  <div className={css.uploadLoading}>
                    <IconSpinner />
                  </div>
                )}
              </div>
            </button>
          </div>
        </div>
       </>
      ) : <IdentityVerificationComponent currentUser={currentUser} transaction={transaction} isVerification={verificationInprogress}/>
    }
    </>)
  ;
};

export default InspectExteriorComponent;
