import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { get } from 'lodash';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { LINE_ITEM_PROVIDER_COMMISSION, propTypes } from '../../util/types';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import BookingBreakdownNew from '../../components/BookingBreakdown/BookingBreakdownNew';
import config from '../../config';
import {
  getUserTxRole,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT_BY_ADMIN,
  txIsAwaitUpdateBookingBeforeDropOff,
  txIsAwaitUpdateBookingBeforePickUp,
} from '../../util/transaction';
import { ensureBooking, ensureUser, restoreTransaction } from '../../util/data';
import { IconSpinner, Logo, NamedLink, Page } from '../../components';

import css from '../../containers/TripDetailsPage/TripDetailsPage.css';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';
const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_TABLET_SCREEN_WIDTH = 1024;

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const TripDetailsSection = props => {
  const {
    bookingUpdateTxs,
    scrollingDisabled,
    transaction: parentTransaction,
    intl,
    transactionRole,
    viewport,
    currentUser,
    history,
    transactionUpdateBooking,
  } = props;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isUsingMastercard, setIsUsingMastercard] = useState(false);

  useEffect(() => {
    if (parentTransaction && !dataLoaded) {
      setDataLoaded(true);
    }
  }, [parentTransaction, dataLoaded]);

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isTabletLayout = viewport.width < MAX_TABLET_SCREEN_WIDTH;

  const currentListing = parentTransaction && parentTransaction.listing;
  const currentProvider = parentTransaction && ensureUser(parentTransaction.provider);
  const currentCustomer = parentTransaction && ensureUser(parentTransaction.customer);
  const listingTitle = currentListing && currentListing.attributes.title;
  const title = intl.formatMessage({ id: 'TripDetailsPage.title' }, { title: listingTitle });
  const isLoading = !dataLoaded;
  const parentCreatedAt = parentTransaction && parentTransaction.attributes.createdAt;
  const parentDateCreated = parentCreatedAt && moment(parentCreatedAt).format('Do MMM, YYYY');

  const currentUserRole =
    currentUser &&
    currentUser.id &&
    parentTransaction &&
    getUserTxRole(currentUser.id, parentTransaction);
  const isProviderRole = currentUserRole === PROVIDER;
  const isCustomerRole = currentUserRole === CUSTOMER;
  const addonstTransactions = get(parentTransaction, 'attributes.metadata.addonsTransactions');
  const tripModificationTransaction = get(
    parentTransaction,
    'attributes.protectedData.childTransaction'
  );

  const pageProps = {
    title,
    scrollingDisabled,
    className: css.root,
  };

  const topbar = (
    <div className={css.topbar}>
      <NamedLink className={css.home} name="LandingPage">
        <Logo
          className={css.logoMobile}
          title={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="mobile"
        />
        <Logo
          className={css.logoDesktop}
          alt={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="desktop"
        />
      </NamedLink>
    </div>
  );

  if (isLoading) {
    return (
      <Page {...pageProps}>
        {topbar}
        <div className={css.loading}>
          <IconSpinner />
          <div>
            <FormattedMessage id={'TripDetailsPage.pleaseWait'} />
          </div>
        </div>
      </Page>
    );
  }

  const timeZone = getDefaultTimeZoneOnBrowser(
    parentTransaction &&
      parentTransaction.attributes &&
      parentTransaction.attributes.protectedData &&
      parentTransaction.attributes.protectedData.transactionTimezone
  );
  const txBooking = parentTransaction && ensureBooking(parentTransaction.booking);

  const acceptedTransactions = bookingUpdateTxs.filter(tx => {
    return tx.attributes.transitions.find(
      item =>
        item.transition === TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT ||
        item.transition === TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT_BY_ADMIN
    );
  });
  const lastUpdateBookingTx =
    acceptedTransactions && acceptedTransactions[acceptedTransactions.length - 1];
  const payoutParentTx = parentTransaction.attributes.payoutTotal.amount || 0;

  const haveAcceptedTransactions = acceptedTransactions && acceptedTransactions.length;

  const totalTripModificationTransactions = haveAcceptedTransactions
    ? acceptedTransactions.reduce((acc, curr) => {
        if (!curr.attributes.protectedData.isTripExtension) {
          acc += curr.attributes.payoutTotal.amount;
        }
        return acc;
      }, payoutParentTx)
    : payoutParentTx;

  const parentLineItemProviderCommission = parentTransaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_COMMISSION
  );

  const providerFeeAllTransactions = haveAcceptedTransactions
    ? acceptedTransactions.reduce((acc, curr) => {
        if (!curr.attributes.protectedData.isTripExtension) {
          const lineItemProviderCommission = curr.attributes.lineItems.find(
            item => item.code === LINE_ITEM_PROVIDER_COMMISSION
          );
          acc += lineItemProviderCommission.lineTotal.amount;
        }
        return acc;
      }, parentLineItemProviderCommission.lineTotal.amount)
    : parentLineItemProviderCommission.lineTotal.amount;

  let tripModificationTransactions = parentTransaction;
  const tripExtensionTransactions = [];

  haveAcceptedTransactions &&
    acceptedTransactions.map(tx =>
      tx.attributes.protectedData.isTripExtension
        ? tripExtensionTransactions.push(tx)
        : (tripModificationTransactions = tx)
    );

  const mappedTransactions = [tripModificationTransactions, ...tripExtensionTransactions];

  const showBreakdownTopic =
    txIsAwaitUpdateBookingBeforePickUp(restoreTransaction(parentTransaction, isUsingMastercard)) ||
    txIsAwaitUpdateBookingBeforeDropOff(restoreTransaction(parentTransaction, isUsingMastercard));

  const breakdown =
    parentTransaction &&
    parentTransaction.id &&
    txBooking.id &&
    mappedTransactions.map(tx => {
      const isExtension = !!tx.attributes.protectedData.isTripExtension;
      const providerFee = tx.attributes.lineItems.find(
        item => item.code === LINE_ITEM_PROVIDER_COMMISSION
      );

      return (
        <div className={css.detailsContainerMain}>
          <h3 className={css.containerTitle}>
            <FormattedMessage
              id={'TripDetailsPage.parentTransactionTitle'}
              values={{ date: parentDateCreated }}
            />
          </h3>
          {showBreakdownTopic ? (
            <div className={css.topicContainer}>
              <FormattedMessage id="TripDetailsPage.breakdownTopicText" />
            </div>
          ) : (
            ''
          )}
          <BookingBreakdownNew
            timeZone={timeZone}
            className={css.bookingBreakdown}
            userRole={currentUserRole}
            unitType={config.bookingUnitType}
            transaction={restoreTransaction(tx, isUsingMastercard)}
            booking={txBooking}
            shouldShowMastercardPromoLineItem={isUsingMastercard}
            isTripDetailsPage={true}
            totalAllTransactions={
              isExtension ? tx.attributes.payoutTotal.amount : totalTripModificationTransactions
            }
            providerFeeAllTransactions={
              isExtension ? providerFee.lineTotal.amount : providerFeeAllTransactions
            }
          />
        </div>
      );
    });

  const childModificationTransaction =
    tripModificationTransaction &&
    tripModificationTransaction.transaction &&
    JSON.parse(tripModificationTransaction.transaction);
  const txBookingModificationTransaction =
    childModificationTransaction && ensureBooking(childModificationTransaction.booking);
  const createdAtModificationTransaction =
    childModificationTransaction && childModificationTransaction.attributes.createdAt;
  const dateCreatedModificationTransaction =
    createdAtModificationTransaction &&
    moment(createdAtModificationTransaction).format('Do MMM, YYYY');

  return (
    <div className={css.contentContainer}>
      <div className={css.contentRow}>
        {breakdown}
        {/*<UpdateBookingTxs*/}
        {/*  updateBookingTxs={bookingUpdateTxs}*/}
        {/*  timeZone={timeZone}*/}
        {/*  currentUserRole={currentUserRole}*/}
        {/*  isUsingMastercard={isUsingMastercard}*/}
        {/*/>*/}
        {tripModificationTransaction && transactionUpdateBooking ? (
          <div className={css.detailsContainerMain}>
            <h3 className={css.containerTitle}>
              <FormattedMessage
                id={'TripDetailsPage.tripModificationTitle'}
                values={{ date: dateCreatedModificationTransaction }}
              />
            </h3>
            <BookingBreakdownNew
              timeZone={timeZone}
              className={css.bookingBreakdown}
              userRole={currentUserRole}
              unitType={config.bookingUnitType}
              transaction={restoreTransaction(transactionUpdateBooking, isUsingMastercard)}
              shouldShowMastercardPromoLineItem={isUsingMastercard}
              booking={txBookingModificationTransaction}
              isModificationTransaction={true}
              isTripDetailsPage={true}
            />
          </div>
        ) : null}
        {addonstTransactions &&
        addonstTransactions.map(i => {
          const childTransaction = i.childTransaction && JSON.parse(i.childTransaction);
          const txBooking = childTransaction && ensureBooking(childTransaction.booking);
          const createdAt = childTransaction && childTransaction.attributes.createdAt;
          const dateCreated = createdAt && moment(createdAt).format('Do MMM, YYYY');
          const isDelivery = i.isDelivery;
          const isExcessReduction = i.isExcessReduction;
          const isFuelInclusion = i.isFuelInclusion;

          return childTransaction ? (
            !isDelivery && isExcessReduction && isProviderRole ? null : (
              <div className={css.detailsContainerMain}>
                <h3 className={css.containerTitle}>
                  <FormattedMessage
                    id={'TripDetailsPage.addonsTitle'}
                    values={{ date: dateCreated }}
                  />
                </h3>
                <BookingBreakdownNew
                  timeZone={timeZone}
                  className={css.bookingBreakdown}
                  userRole={currentUserRole}
                  unitType={config.bookingUnitType}
                  transaction={restoreTransaction(childTransaction, isUsingMastercard)}
                  booking={txBooking}
                  isAddons={true}
                  isTripDetailsPage={true}
                />
              </div>
            )
          ) : null;
        })}
        {isProviderRole && (
          <div className={css.detailsContainerMain} style={{
            marginTop: "30px"
          }}>
            <div style={{
              display: "flex",
              justifyContent: "space-between"
            }}>
              <div>
                Allowed mileage
              </div>
              <div>
                150 kms/day
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

TripDetailsSection.defaultProps = {
  currentUser: null,
  transaction: null,
  parentTransaction: null,
};

const { bool, func, oneOf, shape, string, arrayOf, number } = PropTypes;

TripDetailsSection.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  parentTransaction: propTypes.transaction,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default TripDetailsSection;
