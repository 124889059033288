import React, { useState } from 'react';
import classNames from 'classnames';
import imageMobile from '../../assets/drivemateSection/imageMobile.png';
import { FormattedMessage } from 'react-intl';
import { ExternalLink, Modal, NamedLink, PrimaryButton } from '../../components';
import css from './LandingPageNew.css';
import { BROWSE_BUTTON_PLAY_VIDEO_ID, EVENT_BROWSE_SAWHTD } from '../../util/gtm/gtmConstants';
import { sendGAEvent } from '../../util/googleAnalytics';
import FlexPlusSectionBanner from '../BLandingPage/FlexPlusSectionBanner';
import { LandingPageFlex } from '../index';
import imageSource from '../../util/imageSource';

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const DOMAIN_HELP_URL = process.env.REACT_APP_DOMAIN_HELP_URL;

const DriveMateSection = ({
  onManageDisableScrolling,
  onPushGTMSeeVideoEvent,
  onVideoModalOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenVideo = () => {
    onPushGTMSeeVideoEvent(EVENT_BROWSE_SAWHTD, BROWSE_BUTTON_PLAY_VIDEO_ID);
    sendGAEvent({
      eventCategory: 'Others',
      eventAction: 'See How To Video',
    });
    setIsOpen(true);
    onVideoModalOpen(true);
  };
  return (
    <div className={css.landingContainer}>
      <div className={classNames(css.landingCol)}>
        <div className={css.sectionLeftContent}>
          <img src={imageSource.landingPageImages.left} alt="Long term car rental for your holiday plans | Drive Mate" />

        </div>
        <div className={css.sectionRightContent}>
          <h3>What’s Drive mate?</h3>
          <h4>We’re all mates here. Don’t grab a ride. Get a car!</h4>
          <div className={css.paraContent}>
            <p>
            Drive mate allows guests to borrow local vehicles on our secure, all-in-one rental app.
            </p>
            <p>
            Forget rideshare or public transport. Get your own wheels sorted $30/day without the typical car rental rigmarole.
            </p>
            <p>
               Simply check out vehicles near you, sign up via the Drive mate app, then go pick up your car!
            </p>
          </div>
        </div>
      </div>

      <div className={css.whyWhirl}>
        <div className={css.mobileImage}>
          <img src={imageMobile} alt="" />
        </div>
        <div className={css.leftContent}>
          <h5>Why give Drive mate a whirl?</h5>
          <p>
            Simplify your transportation needs without owning a vehicle. Snag a more convenient solution to your car rental woes.
          </p>
          <div className={css.contentBox}>
            <div className={css.list}>
              <img src={imageSource.landingPageImages.checkCircle} alt="" />
              <p>Better options, minus the hassle</p>
            </div>
            <div className={css.list}>
              <img src={imageSource.landingPageImages.checkCircle} alt="" />
              <p>Fully insured</p>
            </div>
            <div className={css.list}>
              <img src={imageSource.landingPageImages.checkCircle} alt="" />
              <p>Always having what you need available</p>
            </div>
            <div className={css.list}>
              <img src={imageSource.landingPageImages.checkCircle} alt="" />
              <p>Ripper savings - up to 40% compared to regular rental services</p>
            </div>
          </div>
          <div className={css.video} onClick={handleOpenVideo} style={{ cursor: 'pointer' }}>
            <div className={css.profileTitle}>
              <img src={imageSource.landingPageImages.profileImg} alt="Daily short term rental cars available | Drive mate" />
              <p>Watch video to learn more</p>
            </div>
            <img src={imageSource.landingPageImages.videoIcon} alt="" />

          </div>
        </div>
      </div>
      <div className={classNames(css.landingCol, css.landingGo)}>
        <FlexPlusSectionBanner/>
      </div>

      <div className={classNames(css.landingCol, css.landingGo)}>
        <div className={css.sectionRightContent}>
          <div className={css.logo}>
            <img src={imageSource.landingPageImages.goLogo} />

          </div>
          <h6>Book, Unlock and Go!</h6>
          <div className={css.paraContent}>
            <p>
            Drive mate Go™ - an innovative industry-first, advances car sharing into smarter, safer territory!
            </p>
            <p>
            We’ve introduced remote tracking and immobilisation - telemetry data ensures Drive mate remains the safest and most convenient Australian car sharing platform
            </p>
          </div>
          <NamedLink name="LandingPageGo" className={css.learnButton}>
            <PrimaryButton type="button">
              <FormattedMessage id="LandingSectionBanner.LearnMoreMessage" />
            </PrimaryButton>
          </NamedLink>
        </div>
        <div className={css.sectionLeftContent}>
          <img src={imageSource.landingPageImages.rightImg} alt="Best car sharing. Book, Unlock and Go with Drive mate" />
        </div>
      </div>

      <div className={css.makeBankSection}>
        <div className={css.mobileImage}>
          <img src={imageSource.landingPageImages.bgMobile} alt="" />

        </div>
        <div className={css.desktopImage}>
          <img src={imageSource.landingPageImages.bankSectionImg} alt="Earn money with Drive mate car sharing Australia" />
        </div>
        <div className={css.leftContent}>
          <h3>Want to watch your car make bank? Here’s how.</h3>
          <p className={css.paraContent}>
            Car ownership is more rewarding with Drive mate. Free your vehicle from the garage and start earning extra cash today!
          </p>
          <div className={css.boxes}>
            {/* Remove the first box that has 20% higher earnings */}
            {/*
            <div className={css.box}>
              <div className={css.imageContainer}>
                <img src={imageSource.landingPageImages.higherEarning} alt="" />
              </div>
              <p>
                20% higher earnings vs other similar platforms.{' '}
                <ExternalLink
                  href={`https://help.${DOMAIN_HELP_URL}/kb/guide/en/earnings-comparison-guide-DSRhwX4UiP/Steps/827555`}
                  className={css.link}
                >
                  <FormattedMessage id="LandingSectionBanner.SeeHow" />
                </ExternalLink>
              </p>
            </div>
            */}
            
            {/* Fully Verified Guests */}
            <div className={css.box}>
              <div className={css.imageContainer}>
                <img src={imageSource.landingPageImages.guest} alt="" />

              </div>
              <p>
                Fully Verified <br /> Guests
              </p>
            </div>

            {/* Proprietary keyless access and tracking */}
            <div className={css.box}>
              <div className={css.imageContainer}>
                <img src={imageSource.landingPageImages.tracking} alt="" />
              </div>
              <p>Proprietary keyless access and tracking</p>
            </div>

            {/* Comprehensive Insurance */}
            <div className={css.box}>
              <div className={css.imageContainer}>
                <img src={imageSource.landingPageImages.insurance} alt="" />

              </div>
              <p>
                Comprehensive Insurance covering damage, theft, 3rd party loss, hail, flood & fire
              </p>
            </div>
          </div>
          <div className={css.learnButtonBox}>
            <NamedLink name="BecomeAHostPage" className={css.learnButton}>
              <PrimaryButton type="button">
                <FormattedMessage id="LandingSectionBanner.LearnButtonMessage" />
              </PrimaryButton>
            </NamedLink>
          </div>
        </div>
      </div>

      <Modal
        id="videoLandingpage"
        containerClassName={css.modalContainer}
        closeButtonClassName={css.modalCloseButton}
        contentClassName={css.contentModal}
        isOpen={isOpen}
        onClose={() => {
          const videoIframe = document.querySelector('#videoIframe');
          if (videoIframe) {
            const iframeSrc = videoIframe.src;
            videoIframe.src = iframeSrc;
          }
          setIsOpen(false);
          onVideoModalOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ModalVideo isOpen={isOpen} />
      </Modal>
    </div>
  );
};

const ModalVideo = ({ isOpen }) => {
  return (
    <div className={css.videoContentWrapper}>
      {isOpen && (
        <iframe
          id="videoIframe"
          className={css.iframeVideo}
          allowFullScreen={true}
          src="https://www.youtube.com/embed/-fg9W0MZYuo"
        ></iframe>
      )}
    </div>
  );
};

export default DriveMateSection;
