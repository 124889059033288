import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import css from '../index.css';
import feedbackCss from './Feedback.css';
import ProgressBar from '../ProgressBar/ProgressBar';
import { nextPickStep, enableProgressBar } from '../ProgressBar/ProgressBar.duck';
import { FeedbackContext, ReviewMessageChip } from './constant';
import ReviewStar from '../Components/ReviewStar';
import Chip from '../Components/Chip';
import UploadSection from "../UploadSection"
import IconSpinner from '../../IconSpinner/IconSpinner';
import disabledTick from '../../../assets/tripPage/tick-disabled.svg';
import tick from '../../../assets/tripPage/tick.svg';

const Feedback = ({
  isLimitedUser = false,
  isPickUp,
  setActivePhotoSection,
  intl,
  interiorPhotos,
  setInteriorPhotos,
  currentUser,
  isCustomer,
  setCleanlinessValue,
  activeSection,
  setActiveSection,
  isDrivelahGo
}) => {
  const [isCommentsEditing, setIsCommentsEditing] = useState(false);
  const [comments, setComments] = useState('');
  const [currentRating, setCurrentRating] = useState(0);
  const [scoreReason, setScoreReason] = useState([]);
  const dispatch = useDispatch();
  const [isUploaded, setIsUploaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const currentTextContent = FeedbackContext.FeedbackContent;
  const uploadSectionRef = useRef(null);

  const handleNextStep = () => {
    dispatch(nextPickStep());
    dispatch(enableProgressBar(false))
  };


  useEffect(() => {
    const params = {
      rating: currentRating,
      reason: scoreReason,
      comment: comments ,
     }

     setCleanlinessValue(params);

    }, [scoreReason, comments])

  const handleChipSelect = id => {
    setScoreReason(prev =>
      prev.map(item => (item.id === id ? { ...item, isSelected: !item.isSelected } : item))
    );
  };

  const handleCommentsClick = () => {
    setIsCommentsEditing(true);
  };

  const handleInputChange = e => {
    setComments(e.target.value);
  };


  useEffect(() => {
    if (currentRating) {
      setScoreReason(ReviewMessageChip[currentRating]);
      
    }
  }, [currentRating]);


  const handleAddMorePhotos = () => {
    if (uploadSectionRef.current) {
      uploadSectionRef.current.handleClickUpload();
    }
  };

  return (
    <>
       <div className={isLimitedUser ? `${feedbackCss.feedbackContainer}` : `${css.mb} ${css.stepContainer}`}>
        <div className={feedbackCss.wrapper}>
          { isLimitedUser && <div className={feedbackCss.tickContainer}>
              <img src={activeSection ? tick : disabledTick} alt="tick" className={feedbackCss.tick} />
            </div>
          }
          <div className={isLimitedUser ? '' : `${css.contentWrapper}`}>
            <h1 className={isLimitedUser ? `${feedbackCss.title}` : ''}>{currentTextContent.title}</h1>
            <p className={isLimitedUser ? `${feedbackCss.description}` : ''}>{currentTextContent.message}</p>
          </div>
        </div>
        <div className={feedbackCss.starWrapper}>
          <ReviewStar setCurrentRating={(val) => {
            setCurrentRating(val);
            if(isLimitedUser) {
              setActiveSection(true);
            }
          }} />
          {!!currentRating && (
            <>
              <p className={css.selectReasonText}>Select the reason for your score, as applicable (optional)</p>
              <Chip data={scoreReason} onChipSelect={handleChipSelect} />
            </>
          )}
        </div>
        {!!currentRating && !isLimitedUser && (
          <div className={css.addMoreButtonContainer}>
            {isCommentsEditing || comments.length   ? (
              <input
                type="text"
                className={css.remarksInput}
                value={comments}
                onChange={handleInputChange}
                onBlur={() =>  setIsCommentsEditing(false)}
                autoFocus
                placeholder="Add comments"
              />
            ) : (
              <button className={css.remarksButton} onClick={handleCommentsClick}>
                Have any comments? Click here
              </button>
            )}
          </div>
        )}
        { !isLimitedUser && <div className={css.mt}>
          <UploadSection
              ref={uploadSectionRef}
              intl={intl}
              currentUser={currentUser}
              isPickUp={isPickUp}
              isCustomer={isCustomer}
              setIsUploaded={setIsUploaded}
              setLoading={setLoading}
              setPhotosData ={setInteriorPhotos}
              type={'interiorPhotos'}
              setActivePhotoSection = {setActivePhotoSection}
              id={ isDrivelahGo && isPickUp  ? 2 : 1}

            />
        </div>}
        {!!currentRating && !isLimitedUser && (
          <div className={`${css.warningDesktopWrapper} ${css.addMoreButtonWrepper} ${css.addImgWrapper} `}>
            <button className={css.addMoreButton} onClick={handleAddMorePhotos}>Add images (optional)</button>
          </div>
        )}

      </div>

      { !isLimitedUser && (<div className={css.footerContainer}>
        <div className={`${css.footerWrapper} ${css.stepContainer}`}>
          <div className={css.footerProgressBar}>
            <ProgressBar isPickUp={isPickUp} />
          </div>
          <button disabled={loading || !currentRating} className={css.footerButton} onClick={handleNextStep}>
          {loading ? "Uploading..." : (currentTextContent && currentTextContent.buttonText ? currentTextContent.buttonText : '')}
          <div>
            {loading && (
              <div className  ={css.uploadLoading}>
                <IconSpinner />
              </div>
            )}
          </div>
          </button>
        </div>
      </div>
    )}
    </>
  );
};

export default Feedback;
